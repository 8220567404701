<template>
  <ArtworkUploadButton
    class="btn btn--primary product-configurator__upload-button"
    :title="uploadButtonText"
    :joineryHost="joineryHost"
    :disabled="!variant"
    @upload-complete="onUploadComplete($event)"
    @upload-error="handleUploadError($event)"
    @upload-initiated="initUpload"
    @upload-progress="(event) => {
      const { percentComplete } = event;
      const roundedPercentComplete = Math.round(percentComplete);
      onUploading?.(roundedPercentComplete);
    }"
  />
</template>

<script setup>
import {
  computed,
  ref,
  toRefs,
} from 'vue';
import ArtworkUploadButton from '@framebridge/toolbox/ArtworkUploadButton';

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  variant: {
    type: Object,
    required: true,
  },
  buttonText: {
    type: Object,
    required: false,
  },
  initArtworkUpload: {
    type: Function,
    required: true,
  },
  setErrorMessage: {
    type: Function,
    required: true,
  },
  onUploading: {
    type: Function,
    required: false,
  },
  onSizeClick: {
    type: Function,
    required: false,
  },
  selectFirstAvilableSize: {
    type: Function,
    required: false,
  },
  sizes: {
    type: Array,
    required: false,
  },
});

// Reactive properties
const {
  product,
  variant,
  buttonText,
  initArtworkUpload,
  onSizeClick,
  setErrorMessage,
  sizes,
} = toRefs(props);
const artwork = ref(null);
const uploadButtonText = ref(buttonText?.value || 'Upload Your Artwork');

// Computed properties
const joineryHost = computed(() => SDG?.Data?.joineryHost) || 'https://staging.framebridge.com';

// Methods
const handleUploadError = (error) => {
  console.error({ message: 'Error uploading artwork', error });
  setErrorMessage.value(error);
  uploadButtonText.value = buttonText?.value || 'Upload Your Artwork';
};
const initUpload = () => {
  uploadButtonText.value = 'Uploading...';
};
const allEqual = (arr) => arr.every((v) => v === arr[0]);
const onUploadComplete = (event) => {
  artwork.value = event;
  const { artwork: uploadArtwork } = event || null;
  uploadButtonText.value = buttonText?.value || 'Upload Your Artwork';

  if (uploadArtwork) {
    const { dimensionsInPixels } = uploadArtwork?.original || {};

    if (dimensionsInPixels?.width && dimensionsInPixels?.height) {
      const heightNum = parseInt(dimensionsInPixels.height, 10);
      const widthNum = parseInt(dimensionsInPixels.width, 10);

      // try to select first landscape size if available and no selection is made
      if (widthNum > heightNum) {
        const landscapeSize = sizes?.value.find((size) => size?.orientation === 'landscape');
        const isVariantSquare = sizes?.value.find((size) => size?.orientation === 'portrait' && size?.id === variant?.value?.id && allEqual(size?.name?.match(/\d+/g)));

        if (isVariantSquare) {
          const squareSize = sizes?.value.find((size) => size?.orientation === 'landscape' && allEqual(size?.name?.match(/\d+/g)));
          if (squareSize) {
            initArtworkUpload.value({
              product: product?.value,
              variant: squareSize?.id,
              upload: event,
            });
            onSizeClick?.value(squareSize);
            return;
          }
        }

        if (landscapeSize) {
          initArtworkUpload.value({
            product: product?.value,
            variant: landscapeSize?.id,
            upload: event,
          });
          onSizeClick?.value(landscapeSize);
          return;
        }
      } else {
        // check if selected variant is portrait
        const isVariantPortrait = sizes?.value.find((size) => size?.id === variant?.value?.id && size?.orientation === 'portrait');
        const portraitSize = sizes?.value.find((size) => size?.orientation === 'portrait');
        // framespec will auto set square images
        if (widthNum === heightNum) {
          const squareSize = sizes?.value.find((size) => size?.orientation === 'portrait' && allEqual(size?.name?.match(/\d+/g)));
          if (squareSize) {
            initArtworkUpload.value({
              product: product?.value,
              variant: squareSize?.id,
              upload: event,
            });
            onSizeClick?.value(squareSize);
            return;
          }
        }

        // Render portrait no change is needed
        if (isVariantPortrait) {
          initArtworkUpload.value({
            product: product?.value,
            variant: variant?.value?.id,
            upload: event,
          });
          return;
        }

        // variant is not portrait we select first portrait size
        if (portraitSize && !isVariantPortrait) {
          initArtworkUpload.value({
            product: product?.value,
            variant: portraitSize?.id,
            upload: event,
          });
          onSizeClick?.value(portraitSize);
          return;
        }
      }
    }
  }

  uploadButtonText.value = buttonText?.value || 'Upload Your Artwork';
};
</script>
