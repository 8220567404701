/**
* namespace
* @type {Object}
*/
SDG.Bag = SDG.Bag || {};

/**
* data
* @type {Object}
*/
SDG.Bag.data = SDG.Bag.data || {};

/**
* config
* @type {Object}s
*/
SDG.Bag.config = {
  cls: {
    active: 'is-active',
    empty: 'is-empty',
    closing: 'is-bag-closing',
    expose: 'is-bag-exposed',
    loading: 'is-loading',
    hide: 'hide',
    show: 'show-important',
    full_height: 'full-height',
  },
  dom: {
    bag: 'bag',
    bag_btn: 'bagBtn',
    bag_close: 'js-bag-close',
    bag_count: 'bagBtnCount',
    bag_empty: 'bagEmpty',
    bag_overlay: 'bagOverlay',
    checkout_button: 'js-bag-checkout-btn',
    cart_template: '.template-cart',
    content_item: 'js-bag-content-item',
    donations: 'bagDonations',
    empty_cart_hide: '.js-empty-cart-hide',
    empty_cart_show: '.js-empty-cart-show',
    hidden_id: '.js-hidden-id',
    increment_input: '.js-increment-input',
    item: '.js-bag-item',
    item_messages: '.js-bag-item-messages',
    items: 'bagItems',
    products_carousel: '.js-products-carousel',
    remove: 'js-bag-item-remove',
    subtotal: 'bagSubtotal',
    total: 'bagTotal',
  },
  icon: {
    remove: 'icon--cart-close',
    add: 'icon--plus',
    substract: 'icon--minus',
  },
  img: {
    std: '80x',
    rtn: '160x',
    std_cart: '220x',
    rtn_cart: '440x',
  },
  show_event: 'click', // click, mouseenter
  timer: {
    hover: 1500,
    slide_up: 400, // This should match the animation speed of your slideup animation.
    // Typically this is set to $normal-timer in _timers.scss file.
  },
  url: {
    add: `${SDG.Data.apiUrl}/cart/add`,
    cart: `${SDG.Data.apiUrl}/cart`,
    cart_ajax: '/cart?view=ajax',
    cart_drawer_ajax: '/cart?view=drawer-ajax',
    update: '/cart/change.js',
  },
  use: {
    count: true, // update bag count
    swatch: false, // sets whether to use a swatch in the bag item for color
    // display instead of the label. Set to false to show label instead.
  },
  exclude_selectors: '#bagBtn,#bagBtnCount,.icon,.screenreader',
};
