import Flickity from 'flickity';
import trapFocus from './trap-focus';

/**
 * Product Filters Carousel
 * @param {object} opts
 */
function productsFiltersCarouselDropdown(opts) {
  const config = {
    dom: {
      carousel: 'js-products-filters-carousel-dropdown',
      carouselCell: 'js-pi',
      category_button: 'js-category-button',
      labelText: 'js-options-trigger-text',
      dropdownHeading: 'dropdown-heading',
      dropdownItem: 'js-dropdown-list-item',
      dropdownMenu: 'js-options-dropdown-menu',
      dropdownWrapper: 'dropdownWrapper',
      optionsTrigger: 'js-options-trigger',
      optionsLabelIcon: 'options-label__icon',
      wrapper: 'js-products-filters-carousel-dropdown-wrapper',
      shopAll: 'js-shop-all',
      section: 'js-carousel-filters-section',
      section_menu: 'js-main-menu',
      section_list: 'js-dropdown-list',
    },
    cls: {
      active: 'is-active',
      fullHeight: 'full-height',
      hide: 'hide',
      hover: 'is-hovered',
      open: 'is-open',
      flex: 'products-filters-carousel--flex',
    },
  };
  const c = __.extend(config, opts);

  const flickityOpts = {
    adaptiveHeight: true,
    contain: true,
    draggable: true,
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
  };

  // global elements
  const $sections = document.body.querySelectorAll(`.${c.dom.section}`);
  const $wrappers = document.body.querySelectorAll(`.${c.dom.wrapper}`);

  const flickityCarousels = [];
  let view;

  function init() {
    if (!$wrappers?.length) return;
    addEvents();
    onWindowResize();
    __.windowResize(onWindowResize);
  }

  /**
   * Add events
   * @description Adds events
   * @returns {void}
   */
  function addEvents() {
    [...$sections].forEach((section) => {
      const $mainTrigger = section?.querySelector(`.${c.dom.optionsTrigger}`) || null;
      const $mainMenu = section?.querySelector(`.${c.dom.section_menu}`) || null;

      __.addEvent({
        id: $mainTrigger,
        event: 'click',
        fn: (e) => {
          if ($mainMenu) {
            e.preventDefault();
            toggleMenu($mainMenu, section);
          }
        },
      });
    });

    // handle click outside
    __.addEvent({
      id: document.body,
      event: 'click',
      fn: (e) => {
        const { target } = e || null;

        closeAllMenus(target);
      },
    });

    __.addEvent({
      id: document.body,
      event: 'click',
      className: c.dom.dropdownItem,
      fn: (e) => {
        const { currentTarget } = e;
        handleItemClick(currentTarget);
      },
    });
  }

  /**
   * Opens the menu
   * @param {object} menu - required
   * @param {object} parent
   * @description Opens the menu
   * @returns {void}
   */
  function openMenu(menu, parent = false) {
    if (!menu) return;

    const $list = menu?.querySelector(`.${c.dom.section_list}`) || null;
    const $icon = parent ? parent?.querySelector(`.${c.dom.optionsLabelIcon}`) : null;
    const $trigger = parent ? parent?.querySelector(`.${c.dom.optionsTrigger}`) : null;

    __.addClass(menu, c.cls.active);
    if ($icon) __.addClass($icon, c.cls.open);
    if ($trigger) {
      $trigger.setAttribute('aria-expanded', 'true');
      $trigger.setAttribute('aria-label', 'Close dropdown menu');
    }
    if ($list) {
      trapFocus($list);
      __.addEvent({
        id: $list,
        event: 'keydown',
        fn: supportKeyboardNavigation,
      });
    }
  }

  /**
   * Close the menu
   * @param {object} menu - required
   * @param {object} parent
   * @description Closes the menu
   * @returns {void}
   */
  function closeMenu(menu, parent = false) {
    if (!menu) return;

    const $list = menu?.querySelector(`.${c.dom.section_list}`) || null;
    const $icon = parent ? parent?.querySelector(`.${c.dom.optionsLabelIcon}`) : null;
    const $trigger = parent ? parent?.querySelector(`.${c.dom.optionsTrigger}`) : null;

    __.removeClass(menu, c.cls.active);
    if ($icon) __.removeClass($icon, c.cls.open);
    if ($trigger) {
      $trigger.setAttribute('aria-expanded', 'false');
      $trigger.setAttribute('aria-label', 'Open dropdown menu');
    }

    if ($list) {
      __.removeEvent({
        id: $list,
        event: 'keydown',
        fn: supportKeyboardNavigation,
      });
    }
  }

  /**
   * Toggle the menu
   * @param {object} menu - required
   * @param {object} parent
   * @description Open or Closes the menu
   * @returns {void}
   */
  function toggleMenu(menu, parent = false) {
    if (!menu) return;

    if (__.hasClass(menu, c.cls.active)) {
      closeMenu(menu, parent);
    } else {
      openMenu(menu, parent);
    }
  }

  /**
   * Close all menus
   * @param {object} target
   * @description Closes all open menus
   * @returns {void}
   */
  function closeAllMenus(target) {
    if (!target) return;

    const isButton = __.hasClass(target, c.dom.optionsTrigger);
    const $lists = document.body.querySelectorAll(`.${c.dom.section_list}`);

    if (!isButton) {
      [...$lists].forEach(($list) => {
        const $mainSection = __.parents($list, `.${c.dom.section}`)[0] || null;
        if (!$mainSection) return;

        const $menu = $mainSection?.querySelector(`.${c.dom.section_menu}`) || null;

        if (!$list || !$list.contains(target)) {
          if ($menu) closeMenu($menu, $mainSection);
        }
      });
    }
  }

  function supportKeyboardNavigation(e) {
    const $mainSection = __.parents(e.target, `.${c.dom.section}`)[0] || null;
    const $menu = $mainSection?.querySelector(`.${c.dom.section_menu}`) || null;

    // press Escape to close the menu
    if (e?.key === 'Escape') {
      if ($menu) closeMenu($menu, $mainSection);
    }

    // press Enter or Space to select the option
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handleItemClick(e.target);
    }
  }

  function handleItemClick(currentTarget) {
    const $mainSection = __.parents(currentTarget, `.${c.dom.section}`)[0] || null;
    if (!$mainSection) return;

    const currentIndex = Array.from(currentTarget.parentNode.children).indexOf(currentTarget);
    const $menu = $mainSection?.querySelector(`.${c.dom.section_menu}`) || null;
    const $carousels = $mainSection?.querySelectorAll(`.${c.dom.carousel}`) || null;
    const $listItems = $mainSection?.querySelectorAll(`.${c.dom.dropdownItem}`) || null;
    const $sectionTriggerText = $mainSection?.querySelector(`.${c.dom.labelText}`) || null;
    const { text } = currentTarget.dataset;

    if ($carousels?.length > 0) {
      [...$carousels].forEach((carousel) => {
        __.removeClass(carousel.parentElement, c.cls.active);
      });
      __.addClass($carousels[currentIndex + 1]?.parentElement, c.cls.active);
    }
    if ($listItems?.length > 0) {
      [...$listItems].forEach(($item) => {
        const $btn = $item?.querySelector(`.${c.dom.category_button}`) || null;

        if ($btn) {
          __.removeClass($item, c.cls.active);
          __.removeClass($btn, c.cls.active);

          if ($item === currentTarget || $btn === currentTarget) {
            __.addClass($btn, c.cls.active);
            __.addClass($item, c.cls.active);
          }
        }
      });
    }

    __.addClass(currentTarget, c.cls.active);
    if ($sectionTriggerText && text) $sectionTriggerText.innerText = text;
    rebuildFlickity(currentTarget);

    if ($menu) {
      // small timeout for new carousel to render
      setTimeout(() => {
        closeMenu($menu, $mainSection);
        __.scrollTo({
          target: $mainSection,
          offset: 0,
          exec_now: true,
        });
      }, 600);
    }
  }

  function onWindowResize() {
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          rebuildFlickity();
        }
      },
    });

    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
          rebuildFlickity();
        }
      },
    });
  }

  function rebuildFlickity(target = false) {
    [...$wrappers].forEach(($wrapper, index) => {
      const activeCarousel = flickityCarousels[index];

      if (target) {
        const $mainSection = __.parents(target, `.${c.dom.section}`)[0] || null;
        // only rebuild if the target is in the current wrapper
        if (!$mainSection || !$mainSection.contains($wrapper)) return;
      }

      if (activeCarousel) activeCarousel.destroy();
      const $carousel = $wrapper.querySelector(`.${c.dom.carousel}`);
      const cells = $carousel.querySelectorAll(`.${c.dom.carouselCell}`);

      [...cells].forEach(($item) => {
        __.removeClass($item, c.cls.fullHeight);
      });

      // display cells flexed
      if (cells?.length < 4) {
        __.addClass($carousel, c.cls.flex);
        [...cells].forEach(($item) => {
          __.addClass($item, c.cls.active);
        });
      }

      // only build carousel if there are more than 4 cells
      if (!$carousel || cells?.length < 4) {
        const $shopAll = $wrapper.querySelector(`.${c.dom.shopAll}`);
        $carousel.style.opacity = 1;
        if ($shopAll) __.addClass($shopAll, c.cls.hide);
        return;
      }

      flickityOpts.on = {
        ready: () => {
          [...cells].forEach(($item) => {
            __.addClass($item, c.cls.fullHeight);
          });
        },
      };

      // Set the offset divisor based on viewport size
      let offsetDivisor;
      if (view === 'desktop') {
        offsetDivisor = 1.33;
      }

      if (view === 'mobile') {
        offsetDivisor = 1.66;
      }

      const $flickityCell = $carousel.querySelector(`.${c.dom.carouselCell}`);
      const leftOffset = setFlickityOffset($flickityCell.offsetWidth, offsetDivisor);
      const carouselOffset = $carousel.offsetWidth;
      const cellAlign = leftOffset / carouselOffset;
      const updatedFlickityOpts = __.extend(flickityOpts, { cellAlign });

      setTimeout(() => {
        if (activeCarousel) {
          flickityCarousels[index] = new Flickity($carousel, updatedFlickityOpts);
        } else {
          flickityCarousels.push(new Flickity($carousel, updatedFlickityOpts));
        }
      }, 250); // short timeout for Flickity cell sizing
    });
  }

  /**
   * Set the flickity carousel offset
   */
  function setFlickityOffset(flickityCellOffsetWidth, flickityOffsetDivisor) {
    Flickity.Cell.prototype.setDefaultTarget = () => {
      const marginProperty = this.parent.originSide === 'left' ? 'marginLeft' : 'marginRight';
      this.target = this.x + this.size[marginProperty];
    };

    return flickityCellOffsetWidth / flickityOffsetDivisor;
  }

  return init();
}

export default productsFiltersCarouselDropdown;
