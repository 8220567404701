import lottieWeb from 'lottie-web';

/*
* Lottie
* @param {object} opts
*/

function lottie(opts) {
  const config = {
    dom: {
      container: 'js-lottie-container',
    },
    cls: {
      carouselMode: 'carousel-mode',
    },
  };

  const c = __.extend(config, opts);
  const $containers = document.querySelectorAll(`.${c.dom.container}`);

  /**
   * Init
   */
  function init() {
    if (!$containers) return;

    runLottieAnimation($containers);
  }

  /**
   * Run Lottie animation
   * @param {HTMLElement} $els
   */
  function runLottieAnimation($els) {
    $els.forEach(($el) => {
      const { lottieAnimationName } = $el.dataset;
      const { lottieAutoplay } = $el.dataset;
      const { lottieJsonFile } = $el.dataset;
      const { lottieLoop } = $el.dataset;

      // eslint-disable-next-line
      const animation = lottieWeb.loadAnimation({
        container: $el,
        path: lottieJsonFile,
        renderer: 'svg',
        loop: JSON.parse(lottieLoop),
        autoplay: JSON.parse(lottieAutoplay),
        name: lottieAnimationName,
      });
    });
  }

  init();
}

export default lottie;
