/**
 * Tracking Add To Cart
 * @param {Object} data - product JSON
 */
function trackingAddToCart(data) {
  /**
   * Init
   */
  function init() {
    fbqAddToCart();
    pinAddToCart();
  }

  /**
   * fbq add to cart tracking
   * https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking/
   */
  function fbqAddToCart() {
    if (typeof fbq === 'undefined') return;

    fbq('track', 'AddToCart', {
      content_type: 'product_group',
      value: __.formatPriceNumber(data.price),
      currency: 'USD',
      content_ids: [data.variant_id],
    });
  }

  /**
   * Pinterest add to cart tracking
   */
  function pinAddToCart() {
    if (typeof pintrk === 'undefined') return;

    pintrk('track', 'AddToCart', {
      value: __.formatPriceNumber(data.price), // should be variant price
      order_quantity: data.quantity,
      currency: 'USD',
    });
  }

  return init();
}

export default trackingAddToCart;
