/**
* recalculate totals
*/

SDG.Bag.recalc = function (price) {
  const c = SDG.Bag.config;
  const $sub = document.getElementById(c.dom.subtotal);
  const $total = document.getElementById(c.dom.total);
  const { template } = SDG.Data;
  const isCartPage = template === 'cart';
  let nt;
  let pt;

  if ($sub && typeof price !== 'undefined') {
    nt = isCartPage
      ? __.formatPriceDisplay(price, 'money')
      : __.formatPriceDisplay(price, 'money_without_trailing_zeros');
    pt = __.numberWithCommas(nt);
    $sub.innerHTML = pt;
    if ($total) $total.innerHTML = pt;
  }
};
