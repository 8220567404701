import Flickity from 'flickity';

/**
 * Product Filters Carousel
 * @param {object} opts
 */
function productsCarousel(opts) {
  const config = {
    dom: {
      wrapper: 'js-products-carousel-wrapper',
      carousel: 'js-products-carousel',
      carouselCell: 'js-products-carousel-cell',
      pi: 'js-pi',
      shopAll: 'js-shop-all',
    },
    cls: {
      active: 'is-active',
      fullHeight: 'full-height',
      hide: 'hide',
    },
  };
  const c = __.extend(config, opts);

  const flickityOpts = {
    contain: true,
    draggable: true,
    dragThreshold: 5,
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    cellAlign: 'center',
    lazyload: true,
  };
  const flickityMobileOpts = {
    contain: true,
    draggable: true,
    dragThreshold: 5,
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    cellAlign: 'center',
    lazyload: true,
    selectedAttraction: 0.4,
    friction: 0.8,
  };

  // global elements
  const { template } = SDG.Data || false;
  const isCartPage = template === 'cart' || false;
  const $wrappers = document.body.querySelectorAll(`.${c.dom.wrapper}`);
  const flickityCarousels = [];
  let view;

  /**
   * On Window Resize
   */
  function onWindowResize() {
    // desktop
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          rebuildFlickity();
        }
      },
    });

    // mobile
    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
          rebuildFlickity();
        }
      },
    });
  }

  /**
   * rebuild flickity
   */
  function rebuildFlickity() {
    const carouselOpts = view === 'mobile' ? flickityMobileOpts : flickityOpts;

    [...$wrappers].forEach(($wrapper, index) => {
      const activeCarousel = flickityCarousels[index];
      if (activeCarousel) activeCarousel.destroy();

      const $carousel = $wrapper.querySelector(`.${c.dom.carousel}`);
      const cells = $carousel.querySelectorAll(`.${c.dom.carouselCell}`);

      [...cells].forEach(($item) => {
        __.removeClass($item, c.cls.fullHeight);
      });

      // only build carousel if there are more than 4 cells
      if (!$carousel || cells.length < 4) {
        const $shopAll = $wrapper.querySelector(`.${c.dom.shopAll}`);
        $carousel.style.opacity = 1;
        if ($shopAll) __.addClass($shopAll, c.cls.hide);
        return;
      }

      carouselOpts.on = {
        ready: () => {
          [...cells].forEach(($item) => {
            __.addClass($item, c.cls.fullHeight);
          });
        },
      };

      // // Set the offset divisor based on viewport size
      // let offsetDivisor;
      // if (view === 'desktop') {
      //   offsetDivisor = 1.33;
      // }

      // if (view === 'mobile') {
      //   offsetDivisor = 3;
      // }

      // const $flickityCell = $carousel.querySelector(`.${c.dom.carouselCell}`);
      // const leftOffset = setFlickityOffset($flickityCell.offsetWidth, offsetDivisor);
      // const carouselOffset = $carousel.offsetWidth;
      // const cellAlign = leftOffset / carouselOffset;
      // const updatedFlickityOpts = __.extend(flickityOpts, { cellAlign });

      setTimeout(() => {
        if (activeCarousel) {
          flickityCarousels[index] = new Flickity($carousel, carouselOpts);
        } else {
          flickityCarousels.push(new Flickity($carousel, carouselOpts));
        }
      }, 500); // short timeout for Flickity cell sizing
    });
  }

  // /**
  //  * Set the flickity carousel offset
  //  */
  // function setFlickityOffset(flickityCellOffsetWidth, flickityOffsetDivisor) {
  //   Flickity.Cell.prototype.setDefaultTarget = () => {
  //     const marginProperty = this.parent.originSide === 'left' ? 'marginLeft' : 'marginRight';
  //     this.target = this.x + this.size[marginProperty];
  //   };

  //   return flickityCellOffsetWidth / flickityOffsetDivisor;
  // }

  /**
   * Init
   */
  function init() {
    if (!$wrappers?.length) return;

    if (isCartPage) {
      // small timeout to allow for cart page to load
      setTimeout(() => {
        onWindowResize();
        __.windowResize(onWindowResize);
      }, 500);
    } else {
      onWindowResize();
      __.windowResize(onWindowResize);
    }
  }

  return init();
}

export default productsCarousel;
