/**
 * Promo Tab
 * @param {object} opts
 */
function promoTab(opts) {
  const config = {
    dom: {
      button: 'js-promo-tab-btn',
      content: 'js-promo-tab-content',
      media: 'js-promo-tab-media',
      wrapper: 'js-promo-tab',
    },
    cls: {
      active: 'is-active',
    },
  };
  const c = __.extend(config, opts);

  // global elements
  const $wrappers = document.body.querySelectorAll(`.${c.dom.wrapper}`);

  /**
   * Init
   */
  function init() {
    if (!$wrappers?.length) return;
    addEvents();
  }

  /**
   * Add Events
   */
  function addEvents() {
    [...$wrappers].forEach(($wrapper) => {
      __.addEvent({
        id: $wrapper,
        className: c.dom.button,
        event: 'click',
        fn: handleBtnClick,
      });
    });
  }

  /**
   * Handle Btn Click
   * @param {Object} e - click event
   */
  function handleBtnClick(e) {
    const $wrapper = __.parents(e.target, `.${c.dom.wrapper}`)[0];
    const $btn = !__.hasClass(e.target, c.dom.button)
      ? __.parents(e.target, `.${c.dom.button}`)[0]
      : e.target;

    // active tab is being clicked
    if (__.hasClass($btn, c.cls.active)) return;

    const { blockId } = $btn.dataset;
    const $media = $wrapper.querySelector(`.${c.dom.media}[data-block-id="${blockId}"]`);
    const $content = $wrapper.querySelectorAll(`.${c.dom.content}[data-block-id="${blockId}"]`);
    const $activeMedia = $wrapper.querySelector(`.${c.dom.media}.${c.cls.active}`);
    const $activeContent = $wrapper.querySelectorAll(`.${c.dom.content}.${c.cls.active}`);
    const $activeBtn = $wrapper.querySelector(`.${c.dom.button}.${c.cls.active}`);

    // toggle active content
    if ($btn) __.addClass($btn, c.cls.active);
    if ($media) __.addClass($media, c.cls.active);
    if ($activeMedia) __.removeClass($activeMedia, c.cls.active);
    if ($activeBtn) __.removeClass($activeBtn, c.cls.active);
    if ($content?.length) {
      $content.forEach(($el) => __.addClass($el, c.cls.active));
    }
    if ($activeContent?.length) {
      $activeContent.forEach(($el) => __.removeClass($el, c.cls.active));
    }
  }

  return init();
}

export default promoTab;
