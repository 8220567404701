<template>
  <Transition name="gift-note-modal">
    <aside
      v-if="show"
      class="gift-note-modal"
      aria-hidden="true"
      aria-labelledby="giftNote"
      role="dialog"
    >
      <header class="gift-note-modal__header">
        <button class="gift-note-modal__close" type="button" title="Close modal" tabindex="0"
          @click="resetForm">
          <i class="icon icon--close"></i>
          <span class="gift-note-modal__close-text">
            close
          </span>
        </button>
        <img
          v-if="giftNoteBackgroundImage"
          :src="giftNoteBackgroundImage"
          alt="Gift Note background image"
          class="gift-note-modal__header-bg"
        >
      </header>

      <div class="gift-note-modal__content modal__content">
        <div class="gift-note-modal__heading-wrapper">
          <h3 class="gift-note-modal__heading" v-html="giftNoteLabel">
          </h3>
          <p class="gift-note-modal__subheading">
            This note will be printed and included in the box.
            Prices will not be shown on the package slip.
          </p>
        </div>
        <form @submit.prevent="submitForm" class="gift-note-modal__form">
          <textarea
            class="gift-note-modal__textarea"
            v-model="giftNoteMessage"
            placeholder="300 characters or less"
            maxlength="300"
          ></textarea>

          <label class="gift-note-modal__checkbox-wrapper">
            <div class="gift-note-modal__custom-checkbox">
              <input type="checkbox" class="gift-note-modal__checkbox" v-model="isBlank" />
              <span class="gift-note-modal__checkbox-icon"></span>
            </div>
            <span class="gift-note-modal__checkbox-text" v-html="giftNoteBlankOptionText"></span>
          </label>
          <Transition name="gift-note-modal__error-fade">
            <div v-if="!giftNoteMessage.trim() && !isBlank" class="gift-note-modal__error">
              <!-- eslint-disable-next-line max-len -->
              Please enter a custom message or select '<span v-html="giftNoteBlankOptionText"></span>', or cancel to go back.
            </div>
          </Transition>

          <button type="submit"
            class="btn btn--primary gift-note-modal__submit-button"
            title="Save Gift Note"
            @submit="submitForm"
            :disabled="submitDisabled"
            :class="isSubmitting ? 'is-loading' : ''"
          >
            Save Gift Note
          </button>
        </form>

        <button class="gift-note-modal__cancel-button btn" @click="resetForm">Cancel</button>
      </div>
    </aside>
  </Transition>
</template>

<script setup>
import {
  computed,
  onMounted,
  ref, watch,
} from 'vue';
import useCart from '../composables/cart-actions';

const emit = defineEmits(['close']);

defineProps({
  show: Boolean,
});

const giftNoteMessage = ref('');
const isBlank = ref(true);
const isSubmitting = ref(false);
const formSubmitted = ref(false);
const giftNoteLabel = ref(SDG.Data.cart.gift_note_label_text);
const giftNoteBlankOptionText = ref(SDG.Data.cart.gift_note_blank_option_text);

const { cartThemeSettings, cartAttributes, addCartAttributes } = useCart();
const { giftNoteBackgroundImage, giftNoteGlobalBlankText } = cartThemeSettings;

onMounted(() => {
  // eslint-disable-next-line no-underscore-dangle
  giftNoteMessage.value = cartAttributes?.value?.__giftNoteMessage || '';
  // eslint-disable-next-line no-underscore-dangle
  isBlank.value = cartAttributes?.value?.__giftNoteType ? cartAttributes?.value?.__giftNoteType === 'blank' : true;
});

const resetForm = () => {
  if (!formSubmitted.value) {
    giftNoteMessage.value = '';
    isBlank.value = true;
  }

  isSubmitting.value = false;
  emit('close');
};

const configureGiftNoteMessage = computed(() => {
  if (isBlank.value === true && giftNoteGlobalBlankText?.trim() !== '') {
    return giftNoteGlobalBlankText;
  }

  if (!isBlank.value && giftNoteMessage?.value.trim() !== '') {
    return giftNoteMessage.value;
  }

  return null;
});

const submitForm = () => {
  isSubmitting.value = true;
  formSubmitted.value = true;

  const giftNoteAttributes = {
    __giftNote: 'true',
    __giftNoteType: isBlank.value ? 'blank' : 'custom',
    __giftNoteMessage: configureGiftNoteMessage.value,
  };

  addCartAttributes(giftNoteAttributes, resetForm);
};

// Automatic switch between blank and custom gift note based on textarea content
watch(giftNoteMessage, (newMessage) => {
  isBlank.value = newMessage.trim() === '';
});

// Validation rule for giftNoteMessage and isBlank
const isInvalidSelection = ref(false);
watch([giftNoteMessage, isBlank], ([message, blank]) => {
  isInvalidSelection.value = !message.trim() && !blank;
});

const submitDisabled = computed(() => isInvalidSelection.value);

// Clear the giftNoteMessage when the checkbox is checked (isBlank is true)
watch(isBlank, (newValue) => {
  if (newValue) {
    giftNoteMessage.value = '';
  }
});

const clearForm = () => {
  giftNoteMessage.value = '';
  isBlank.value = true;
};

defineExpose({
  clearForm,
});
</script>
