/**
 * Sub navigation Menu
 */
function subNavigation(opts) {
  const config = {
    dom: {
      activeLink: '.js-active-menu-link',
    },
  };
  // Extend config
  const c = __.extend(config, opts); // Extend defaults with passed options
  const $activeLink = document.querySelector(c.dom.activeLink) || null;

  /**
   * Init
   */
  function init() {
    if ($activeLink) {
      slideIntoView();
    }
  }

  /**
   * Slide Into View
   */
  function slideIntoView() {
    $activeLink.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  }
  return init();
}

export default subNavigation;
