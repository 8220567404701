<template>
  <div class="bag__cart-delivery-item">
    <div class="bag__cart-delivery-item-wrapper">
      <div class="bag__cart-delivery-item-position">
        <span>
          {{ itemPosition }}
        </span>
      </div>
      <div class="bag__cart-delivery-item-row">
        <span
          v-if="title"
          class="bag__cart-delivery-item-text"
          >
          {{ title }}
        </span>
        <span
          v-if="message"
          class="bag__cart-delivery-item-message"
          >
          {{ message }}
        </span>
      </div>
      <span
        v-if="arrivalDate"
        class="bag__cart-delivery-item-date"
      >
        {{ arrivalDate }}
      </span>
    </div>
  </div>
</template>
<script>
import {
  ref,
  computed,
  onMounted,
  onUpdated,
} from 'vue';
import useDelivery from '../composables/delivery-estimator';

export default {

  name: 'CartDeliveryDataItem',
  props: {
    position: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    currentDate: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const {
      fetchSingleDeliveryEstimate,
      zipcode,
    } = useDelivery();
    const getItByData = ref({});
    const currentConveyance = ref(null);
    const arrivalDate = ref('');

    function handleMounted() {
      const isGiftCard = props.item?.product_type?.toLowerCase().includes('gift card');
      const isPhysicalConveyance = props.item?.options_with_values?.filter((options) => (
        !isGiftCard && options.value.toLowerCase().includes('physical')
      )).length > 0;
      const conveyance = isPhysicalConveyance ? 'mailin' : 'upload';
      if (!zipcode?.value || currentConveyance.value === conveyance) return;

      fetchSingleDeliveryEstimate(zipcode.value, conveyance)
        .then((data) => {
          getItByData.value = data;
          currentConveyance.value = conveyance;

          if (data.arrival) {
            const date = new Date(data.arrival);
            const formattedDate = date.toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              timeZone: 'UTC',
            });

            arrivalDate.value = `Arrives by ${formattedDate}`;
          }
        })
        .catch((error) => {
          console.error({ message: 'Unable to fetch delivery estimate', error });
        });
    }

    onMounted(handleMounted);
    onUpdated(handleMounted);

    const title = computed(() => `${props.item.product_title} (${props.item.quantity})`);
    const itemPosition = computed(() => parseInt(props.position, 10) + 1);
    const message = computed(() => {
      if (getItByData.value.message === undefined) return null;
      const { options_with_values: options } = props.item || [];
      const isDigitalItem = options?.find((option) => option.value === 'Digital');
      const isPhysical = options?.find((option) => option.value === 'Physical');

      if (isDigitalItem) {
        return `Digital: ${getItByData.value.message}`;
      }

      if (isPhysical) {
        return `Physical: ${getItByData.value.message} of receiving your art`;
      }

      return `Physical: ${getItByData.value.message}`;
    });

    return {
      arrivalDate,
      itemPosition,
      message,
      title,
    };
  },
};
</script>
