import Flickity from 'flickity';
import productsCarousel from './products-carousel';

/**
 * bag empty
 */
SDG.Bag.empty = function (empty) {
  const c = SDG.Bag.config;

  // cached globals
  const $emptyCartHide = document.querySelectorAll(c.dom.empty_cart_hide);
  const $emptyCartShow = document.querySelectorAll(c.dom.empty_cart_show);
  const $bagCount = document.getElementById(c.dom.bag_count);

  if (empty) {
    // make sure the body is scrollable when the cart items change
    __.removeClass(document.body, 'overflow-hidden');
    $emptyCartHide.forEach(($el) => {
      __.removeClass($el, c.cls.show);
      __.addClass($el, c.cls.hide);
    });
    $emptyCartShow.forEach(($el) => {
      __.removeClass($el, c.cls.hide);
      __.addClass($el, c.cls.show);
      // rebuild products carousel
      const $productsCarousel = $el.querySelectorAll(c.dom.products_carousel);
      let hasCarousels = false;
      $productsCarousel.forEach(($carousel) => {
        const carousel = Flickity.data($carousel);
        if (carousel) {
          __.removeClass($carousel, c.cls.active);
          const $cells = $carousel.querySelectorAll(c.dom.products_carousel_cell);
          $cells.forEach(($cell) => {
            __.removeClass($cell, c.cls.full_height);
          });
          carousel.destroy();
          hasCarousels = true;
        }
      });
      if (hasCarousels) {
        productsCarousel();
      }
    });
  } else {
    $emptyCartHide.forEach(($el) => {
      __.removeClass($el, c.cls.hide);
      __.addClass($el, c.cls.show);
    });
    $emptyCartShow.forEach(($el) => {
      __.removeClass($el, c.cls.show);
      __.addClass($el, c.cls.hide);
    });
  }
  if ($bagCount) {
    const { innerText: bagCount } = $bagCount;
    const itemCount = __.toNumber(bagCount) + 0;
    // toggle hide class for bag count span
    if (itemCount > 0) {
      __.removeClass($bagCount, c.cls.hide);
    } else if (!__.hasClass($bagCount, c.cls.hide)) {
      __.addClass($bagCount, c.cls.hide);
    }
  }
};
