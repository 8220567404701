<template>
  <div v-if="!hasSingleGiftCard">
    <div v-if="!cartAttributes?.__giftNote"
      class="gift-note"
    >
      <div class="gift-note__content-wrapper">
        <div class="gift-note__image"></div>
        <div class="gift-note__text">
          <h4 class="gift-note__main-text" v-html="giftNoteLabel"></h4>
          <p class="gift-note__subtext">Make your order extra special!</p>
        </div>
      </div>
      <button class="gift-note__add-button" @click="showGiftNodeModal = true">
        Add A Note
      </button>
    </div>

    <div
    v-else
      class="gift-note gift-note--filled"
      :class="isSubmitting ? 'is-loading' : ''"
      >
      <div class="gift-note__top">
        <h4 class="gift-note__main-text" v-html="giftNoteAddedText"></h4>

        <div>
          <button class="gift-note__button" @click="showGiftNodeModal = true">
            Edit Note
          </button>
          <button class="gift-note__button" @click.prevent="deleteGiftNote">
            Delete
          </button>
        </div>

      </div>
      <p v-if="cartAttributes.__giftNoteMessage" class="gift-note__subtext">
        {{ cartAttributes.__giftNoteMessage }}
      </p>
    </div>

    <Teleport to="body">
      <GiftNoteModal
        ref="giftModal"
        :show="showGiftNodeModal"
        @close="showGiftNodeModal = false"
      />
      <Transition name="gift-note-modal__overlay">
        <div
          v-if="showGiftNodeModal"
          class="gift-note-modal__overlay"
          @click="showGiftNodeModal = false"
        ></div>
      </Transition>
    </Teleport>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import GiftNoteModal from './GiftNoteModal';
import useCart from '../composables/cart-actions';

const {
  cartAttributes,
  addCartAttributes,
  cartItems,
} = useCart();
const giftModal = ref(null);
const showGiftNodeModal = ref(false);
const isSubmitting = ref(false);
const giftNoteLabel = ref(SDG.Data.cart.gift_note_label_text);
const giftNoteAddedText = ref(SDG.Data.cart.gift_note_added_text);

const clearIsSubmitting = () => {
  isSubmitting.value = false;
};

const deleteGiftNote = () => {
  isSubmitting.value = true;

  const giftNoteAttributes = {
    __giftNote: null,
    __giftNoteType: null,
    __giftNoteMessage: null,
  };

  addCartAttributes(giftNoteAttributes, clearIsSubmitting);
  giftModal?.value?.clearForm();
};

const hasSingleGiftCard = ref(checkIfSingleGiftCard(cartItems.value));

// Watch for changes in the cartItems array
watch(cartItems, (newCartItems) => {
  hasSingleGiftCard.value = checkIfSingleGiftCard(newCartItems);
});

// Check if the cartItems array has a single gift card item
function checkIfSingleGiftCard(items) {
  if (items.length === 1 && items[0].gift_card) {
    return true;
  }

  return false;
}

</script>
