/**
 * Load script
 * @param {string}   src  script source
 * @param {function} done callback
 */
function loadScript(src, done) {
  const js = document.createElement('script');

  js.src = src;

  js.onload = () => {
    done();
  };

  js.onerror = () => {
    console.error(`Failed to load script ${src}`);
    done();
  };

  document.head.appendChild(js);
}

export default loadScript;
