/**
 * expand up
 * @param {element} DOM Element
 * @param {int} Animation timer
 */
__.expandUp = function(el, paddingBottom = 0) {
  if (!el) return;

  const hiddenStyles = __.getHiddenStyles(el);
  const { elHeight, elPaddingTop, elPaddingBottom } = hiddenStyles;

  el.style.display = 'block';
  el.style.paddingTop = 0;
  el.style.paddingBottom = 0;
  el.style.height = 0;

  setTimeout(() => {
    el.style.height = `calc(${elHeight}px + ${paddingBottom}px)`;
    el.style.paddingTop = elPaddingTop;
    el.style.paddingBottom = elPaddingBottom;
  }, 1);
};

/**
 * minimize down
 * @param {element} DOM Element
 * @param {int} Animation timer
 */
__.minimizeDown = function(el = null) {
  if (el === undefined) el = null;

  if (el) {
    el.style.height = `${el.offsetHeight}px`;

    el.style.height = 0;
    el.style.paddingTop = 0;
    el.style.paddingBottom = 0;
  }
};
