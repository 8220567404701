/**
 * Consultation Fees
 */

function consultationFees() {
  const config = {
    dom: {
      variantButton: 'js-consultation-fees-variant-button',
      ctaButton: 'js-consultation-fees-cta-button',
    },
  };

  const c = config;
  const $variantButtons = [...document.querySelectorAll(`.${c.dom.variantButton}`)];
  const $ctaButton = document.querySelector(`.${c.dom.ctaButton}`);

  /**
   * Init
   */
  function init() {
    if (!$variantButtons?.length || !$ctaButton) {
      return;
    }

    addEvents();
  }

  /**
   * Add events
   */
  function addEvents() {
    $variantButtons.forEach(($button) => {
      $button.addEventListener('click', handleVariantButtonClick);
    });
  }

  /**
   * Handle variant button click
   */
  function handleVariantButtonClick(e) {
    $ctaButton.href = e.target.value;
    $ctaButton.removeAttribute('aria-disabled');
    $ctaButton.classList.remove('disabled');
  }

  return init();
}

export default consultationFees;
