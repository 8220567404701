/**
 * App init
 */

import globalUi from './global-ui';
import loadScript from '../util/load-script';

/**
 * Browser supports all features
 */
function browserSupportsAllFeatures() {
  return window.Promise && window.fetch;
}

/**
 * Init the Global UI
 */
if (browserSupportsAllFeatures()) {
  globalUi();
} else {
  const src = 'https://polyfill.io/v3/polyfill.min.js?features=Promise%2Cfetch';
  loadScript(src, globalUi);
}
