import moment from 'moment';
import 'moment-timezone';

/**
 * Countdown Timer
 * @requires [lib/util.js]
 * @return   {Object}
 */
SDG.countdownTimer = function (opts) {
  const config = {
    dom: {
      id: null,
      timer: 'js-countdown-timer',
      timerOutput: '[data-countdown-timer-rendered]',
      timerOutputDays: '[data-countdown-timer-rendered="days"]',
      timerOutputHours: '[data-countdown-timer-rendered="hours"]',
      timerOutputMinutes: '[data-countdown-timer-rendered="minutes"]',
      timerOutputSeconds: '[data-countdown-timer-rendered="seconds"]',
      wrapper: 'js-countdown-timer-wrapper',
    },
    cls: {
      hiddenTimer: 'hide',
    },
  };

  const c = __.extend(config, opts);

  // Globals
  const parent = document.getElementById(c.dom.id);
  const events = {
    ENDED: 'ended.countdownTimer',
    READY: 'ready.countdownTimer',
  };
  let $wrapper;
  let $timer;
  let $outputDays;
  let $outputHours;
  let $outputMinutes;
  let interval;
  let timeRemaining;
  let timerData;

  /**
   * Init
   * @return {Function}
   */
  function init() {
    if (!parent) return;

    initializeCountdownTimer();
  }

  /**
   * Initialize the countdown timer
   * @type {Function}
   */
  function initializeCountdownTimer() {
    $wrapper = parent.querySelector(`.${c.dom.wrapper}`);
    $timer = $wrapper ? $wrapper.querySelector(`.${c.dom.timer}`) : null;

    const inEST = $wrapper?.dataset.timezone || false;

    if (!$timer) return;
    $outputDays = $timer.querySelector(c.dom.timerOutputDays);
    $outputHours = $timer.querySelector(c.dom.timerOutputHours);
    $outputMinutes = $timer.querySelector(c.dom.timerOutputMinutes);
    const timeFormat = 'MM-DD-YYYY hh:mm:ss A'; // ex. 01-01-1990 12:00:00 PM
    const timezone = inEST ? 'America/New_York' : moment.tz.guess();
    timerData = $timer.dataset.dateTime;

    // Convert the current time and target time into moments.
    // Get current time in desired timezone.
    const currentTime = moment.tz(timezone).startOf('second');

    // Get target time in desired timezone
    const targetTime = moment.tz(timerData, timeFormat, timezone).startOf('second');

    const timeDiff = targetTime.diff(currentTime);
    timeRemaining = moment.duration(timeDiff);

    if (!validate(targetTime)) {
      stopTimer();
      removeTimer();
      return;
    }

    startTimer();
    __.removeClass($timer, c.cls.hiddenTimer);

    $timer.dispatchEvent(new Event(events.READY));
  }

  /*
   * Method to make sure the timer is valid.
   * Uses moment's 'isValid()' helper function.
   */
  function validate(momentDateTime) {
    if (!momentDateTime.isValid()) {
      return false;
    }

    return true;
  }

  /**
   * Method to start timer
   */
  function startTimer() {
    step();
    interval = setInterval(step, 1000);
  }

  /**
   * Method to stop timer
   */
  function stopTimer() {
    clearInterval(interval);
  }

  /**
   * Method to remove timer once it's elapsed
   */
  function removeTimer() {
    $timer.dispatchEvent(new Event(events.ENDED));
    $timer.remove();
  }

  /**
   * Method to step timer forward 1 second
   */
  function step() {
    timeRemaining = timeRemaining.subtract(1, 'seconds');

    if (timeRemaining.asSeconds() <= 0) {
      stopTimer();
      removeTimer();
      return;
    }
    /*
     * Note on Moment.js - The .hours(), .minutes(), etc helper methods return the
     * number of days/hours/minutes left, but won't elapse into another unit.
     *
     * Ex. if there's 3 hours, 40 minutes remaining, .hours() will return 3, and .minutes()
     * will return 40.
     *
     * You can use the asHours(), asMinutes(), etc. helper methods to return the total
     * remaining duration in a given unit.
     */
    const daysRemaining = Math.floor(timeRemaining.as('days'));
    const hoursRemaining = timeRemaining.hours();
    const minutesRemaining = timeRemaining.minutes();

    // Singular/Plural text for days/hours
    $outputDays.nextElementSibling.innerText = daysRemaining === 1 ? 'day' : 'days';
    $outputHours.nextElementSibling.innerText = hoursRemaining === 1 ? 'hour' : 'hours';

    // 0-prefix numbers under 10
    const daysText = zeroPrefix(daysRemaining);
    const hoursText = zeroPrefix(hoursRemaining);
    const minutesText = zeroPrefix(minutesRemaining);
    const outputNew = [
      {
        el: $outputDays,
        value: daysText,
      },
      {
        el: $outputHours,
        value: hoursText,
      },
      {
        el: $outputMinutes,
        value: minutesText,
      },
    ];

    renderArray(outputNew);
  }

  /**
   * Method to render the output and insert it into the dom.
   * Doesn't do anything if the output we pass it is what's already present.
   * @param {Array} outputNew - an array of objects containing output + element to insert it into.
   */
  function renderArray(outputNew) {
    const self = {};
    outputNew.forEach((outputObj, i) => {
      const $outputObjEl = outputObj.el;
      const outputValue = outputObj.value;
      if (!self.output) self.output = [];
      if (self.output[i] !== outputValue) {
        self.output[i] = outputValue;
        $outputObjEl.innerHTML = outputValue;
      }
    });
  }

  /**
   * Adds leading 0 to single digit integers
   */
  function zeroPrefix(integer) {
    return integer < 10 ? `0${integer}` : integer;
  }

  /**
   * Return
   * @type {Object}
   */
  return {
    init,
  };
};
