/**
 * How It Works
 * @param {object} opts
 */
function howItWorks(opts) {
  const config = {
    dom: {
      wrapper: 'js-how-it-works',
    },
  };
  const c = __.extend(config, opts);

  // global elements
  const $wrappers = document.body.querySelectorAll(`.${c.dom.wrapper}`);

  /**
   * Init
   */
  function init() {
    if (!$wrappers?.length) return;

    [...$wrappers].forEach(($wrapper) => {
      const howItWorksAccordion = SDG.accordion({
        dom: {
          id: $wrapper.id,
        },
        open_option: 0,
      });
      howItWorksAccordion.init();
    });
  }

  return init();
}

export default howItWorks;
