/**
 * Footer
 */

function footer() {
  const c = {
    dom: {
      footer: 'footer',
    },
  };

  // Globals
  const $footer = document.getElementById(c.dom.footer);

  /**
   * Init
   */
  function init() {
    if (!$footer) return;

    initFooterAccordions();
  }

  /**
   * Initialize footer accordions
   */
  function initFooterAccordions() {
    // Mobile footer linklist accordions
    const footerLinksAccordion = SDG.accordion({
      dom: {
        id: 'js-links-acc', // id of the accordion
      },
    });

    footerLinksAccordion.init();
  }

  return init();
}

export default footer;
