/**
 * get image sizes
 * @param  {number} size   image width, e.x. 250
 * @return {string}        image sizes string
 */
__.getImageSizes = function(size = null) {
  let widths;
  let dataWidths;
  let dataWidthsArray = [];

  if (! size) return null;

  widths = [180,360,540,720,900,1080,1296,1512,1728,1944,2160,2376,2592,2808,3024];

  widths.forEach((width) => {
    if (size > width) dataWidthsArray.push(width);
  });

  dataWidthsArray.push(size);
  dataWidths = dataWidthsArray.toString();

  return dataWidths;
};
