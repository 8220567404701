import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

/**
 * Dancing Frames
 * @param {object} opts
 */
function dancingFrames(opts) {
  const config = {
    dom: {
      section: '.js-dancing-frames',
      frame: '.js-dancing-frame',
    },
  };
  const c = __.extend(config, opts);

  // global elements
  const $sections = document.querySelectorAll(c.dom.section);

  /**
   * Init
   */
  function init() {
    if (!$sections?.length) return;

    // short timeout is required as vertical-text-image can interfere if not ran first
    setTimeout(runScrollTrigger, 50);
  }

  /**
   * Run Scroll Trigger
   */
  function runScrollTrigger() {
    $sections.forEach(($section) => {
      const mm = gsap.matchMedia();
      gsap.registerPlugin(ScrollTrigger);

      // use the parent as the trigger so the animation
      // is smoother since we are animating the section itself
      const trigger = $section.parentNode;
      const scrollTrigger = { trigger, scrub: 0.5 };

      mm.add('(max-width: 1024px)', () => {
        gsap.fromTo($section, { xPercent: -33 }, {
          xPercent: -60,
          scrollTrigger,
        });
      });

      mm.add('(min-width: 1025px)', () => {
        gsap.to($section, {
          xPercent: -50,
          scrollTrigger,
        });
      });

      const $frames = $section.querySelectorAll(c.dom.frame);
      $frames.forEach(($frame, index) => {
        const rotation = index % 2 ? -15 : 15;
        gsap.fromTo($frame, { rotation }, {
          rotation: rotation * -1,
          scrollTrigger,
        });
      });
    });
  }

  return init();
}

export default dancingFrames;
