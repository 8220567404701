/**
 * Typewriter
 * @param {Object} opts
 */

__.typewriter = function (opts) {
  const config = {
    cls: {
      desktop_only: 'desktop-only',
      hide_desktop: 'hide-desktop',
      hide_mobile: 'hide-mobile',
      mobile_only: 'mobile-only',
      remaining: 'typewriter-remaining',
    },
    wrapper: 'js-typewriter',
    text: 'js-typewriter-text',
    speed: 10,
    callback: null,
  };

  // extend config
  const c = __.extend(config, opts);

  // global elements
  const $wrapper = typeof c.wrapper !== 'object'
    ? document.querySelector(`.${c.wrapper}`) || document.querySelector(c.wrapper)
    : c.wrapper;

  // global data
  let globalTimeout = 0;
  let view;

  /**
   * Init
   */
  function init() {
    if (!$wrapper) return;
    onWindowResize();
    __.windowResize(onWindowResize);
  }

  /**
   * On Window Resize
   */
  function onWindowResize() {
    // desktop
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          initTypewriter();
        }
      },
    });

    // mobile
    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
          initTypewriter();
        }
      },
    });
  }

  /**
   * Init Typewriter
   */
  function initTypewriter() {
    const $typewriters = $wrapper.querySelectorAll(`.${c.text}`)

    // ignore mobile or desktop only sections when not using that device
    const $viewTyperwriters = [...$typewriters].filter(($typewriter) => {
      const isDesktopOnly = __.hasClass($typewriter, c.cls.hide_mobile)
      || __.hasClass($typewriter, c.cls.desktop_only);
      const isMobileOnly = __.hasClass($typewriter, c.cls.hide_desktop)
      || __.hasClass($typewriter, c.cls.mobile_only);
      const doesNotMatchView = (view === 'mobile' && isDesktopOnly)
        || (view === 'desktop' && isMobileOnly);
      return !doesNotMatchView;
    });

    // loop through all view matching typewriters subsequently
    $viewTyperwriters.forEach(($typewriter, typewriterIndex) => {
      const { textContent } = $typewriter.dataset;
      let currentString = '';
      let pauseRender = false;

      // render each character of the string individually
      [...textContent].forEach((char, index) => {
        const finalLoop = index === textContent.length - 1
          && typewriterIndex === $viewTyperwriters.length - 1;

        // increment global timeout
        globalTimeout += c.speed;

        setTimeout(() => {
          currentString = `${currentString}${char}`;
          const remainingText = textContent.replace(currentString, '');

          // do not render HTML code until it is complete
          if (char === '<') pauseRender = true;

          // render character appended to current string, with hidden remaining text
          if (!pauseRender) {
            const spanHTML =
              `<span class="${c.cls.remaining}" aria-hidden="true">${remainingText}</span>`;
            $typewriter.innerHTML = `${currentString}${spanHTML}`;
          }

          // do not render HTML code until it is complete
          if (char === '>') pauseRender = false;

          // use optional callback on last instance of final loop
          if (finalLoop && c.callback) c.callback();
        }, globalTimeout);
      });
    });
  }

  return init ();
};
