import Flickity from 'flickity';

/**
 * Testimonials
 * @param {object} opts
 */
function testimonials(opts) {
  const config = {
    dom: {
      wrapper: 'js-testimonials',
      carousel: 'js-testimonials-carousel',
    },
    cls: {
      flickity_init: 'flickity-has-initialized',
    },
  };
  const c = __.extend(config, opts);

  const flickityOpts = {
    adaptiveHeight: true,
    contain: true,
    draggable: true,
    pageDots: false,
    prevNextButtons: true,
    wrapAround: true,
  };

  // global elements
  const $wrappers = document.body.querySelectorAll(`.${c.dom.wrapper}`);
  const flickityCarousels = [];
  let view;

  /**
   * Init
   */
  function init() {
    if (!$wrappers?.length) return;
    onWindowResize();
    __.windowResize(onWindowResize);
  }

  /**
   * On Window Resize
   */
  function onWindowResize() {
    // desktop
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          rebuildFlickity();
        }
      },
    });

    // mobile
    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
          rebuildFlickity();
        }
      },
    });
  }

  /**
   * rebuild flickity
   */
  function rebuildFlickity() {
    [...$wrappers].forEach(($wrapper, index) => {
      const activeCarousel = flickityCarousels[index];
      if (activeCarousel) activeCarousel.destroy();
      const $carousel = $wrapper.querySelector(`.${c.dom.carousel}`);
      if (!$carousel) return;

      const {
        enableDesktopArrows,
        enableDesktopPageDots,
        enableMobileArrows,
        enableMobilePageDots,
      } = $carousel.dataset;

      const enableArrows = view === 'desktop'
        ? enableDesktopArrows
        : enableMobileArrows;
      const enablePageDots = view === 'desktop'
        ? enableDesktopPageDots
        : enableMobilePageDots;

      setTimeout(() => {
        const flickityCarousel = new Flickity($carousel, {
          ...flickityOpts,
          pageDots: enablePageDots !== 'false',
          prevNextButtons: enableArrows !== 'false',
        });

        if (activeCarousel) {
          flickityCarousels[index] = flickityCarousel;
        } else {
          flickityCarousels.push(flickityCarousel);
        }

        setTimeout(() => {
          __.addClass($carousel, c.cls.flickity_init);
        }, 500); // short timeout to prevent initial transition animation which causes flicker
      }, 500); // short timeout for Flickity cell sizing
    });
  }

  return init();
}

export default testimonials;
