function articlesToolbar(opts) {
  const config = {
    dom: {
      toolbar: 'articlesToolbar',
      stickyMarker: '.js-sticky-articles-marker',
      collectionView: 'js-articles-view',
      productItemCarousel: 'js-product-item-carousel',
      productsGrid: 'articlesWrapper',
      header: 'header',
    },
    cls: {
      active: 'is-active',
      isPinned: 'is-pinned',
    },
    modifier: {
      productsPerRow: 'products-per-row--',
    },
  };

  // Shorthand config
  const c = __.extend(config, opts); // Extend defaults with passed options
  const $productsGrid = document.getElementById(c.dom.productsGrid);
  const $toolbar = document.getElementById(c.dom.toolbar);
  const $stickyMarker = document.querySelector(c.dom.stickyMarker);
  let view;

  /**
   * Init
   */
  function init() {
    if (!$productsGrid || !$toolbar) return;
    addEvents();
    responsive();
    __.windowResize(responsive);
    detectPinnedToolbar();
  }

  /**
   * Add Events
   */
  function addEvents() {
    __.addEvent({
      id: c.dom.toolbar,
      event: 'click',
      className: c.dom.collectionView,
      fn: handleViewChange,
    });
  }

  function handleViewChange(e) {
    const { currentTarget } = e;
    const gridClass = $productsGrid.classList;
    gridClass.forEach((className) => {
      if (className.includes(c.modifier.productsPerRow)) {
        __.removeClass($productsGrid, className);
      }
    });
    const $viewBtns = document.querySelectorAll(`.${c.dom.collectionView}`);
    $viewBtns.forEach((btn) => {
      __.removeClass(btn, c.cls.active);
    });
    const { classModifier } = currentTarget.dataset;
    __.addClass($productsGrid, classModifier);
    __.addClass(currentTarget, c.cls.active);
  }

  function detectPinnedToolbar() {
    if ($stickyMarker) {
      const observer = new IntersectionObserver(
        ([entries]) => {
          const boundingRect = entries.boundingClientRect;
          const isPinned = boundingRect.top < 0;
          $toolbar.classList.toggle(c.cls.isPinned, isPinned);
        },
        { threshold: [1] },
      );

      observer.observe($stickyMarker);
    }
  }

  /**
   * Responsive functions
   */
  function responsive() {
    const $viewBtns = document.querySelectorAll(`.${c.dom.collectionView}`);
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          const $viewBtn = document.querySelectorAll(`.${c.dom.collectionView}`)[2];
          resetProductsPerRow();
          $viewBtns.forEach((btn) => {
            __.removeClass(btn, c.cls.active);
          });
          __.addClass($productsGrid, `${c.modifier.productsPerRow}three`);
          __.addClass($viewBtn, c.cls.active);
        }
      },
    });

    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
          const $viewBtn = document.querySelectorAll(`.${c.dom.collectionView}`)[0];
          $viewBtns.forEach((btn) => {
            __.removeClass(btn, c.cls.active);
          });
          __.addClass($productsGrid, `${c.modifier.productsPerRow}one`);
          __.addClass($viewBtn, c.cls.active);
          resetProductsPerRow();
        }
      },
    });
  }

  function resetProductsPerRow() {
    const gridClass = document.getElementById(c.dom.productsGrid).classList;
    gridClass.forEach((className) => {
      if (className.includes(c.modifier.productsPerRow)) {
        __.removeClass($productsGrid, className);
      }
    });
  }

  return init();
}

export default articlesToolbar;
