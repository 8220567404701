import Flickity from 'flickity';

/*
* Gallery Carousel
* @param {object} opts
*/

function galleryCarousel(opts) {
  const config = {
    dom: {
      section: 'js-gallery-carousel-section',
      animationElement: 'js-gallery-carousel-animation-element',
      carousel: 'js-gallery-carousel',
      carouselCell: 'js-gallery-carousel-cell',
      pageDots: 'flickity-page-dots',
    },
    flickityOpts: {
      desktop: {
        prevNextButtons: true,
        pageDots: false,
        draggable: true,
        wrapAround: true,
        imagesLoaded: true,
        lazyLoad: true,
        adaptiveHeight: true,
      },
      mobile: {
        prevNextButtons: false,
        pageDots: true,
        draggable: true,
        wrapAround: true,
        imagesLoaded: true,
        lazyLoad: true,
        adaptiveHeight: true,
      },
    },
    cls: {
      scrolled: 'is-scrolled',
      dotsMargin: 'flickity-page-dots--bottom-margin',
    },
  };

  const c = __.extend(config, opts);

  const $carousels = document.body.querySelectorAll(`.${c.dom.carousel}`);
  const $wrappers = document.body.querySelectorAll(`.${c.dom.section}`);
  let view = null;

  /**
   * Init
   */
  function init() {
    if (!$wrappers?.length) return;
    onWindowResize();
    __.windowResize(onWindowResize);
  }

  /**
  * Responsive functions
  */
  function onWindowResize() {
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          addScrollEvents();
          createCarousel(c.flickityOpts.desktop);
        }
      },
    });

    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
          addScrollEvents();
          createCarousel(c.flickityOpts.mobile);
        }
      },
    });
  }

  /**
  * Create Carousel
  */
  function createCarousel(flickityOpts) {
    $carousels.forEach(($carousel) => {
      let flickity = Flickity.data($carousel);
      if (flickity) flickity.destroy();

      setTimeout(() => {
        flickity = new Flickity($carousel, flickityOpts);

        const pageDots = document.querySelector(`.${c.dom.pageDots}`);
        if (pageDots) {
          __.addClass($carousel, c.cls.dotsMargin);
        } else {
          __.removeClass($carousel, c.cls.dotsMargin);
        }
      }, 500);
    });
  }

  /**
   * Add Scroll Events
   */
  function addScrollEvents() {
    __.addEvent({
      id: document,
      event: 'scroll',
      fn: () => {
        __.debounce(handleScrollEvent(), 100);
      },
    });

    // trigger initially on page load, before scroll
    handleScrollEvent(true);
  }

  /**
   * Handle Scroll Event
   */
  function handleScrollEvent(isPageLoad) {
    const offset = window.innerHeight / 2;

    [...$wrappers].forEach(($wrapper) => {
      const $animationEls = $wrapper.querySelectorAll(`.${c.dom.animationElement}`);
      const { top, bottom } = $wrapper.getBoundingClientRect();
      const isSectionInView = top - window.innerHeight <= 0 && bottom >= 0;
      if (!isSectionInView && !isPageLoad) return;

      // toggle gallery carousel animation elements visibility and transitions
      [...$animationEls].forEach(($el, index) => {
        if (!Shopify.designMode && !$el.classList.contains('is-scrolled')) {
          $el.style.opacity = 0;
        } else {
          $el.style.opacity = 1;
        }

        const animationClass = `.${c.dom.animationElement}-${index + 1}`;
        const $animationEl = $wrapper.querySelector(animationClass);
        const $textContent = $animationEl.querySelectorAll('.gallery-carousel__text-wrapper');
        const $flickityDots = $animationEl.querySelector('.flickity-page-dots');
        if (!$animationEl) return;

        const { top: animationTop } = $animationEl.getBoundingClientRect();
        const animationOffset = (offset * (view === 'mobile' ? 1.75 : 1.5)) - (index * 100);
        const isAnimationElInView = animationTop - animationOffset <= 0;

        if (isAnimationElInView && !__.hasClass($animationEl, c.cls.scrolled)) {
          __.addClass($animationEl, c.cls.scrolled);

          // Add animation timeout to text content first
          if ($textContent) {
            $textContent.forEach(($text) => {
              __.addClass($text, c.cls.scrolled);
            });
          }

          // Add animation timeout to text flickity dots on mobile next
          if ($flickityDots) {
            setTimeout(() => {
              __.addClass($flickityDots, c.cls.scrolled);
            }, 500);
          }
        } else if (!isAnimationElInView && __.hasClass($animationEl, c.cls.scrolled)) {
          __.removeClass($animationEl, c.cls.scrolled);

          if ($textContent) {
            $textContent.forEach(($text) => {
              __.removeClass($text, c.cls.scrolled);
            });
          }

          if ($flickityDots) {
            __.removeClass($flickityDots, c.cls.scrolled);
          }
        }
      });
    });
  }

  return init();
}

export default galleryCarousel;
