/**
 * Klaviyo
 * @param {object} opts
 */
function klaviyo(opts) {
  const config = {
    dom: {
      input: 'input',
      input_placeholder: 'input-placeholder',
      newsletter: 'klaviyo-footer-newsletter-container',
    },
    cls: {
      has_value: 'has-value',
      hide_after: 'hide-after',
      klaviyo_input: 'go2119247897',
    },
    placeholder: 'Your Email Address',
  };

  const c = __.extend(config, opts);

  /**
   * Init
   */
  function init() {
    waitForKlaviyo();
  }

  /**
   * Wait For Klaviyo
   */
  function waitForKlaviyo() {
    const disconnectTimeout = 3000; // 3 seconds
    const mutationObserver = new MutationObserver((mutationRecords, observer) => {
      const $klaviyo = document.querySelector(`.${c.dom.newsletter}`);
      if (!$klaviyo) return;

      const klaviyoInnerHTML = $klaviyo.children[0]?.innerHTML;
      if (klaviyoInnerHTML === '') return;

      checkNewsletter();
      observer.disconnect();
    });

    mutationObserver.observe(document.documentElement, {
      childList: true,
      subtree: true,
    });

    // If Klaviyo is not found after 3 seconds, disconnect the observer
    setTimeout(() => {
      mutationObserver.disconnect();
    }, disconnectTimeout);
  }

  /**
   * Check Newsletter
   */
  function checkNewsletter() {
    const $newsletter = document.querySelector(`.${c.dom.newsletter}`);
    const $input = $newsletter?.querySelector(c.dom.input);
    if (!$input) return;

    // create input placeholder elements and apply classes
    const $inputWrapper = $input.parentElement;
    const $label = document.createElement('label');
    $label.innerHTML = c.placeholder;
    $inputWrapper.appendChild($label);
    $input.placeholder = '';
    __.addClass($input, c.dom.input);
    __.addClass($inputWrapper, c.dom.input_placeholder);

    // fix for weird Klaviyo hover styling that removes borders
    if (__.hasClass($input, c.cls.klaviyo_input)) {
      __.removeClass($input, c.cls.klaviyo_input);
    }

    // handle event for this new input placeholder
    __.addEvent({
      id: $input,
      event: 'change,blur',
      fn: () => {
        if ($input.value !== '') {
          __.addClass($inputWrapper, c.cls.has_value);
        } else {
          __.removeClass($inputWrapper, c.cls.has_value);
        }

        // removes weird Klaviyo ::after element that is visually breaking bottom border
        setTimeout(() => {
          const $spanAlert = $inputWrapper.querySelector('span[role="alert"]');
          const $spanAlertParent = $spanAlert?.parentElement;
          if (!$spanAlertParent) return;
          __.addClass($spanAlertParent, c.cls.hide_after);
        }, 100); // timeout to wait for Klaviyo error span to render
      },
    });
  }

  return init();
}

export default klaviyo;
