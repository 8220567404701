/**
 * Hide Header & Footer global functions
 * * * * * * * * * * * * * * * * * * * *
 * Hide Header
 * Show Header
 * Hide Footer
 * Show Footer
 */

/**
 * Hide Header
 */
SDG.hideHeader = function (opts) {
  const config = {
    dom: {
      header: 'header',
      mobile_menu: 'mobileMenu',
      navigation: 'navigation',
      page_wrap: 'page-wrap',
    },
    cls: {
      hide: 'hide',
      no_header: 'no-header',
    },
  };

  // extend config
  const c = __.extend(config, opts);

  // global elements
  const $header = document.getElementById(c.dom.header);
  const $mobileMenu = document.getElementById(c.dom.mobile_menu);
  const $navigation = document.getElementById(c.dom.navigation);
  const $pageWrap = document.querySelector(`.${c.dom.page_wrap}`);

  if ($header) __.addClass($header, c.cls.hide);
  if ($mobileMenu) __.addClass($mobileMenu, c.cls.hide);
  if ($navigation) __.addClass($navigation, c.cls.hide);
  if ($pageWrap) __.addClass($pageWrap, c.cls.no_header);
};

/**
 * Show Header
 */
SDG.showHeader = function (opts) {
  const config = {
    dom: {
      header: 'header',
      mobile_menu: 'mobileMenu',
      navigation: 'navigation',
      page_wrap: 'page-wrap',
    },
    cls: {
      hide: 'hide',
      no_header: 'no-header',
    },
  };

  // extend config
  const c = __.extend(config, opts);

  // global elements
  const $header = document.getElementById(c.dom.header);
  const $mobileMenu = document.getElementById(c.dom.mobile_menu);
  const $navigation = document.getElementById(c.dom.navigation);
  const $pageWrap = document.querySelector(`.${c.dom.page_wrap}`);

  if ($header) __.removeClass($header, c.cls.hide);
  if ($mobileMenu) __.removeClass($mobileMenu, c.cls.hide);
  if ($navigation) __.removeClass($navigation, c.cls.hide);
  if ($pageWrap) __.removeClass($pageWrap, c.cls.no_header);
};

/**
 * Hide Footer
 */
SDG.hideFooter = function (opts) {
  const config = {
    dom: {
      footer: 'footer',
    },
    cls: {
      hide: 'hide',
    },
  };

  // extend config
  const c = __.extend(config, opts);

  // global elements
  const $footer = document.getElementById(c.dom.footer);
  if ($footer) __.addClass($footer, c.cls.hide);
};

/**
 * Show Footer
 */
SDG.showFooter = function (opts) {
  const config = {
    dom: {
      footer: 'footer',
    },
    cls: {
      hide: 'hide',
    },
  };

  // extend config
  const c = __.extend(config, opts);

  // global elements
  const $footer = document.getElementById(c.dom.footer);
  if ($footer) __.removeClass($footer, c.cls.hide);
};
