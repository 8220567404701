
/**
 * How It Works
 * @param {object} opts
 */

import trapFocus from "./trap-focus";

function hotSpots(opts) {
  const config = {
    dom: {
      hotSpot: "js-hotspot",
      hotSpotButton: "js-hotspot-button",
      section: "js-hotspots-section",
      desktopImage: "js-hotspots-bg-desktop-image",
      mobileImage: "js-hotspots-bg-mobile-image",
      overlay: "js-hotspots-overlay",
      closeButton: "js-hotspots-close-button",
      productCard: "js-hotspot-product",
      containerDestination: "js-container-destination",
      containerWrapper: "js-container-wrapper",
      sourceContent: "js-product-source"
    },
    cls: {
      hide: "hide",
      visuallyHidden: "visually-hidden",
      isActive: "is-active",
      pulse: "pulse"
    }
  };
  const c = __.extend(config, opts);

  // global elements
  let view;
  let baseX;

  /**
   * Init
   */
  function init() {
    addEvents();
    updateHotspotPositions();
  }

  function addEvents() {

    __.addEvent({
      id: document,
      className: c.dom.hotSpotButton,
      event: "click",
      fn: (e) => {
        handleHotspotClick(e);
      }
    });

    __.addEvent({
      id: document,
      className: c.dom.overlay,
      event: "click",
      fn: (e) => {
       handleCloseCurrentSection(e);
      }
    });

    __.addEvent({
      id: document,
      className: c.dom.hotSpotButton,
      event: "keydown",
      fn: (e) => {
        handleHotspotKeydown(e);
      }
    });

    __.addEvent({
      id: document,
      className: c.dom.closeButton,
      event: "click",
      fn: (e) => {
       handleCloseCurrentSection(e);
      }
    });

    onWindowResize();
    __.windowResize(onWindowResize);
  }

  function pauseAllPulseAnimations() {
    const $inactiveHotspots = document.querySelectorAll(`.${c.dom.hotSpot}:not(.${c.cls.isActive})`);
    $inactiveHotspots.forEach(($hotspot) => {
      const svg = $hotspot.querySelector("svg");
      if (svg) {
        svg.classList.add('paused');
      }
    });
  }

  function resumeAllPulseAnimations() {
    const $hotspots = document.querySelectorAll(`.${c.dom.hotSpot}`);
    $hotspots.forEach(($hotspot) => {
      const svg = $hotspot.querySelector("svg");
      if (svg) {
        svg.classList.remove('paused');
      }
    });
  }

  function handleCloseCurrentSection(e) { 
    const { currentTarget } = e;
    const $section = e.currentTarget.closest(`.${c.dom.section}`);
    const $overlay = $section.querySelector(`.${c.dom.overlay}`);
    const $productCards = $section.querySelectorAll(`.${c.dom.productCard}`);
    $productCards.forEach(($productCard) => {
      __.addClass($productCard, c.cls.visuallyHidden);
      $productCard.style.top = 0;
      $productCard.style.left = 0;
    });
     const $activeProductCards = $section.querySelectorAll(`.${c.dom.productCard}.${c.cls.isActive}`);
      $activeProductCards.forEach(($activeProductCard) => {
        __.removeClass($activeProductCard, c.cls.isActive);
        const {index} = $activeProductCard.dataset;
        const $activeHotspot = $section.querySelector(`.${c.dom.hotSpot}[data-index="${index}"]`);
        $activeHotspot.parentNode.insertBefore($activeProductCard, $activeHotspot.nextSibling);
        $activeHotspot.appendChild($activeProductCard);
      });

      const $activeHotspots = $section.querySelectorAll(`.${c.dom.hotSpot}.${c.cls.isActive}`);
      $activeHotspots.forEach(($activeHotspot) => {
        __.removeClass($activeHotspot, c.cls.isActive);
      });
      __.removeClass($overlay, c.cls.isActive);
    __.addClass($overlay, c.cls.visuallyHidden);
    resumeAllPulseAnimations();
  }

  function handleHotspotKeydown(e) {
    if (e.key === "Enter" || e.key === " ") {
      handleHotspotClick(e);
    }
  }

  function getHiddenBottomPixels($section, $productEl) {
      const sectionBottom = $section.getBoundingClientRect().bottom;
      const productBottom = $productEl.getBoundingClientRect().bottom;  
      const difference = sectionBottom - productBottom;
      return difference < 0 ? Math.abs(difference) : 0;
  }

  function handleHotspotClick(e) {
    const {currentTarget} = e;
    const $productEl = currentTarget.closest(`.${c.dom.hotSpot}`).querySelector(`.${c.dom.productCard}`);
    const $section = currentTarget.closest(`.${c.dom.section}`);
    const $overlay = $section.querySelector(`.${c.dom.overlay}`);

    if (view === "desktop") {
      const hiddenBottom = getHiddenBottomPixels($section, $productEl);
      const productCardWidth = $productEl.offsetWidth;
      const productCardHeight = $productEl.offsetHeight;
      $productEl.style.top = `-${(productCardHeight / 5)}px`;
      $productEl.style.left = `-${productCardWidth / 1.5}px`;

      // if the product card is hidden at the bottom, move it up
      if (hiddenBottom !== 0) {
        const padding = 10;
        $productEl.style.top = `-${hiddenBottom + padding}px`;
      }
    }

    if(view === "mobile") {
      // move the product card tom to a a child of the section element;
      $section.appendChild($productEl);
      $productEl.style.top = "50%";
      $productEl.style.left = "50%";
    }


    __.removeClass($overlay, c.cls.visuallyHidden);
    __.removeClass($productEl, c.cls.visuallyHidden);
    __.addClass($productEl, c.cls.isActive);
    __.addClass(currentTarget.parentNode, c.cls.isActive);
    const $closeBtn = $productEl.querySelector(`.${c.dom.closeButton}`);
    $closeBtn.focus();
    trapFocus($productEl);
    pauseAllPulseAnimations();
  }

  function onWindowResize() {
    // desktop
    __.mq({
      view: "desktop",
      callback: () => {
        if (view !== "desktop") {
          view = "desktop";
        }
      }
    });

    // mobile
    __.mq({
      view: "mobile",
      callback: () => {
        if (view !== "mobile") {
          view = "mobile";
        }
      }
    });
    updateHotspotPositions();
  }

  // move the hotspot to the correct coordinates proportionally to the image for mobile only
  function updateHotspotPositions() {
    const $hotspots = document.querySelectorAll(`.${c.dom.hotSpot}`);
    if (view === "desktop") {
      $hotspots.forEach(($hotspot) => {
        const { xDesktopPosition } = $hotspot.dataset;
        const { yDesktopPosition } = $hotspot.dataset;
        $hotspot.style.left = `${xDesktopPosition}%`;
        $hotspot.style.top = `${yDesktopPosition}%`;
      });
    } else if (view === "mobile") {
      const $innerSection = document.querySelector(".hotspots-section__inner");
      const innerSectionWidth = $innerSection.offsetWidth;
      const innerSectionHeight = $innerSection.offsetHeight;
      baseX = 375;
      const proportionalDiff = (innerSectionWidth - baseX) * 0.07;
  
      
      $hotspots.forEach(($hotspot) => {
        const { xMobilePosition } = $hotspot.dataset;
        const { yMobilePosition } = $hotspot.dataset;
        
        // convert the percentage to pixels
        const yPosition = ((yMobilePosition / 100) * innerSectionHeight);
        const xPosition = ((xMobilePosition / 100) * innerSectionWidth);
        $hotspot.style.left = `${xPosition + proportionalDiff}px`;
        $hotspot.style.top = `${yPosition + proportionalDiff}px`;
      });
    }
  }
  return init();
}

export default hotSpots;