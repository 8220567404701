<template>
  <ul
    v-if="sizes"
    class="product-configurator__pv-size list-reset"
  >
    <li
      v-for="(size, idx) in sizes"
      :key="size.name"
      :class="{
        'product-configurator__pv-size-item--right-border': sizes[idx + 1] === undefined,
        hide: !includeLandscape && size.orientation === 'landscape'
          || !includePortrait && size.orientation === 'portrait',
      }"
      class="product-configurator__pv-size-item"
    >
      <button
        v-if="(size?.name)"
        :data-size="size.name"
        :disable="isUploadProcessing"
        :class="{
          'is-active': size.id === selectedSize?.id,
        }"
        class="product-configurator__pv-size-button"
        type="button"
        @click="onSizeClick(size)"
      >
        <span>{{ size.name }}</span>
        <span
          v-if="size?.label"
          class="screenreader"
        >
          {{ size.label }}
        </span>
      </button>
    </li>
  </ul>
</template>
<script setup>
import { toRefs } from 'vue';

const props = defineProps({
  includeLandscape: {
    type: Boolean,
    required: false,
    default: false,
  },
  includePortrait: {
    type: Boolean,
    required: false,
    default: true,
  },
  sizes: {
    type: Array,
    required: true,
  },
  selectedSize: {
    type: Object,
    required: true,
  },
  isUploadProcessing: {
    type: Boolean,
    required: false,
    default: false,
  },
  onSizeClick: {
    type: Function,
    required: true,
  },
});

// Reactive properties
const { sizes } = toRefs(props);

// Computed properties
</script>
