import elevarTracking from './elevar-tracking';

/**
 * Bag Upsell
 * @type {Function}
 */
SDG.Bag.upsell = function () {
  const config = {
    dom: {
      bagCount: 'bagBtnCount',
      contentItem: 'js-bag-content-item',
      upsell: 'js-bag-upsell',
      upsellItem: 'js-upsell-item',
      upsellImage: 'js-upsell-image',
    },
    cls: {
      active: 'is-active',
      loading: 'is-loading',
    },
  };

  const c = __.extend(SDG.Bag.config, config);

  // Globals
  const $wrappers = document.querySelectorAll(`.${c.dom.upsell}`);

  /**
   * Init
   */
  function init() {
    if (!$wrappers || !$wrappers.length) return;
    addEvents();
  }

  /**
   * Add Events
   */
  function addEvents() {
    [...$wrappers].forEach(($wrapper) => {
      __.addEvent({
        id: $wrapper,
        className: c.dom.upsellItem,
        event: 'click',
        fn: handleUpsellItemClick,
      });
    });
  }

  /**
   * Handle Upsell Item Click
   * @param {Object} e - click event
   */
  function handleUpsellItemClick(e) {
    const $btn = !__.hasClass(e.target, c.dom.upsellItem)
      ? __.parents(e.target, `.${c.dom.upsellItem}`)[0]
      : e.target;
    const $item = __.parents(e.target, `.${c.dom.contentItem}`).length
      ? __.parents(e.target, `.${c.dom.contentItem}`)[0]
      : null;
    const $image = $btn.querySelector(`.${c.dom.upsellImage}`);
    const { productId } = $item ? $item.dataset : {};
    const { variantId } = $btn.dataset;

    // disable upsell item button
    $btn.disabled = true;

    // set loading state
    __.addClass($image, c.cls.loading);

    // build upsell item ATC data
    const upsellHashIdentifier = __.setHash(productId, [variantId]);
    const dataObject = {
      id: __.toNumber(variantId),
      quantity: 1,
      properties: {
        _upsellHashIdentifier: upsellHashIdentifier,
      },
    };

    const cidValue = SDG.Data.cid;
    const addEndpoint = `${c.url.add}?cid=${cidValue}`;

    // add upsell item to cart
    __.ajax({
      type: 'POST',
      url: addEndpoint,
      contentType: 'application/json',
      data: JSON.stringify(dataObject),
      error: (err) => console.error('bag upsell error: ', err),
      success: (itemData) => {
        // remove loading and add active state
        __.removeClass($image, c.cls.loading);
        __.addClass($btn, c.cls.active);

        // elevar tracking
        const addedItem = itemData.items.find((item) => (
          item.variant_id === `${variantId}`
        ));
        elevarTracking(addedItem, 'add');
      },
    });
  }

  return init();
};
