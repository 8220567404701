import Flickity from 'flickity';
import formatMoney from '../util/format-money';
import elevarTracking from './elevar-tracking';
import trapFocus from './trap-focus';

/**
 * Quickview
 */
SDG.quickview = function (opts) {
  const config = {
    type: null, // 'single' or 'multi'
    wrapper: null, // wrapper element
    dom: {
      atc: 'js-quickview-atc',
      carousel: 'js-quickview-carousel',
      collection: 'collection',
      content: 'js-quickview-content',
      close: 'js-quickview-close',
      custom_upload: 'js-uploaded-image',
      frameNum: 'js-quickview-frame-num',
      next: 'js-quickview-next',
      overlay: 'quickviewOverlay',
      product_item: '.js-product-item',
      quickview: 'quickview',
      quickviewBtn: 'js-quickview-button',
      swatch: 'js-product-swatch',
      swatch_wrapper: 'js-item-swatches',
      swatches: 'js-product-swatches',
    },
    cls: {
      active: 'active',
      closing: 'is-quickview-closing',
      expose: 'is-quickview-exposed',
      hasInit: 'has-initialized',
      quickviewActive: 'quickview-active',
      hidden: 'hide',
      loading: 'is-loading',
    },
    url: {
      add: `${SDG.Data.apiUrl}/cart/add`,
    },
    ctaUrl: {
      default: '/products/',
      designersChoice: '/apps/designer/designers-choice',
      frameBundle: '/apps/designer/bundle',
      framingFlow: '/apps/designer/framing-flow',
      uploadedImageFramingFlow: '/apps/designer/review-your-frame?',
      editFrameSpec: '/apps/designer/framing-flow/edit?',
    },
    ctaText: {
      default: 'Add To Cart - ',
      designersChoice: 'Start with Designer\'s Choice',
      frameBundle: 'Start Framing',
      framingFlow: 'Start With This Frame',
      getTheLook: 'Get This Look',
      giftCard: 'Select Your Gift Card',
      uploadedImage: 'Continue With This Frame',
    },
  };
  const c = __.extend(config, opts);

  // flickity options
  const flickityOpts = {
    draggable: true,
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    lazyload: true,
  };
  const flickityMobileOpts = {
    draggable: true,
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    lazyload: true,
    selectedAttraction: 0.4,
    friction: 0.8,
  };

  // global elements
  const $body = document.body;
  const $quickview = document.getElementById(c.dom.quickview);
  const $overlay = document.getElementById(c.dom.overlay);
  let defaultLoaderHtml;
  let flickityCarousel;
  let isUploaded = false;
  let $productCard = null;

  // get dynamic wrapper element
  let $wrapper = c.wrapper;
  if (typeof c.wrapper === 'string') {
    $wrapper = c.wrapper.includes('.')
      ? document.querySelector(c.wrapper)
      : document.getElementById(c.wrapper);
  }

  /**
   * Init
   */
  function init() {
    if (!$quickview || !$wrapper) return;
    const $content = $quickview.querySelector(`.${c.dom.content}`);

    // set default loader HTML for reset
    defaultLoaderHtml = $content.innerHTML;

    // add quickview drawer events
    addEvents();
  }

  /**
   * Add Events
   */
  function addEvents() {
    __.addEvent({
      id: $quickview,
      className: c.dom.close,
      event: 'click',
      fn: quickviewClose,
    });

    __.addEvent({
      id: $overlay,
      event: 'click',
      fn: quickviewClose,
    });
  }

  function handleUploadedProductImageClick(target) {
    if (!target) return;

    const $productItem = __.parents(target, c.dom.product_item)?.[0] || null;

    if ($productItem) {
      const $customImage = $productItem.querySelector(`.${c.dom.custom_upload}`);
      if ($customImage) {
        isUploaded = true;
        $productCard = $productItem;
      }
    }
  }

  /**
   * Close Quickview
   */
  function quickviewClose() {
    // close quickview drawer
    __.addClass($body, c.cls.closing);
    __.removeClass($body, c.cls.expose);

    // full hide quickview drawer after close animation and remove content
    setTimeout(() => {
      __.removeClass($body, c.cls.closing);
      renderQuickviewContent({ shouldClear: true });
    }, 500);

    // remove active class from styled quickview buttons
    const $activeQuickviewBtn = $body
      .querySelector(`.${c.dom.quickviewBtn}.${c.cls.quickviewActive}`);
    if ($activeQuickviewBtn) {
      __.removeClass($activeQuickviewBtn, c.cls.quickviewActive);
    }
  }

  /**
   * Open Quickview
   * @param {Object} e - click event
   * @param {String} variantColor of active swatch (optional)
   */
  function quickviewOpen(e, variantColor) {
    const { target } = e;

    handleUploadedProductImageClick(target);
    const $quickviewBtn = target && __.parents(target, `.${c.dom.quickviewBtn}`)?.length
      ? __.parents(target, `.${c.dom.quickviewBtn}`)[0]
      : null;
    const $productItem = target && __.parents(target, c.dom.product_item)?.length
      ? __.parents(target, c.dom.product_item)[0]
      : null;

    if (!$quickviewBtn) return;

    // disable swatch click from triggering quickview
    if (__.hasClass(target, c.dom.swatch_wrapper)) return;

    // get basic product data from quickview button
    const {
      productHandle,
      productVariantId,
      multiFrameNum,
    } = $quickviewBtn.dataset;
    if (!productHandle) return;

    fetchProductData(
      productHandle,
      variantColor,
      productVariantId,
      multiFrameNum,
      $productItem,
    );
  }

  /**
   * Fetch Product Data
   * @param {String} productHandle
   * @param {String} variantColor of active swatch (optional)
   * @param {String} productVariantId (optional)
   * @param {String} multiFrameNum (optional)
   */
  function fetchProductData(
    productHandle,
    variantColor,
    productVariantId,
    multiFrameNum,
    productItemCard = null,
  ) {
    __.ajax({
      url: `/products/${productHandle}?view=json`,
      type: 'GET',
      error: (err) => console.error(err),
      success: (resp) => {
        if (!resp || !resp.product) {
          console.error('quickview product data error', resp);
          quickviewClose();
          return;
        }
        // render quickview main content
        renderQuickviewContent({
          ...resp.product,
          multiFrameNum,
          variantColor,
          variantId: productVariantId,
          productItemCard,
        });
      },
    });

    // open quickview drawer
    __.addClass($body, c.cls.expose);
  }

  /**
   * Render Quickview Content
   * @param {Object} metafields
   * @param {Object} productJson
   * @param {Object} variants
   * @param {String} variantColor - optional to specify variant color
   * @param {String} variantId - optional to specify variant id
   * @param {String} multiFrameNum - optional for 'multi' type only
   * @param {Boolean} shouldClear = false (enable to clear)
   */
  function renderQuickviewContent({
    metafields,
    productJson,
    variants,
    variantColor,
    variantId,
    multiFrameNum,
    shouldClear,
    productItemCard = null,
  }) {
    const $content = $quickview.querySelector(`.${c.dom.content}`);
    let $customUploadedImage = '';
    let frameSpecUrlParams = null;
    let fsCTAParam = null;

    if (shouldClear) {
      $content.innerHTML = defaultLoaderHtml;
      return;
    }

    if (productItemCard) {
      $customUploadedImage = productItemCard.querySelector(`.${c.dom.custom_upload}`) || '';
    }

    // find specific variant, if provided id or color
    const variant = productJson.variants.find((productVariant) => {
      if (variantId) {
        return productVariant.id === __.toNumber(variantId);
      }
      if (variantColor) {
        const optionLowerCase = productVariant?.option1?.toLowerCase();
        const productVariantOption = optionLowerCase ? optionLowerCase.replace(/:/g, '').split(' ').join('_') : '';
        const formattedVariant = variantColor.replace(/:/g, '').split(' ').join('_');

        return productVariantOption === variantColor || productVariantOption === formattedVariant;
      }
      return productVariant.available;
    });

    // dynamic data for html content
    const isMulti = c.type === 'multi';
    const isCuratedOrCustomFrame = productJson.type.toLowerCase() === 'curated design frame'
      || productJson.type.toLowerCase() === 'custom frame';
    const isFrameBundle = productJson.type.toLowerCase() === 'frame bundle';
    const isGetTheLook = productJson.type.toLowerCase() === 'get this look'
      || productJson.type.toLowerCase() === 'get the look';
    const isGiftCard = productJson.type.toLowerCase() === 'gift card';

    const productColors = getProductColors(productJson, variants);
    const allProductMedia = getProductMedia(productJson, variants, variant?.id);
    const getTheLookConfig = parseGetTheLookConfig(metafields.get_the_look_configuration);
    const colorOption = productJson.options.find((option) => (
      option.toLowerCase() === 'frame style' || option.toLowerCase() === 'color'
    ));
    const shouldRenderDetails = metafields.short_description
      || metafields.attributes
      || getTheLookConfig;
    const shouldDisplayDetailsPrice = isCuratedOrCustomFrame || isFrameBundle || isGiftCard;

    let productMedia = allProductMedia?.filter((media) => {
      const { alt } = media;
      const variantTitleArray = variant?.title.split('/');
      const formattedVariantTitle = variantTitleArray[0]?.trim()?.toLowerCase();

      return alt && alt.toLowerCase().includes(formattedVariantTitle);
    });

    if (!productMedia?.length) {
      productMedia = allProductMedia;
    }

    // build carousel class string
    let carouselClass = '';
    if (!productMedia?.length) {
      carouselClass = ' hide';
    } else if (productMedia?.length > 1) {
      carouselClass = ' js-quickview-carousel';
    }

    if ($customUploadedImage) {
      const { fsUrl: url } = $customUploadedImage.dataset;

      if (url) {
        frameSpecUrlParams = url;
        fsCTAParam = url;
      }
    }

    // build html content
    const contentHtml = `
      <div class="quickview__header${isMulti ? ' quickview__header--multi' : ''}">
        ${isMulti && multiFrameNum ? `
          <div class="quickview__header-icon">
            <span
              class="quickview__header-icon-text js-quickview-frame-num"
              data-multi-frame-num="${multiFrameNum}"
            >
              ${multiFrameNum}
            </span>
            <span class="quickview__header-icon-text mobile-only">.</span>
          </div>
        ` : ''}
        <div class="quickview__header-wrapper">
          <h2 class="quickview__header-title">
            ${isGetTheLook ? 'Get The Look' : productJson.title}
          </h2>
          ${metafields.subtitle ? `
            <span class="quickview__header-text">${metafields.subtitle}</span>
          ` : ''}
        </div>
        ${isMulti ? `
          <button
            type="button"
            class="btn quickview__header-btn js-quickview-next"
          >
            <span class="quickview__header-btn-text hide-mobile">next frame</span>
            <i class="icon icon--arrow-right--black"></i>
          </button>
        ` : ''}
      </div>

      <div class="quickview__wrapper">
        <div
          class="quickview__carousel quickview__carousel--${(productMedia?.length || 0)}${carouselClass}"
        >
          ${$customUploadedImage
            && $customUploadedImage?.innerHTML
            && (`<div class="quickview__image-wrapper quickview__image-wrapper--uploaded">
              ${$customUploadedImage?.innerHTML}
            </div>`)}
            ${productMedia?.slice(0, productMedia?.length < 2 ? 1 : productMedia?.length).map((media) => (`
              <div 
                class="quickview__image-wrapper ${$customUploadedImage && productMedia?.length < 2 ? 'hide' : ''}"
              >
                <div class="quickview__image ir is-loading">
                  <img
                    class="lazyload"
                    data-src="${media.src}"
                    data-flickity-lazyload="${media.src}"
                    data-widths="[180,360]"
                    data-sizes="auto"
                    tabindex="-1"
                    alt="${media.alt}"
                  >
                </div>
              </div>
            `)).join('')}
        </div>

        ${productColors && colorOption ? `
          <div class="quickview__swatches ${c.dom.swatches}">
            ${variant ? (`
              <div class="quickview__swatches-info">
                <span class="quickview__swatches-info-text quickview__swatches-info-text--bold">
                  ${colorOption}:
                </span>
                <span class="quickview__swatches-info-text">
                  ${variant.title}
                </span>
              </div>
            `) : ''}
            <ul class="quickview__swatches-list swatches list-reset">
              ${productColors.map((color) => (`
              <li class="quickview__swatches-item swatches__item${variant?.option1.toLowerCase() === color.color.split('_').join(' ') ? ' is-selected' : ''}">
                <button
                  type="button"
                  class="swatches__btn btn-icon ${c.dom.swatch} product-item__swatch"
                  data-handle="${productJson.handle}"
                  data-color="${color.color}"
                  data-image="${color.featured_image?.src || null}"
                  data-id="${color.id || null}"
                >
                  <div class="swatch">
                    ${color.type === 'image' ? (`
                      <img
                        class="lazyload"
                        data-src="${color.value}"
                        data-widths="[180]"
                        data-sizes"auto"
                        tabindex="-1"
                        alt="${color.color}"
                      >
                    `) : (`
                      <div
                        class="ir ir--swatch swatches__btn-swatch"
                        style="background-color: ${color.value};"
                      >
                        <span class="screenreader">${color.color}</span>
                      </div>
                    `)}
                    <div class="swatch__active"></div>
                  </div>
                </button>
              </li>
              `)).join('')}
            </ul>
          </div>
        ` : ''}

        ${shouldRenderDetails ? `
          <div class="quickview__details">
            <div class="quickview__details-wrapper">
              <h3 class="quickview__details-title">
                ${isGetTheLook ? productJson.title : 'Why You’ll Love It'}
              </h3>
              ${metafields.short_description ? `
                <div class="quickview__details-description">
                  ${metafields.short_description}
                </div>
              ` : ''}
              ${getTheLookConfig ? `
                <div class="quickview__details-configuration">
                  ${getTheLookConfig}
                </div>
              ` : ''}
              ${metafields.attributes ? `
                <span class="quickview__details-attributes">
                  ${metafields.attributes}
                </span>
              ` : ''}
            </div>
          </div>
        ` : ''}
      </div>

      <div class="quickview__footer">
        ${getQuickviewCta(productJson, variant, frameSpecUrlParams, fsCTAParam)}
        <div class="quickview__footer-wrapper">
          ${isUploaded ? (`
            <span class="quickview__footer-text${shouldDisplayDetailsPrice ? '' : ' hide'}">
              Starting at ${formatMoney(productJson.price_min, 'amount_with_currency_without_trailing_zeros')}+
            </span>
            `) : (`
            <span class="quickview__footer-text${shouldDisplayDetailsPrice ? '' : ' hide'}">
              Starting at ${formatMoney(productJson.price_min, 'amount_with_currency_without_trailing_zeros')}+
            </span>
            <a
              href="/products/${productJson.handle}${variant ? `?variant=${variant.id}` : ''}"
              class="quickview__footer-link"
            >
              See Full Details
            </a>
            `)}
        </div>
      </div>
    `;

    // render html content
    $content.innerHTML = contentHtml.trim();

    // init flickity carousel
    rebuildFlickity();

    // add quickview events
    addQuickviewEvents();

    // trap focus for quickview drawer
    trapFocus($quickview);
  }

  /**
   * Get Product Media
   * @param {Object} productJson
   * @param {Object} variants
   * @param {String} variantId - optional
   */
  function getProductMedia(productJson, variants, variantId) {
    if (!productJson.media) return null;
    const firstVariantId = productJson.variants[0].id;
    const variantMetafields = variants.metafields[(variantId || firstVariantId)];
    if (!variantMetafields) return null;
    const { quick_view_assets: quickviewAssets } = variantMetafields;
    if (!quickviewAssets?.length) {
      return productJson.media.filter((media) => media.src);
    }

    return quickviewAssets.map((asset) => ({
      src: asset.src || asset,
      aspect_ratio: 1,
      height: 460,
      width: 460,
    }));
  }

  /**
   * Rebuild Flickity
   */
  function rebuildFlickity() {
    let view;
    const $carousel = $quickview.querySelector(`.${c.dom.carousel}`);
    if (!$carousel) return;

    // destroy current flickity carousel, if one exists
    if (flickityCarousel) flickityCarousel.destroy();

    // adds init class so height transition is not applied immediately
    const readyEvent = () => setTimeout(() => (
      __.addClass($carousel, c.cls.hasInit)
    ), 100);

    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          flickityCarousel = new Flickity($carousel, {
            ...flickityOpts,
            on: {
              ready: readyEvent,
            },
          });
        }
      },
    });

    // mobile
    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
          flickityCarousel = new Flickity($carousel, {
            ...flickityMobileOpts,
            on: {
              ready: readyEvent,
            },
          });
        }
      },
    });
  }

  /**
   * Parse Get The Look Config
   */
  function parseGetTheLookConfig(gtlConfig) {
    if (!gtlConfig) return null;

    let parsedConfig = '';
    const configSplit = gtlConfig.split('<li');
    configSplit.forEach((split, index) => {
      const isLast = index === configSplit.length - 1;
      const splitBold = split.includes(':')
        ? split.replace(':', ':<b>').replace('</li>', '</b></li>')
        : split;
      const splitEnd = !isLast
        ? splitBold.replace('</li>', '</li>•')
        : splitBold;

      parsedConfig = `${parsedConfig}<li${splitEnd}`;
    });

    return parsedConfig;
  }

  /**
   * Add Quickview Events
   */
  function addQuickviewEvents() {
    const $quickviewAtc = $quickview.querySelector(`.${c.dom.atc}`);
    const $quickviewNext = $quickview.querySelector(`.${c.dom.next}`);
    const $quickviewSwatches = $quickview.querySelector(`.${c.dom.swatches}`);

    if ($quickviewAtc) {
      __.addEvent({
        id: $quickviewAtc,
        event: 'click',
        fn: handleQuickviewAtcClick,
      });
    }

    if ($quickviewNext) {
      __.addEvent({
        id: $quickviewNext,
        event: 'click',
        fn: handleQuickviewNextClick,
      });
    }

    if ($quickviewSwatches) {
      __.addEvent({
        id: $quickviewSwatches,
        className: c.dom.swatch,
        event: 'click',
        fn: handleQuickviewSwatchClick,
      });
    }
  }

  /**
   * Handle Quickview Next Click
   */
  function handleQuickviewNextClick() {
    const $frameNum = $quickview.querySelector(`.${c.dom.frameNum}`);
    if (!$frameNum) return;
    const { multiFrameNum } = $frameNum.dataset;
    const frameNum = multiFrameNum ? __.toNumber(multiFrameNum) : null;
    if (!frameNum) return;

    // find next frame button element
    let $nextFrameBtn = $wrapper.querySelector(`.${c.dom.quickviewBtn}.${c.cls.active}[data-multi-frame-num="${frameNum + 1}"]`);
    if (!$nextFrameBtn) {
      $nextFrameBtn = $wrapper.querySelector(`.${c.dom.quickviewBtn}.${c.cls.active}[data-multi-frame-num="1"]`);
    }

    if (!$nextFrameBtn) return;
    $nextFrameBtn.click();
  }

  /**
   * Handle Quickview ATC Click
   * @param {Object} e - click event
   */
  function handleQuickviewAtcClick(e) {
    const { target } = e;
    const $quickviewAtc = !__.hasClass(target, `.${c.dom.atc}`) && __.parents(target, `.${c.dom.atc}`).length
      ? __.parents(target, `.${c.dom.atc}`)[0]
      : target;
    const { variantId } = target.dataset;
    if (!variantId) return;

    const dataObject = {
      items: [{
        id: `${variantId}`,
        quantity: 1,
      }],
    };

    const cidValue = SDG.Data.cid;
    const addEndpoint = `${c.url.add}?cid=${cidValue}`;

    // add loading state before ATC request
    const defaultQuickviewAtcText = $quickviewAtc.innerText;
    $quickviewAtc.innerHTML = 'Adding...';
    $quickviewAtc.disabled = true;

    // add product to cart
    __.ajax({
      url: addEndpoint,
      type: 'POST',
      contentType: 'application/json',
      dataObject: JSON.stringify(dataObject),
      success: (itemData) => {
        SDG.Bag.onSuccess(itemData, variantId);
        quickviewClose();

        // remove loading state
        $quickviewAtc.innerHTML = defaultQuickviewAtcText;
        $quickviewAtc.disabled = false;

        // elevar tracking
        const addedItem = itemData.items.find((item) => (
          item.variant_id === `${variantId}`
        ));
        elevarTracking(addedItem, 'add');
      },
      error: SDG.Bag.onError,
    });
  }

  /**
   * Get Quickview CTA
   * @param {Object} productJson
   * @param {Object} variant
   * @param {Boolean} isFullWidth
   */
  // eslint-disable-next-line max-len
  function getQuickviewCta(productJson, variant, isFullWidth, fsCTAParam, frameSpecUrlParams = null) {
    const isAvailable = productJson.available && variant?.available;
    const isCuratedOrCustomFrame = productJson.type.toLowerCase() === 'curated design frame'
      || productJson.type.toLowerCase() === 'custom frame';
    const isDesignersChoice = productJson.type.toLowerCase() === 'designers choice'
      || productJson.type.toLowerCase() === 'designer\'s choice';
    const isFrameBundle = productJson.type.toLowerCase() === 'frame bundle';
    const isGetTheLook = productJson.type.toLowerCase() === 'get this look'
      || productJson.type.toLowerCase() === 'get the look';
    const isGiftCard = productJson.type.toLowerCase() === 'gift card';
    const $collection = document.getElementById(c.dom.collection);
    // determine CTA URL and text
    let ctaUrl;
    let ctaText = c.ctaText.default;
    if (isCuratedOrCustomFrame) {
      ctaUrl = c.ctaUrl.framingFlow;
      ctaText = c.ctaText.framingFlow;
    } else if (isDesignersChoice) {
      ctaUrl = c.ctaUrl.designersChoice;
      ctaText = c.ctaText.designersChoice;
    } else if (isFrameBundle) {
      ctaUrl = c.ctaUrl.frameBundle;
      ctaText = c.ctaText.frameBundle;
    } else if (isGetTheLook) {
      ctaUrl = `${c.ctaUrl.default}${productJson.handle}`;
      ctaText = c.ctaText.getTheLook;
    } else if (isGiftCard) {
      ctaUrl = `${c.ctaUrl.default}${productJson.handle}`;
      ctaText = c.ctaText.giftCard;
    }

    // if ctaUrl, append product and variant params at end of URL
    if (ctaUrl) {
      let params = '';
      if (isCuratedOrCustomFrame || isDesignersChoice || isFrameBundle) {
        params = variant
          ? `?product=${productJson.id}&variant=${variant.id}`
          : `?product=${productJson.id}`;
      }
      ctaUrl = `${ctaUrl}${params}`;
    }

    // if no ctaUrl, append formatted price at end of ctaText
    if (!ctaUrl) {
      const formattedPrice = formatMoney(
        productJson.variants[0].price,
        'amount_with_currency_without_trailing_zeros',
      );
      ctaText = `${ctaText}${formattedPrice}`;
    }

    if (frameSpecUrlParams) {
      ctaText = `${c.ctaText.uploadedImage}`;
      ctaUrl = `${c.ctaUrl.uploadedImageFramingFlow}${frameSpecUrlParams}`;
    }

    if (fsCTAParam) {
      ctaText = `${c.ctaText.uploadedImage}`;
      ctaUrl = `${c.ctaUrl.editFrameSpec}${fsCTAParam}`;
    }

    if ($collection) {
      const hasCtaText = $collection.dataset.cta || null;
      if (hasCtaText && isUploaded) {
        ctaText = hasCtaText;
      }
    }

    // dynamic full width class for CTA button
    const fullWidthClass = isFullWidth ? ' full-width' : '';

    // build quickview CTA HTML
    const html = ctaUrl
      ? `
        <a
          href="${ctaUrl}"
          class="btn btn--primary quickview__details-cta${fullWidthClass}"
        >
          ${ctaText}
        </a>
      ` : `
        <button
          type="button"
          class="btn btn--primary quickview__details-cta ${c.dom.atc}${fullWidthClass}"
          data-variant-id="${productJson.variants[0].id}"
          ${!isAvailable ? 'disabled="disabled"' : ''}
        >
          ${isAvailable ? ctaText : 'Out of Stock'}
        </button>
      `;

    return html.trim();
  }

  /**
   * Get Product Colors
   * @param {Object} productJson
   * @returns {Array}
   */
  function getProductColors(productJson, prodVariants) {
    const { metafields: variants } = prodVariants;
    const colorOption = productJson.options.filter((option) => (
      option.toLowerCase() === 'frame style' || option.toLowerCase() === 'color'
    ));
    const colorOptions = [];
    const optionsArray = [];
    if (colorOption.length > 0) {
      const variantsOptions = productJson.variants.filter((variant) => variant.available);
      variantsOptions.forEach((variantOption) => {
        const variantId = variantOption.id;
        const firstVariantId = productJson.variants[0].id;
        const variantMetafields = prodVariants.metafields[(variantId || firstVariantId)];
        if (variantMetafields.discontinued) return;

        const variantSwatchAsset = variants[variantOption.id]?.swatch || null;

        if (variantSwatchAsset && variantOption.option1) {
          const swatchObject = {
            color: variantOption.option1.toLowerCase(),
            id: variantOption.id,
            value: variantSwatchAsset,
            featured_image: variantOption?.featured_image || null,
          };

          if (swatchObject && !colorOptions.includes(swatchObject.color)) {
            if (swatchObject.value.includes('//')) {
              swatchObject.type = 'image';
            } else {
              swatchObject.type = 'color';
            }
            colorOptions.push(swatchObject.color);
            optionsArray.push(swatchObject);
          }
        }
      });

      return optionsArray;
    }
    return [];
  }

  /**
   * Handle Quickview Swatch Click for frameSpec
   * @param {string} product id
   */
  function dispatchUpdateSpecOnSwatchClick(productId, variantId, handle, color) {
    const $content = $quickview.querySelector(`.${c.dom.content}`);
    __.addClass($content, c.cls.loading);

    const event = new CustomEvent(`updateSpecOnSwatchClick-${productId}`, {
      detail: {
        id: variantId,
        callback: () => {
          fetchProductData(handle, color, null, null, $productCard);
        },
      },
    });
    document.dispatchEvent(event);
  }

  /**
   * Handle Quickview Swatch Click
   * @param {Object} e - click event
   */
  function handleQuickviewSwatchClick(e) {
    const $btn = !__.hasClass(e.target, c.dom.swatch)
      ? __.parents(e.target, `.${c.dom.swatch}`)[0]
      : e.target;
    if (!$btn) return;
    const { color, handle, id } = $btn.dataset;
    if (!color || !handle) return;

    if (id && $productCard) {
      const { productId } = $productCard.dataset;
      dispatchUpdateSpecOnSwatchClick(productId, id, handle, color);
      return;
    }

    fetchProductData(handle, color);
  }

  return {
    init,
    close: quickviewClose,
    open: quickviewOpen,
  };
};
