import Flickity from 'flickity';

/*
* Before/After Carousel
* @param {object} opts
*/

function beforeAfterCarousel(opts) {
  const config = {
    dom: {
      carousel: 'js-before-after-carousel',
      carouselCell: 'js-before-after-carousel-cell',
      beforeBtn: 'js-before-after-carousel-btn-before',
      afterBtn: 'js-before-after-carousel-btn-after',
      beforeImg: 'js-before-after-carousel-img-before',
      afterImg: 'js-before-after-carousel-img-after',
    },
    flickityOpts: {
      desktop: {
        prevNextButtons: true,
        pageDots: true,
        draggable: true,
        wrapAround: true,
        imagesLoaded: true,
        lazyLoad: true,
        adaptiveHeight: false,
      },
      mobile: {
        prevNextButtons: false,
        pageDots: true,
        draggable: true,
        wrapAround: true,
        imagesLoaded: true,
        lazyLoad: true,
        adaptiveHeight: false,
      },
    },
    cls: {
      active: 'is-active',
    },
  };

  const c = __.extend(config, opts);
  const $carousels = document.body.querySelectorAll(`.${c.dom.carousel}`);
  let view = null;

  /**
   * Init
   */
  function init() {
    if (!$carousels?.length) return;
    onWindowResize();
    __.windowResize(onWindowResize);
  }

  /**
  * Responsive functions
  */
  function onWindowResize() {
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          createCarousel(c.flickityOpts.desktop);
        }
      },
    });

    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
          createCarousel(c.flickityOpts.mobile);
        }
      },
    });
  }

  /**
   * Create Carousel
   * @param {object} opts.flickityOpts
   */
  function createCarousel(flickityOpts) {
    $carousels.forEach(($carousel) => {
      let flickity = Flickity.data($carousel);
      if (flickity) flickity.destroy();

      setTimeout(() => {
        flickity = new Flickity($carousel, flickityOpts);
      }, 500);

      const updateCellHeight = () => {
        let maxHeight = 0;
        const carouselCells = $carousel.querySelectorAll(`.${c.dom.carouselCell}`);
        carouselCells.forEach((cell) => {
          cell.style.height = 'auto'; // Reset the height first
          const cellHeight = cell.offsetHeight;
          if (cellHeight > maxHeight) {
            maxHeight = cellHeight;
          }
        });
        carouselCells.forEach((cell) => {
          cell.style.height = `${maxHeight}px`; // Apply the maximum height
        });
      };

      updateCellHeight(); // Initial calculation

      window.addEventListener('resize', () => {
        flickity.resize();
        updateCellHeight();
      });

      const $carouselCells = $carousel.querySelectorAll(`.${c.dom.carouselCell}`);
      toggleImages($carouselCells);
    });
  }

  /**
   * Toggle Before/After Images
   * @param {NodeListOf<Element>} carouselCells - List of carousel cells
   */
  function toggleImages(carouselCells) {
    carouselCells.forEach(($cell) => {
      const $beforeBtn = $cell.querySelector(`#${c.dom.beforeBtn}`);
      const $afterBtn = $cell.querySelector(`#${c.dom.afterBtn}`);
      const $beforeImg = $cell.querySelector(`#${c.dom.beforeImg}`);
      const $afterImg = $cell.querySelector(`#${c.dom.afterImg}`);

      __.addEvent({
        id: $beforeBtn,
        event: 'click',
        fn: () => {
          __.removeClass($afterImg, c.cls.active);
          __.removeClass($afterBtn, c.cls.active);
          __.addClass($beforeImg, c.cls.active);
          __.addClass($beforeBtn, c.cls.active);
        },
      });

      __.addEvent({
        id: $afterBtn,
        event: 'click',
        fn: () => {
          __.removeClass($beforeImg, c.cls.active);
          __.removeClass($beforeBtn, c.cls.active);
          __.addClass($afterImg, c.cls.active);
          __.addClass($afterBtn, c.cls.active);
        },
      });
    });
  }

  return init();
}

export default beforeAfterCarousel;
