/**
 * Carousel
 */

import Flickity from 'flickity';

function heroCategoryTile(opts) {
  const config = {
    dom: {
      carousel: 'js-hero-category-carousel',
      carouselCell: 'js-hero-category-carousel-cell',
    },
    flickityOpts: {
      contain: true,
      adaptiveHeight: false,
      cellAlign: 'left',
      prevNextButtons: false,
      pageDots: true,
      watchCSS: true,
      draggable: true,
      dragThreshold: 10,
      wrapAround: true,
      imagesLoaded: true,
    },
    cls: {
      fullHeight: 'full-height',
    },
  };

  // Shorthand config
  const c = __.extend(config, opts); // Extend defaults with passed options

  // cached global(s)
  const $carousels = document.querySelectorAll(`.${c.dom.carousel}`);
  let view;

  /**
   * Init
   */
  function init() {
    if ($carousels?.length > 0) {
      responsive();
      __.windowResize(responsive);
    }
  }

  /**
   * Make carousel
   */
  function makeCarousel() {
    $carousels.forEach(($carousel) => {
      const $carouselItems = $carousel.querySelectorAll(`.${c.dom.carouselCell}`);

      [...$carouselItems].forEach(($item) => {
        __.removeClass($item, c.cls.fullHeight);
      });

      c.flickityOpts.on = {
        ready: () => {
          [...$carouselItems].forEach(($item) => {
            __.addClass($item, c.cls.fullHeight);
          });
        },
      };
      setTimeout(() => {
        /* eslint-disable-next-line */
        new Flickity($carousel, c.flickityOpts);
      }, 300);
    });
  }

  /**
   * Responsive functions
   */
  function responsive() {
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
        }
      },
    });

    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
        }
      },
    });

    makeCarousel();
  }
  return init();
}

export default heroCategoryTile;
