/**
 * Creates a hash for an upsell
 * @param {number | string} mainProductId - main product id
 * @param {number | string} variantIdsToAdd - array of variant ids
 */
__.setHash = function(mainProductId, variantIds) {
  const list = [mainProductId];
  variantIds.forEach((id) => {
    list.push(id);
  });

  return window.btoa(list.join('-'));
}
