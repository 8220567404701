import { createApp } from 'vue';
import Cart from '../components/cart/Cart';

function cartApp(opts) {
  const config = {
    dom: {
      parent: 'cartApp',
    },
  };

  const c = __.extend(config, opts);

  // Globals
  const $parent = document.getElementById(c.dom.parent);
  function init() {
    if (!$parent) return;

    const app = createApp(Cart);

    app.config.globalProperties.$config = {
      joineryHost: SDG.Data.joineryHost || 'https://staging.framebridge.com',
    };

    app.mount(`#${c.dom.parent}`);
  }

  init();
}

export default cartApp;
