import Flickity from 'flickity';

/*
* Related Articles
* @param {object} opts
*/

function relatedArticles(opts) {
  const config = {
    dom: {
      section: 'js-related-articles',
      carousel: 'js-related-articles-content-wrapper',
      carouselCell: 'js-related-articles-content',
    },
    flickityOpts: {
      prevNextButtons: false,
      pageDots: true,
      draggable: true,
      wrapAround: true,
      imagesLoaded: true,
      lazyLoad: true,
    },
  };

  const c = __.extend(config, opts);
  const $sections = document.querySelectorAll(`.${c.dom.section}`);
  const $carousels = document.querySelectorAll(`.${c.dom.carousel}`);

  /**
  * Init
  */
  function init() {
    if (!$sections?.length) return;
    toggleCarousel();

    __.windowResize(toggleCarousel);
  }

  /**
  * Toggle Carousel
  */
  function toggleCarousel() {
    if (!$carousels?.length) return;
    const windowWidth = window.innerWidth;

    $carousels.forEach(($carousel) => {
      const carouselWidth = calculateCarouselWidth($carousel);
      let flickity = Flickity.data($carousel);

      if (windowWidth <= carouselWidth) {
        flickity = new Flickity($carousel, c.flickityOpts);
        updateCellHeight($carousel);

        window.addEventListener('resize', () => {
          flickity.resize();
          updateCellHeight($carousel);
        });
      } else if (windowWidth > carouselWidth && flickity) {
        flickity.destroy();
      }
    });
  }

  /**
  * Calculate Carousel Width
  * @param {object} carousel
  */
  function calculateCarouselWidth($carousel) {
    const $cells = $carousel.querySelectorAll(`.${c.dom.carouselCell}`);
    let cellsWidth = 0;

    $cells.forEach((cell) => {
      cellsWidth += cell.offsetWidth;
    });

    return cellsWidth;
  }

  /**
  * Calculate Carousel Cell Height
  * @param {object} carousel
  */
  const updateCellHeight = (carousel) => {
    let maxHeight = 0;
    const carouselCells = carousel.querySelectorAll(`.${c.dom.carouselCell}`);
    carouselCells.forEach((cell) => {
      cell.style.height = 'auto'; // Reset the height first
      const cellHeight = cell.offsetHeight;
      if (cellHeight > maxHeight) {
        maxHeight = cellHeight;
      }
    });
    carouselCells.forEach((cell) => {
      cell.style.height = `${maxHeight}px`; // Apply the maximum height
    });
  };

  return init();
}

export default relatedArticles;
