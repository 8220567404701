<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <section>
    <div
      class="video"
      :class="wrapperClass"
      @click="togglePlay"
    >
      <div
        class="ir"
        :class="modifierClass"
      >
        <video
          class="video"
          ref="videoElement"
          :src="selectedSource"
          :poster="coverImage"
          playsinline
          :controls="controls"
          :autoplay="autoplay"
          loop
          @play="isPlaying = true"
          @pause="isPlaying = false"
          style="object-fit: contain; width: 100%; height: 100%;"
        />
        <i v-if="isPlaying" class="icon icon--video-pause--mobile" />
        <i v-if="!isPlaying" class="icon icon--video-play--mobile" />
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed, toRefs } from 'vue';

const props = defineProps({
  asset: {
    type: Object,
    required: true,
  },
  autoplay: {
    type: Boolean,
    default: false,
  },
  controls: {
    type: Boolean,
    default: false,
  },
  modifier: {
    type: String,
    default: 'video',
  },
  wrapperClass: {
    type: String,
    default: '',
  },
});

// Reactive properties
const videoElement = ref(null);
const isPlaying = ref(false);
const { asset, modifier } = toRefs(props);

// Computed properties
const coverImage = computed(() => asset?.value.preview_image?.src);
const selectedSource = computed(() => {
  const mp4Sources = asset?.value.sources.filter((source) => source.mime_type === 'video/mp4');
  return mp4Sources.sort((a, b) => a.height - b.height)[0]?.url || mp4Sources[0]?.url || '';
});
const modifierClass = computed(() => `ir--${modifier.value}`);

// Methods
const togglePlay = () => {
  if (!videoElement.value) return;

  if (isPlaying.value) {
    videoElement.value.pause();
  }

  if (!isPlaying.value) {
    videoElement.value.play();
  }
};
</script>
