<template>
  <section class="product-configurator__media-gallery">
    <div class="product-configurator__media-wrapper">
      <div
        v-show="isUploadProcessing"
        class="product-configurator__uploading"
        style="position: absolute"
      >
        <i class="icon icon--spinner product-configurator__uploading-icon"></i>
        <p
          v-if="!frameSpec"
          class="product-configurator__uploading-text">
          {{ uploadPercent }}% Uploaded
        </p>
      </div>
      <MaskedRenderer
        v-if="(frameSpec && hasMaskRender && canPrintArtwork)"
        :frameSpec="frameSpec"
        :artwork="frameSpecUrl"
        class="content single-frame-preview-svg product-configurator__pv-image"
      />
      <SingleFramePreviewSvg
        v-else-if="(frameSpec && canPrintArtwork)"
        :frameSpec="frameSpec"
        class="content single-frame-preview-svg product-configurator__pv-image"
      />
      <ResponsiveImage
        v-else-if="(mainImage)"
        :image="mainImage"
        :image-alt="title"
        image-class="product-configurator__pv-image"
        modifier="product-configurator"
      />
      <div
        class="product-configurator__media-zoom"
        :class="{'product-configurator__media-zoom--upload': frameSpec}"
      >
        <button
          class="product-configurator__media-zoom-btn"
          type="button"
          @click="toggleGallery"
        >
          <i class="icon icon--zoom-out-half"></i>
          <span class="screenreader">Open Gallery</span>
        </button>
      </div>
    </div>
    <div
      v-if="frameSpec"
      class="product-configurator__media-actions"
    >
      <a
        :href="editImageUrl"
        class="product-configurator__media-actions-btn product-configurator__media-actions-crop"
        type="button"
      >
        <i class="icon icon--crop"></i>
        Crop/Edit Photo
      </a>
      <button
        class="product-configurator__media-actions-btn"
        type="button"
        @click="remove"
      >
        <i class="icon icon--trash-bin"></i>
        Remove Photo
      </button>
    </div>
  </section>
</template>

<script setup>
import {
  defineProps,
  toRefs,
} from 'vue';
import SingleFramePreviewSvg from '@framebridge/toolbox/SingleFramePreviewSvg';
import MaskedRenderer from '@framebridge/toolbox/MaskedRenderer';
import ResponsiveImage from '../common/responsive-image/ResponsiveImage';

const props = defineProps({
  canPrintArtwork: {
    type: Boolean,
    required: false,
    default: false,
  },
  frameSpec: {
    type: Object,
    required: false,
    default: null,
  },
  frameSpecUrl: {
    type: String,
    required: false,
    default: null,
  },
  mainImage: {
    type: Object,
    required: false,
    default: null,
  },
  title: {
    type: String,
    required: false,
    default: null,
  },
  editImageUrl: {
    type: String,
    required: false,
    default: null,
  },
  isUploadProcessing: {
    type: Boolean,
    required: false,
    default: false,
  },
  uploadPercent: {
    type: Number,
    required: false,
    default: 0,
  },
  hasMaskRender: {
    type: Boolean,
    required: false,
    default: false,
  },
  toggleGallery: {
    type: Function,
    required: true,
  },
  resetVariant: {
    type: Function,
    required: true,
    default: null,
  },
});
const { resetVariant } = toRefs(props);

const remove = () => {
  resetVariant?.value?.();
};
</script>
