<template>
  <section class="bag-item__instructions">
    <VueAccordion
      :title="(specialInstructions !== '' && !accordionRef?.showPanel
      ? specialInstructionsAddedState : specialInstructionsLabelText)"
      :ariaTitle="(`${productId}-special-instructions`)"
      :defaltOpen="false"
      :buttonClass="'bag-item__instructions-btn'"
      ref="accordionRef"
    >
      <div class="bag-item__instructions-text-wrapper">
        <textarea
          class="bag-item__instructions-text"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          :value="specialInstructions"
          @input="handleChange"
          @blur="saveOnUnfocus"
          :placeholder="specialInstructionsPlaceholderText"
        >
        </textarea>
        <button
          class="bag-item__instructions-submit"
          @click="postNote"
        >
          <span>Save Note</span>
        </button>
      </div>
      <template #opened-icon>
        <i class="cart-upsell__header-icon icon icon--minus"></i>
        <span class="screenreader">Minimize Options</span>
      </template>
      <template #closed-icon>
        <span
        v-if="(specialInstructions === '')"
        >
          <i class="cart-upsell__header-icon icon icon--plus"></i>
          <span class="screenreader">Expand Options</span>
        </span>
        <span
          v-else
        >
          <span class="cart-upsell__header-icon">Edit</span>
        </span>
      </template>
    </VueAccordion>
    <div
      v-if="(specialInstructions !== '' && !accordionRef?.showPanel)"
    >
      <p class="bag-item__instructions-note">{{ specialInstructions }}</p>
    </div>
  </section>
</template>
<script>
import {
  toRefs,
  unref,
  ref,
  onMounted,
} from 'vue';
import useCart from '../composables/cart-actions';
import VueAccordion from '../common/accordion/Accordion';

export default {

  name: 'CartItemInstructions',
  components: {
    VueAccordion,
  },
  props: {
    productId: {
      type: String,
      default: '',
      required: true,
    },
    productQty: {
      type: Number,
      default: 1,
      required: true,
    },
    productProperties: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    isWishlist: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { updateCartItemById } = useCart();
    const {
      productId,
      productProperties,
      productQty,
      isWishlist,
    } = toRefs(props);
    const isSaving = ref(false);
    const specialInstructions = ref('');
    const accordionRef = ref(false);

    function decodeHTML(string) {
      return String(string)
        .replace(/&amp;/g, '&') // Converts &amp; to &
        .replace(/&quot;/g, '"') // Converts &quot; to "
        .replace(/&#39;/g, "'"); // Converts &#39; to '
    }
    const specialInstructionsLabelText = ref(
      decodeHTML(SDG.Data.cart.add_special_instructions_label_text),
    );
    const specialInstructionsAddedState = ref(
      decodeHTML(SDG.Data.cart.special_instructions_added_state_text),
    );
    const specialInstructionsPlaceholderText = ref(
      decodeHTML(SDG.Data.cart.special_instructions_placeholder_text),
    );

    onMounted(() => {
      // eslint-disable-next-line no-underscore-dangle
      specialInstructions.value = unref(productProperties.value)._specialInstructionsMessage || '';
    });

    function handleChange(event) {
      specialInstructions.value = event.target.value;
    }

    function saveOnUnfocus() {
      postNote();

      if (accordionRef.value && accordionRef.value.showPanel) {
        accordionRef.value.closePanel();
      }
    }

    function postNote(event = null) {
      // stop double saving on unfocus
      if (isSaving.value) return;
      isSaving.value = true;
      const properties = unref(productProperties.value) || {};

      const newProperties = {
        ...properties,
        _specialInstructions: 'true',
        _specialInstructionsMessage: specialInstructions.value,
      };

      const closeAcc = () => {
        accordionRef.value.closePanel();
        isSaving.value = false;
      };

      updateCartItemById(
        event,
        productId.value,
        newProperties,
        productQty.value,
        isWishlist.value,
        false,
        closeAcc,
      );
    }

    return {
      accordionRef,
      specialInstructions,
      specialInstructionsLabelText,
      specialInstructionsAddedState,
      specialInstructionsPlaceholderText,
      handleChange,
      postNote,
      saveOnUnfocus,
      decodeHTML,
    };
  },
};
</script>
