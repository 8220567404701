<template>
  <div>
    <aside
      class="store-selector modal js-modal"
      id="storeSelectorModal"
      aria-hidden="true"
      aria-labelledby="storeSelector"
      role="dialog"
    >
      <div class="store-selector__inner modal__inner">
        <header class="store-selector__header modal__header">
          <button
            class="store-selector__close modal__close btn-icon js-modal-close"
            id="modalCloseButton"
            type="button"
            title="Close modal"
            :tabindex="0"
          >
            <i class="icon icon--close"></i>
            <span class="store-selector__close-text">
              close
            </span>
          </button>
        </header>
        <div class="store-selector__content modal__content">
          <div class="store-selector__content-header">
            <h3
              class="store-selector__heading"
              id="storeSelector"
            >
              Find a store near you
            </h3>
            <div class="store-selector__content-search">
              <form
                class="store-selector__form"
                id="storeSelectorForm"
              >
                <input
                  class="js-hidden-item-id"
                  type="hidden"
                  value=""
                >
                <input
                  class="js-hidden-item-properties"
                  type="hidden"
                  value=""
                >
                <fieldset class="store-selector__fieldset">
                  <legend class="screenreader">Find a store near you</legend>
                  <ul class="form-list">
                    <li class="form-item">
                      <div class="input-placeholder">
                        <input
                          class="input zip-code"
                          id="zip_code"
                          type="text"
                          placeholder="Zip Code"
                        />
                        <label for="zip_code">
                          Zip Code
                        </label>
                      </div>
                    </li>
                    <li class="form-item">
                      <div
                        class="store-selector__dropdown-menu js-store-selector-dropdown-menu"
                        id="js-store-selector-dropdown-menu"
                      >
                        <h2 class="dropdown-heading">
                          <button
                            class="js-store-selector-trigger options-label"
                            id="dropdown-btn-store-selector"
                            type="button"
                            :tabindex="0"
                            aria-label="Open dropdown menu"
                            aria-controls="storeSelectorDropdownWrapper"
                            aria-expanded="false"
                          >
                            <span class="options-label__select js-store-selector-label">
                              Radius
                            </span>
                            <span class="options-label__text js-store-selector-trigger-text">
                              Select
                            </span>
                            <span class="options-label__icon icon icon--chevron-down"></span>
                          </button>
                        </h2>
                        <div
                          class="store-selector__dropdown-wrapper"
                          id="storeSelectorDropdownWrapper"
                          :tabindex="0"
                          aria-labelledby="dropdown-btn-store-selector"
                          role="region"
                        >
                          <ul class="store-selector__dropdown-list list-reset">
                            <li
                              v-for="radius in radiusDropdown"
                              :key="radius.value"
                              class="dropdown-list__item js-dropdown-list-item"
                              :class="{ 'is-active': index && index === 0 }"
                              :data-option="radius.value"
                              :data-text="radius.text"
                              :data-value="radius.value"
                            >
                              <button
                                class="dropdown-list__item-btn"
                                type="button"
                                :aria-pressed="{ 'true': index && index === 0 }"
                              >
                                <span class="dropdown-list__item-btn-text">
                                  {{ radius.text }}
                                </span>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li
                      class="store-selector__form-btn form-item js-store-selector-search-btn"
                      id="js-store-selector-search-btn"
                    >
                      <button
                        class="btn btn--primary btn--full-width"
                        type="button"
                        @click="setNearestStoreData"
                      >
                        Search
                      </button>
                    </li>
                  </ul>
                </fieldset>
              </form>
            </div>
          </div>
          <div class="store-selector__content-stores">
            <div class="store-selector__content-result-text">
              <p
                v-if="storeCount"
                class="store-selector__content-result-count js-store-result-count"
              >
                {{ storeCount }} location<span v-if="storeCount > 1">s</span>
              </p>
            </div>
            <div
              v-if="storeCount === 0"
              class="store-selector__content-result-list"
            >
              <p>No stores available</p>
            </div>
            <div v-else class="store-selector__content-result-list">
              <SelectedStore
                v-for="store in stores"
                :key="store.name"
                :store="store"
                :storesWithDistance="storesWithDistance"
                @set-store-data="handleStoreData"
              ></SelectedStore>
            </div>
          </div>
        </div>
      </div>
    </aside>
    <div
      class="store-selector__overlay modal-overlay"
      id="storeSelectorModalOverlay"
    ></div>
  </div>
</template>
<script>
import {
  onMounted,
  ref,
} from 'vue';
import storeSelector from '../../lib/store-selector';
import useCart from '../composables/cart-actions';
import trapFocus from '../../lib/trap-focus';
import SelectedStore from './SelectedStore';

export default {
  name: 'StoreSelector',
  components: {
    SelectedStore,
  },
  setup(props, context) {
    const {
      addDropOffStore,
    } = useCart();

    const radiusDropdown = JSON.parse(SDG.Data.radiusDropdownOptions || null);
    const widgetTag = SDG.Data.stockistWidgetTag;
    const { mapBoxApiKey } = SDG.Data;
    const storage = window.sessionStorage;
    const stores = ref([]);
    const storeCount = ref(0);
    const storesWithDistance = ref(null);
    const shipToUsPropertyName = ref('shipToUs');

    onMounted(() => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setDefaultDropOffStores(position);
            storeSelector();
          },
          (error) => {
            console.error(error);
            setDefaultDropOffStores();
            storeSelector();
          },
        );
      } else {
        console.warn('Geolocation is not available.');
        setDefaultDropOffStores();
        storeSelector();
      }
    });

    const handleStoreData = (newStore) => {
      if (newStore) {
        const itemId = document.querySelector('.js-hidden-item-id');
        const itemIdValue = itemId.value || null;
        const itemProperties = document.querySelector('.js-hidden-item-properties');
        const itemPropertiesValue = JSON.parse(itemProperties.value) || null;
        const itemPropertiesArray = itemPropertiesValue
          ? Object.entries(itemPropertiesValue)
          : [];

        const filteredItemPropertiesArray = itemPropertiesArray.filter(
          (property) => property[0] !== shipToUsPropertyName.value,
        );

        const filteredItemProperties = {};
        filteredItemPropertiesArray.forEach(
          (array) => {
            const key = array[0];
            const value = array[1];
            filteredItemProperties[key] = value;
          },
        );

        if (itemIdValue != null) {
          addDropOffStore(itemIdValue, filteredItemProperties, newStore);
          const newStoreData = {
            storeName: newStore,
            itemId: itemIdValue,
          };

          context.emit('setDropOffStore', newStoreData);
        }
      }
    };

    const storeSelectorBtn = () => {
      const $body = document.body;
      __.addClass($body, 'is-store-selector-exposed');
      const $storeSelectorModal = document.getElementById('storeSelectorModal');

      setTimeout(() => {
        trapFocus($storeSelectorModal);
      }, 150);
    };

    /**
     * Set the Stockist store data
     */
    const setDefaultDropOffStores = async (geolocationPosition = null) => {
      let coords = null;

      if (storage) storage.setItem('dropOffStores', null);
      const ipStackData = storage.getItem('ipStackData')
        ? JSON.parse(storage.getItem('ipStackData'))
        : null;

      if (geolocationPosition !== null) {
        coords = {
          latitude: geolocationPosition.coords.latitude,
          longitude: geolocationPosition.coords.longitude,
        };
      }

      if (coords === null) {
        // If no Geolocation data set coords from IPStack data
        const { latitude, longitude } = ipStackData || {};
        coords = { latitude, longitude };
      }

      const { latitude, longitude } = coords;

      const stockistEndpoint = latitude && longitude
        ? `https://stockist.co/api/v1/${widgetTag}/locations/search?latitude=${latitude}&longitude=${longitude}`
        : `https://stockist.co/api/v1/${widgetTag}/locations/all`;

      const results = await fetch(stockistEndpoint)
        .then((searchResult) => searchResult.json())
        .catch((err) => console.error(err));

      const filteredResults = (latitude && longitude ? results.locations : results)
        .filter((result) => (
          result.custom_fields.find((field) => (
            field.name.toLowerCase() === 'drop off in store' && field.value.toLowerCase() === 'true'
          ))
        ));

      if (storage) storage.setItem('dropOffStores', JSON.stringify(filteredResults));
      stores.value = filteredResults;
      storeCount.value = filteredResults.length;
    };

    /**
     * Display the Store Selector nearest stores
     */
    function displayNearestDropOffStores(storesData, distance) {
      if (!distance || distance === 'all') {
        stores.value = storesData;
        storeCount.value = storesData.length;
      } else {
        const filteredStoresData = storesData.filter(
          (singleDropOffStore) => singleDropOffStore.distance <= distance,
        );

        if (filteredStoresData && filteredStoresData.length > 0) {
          stores.value = filteredStoresData;
          storeCount.value = filteredStoresData.length;
        } else {
          stores.value = [];
          storeCount.value = 0;
        }
      }
    }

    /**
     * Set the Stockist store data
     */
    const setNearestStoreData = async () => {
      if (storage) storage.setItem('dropOffStores', null);

      const $form = document.getElementById('storeSelectorForm');
      const zipCode = $form.querySelector('.zip-code').value || null;
      const selectOption = $form.querySelector('.js-store-selector-trigger-text').dataset.value || 0;

      if (zipCode) {
        // Set Mapbox data
        await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${zipCode}.json?access_token=${mapBoxApiKey}`)
          .then((resp) => resp.json())
          .then(async (data) => {
            // Get Stockist data
            const results = data.features;

            if (results.length > 0) {
              const result = results[0].center;
              const latitude = result[1];
              const longitude = result[0];

              await fetch(`https://stockist.co/api/v1/${widgetTag}/locations/search?latitude=${latitude}&longitude=${longitude}`)
                .then((resp) => resp.json())
                .then((searchResult) => {
                  const allStores = searchResult.locations;
                  const filteredStores = allStores.filter((store) => {
                    const customFields = store.custom_fields;
                    return customFields.find((field) => field.name.toLowerCase() === 'drop off in store' && field.value.toLowerCase() === 'true');
                  });

                  if (storage) storage.setItem('dropOffStores', JSON.stringify(filteredStores));

                  stores.value = filteredStores;
                  storesWithDistance.value = filteredStores;
                  displayNearestDropOffStores(filteredStores, parseInt(selectOption, 10));
                })
                .catch((err) => console.error(err));
            } else {
              stores.value = [];
              storeCount.value = 0;
            }
          })
          .catch((err) => console.error(err));
      } else {
        setDefaultDropOffStores();
      }
    };

    return {
      storeSelectorBtn,
      radiusDropdown,
      stores,
      storeCount,
      storesWithDistance,
      setNearestStoreData,
      handleStoreData,
    };
  },
};
</script>
