/**
 * Search
 * @param {object} opts
 */

import trapFocus from './trap-focus';
import productItemApp from './product-item-app';
import collectionPagination from './collection-pagination';
import articlesPagination from './articles-pagination';
import collectionFilters from './collection-filters';
import collectionQuickview from './collection-quickview';
import collectionToolbar from './collection-toolbar';
import articlesToolbar from './articles-toolbar';

function search(opts) {
  const config = {
    dom: {
      header: 'header',
      nav: 'navigation',
      navOverlay: 'navigationOverlay',
      navMenuBtn: 'js-nav-menu-btn',
      search: 'searchNavigation',
      searchOverlay: 'searchOverlay',
      searchBtn: 'searchBtn',
      searchCloseBtn: 'searchCloseBtn',
      searchForm: 'searchForm',
      searchInput: 'searchInput',
      searchNoResults: 'searchNoResults',
      defaultSearchResults: 'defaultSearchResults',
      searchResults: 'searchResults',
      predictiveSearchResults: 'predictiveSearchResults',
      noResultsCTA: 'noResultsCta',
      searchTabsWrapper: 'searchTabs',
      searchTabs: 'js-search-tabbed-btn',
      articlesTabContent: 'articlesTab',
      articlesWrapper: '#articlesWrapper',
      collection: 'collection',
      tabbedContent: 'js-tabbed-content',
      productsGrid: '#productsGrid',
      searchResultsCount: '.js-search-results-count',
      collectionPagination: 'js-collection-pagination',
      articlesPagination: 'js-articles-pagination',
    },
    cls: {
      active: 'is-active',
      hide: 'hide',
      no_scroll: 'overflow-hidden',
    },
  };

  const c = __.extend(config, opts);
  const $header = document.getElementById(c.dom.header);
  const $search = document.getElementById(c.dom.search);
  const $searchOverlay = document.getElementById(c.dom.searchOverlay);
  const $searchBtn = document.getElementById(c.dom.searchBtn);
  const $searchCloseBtn = document.getElementById(c.dom.searchCloseBtn);
  const $searchInput = document.getElementById(c.dom.searchInput);
  const $searchForm = document.getElementById(c.dom.searchForm);
  const $searchNoResults = document.getElementById(c.dom.searchNoResults);
  const $defaultSearchResults = document.getElementById(c.dom.defaultSearchResults);
  const $searchResults = document.getElementById(c.dom.searchResults);
  const $nav = document.getElementById(c.dom.nav);
  const $navOverlay = document.getElementById(c.dom.navOverlay);
  const $searchTabsWrapper = document.getElementById(c.dom.searchTabsWrapper);
  const $itemCount = document.querySelector(c.dom.searchResultsCount);
  let productCount;
  let articleCount;

  /**
   * Init
   */
  function init() {
    if ($header
      && $search
      && $searchOverlay
      && $searchBtn
      && $searchCloseBtn
      && $searchInput
      && $searchForm
      && $searchNoResults
      && $defaultSearchResults
      && $searchResults
      && $nav
      && $navOverlay
    ) {
      addGlobalEvents();
    }

    // Search Page events
    if (window.location.pathname === '/search') {
      addSearchPageEvents();
    }
  }

  /**
   * Add Global Events
   */
  function addGlobalEvents() {
    // Search open event
    __.addEvent({
      id: $searchBtn,
      event: 'click',
      fn: toggleSearchVisibility,
    });

    // Search open event
    __.addEvent({
      id: c.dom.noResultsCTA,
      event: 'click',
      fn: toggleSearchVisibility,
    });

    // Search open event
    __.addEvent({
      id: $searchTabsWrapper,
      event: 'click',
      className: c.dom.searchTabs,
      fn: handleTabVisibility,
    });

    // Search close event
    __.addEvent({
      id: $searchCloseBtn,
      event: 'click',
      fn: closeSearch,
    });

    __.addEvent({
      id: $searchInput,
      event: 'input',
      fn: (e) => __.debounce(getSuggestions(e.target.value), 300),
    });
  }

  /**
   * Add Search Page Events
   */
  function addSearchPageEvents() {
    productItemApp({
      dom: {
        parent: 'collection',
      },
    });

    // Collection Toolbar
    collectionToolbar();

    // Articles Toolbar
    articlesToolbar();

    // Collection Pagination
    collectionPagination();

    // Collection Filters
    collectionFilters();

    // Collection Quickview
    collectionQuickview();

    if ($itemCount) {
      productCount = $itemCount.children[0].innerHTML;
    }

    fetchArticles();
  }

  /**
   * Handle Tab Visibility
   */
  function handleTabVisibility() {
    const $searchTabs = document.querySelectorAll(`.${c.dom.searchTabs}`);
    const $tabbedContent = document.querySelectorAll(`.${c.dom.tabbedContent}`);

    $searchTabs.forEach((tab) => {
      tab.classList.toggle(c.cls.active);
    });
    $tabbedContent.forEach((content) => {
      content.classList.toggle(c.cls.hide);
    });

    const articlesContent = $tabbedContent[0];
    const productsContent = $tabbedContent[1];

    if (!__.hasClass(articlesContent, c.cls.hide)) {
      $itemCount.children[0].innerHTML = articleCount;
      articlesContent.setAttribute('aria-hidden', 'false');
      articlesContent.setAttribute('aria-open', 'true');
      productsContent.setAttribute('aria-hidden', 'true');
      productsContent.setAttribute('aria-open', 'false');
      window.document.title = window.document.title.replace(/\d+/g, articleCount);
    } else {
      $itemCount.children[0].innerHTML = productCount;
      productsContent.setAttribute('aria-hidden', 'false');
      productsContent.setAttribute('aria-open', 'true');
      articlesContent.setAttribute('aria-hidden', 'true');
      articlesContent.setAttribute('aria-open', 'false');
      window.document.title = window.document.title.replace(/\d+/g, productCount);
    }
  }

  /**
   * Fetch Articles
   */
  function fetchArticles() {
    const url = new URL(window.location.href);

    const params = new URLSearchParams(url.search);
    params.delete('type');
    params.append('type', 'article,page');
    const fetchUrl = `${window.location.origin}${window.location.pathname}?q=${params.get('q')}&type=article,page`;

    let requestResponse;
    fetch(fetchUrl)
      .then((response) => {
        requestResponse = response;
        return response.text();
      })
      .then((text) => {
        if (!requestResponse.ok) {
          throw new Error(`${requestResponse.status}: ${text}`);
        }

        const resultsMarkup = new DOMParser()
          .parseFromString(text, 'text/html')
          .getElementById(c.dom.collection);

        const $articlesContent = resultsMarkup.querySelector(c.dom.productsGrid)?.innerHTML;

        if ($articlesContent) {
          const $articlesPagination = resultsMarkup.querySelector(`.${c.dom.collectionPagination}`);
          __.removeClass($articlesPagination, c.dom.collectionPagination);
          __.addClass($articlesPagination, c.dom.articlesPagination);
          const $articleCount = resultsMarkup?.querySelector(c.dom.searchResultsCount);
          articleCount = $articleCount?.children[0].innerHTML;
          const $articlesWrapper = document.querySelector(c.dom.articlesWrapper);
          $articlesWrapper.innerHTML = $articlesContent;
          $articlesWrapper.innerHTML += $articlesPagination.outerHTML;
        }

        // Articles Pagination
        articlesPagination();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**
   * Toggle Search Visibility
   */
  function toggleSearchVisibility() {
    if (__.hasClass($search, c.cls.active)) {
      closeSearch();
    } else {
      openSearch();
    }
  }

  /**
   * Open Search
   * @returns {void}
   * @description Opens the search
   */
  function openSearch() {
    $searchInput.value = '';

    __.addClass($search, c.cls.active);
    __.addClass($searchOverlay, c.cls.active);
    __.addClass(document.body, c.cls.no_scroll);

    // close nav
    __.removeClass($nav, c.cls.active);
    __.removeClass($navOverlay, c.cls.active);
    const $activeMenuBtn = $header.querySelector(`.${c.dom.navMenuBtn}.${c.cls.active}`);
    // disable currently active nav menu button
    if ($activeMenuBtn) {
      __.removeClass($activeMenuBtn, c.cls.active);
    }
    $search.setAttribute('aria-hidden', 'false');

    // setTimeout to allow for animation to complete
    setTimeout(() => {
      trapFocus($search);
      $searchInput.focus();
    }, 500);
  }

  /**
   * Close Search
   */
  function closeSearch() {
    hideNoResults();
    showDefaultSearch();

    __.removeClass($search, c.cls.active);
    __.removeClass($searchOverlay, c.cls.active);
    __.removeClass(document.body, c.cls.no_scroll);
    $searchInput.value = '';
    $search.setAttribute('aria-hidden', 'true');

    // updateSearchList();

    // setTimeout to allow for animation to complete
    setTimeout(() => {
      $searchBtn.focus();
    }, 500);
  }

  /**
   * Get Suggestions
   */
  function getSuggestions(query) {
    const searchTerm = query.trim();

    if (!searchTerm.length) {
      hideNoResults();
      showDefaultSearch();
      return;
    }
    const searchUrl = `${SDG.Data.predictiveSearchUrl}?q=${searchTerm}&resources[type]=product,page,article,query&section_id=predictive-search`;
    if (query) {
      let requestResponse;
      fetch(searchUrl)
        .then((response) => {
          requestResponse = response;
          return response.text();
        })
        .then((text) => {
          if (!requestResponse.ok) {
            throw new Error(`${requestResponse.status}: ${text}`);
          }

          const resultsMarkup = new DOMParser()
            .parseFromString(text, 'text/html')
            .getElementById(c.dom.predictiveSearchResults).innerHTML;

          $searchResults.innerHTML = resultsMarkup;
          if (resultsMarkup.trim() === '') {
            showNoResults();
          } else {
            showResults();
          }
          // in case the user deletes their search faster than the request can complete
          if ($searchInput.value.trim() === '') {
            hideNoResults();
            showDefaultSearch();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  /**
   * Show Results
   */
  function showResults() {
    __.removeClass($searchResults, c.cls.hide);
    $searchInput.setAttribute('aria-expanded', 'true');
    hideNoResults();
    __.addClass($defaultSearchResults, c.cls.hide);
  }

  /**
   * Show No Results
   */
  function showNoResults() {
    __.removeClass($searchNoResults, c.cls.hide);
    showDefaultSearch();
  }

  /**
   * Show Default Search
   */
  function showDefaultSearch() {
    __.removeClass($defaultSearchResults, c.cls.hide);
    $searchInput.setAttribute('aria-expanded', 'false');
    // hide results
    __.addClass($searchResults, c.cls.hide);
  }

  /**
   * Hide No Results
   */
  function hideNoResults() {
    __.addClass($searchNoResults, c.cls.hide);
  }

  return init();
}

export default search;
