<template>
  <div class="store-selector__content-result">
    <div class="store-selector__content-result-details">
      <div class="store-selector__content-result-details-wrapper">
        <div class="store-selector__content-result-image">
          <div class="store-selector__image-wrapper">
            <div class="ir ir--swatch">
              <img
                class="store-selector__image
                  js-store-selector-image
                  lazyautosizes
                  ls-is-cached
                  lazyloaded"
                data-widths="[180]"
                data-sizes="auto"
                tabindex="-1"
                :alt="name"
                :data-srcset="storeImageUrl"
                sizes="150px"
                :srcset="storeImageUrl"
              >
            </div>
          </div>
        </div>
        <div class="store-selector__content-result-info">
          <div class="store-selector__content-result-title">
            <h2>
              {{ name }}
            </h2>
          </div>
          <div class="store-selector__content-result-address">
            <p class="js-store-address-line-1">
              {{ addressLine1 }}
            </p>
            <p
              v-if="addressLine2"
              class="js-store-address-line-2"
            >
                {{ addressLine2 }}
            </p>
            <p class="js-store-city-state-zip">
              {{ city }}, {{ state }} {{ postalCode }}
            </p>
          </div>
        <div
          v-if="distance"
          class="store-selector__content-result-distance"
        >
          <p>
            (<span class="js-store-distance">
              {{ distance }} {{ distanceUnits }}
            </span> away)
          </p>
        </div>
        <div class="store-selector__content-result-phone">
          <p
            v-if="phone"
            class="js-store-phone"
          >
            +1 {{ phone }}
          </p>
        </div>
        </div>
      </div>
    </div>
    <div class="store-selector__content-result-actions">
      <div class="store-selector__content-result-select">
        <button
          class="btn btn--secondary js-set-dropoff-store"
          :data-store="JSON.stringify(name)"
          @click="handleClick"
        >
          Select This Store
        </button>
      </div>
      <div class="store-selector__content-result-view">
        <a
          class="store-selector__content-result-link"
          href=""
          title=""
        >
          See Store Details
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import {
  computed,
  ref,
  toRefs,
  watch,
} from 'vue';

export default {
  name: 'SelectedStore',
  props: {
    store: {
      type: Object,
      default: () => ({
        name: null,
        address_line_1: null,
        address_line_2: null,
        city: null,
        state: null,
        postal_code: null,
        distance: null,
        distance_units: null,
        image_url: null,
      }),
    },
    storesWithDistance: {
      type: Array,
      default: null,
    },
  },
  setup(props, context) {
    const {
      image_url: storeImage,
      name,
      address_line_1: addressLine1,
      address_line_2: addressLine2,
      city,
      state,
      postal_code: postalCode,
      phone,
    } = toRefs(props.store);
    const { storesWithDistance } = toRefs(props);
    const distance = ref(null);
    const distanceUnits = ref(null);
    const placeholderImage = 'https://cdn.shopify.com/s/files/1/0727/7196/1122/files/placeholder_stockist_image.png?v=1682542284_180x.jpg 180w';

    const storeImageUrl = computed(() => {
      if (storeImage?.value) {
        return storeImage.value;
      }

      return placeholderImage;
    });

    watch(storesWithDistance, (newValue) => {
      if (newValue) {
        storesWithDistance.value.forEach((store) => {
          if (store.name === name.value) {
            distance.value = store.distance;
            distanceUnits.value = store.distance_units;
          }
        });
      }
    });

    const handleClick = (e) => {
      const { store } = e.target.dataset;
      const parsedStore = JSON.parse(store);
      context.emit('setStoreData', parsedStore);

      const $body = document.body;
      __.addClass($body, 'is-store-selector-closing');
      __.removeClass($body, 'is-store-selector-exposed');

      // full hide store selector after close animation
      setTimeout(() => {
        __.removeClass($body, 'is-store-selector-closing');
      }, 500);
    };

    return {
      name,
      addressLine1,
      addressLine2,
      city,
      state,
      postalCode,
      distance,
      distanceUnits,
      phone,
      storeImageUrl,
      handleClick,
    };
  },
};
</script>
