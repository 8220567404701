<template>
  <div
    class="bag-item__delivery-methods-wrapper"
    :class="(checkoutLoading && 'disabled')"
    data-conveyance="physicalConveyance"
    v-if="physicalConveyance"
  >
    <div
      :class="(itemPackingError && hasPackageError && 'bag-item__delivery-methods-tab--error')"
      class="bag-item__delivery-methods-tab"
    >
      <h3 class="bag-item__delivery-methods-tab-title">
        Required
      </h3>
    </div>
    <div
      :class="(itemPackingError && hasPackageError && 'bag-item__delivery-methods-details--error')"
      class="bag-item__delivery-methods-details"
    >
      <h3 class="bag-item__delivery-methods-details-title" v-html="packagingOptionsLabel">
      </h3>
      <ul class="bag-item__delivery-methods form-inline">
        <li class="bag-item__delivery-method
          bag-item__delivery-method--drop-off-at-store
          form-item form-inline__input"
        >
          <div :class="(displayDropOff) ? 'radio is-active' : 'radio'">
            <input
              class="js-option-dropoff"
              :id="`${id}-drop-off-at-store`"
              type="radio"
              :name="`${id}-radio`"
              ref="deliveryOption"
              :checked="displayDropOff"
              :disabled="checkoutLoading"
              @change="getDropOffStore($event)"
            />
            <label class="radio--drop-off-at-store" :for="`${id}-drop-off-at-store`">
              <span class="bag-item__delivery-method-text" v-html="dropOffLabelText"></span>
            </label>
          </div>
        </li>
        <li class="bag-item__delivery-method
          bag-item__delivery-method--ship-to-us
          form-item form-inline__input"
        >
          <div :class="displayShip ? 'radio is-active' : 'radio'">
            <input
              class="js-option-ship"
              :id="`${id}-ship-to-us`"
              type="radio"
              :name="`${id}-radio`"
              ref="shippingOption"
              :checked="displayShip"
              :disabled="checkoutLoading"
              @change="shipToStore($event)"
            />
            <label class="radio--ship-to-us" :for="`${id}-ship-to-us`">
              <span class="bag-item__delivery-method-text" v-html="shipLabelText"></span>
            </label>
          </div>
        </li>
      </ul>
      <div
        class="bag-item__delivery-methods-additional-details"
        v-if="displayDropOff"
      >
        <p class="bag-item__delivery-methods-additional-details-text" v-html="dropOffInfo">
        </p>
      </div>
      <div
        class="bag-item__delivery-methods-additional-details"
        v-if="displayShip"
      >
        <p class="bag-item__delivery-methods-additional-details-text" v-html="shipToStoreInfo">
        </p>
      </div>
      <div
        class="bag-item__delivery-method-menu js-option-delivery-menu"
        v-if="displayDropOff || displayShip"
      >
        <div
          class="bag-item__delivery-method-menu-none js-option-select-store"
          v-if="!dropOffStore && displayDropOff"
        >
          <button
            class="btn btn--primary btn--full js-store-selector-btn"
            id="js-store-selector-btn"
            type="button"
            :data-product-id="id"
            :disabled="checkoutLoading"
            @click="storeSelectorBtn"
          >
            Select A Store
          </button>
        </div>
        <div
          class="bag-item__delivery-method-menu-dropoff js-option-dropoff"
          :data-display-store="dropOffStore"
          :data-product-id="id"
          v-if="dropOffStore && displayDropOff"
        >
          <CartItemDeliveryStore
            :selectedDropOffStore="dropOffStore"
            :productId="id"
            :productProperties="productProperties"
            :dropOffStoreProperty="storeProperty"
          >
          </CartItemDeliveryStore>
        </div>
        <div
          class="bag-item__delivery-method-menu-ship js-option-ship"
          :data-display-ship="displayShip"
          :data-product-id="id"
          v-if="displayShip"
        >
          <CartItemShip
            :itemSelector="itemSelector"
            :productId="id"
            :productProperties="productProperties"
            :setProductProperty="setProductProperty"
            :shipToStoreProperty="productProperties?.shipToUs"
            :handle="productHandle"
            :shippingOptions="globalShippingOptions"
            :packagingOptions="packagingOptions"
            @set-ship-to-us="handleSetShipToUs"
          >
          </CartItemShip>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  toRefs,
  ref,
  toRaw,
  watch,
  onMounted,
  computed,
} from 'vue';
import CartItemDeliveryStore from './CartItemDeliveryStore';
import CartItemShip from './CartItemShip';
import trapFocus from '../../lib/trap-focus';
import useCart from '../composables/cart-actions';

export default {

  name: 'CartItemDeliveryMethod',
  components: {
    CartItemDeliveryStore,
    CartItemShip,
  },
  props: {
    itemSelector: {
      type: String,
      default: '',
    },
    variantId: {
      type: String,
      default: '',
    },
    isPhysical: {
      type: Boolean,
      default: false,
    },
    parentProductId: {
      type: String,
      default: '',
    },
    parentProductProperties: {
      type: Object,
      default: () => ({}),
    },
    dropOffStores: {
      type: Object,
      default: () => ({}),
    },
    selectedDropOffStore: {
      type: Object,
      default: () => ({}),
    },
    dropOffStoreProperty: {
      type: String,
      default: '',
    },
    handle: {
      type: String,
      default: '',
    },
    checkoutAvailable: {
      type: Boolean,
      default: false,
    },
    hasPackageError: {
      type: Boolean,
      default: false,
    },
    togglePackingError: {
      type: Function,
      default: () => {},
    },
  },

  setup(props, context) {
    const {
      variantId,
      isPhysical,
      parentProductProperties,
      parentProductId,
      dropOffStores,
      dropOffStoreProperty,
      handle,
      itemSelector,
    } = toRefs(props);

    const productProperties = ref(parentProductProperties.value);
    const setProductProperty = (property, value) => {
      productProperties.value = {
        ...productProperties.value,
        [property]: value,
      };
    };

    const removeProductProperty = (property) => {
      if (productProperties.value[property]) {
        delete productProperties.value[property];
      }
    };

    const {
      addShipToUs,
      addDropOffStore,
      checkoutLoading,
    } = useCart();

    const physicalConveyance = ref(isPhysical.value);
    const dropOffInfo = SDG.Data.dropOffAtStoreInfo || null;
    const shipToStoreInfo = SDG.Data.shipToStoreInfo || null;
    const storeProperty = ref(null);
    const id = parentProductId.value;
    const storage = window.sessionStorage;
    const productHandle = handle.value;
    const store = ref({});
    const globalShippingOptions = ref([]);
    const packagingOptions = ref([]);
    const packagingOptionsLabel = ref(SDG.Data.cart.packaging_options_label_text);
    const shipLabelText = ref(SDG.Data.cart.ship_label_text);
    const shipInstructionText = ref(SDG.Data.cart.ship_instruction_text);
    const dropOffLabelText = ref(SDG.Data.cart.drop_off_label_text);
    const shippingOption = ref(null);
    const deliveryOption = ref(null);
    const tempShip = 'temp_ship';
    const tempDropOff = 'temp_drop_off';

    const itemPackingError = computed(() => {
      const { _dropOffAtStore: dropOffProperty, shipToUs: shipToUsProp } = productProperties.value;
      if ((!dropOffProperty || dropOffProperty === tempDropOff) && (!shipToUsProp || shipToUsProp === tempShip)) {
        return true;
      } else {
        return false;
      }
    });

    const displayShip = computed(() => productProperties?.value?.shipToUs);
    const displayDropOff = computed(() => productProperties?.value?._dropOffAtStore);
    const dropOffStore = computed(() => {
      const nearestStores = JSON.parse(storage.getItem('nearestStoresData'));
      const nearestStore = nearestStores ? nearestStores[0] : null;
      let isDropOffStore = false;
      let dropOffStoreData = null;

      if (nearestStore) {
        dropOffStoreData = nearestStore;
        isDropOffStore = nearestStore.customFields
          ? nearestStore.customFields.find(
            (field) => field.name.toLowerCase() === 'drop off in store' && field.value.toLowerCase() === 'true',
          )
          : false;
      }

      if (!nearestStore || !isDropOffStore) {
        const allStores = dropOffStores.value;

        if (!allStores) return;

        dropOffStoreData = allStores && allStores.length > 0
          ? allStores.find((s) => s.name === dropOffStoreProperty.value)
          : null;
      }
      return dropOffStoreData;
    });

    const storeSelectorBtn = () => {
      const $body = document.body;
      __.addClass($body, 'is-store-selector-exposed');
      const $storeSelectorModal = document.getElementById('storeSelectorModal');
      const $hiddenItemId = $storeSelectorModal.querySelector('.js-hidden-item-id');
      $hiddenItemId.value = id;
      const $hiddenItemProperties = $storeSelectorModal.querySelector('.js-hidden-item-properties');
      $hiddenItemProperties.value = JSON.stringify(productProperties.value);

      setTimeout(() => {
        trapFocus($storeSelectorModal);
      }, 150);
    };

    const $cartItem = ref(null);

    onMounted(() => {
      getVariantMetafields();
      $cartItem.value = document.getElementById(itemSelector.value);
    });

    const shipToStore = () => {
      if (parentProductProperties.value.shipToUs) {
        // fix radio option being unchecked after data updates
        const $shipRadioOption = $cartItem.value?.querySelector('.js-ship-radio-option[data-checked="true"]');
        if ($shipRadioOption) {
          $shipRadioOption.checked = true;
        }
      } else {
        // add temporary ship option
        setProductProperty('shipToUs', tempShip);
        removeProductProperty('_dropOffAtStore');
        addShipToUs(id, productProperties.value, tempShip);

        const newShipData = {
          shipOption: tempShip,
          itemId: id,
        };
        handleSetShipToUs(newShipData);
      }
    };

    const getDropOffStore = () => {
      if (parentProductProperties.value._dropOffAtStore) {
        removeProductProperty('shipToUs');
        setProductProperty('_dropOffAtStore', parentProductProperties.value._dropOffAtStore);
        handleSetShipToUs(null);
      } else {
        // remove ship option and add temp drop off
        removeProductProperty('shipToUs');
        setProductProperty('_dropOffAtStore', tempDropOff);
        addDropOffStore(id, productProperties.value, tempDropOff);
        handleSetShipToUs(null);
      }
    };

    const getVariantMetafields = () => {
      if (globalShippingOptions.value.length > 0) return;
      const shipToStoreURL = `/products/${handle.value}?view=ship-to-store`;
      fetch(shipToStoreURL)
        .then((res) => res.json())
        .then((shipToStoreData) => {
          const { shippingOptions } = shipToStoreData?.shipToStore[0] || [];
          const { variantPackagingOptions } = shipToStoreData?.shipToStore[1] || [];

          if (!shippingOptions || !variantPackagingOptions) return;

          globalShippingOptions.value = shippingOptions;

          const filteredVariantOption = variantPackagingOptions.filter(
            (option) => option.variantId === parseInt(variantId.value, 10),
          );

          packagingOptions.value = filteredVariantOption
            ? filteredVariantOption[0].options
            : null;
        })
        .catch((err) => console.error(err));
    };

    const handleSetShipToUs = (data) => {
      context.emit('setShipToUs', data);
    };

    watch(parentProductProperties, (newParentProductProperties) => {
      productProperties.value = toRaw(newParentProductProperties);
    });

    return {
      id,
      physicalConveyance,
      displayShip,
      displayDropOff,
      storeSelectorBtn,
      getDropOffStore,
      store,
      shipToStore,
      handleSetShipToUs,
      dropOffStore,
      dropOffInfo,
      productProperties,
      shipToStoreInfo,
      storeProperty,
      productHandle,
      globalShippingOptions,
      packagingOptions,
      packagingOptionsLabel,
      shipLabelText,
      shipInstructionText,
      dropOffLabelText,
      setProductProperty,
      itemPackingError,
      shippingOption,
      deliveryOption,
      itemSelector,
      checkoutLoading,
    };
  },
};
</script>
