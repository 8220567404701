/**
 * CID Check
 */

function cidCheck(opts) {
  const config = {
    cookie: 'cid-cookie',
  };

  // Extend config
  const c = __.extend(config, opts); // Extend defaults with passed options

  /**
   * Init
   */
  function init() {
    checkCidCookies();
  }

  /**
   * Check CID Cookies
   */
  function checkCidCookies() {
    console.log("Checking CID Cookies");
    const { customerId } = SDG.Data;
    const cidCookie = SDG.getCookie(c.cookie);
    const atobCidCookie = cidCookie ? atob(cidCookie) : null;
    const isIncorrectCid = customerId && __.toNumber(atobCidCookie) !== customerId;

    if (cidCookie && !isIncorrectCid) {
      console.log("CID Cookie is correct, no need to set a new one");
      SDG.Data.cid = cidCookie;
    } else {
      console.log("CID Cookie is blank or incorrect, setting a new one", cidCookie, customerId, isIncorrectCid);
      mergeCartData(cidCookie, customerId);
    }
  }

  /**
   * Merge Cart Data
   * @param {String} oldCid
   * @param {Number} customerId
   */
  async function mergeCartData(oldCid, customerId) {
    if (!oldCid || !customerId) {
      console.log("No existing cid, setting cid cookie to", customerId);
      setNewCidCookie(customerId);
    }
    const newCid = btoa(customerId);
    const cartCookieValue = decodeURIComponent(SDG.getCookie('cart'));
    // Only pluck out the cartId from the cookie in bizzness context
    const cartId = cartCookieValue && SDG.Data.b2bLocationName ? `gid://shopify/Cart/${cartCookieValue}` : '';
    const mergeEndpoint = `${SDG.Data.apiUrl}/cart/merge`;
    const postData = {
      from: oldCid || "",
      to: newCid,
      cartId,
    };

    console.log("Well in any case, setting cid cookie to", customerId);
    setNewCidCookie(customerId);

    await __.ajax({
      url: mergeEndpoint,
      type: 'POST',
      data: JSON.stringify(postData),
      contentType: 'application/json',
      success(data) {
        if (!data) {
          console.error('merge API error: no data response');
        }
      },
      error(err) {
        console.error('merge API error: ', err);
      },
    });
  }

  /**
   * Set New CID Cookie
   * @param {Number} customerId - optional
   */
  function setNewCidCookie(customerId) {
    const randomString = (+new Date() * Math.random())
      .toString(36)
      .substring(0, 12)
      .replace('.', '');
    const newCidValue = btoa(customerId || randomString);

    SDG.setCookie(c.cookie, newCidValue, 30);
    SDG.Data.cid = newCidValue;
  }

  return init();
}

export default cidCheck;
