import lottieWeb from 'lottie-web';

/*
* Curly Arrow Animation
* @param {object} opts
*/

function curlyArrowAnimation(opts) {
  const config = {
    dom: {
      wrapper: 'js-how-it-works-hero-arrow',
      container: 'js-lottie-container',
    },
    cls: {
      carouselMode: 'carousel-mode',
    },
  };

  const c = __.extend(config, opts);
  const $wrappers = document.querySelectorAll(`.${c.dom.wrapper}`);
  let view;

  /**
   * Init
   */
  function init() {
    if (!$wrappers) return;

    responsive();
    __.windowResize(responsive);

    $wrappers.forEach(($wrapper) => {
      const $containers = $wrapper
        ? $wrapper.querySelectorAll(`.${c.dom.container}`)
        : null;

      if (!$containers) return;

      runLottieAnimation($containers);
    });
  }

  /**
   * Responsive functions
   */
  function responsive() {
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
        }
      },
    });

    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
        }
      },
    });
  }

  /**
   * Run Lottie animation
   * @param {HTMLElement} $els
   */
  function runLottieAnimation($els) {
    $els.forEach(($el) => {
      const { lottieAnimationName } = $el.dataset;
      const { lottieAutoplay } = $el.dataset;
      const { lottieJsonFile } = $el.dataset;
      const { lottieLoop } = $el.dataset;

      // eslint-disable-next-line
      const animation = lottieWeb.loadAnimation({
        container: $el,
        path: lottieJsonFile,
        renderer: 'svg',
        loop: JSON.parse(lottieLoop),
        autoplay: JSON.parse(lottieAutoplay),
        name: lottieAnimationName,
      });

      if (view === 'desktop') {
        setTimeout(() => {
          animation.playSegments([0, 50], true);
        }, 2200);
      }

      if (view === 'mobile') {
        setTimeout(() => {
          animation.playSegments([0, 50], true);
        }, 1800);
      }
    });
  }

  init();
}

export default curlyArrowAnimation;
