/**
 * Check Cart Attributes
 */
function checkCartAttributes(opts) {
  const config = {
    url: {
      cart: '/cart',
      update: '/cart/update',
    },
    attribute: '__order_source',
    dom: {
      counter: 'bagBtnCount',
    },
    cls: {
      hide: 'hide',
    },
  };

  const c = __.extend(config, opts);
  const { apiUrl: baseURL, cid } = SDG.Data;
  const cidParam = `?cid=${cid}`;
  const $counter = document.getElementById(c.dom.counter);
  const { template } = SDG.Data;
  const isCartPage = template === 'cart' || false;

  /**
   * Init
   */
  function init() {
    __.ajax({
      url: `${baseURL}${c.url.cart}${cidParam}`,
      type: 'GET',
      contentType: 'application/json',
      success(cartData) {
        if (!cartData?.id) return;

        if ($counter && !isCartPage) {
          setBagCount(cartData);
        }

        const currentAttributes = cartData?.attributes || [];

        // check if cart already has attribute
        if (currentAttributes[c.attribute]) return;

        const updateData = {
          attributes: {
            ...currentAttributes,
            [c.attribute]: 'web',
          },
        };

        __.ajax({
          url: `${baseURL}${c.url.update}${cidParam}`,
          type: 'POST',
          data: JSON.stringify(updateData),
          contentType: 'application/json',
          error(err) {
            console.error(err);
          },
        });
      },
      error(err) {
        console.error(err);
      },
    });
  }

  /**
   * set bag count - sets bag count text
   * @param {Object} data
   * @returns {void}
   */
  function setBagCount(data) {
    const { item_count: currentCount } = data;
    const itemCount = currentCount || 0;
    const itemsInCart = data?.items || [];
    const addOnProducts = itemsInCart?.filter((item) => item?.product_type === 'Addition');

    if (itemsInCart?.length > 0) {
      const countWithoutAddOns = itemCount - addOnProducts.length;
      $counter.innerText = countWithoutAddOns;
    }

    // remove hide class for bag count span
    if (itemCount > 0 && __.hasClass($counter, c.cls.hide)) {
      __.removeClass($counter, c.cls.hide);
    }
  }

  init();
}

export default checkCartAttributes;
