import cartApp from './cart-app';
import itemAddedApp from './item-added-app';
/**
 * bag timeout
 */
SDG.Bag.timeout = null;

SDG.Bag.clearTimeout = function () {
  if (SDG.Bag.timeout) {
    clearTimeout(SDG.Bag.timeout);
  }
};

/**
 * is open
 * @return {Function} Checks if bag is open
 */
SDG.Bag.isOpen = false;

/**
 * open bag
 * @return {Function} adds expose class from the body
 */
SDG.Bag.open = function (e, closeCb = null) {
  const c = SDG.Bag.config;

  // cached globals
  const $body = document.body;

  if (e) {
    e.preventDefault();

    if (e.type === 'mouseenter') {
      SDG.Bag.clearTimeout();
      __.addClass($body, c.cls.expose);
    }

    if (e.type === 'click') {
      if (SDG.Bag.isOpen) {
        SDG.Bag.close(null);
      } else {
        SDG.Bag.isOpen = true;
        __.addClass($body, c.cls.expose);
      }
    }
  } else {
    __.addClass($body, c.cls.expose);
  }

  // init bag upsell
  SDG.Bag.upsell();

  if (closeCb) {
    SDG.Bag.addEvents(closeCb);
  }
};

/**
 * wait to close bag
 * @return {Function} removes expose class from the body
 */
SDG.Bag.waitToClose = function () {
  const c = SDG.Bag.config;

  SDG.Bag.timeout = setTimeout(SDG.Bag.close, c.timer.hover);
};

/**
 * close bag
 * @return {Function} removes expose class from the body
 */
SDG.Bag.close = function (e, cb = null) {
  const c = SDG.Bag.config;

  // cached globals
  const $body = document.body;

  if (e) {
    e.preventDefault();
  }

  // start closing bag drawer
  __.addClass($body, c.cls.closing);
  __.removeClass($body, c.cls.expose);

  // fully hide bag drawer after close animation
  setTimeout(() => {
    __.removeClass($body, c.cls.closing);
  }, 500);

  SDG.Bag.isOpen = false;

  if (cb) {
    cb();
  }
};

/**
 * Add Events
 * @type {Function}
 */
SDG.Bag.addEvents = function (closeCb = null) {
  const c = SDG.Bag.config;

  // bag close
  __.addEvent({
    id: c.dom.bag,
    className: c.dom.bag_close,
    event: 'click',
    fn: (e) => SDG.Bag.close(e, closeCb),
  });

  // close on escape get
  document.addEventListener('keyup', getKeyCode);

  // close on click outside
  __.addEvent({
    id: c.dom.bag_overlay,
    event: 'click',
    fn: (e) => SDG.Bag.close(e, closeCb),
  });

  /**
   * get keycode
   * @return {Function} close modal on escape key
   */
  function getKeyCode(e) {
    if (e.keyCode === 27) {
      SDG.Bag.close(null, closeCb);
    }
  }
};

/**
 * Remove Events
 * @type {Function}
 */
SDG.Bag.removeEvents = function (closeCb = null) {
  const c = SDG.Bag.config;

  // bag close
  __.removeEvent({
    id: c.dom.bag,
    className: c.dom.bag_close,
    event: 'click',
    fn: (e) => SDG.Bag.close(e, closeCb),
  });

  // close on escape get
  document.removeEventListener('keyup', getKeyCode);

  // close on click outside
  __.removeEvent({
    id: c.dom.bag_overlay,
    event: 'click',
    fn: (e) => SDG.Bag.close(e, closeCb),
  });

  /**
   * get keycode
   * @return {Function} close modal on escape key
   */
  function getKeyCode(e) {
    if (e.keyCode === 27) {
      SDG.Bag.close(null, closeCb);
    }
  }
};

/**
* init
* @type {Function}
*/
SDG.Bag.init = function () {
  const { template } = SDG.Data;
  const isCartPage = template === 'cart' || false;

  function init() {
    if (isCartPage) {
      cartApp();
    } else {
      itemAddedApp();

      // Add Events
      SDG.Bag.addEvents();
    }
  }

  return init();
};
