import Flickity from 'flickity';

/*
* Shop the Story Carousel
* @param {object} opts
*/

function shopTheStoryCarousel(opts) {
  const config = {
    dom: {
      wrapper: 'js-shop-the-story-carousel-wrapper',
      carousel: 'js-shop-the-story-carousel',
      carouselCell: 'js-pi',
    },
    flickityOpts: {
      desktop: {
        prevNextButtons: true,
        pageDots: true,
        draggable: true,
        wrapAround: true,
        imagesLoaded: true,
        lazyLoad: true,
        adaptiveHeight: false,
      },
      mobile: {
        prevNextButtons: false,
        pageDots: true,
        draggable: true,
        wrapAround: true,
        imagesLoaded: true,
        lazyLoad: true,
        adaptiveHeight: false,
      },
    },
  };

  const c = __.extend(config, opts);
  const $carousels = document.body.querySelectorAll(`.${c.dom.carousel}`);
  let view = null;
  let flickityOptions = null;

  /**
   * Init
   */
  function init() {
    if (!$carousels?.length) return;
    onWindowResize();
    __.windowResize(onWindowResize);
  }

  /**
  * Responsive functions
  */
  function onWindowResize() {
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          flickityOptions = c.flickityOpts.desktop;
        }
      },
    });

    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
          flickityOptions = c.flickityOpts.mobile;
        }
      },
    });

    toggleCarousel(flickityOptions);
  }

  /**
   * Toggle Carousel
   * @param {object} flickityOpts
   */
  function toggleCarousel(flickityOpts) {
    const windowWidth = window.innerWidth;

    $carousels.forEach(($carousel) => {
      const carouselWidth = calculateCarouselWidth($carousel);
      let flickity = Flickity.data($carousel);

      // Set the offset divisor based on viewport size
      let offsetDivisor;
      if (view === 'desktop') {
        offsetDivisor = 1.33;
      }

      if (view === 'mobile') {
        offsetDivisor = 1.66;
      }

      const $flickityCell = $carousel.querySelector(`.${c.dom.carouselCell}`);
      const leftOffset = setFlickityOffset($flickityCell.offsetWidth, offsetDivisor);
      const carouselOffset = $carousel.offsetWidth;
      const cellAlign = leftOffset / carouselOffset;
      const updatedFlickityOpts = __.extend(flickityOpts, { cellAlign });

      if (windowWidth <= carouselWidth) {
        flickity = new Flickity($carousel, updatedFlickityOpts);
        updateCellHeight($carousel);

        window.addEventListener('resize', () => {
          flickity.resize();
          updateCellHeight($carousel);
        });
      } else if (windowWidth > carouselWidth && flickity) {
        flickity.destroy();
      }
    });
  }

  /**
  * Calculate Carousel Width
  * @param {object} carousel
  */
  function calculateCarouselWidth($carousel) {
    const $cells = $carousel.querySelectorAll(`.${c.dom.carouselCell}`);
    let cellsWidth = 0;

    $cells.forEach((cell) => {
      cellsWidth += cell.offsetWidth;
    });

    return cellsWidth;
  }

  /**
  * Calculate Carousel Cell Height
  * @param {object} carousel
  */
  const updateCellHeight = (carousel) => {
    let maxHeight = 0;
    const carouselCells = carousel.querySelectorAll(`.${c.dom.carouselCell}`);
    carouselCells.forEach((cell) => {
      cell.style.height = 'auto'; // Reset the height first
      const cellHeight = cell.offsetHeight;
      if (cellHeight > maxHeight) {
        maxHeight = cellHeight;
      }
    });
    carouselCells.forEach((cell) => {
      cell.style.height = `${maxHeight}px`; // Apply the maximum height
    });
  };

  /**
   * Set the flickity carousel offset
   */
  function setFlickityOffset(flickityCellOffsetWidth, flickityOffsetDivisor) {
    Flickity.Cell.prototype.setDefaultTarget = () => {
      const marginProperty = this.parent.originSide === 'left' ? 'marginLeft' : 'marginRight';
      this.target = this.x + this.size[marginProperty];
    };

    return flickityCellOffsetWidth / flickityOffsetDivisor;
  }

  return init();
}

export default shopTheStoryCarousel;
