<template>
  <article
    v-if="!childProduct && !shippingFeeProduct"
    :class="[
      'bag-item js-bag-item',
      isWishlist ? 'bag-item--wishlist' : '',
      isLoading ? 'is-loading' : '',
    ]"
    :id="`bagItem${variantId}-${id}`"
    :data-variant-id="variantId"
    :data-qty="itemQuantity"
  >
  <div class="bag-item__wrapper">
    <div class="bag-item__photo">
      <CartItemGallery
        :title="title"
        :image="image"
        :url="url"
        :frameSpec="frameSpec"
        :isLoading="isFrameSpecLoading"
        :line-item-id="item.id"
        :bundle-spec-number="bundleSpecNumber"
        :bundle-spec-token="bundleSpecAuthToken"
      />
      <span v-if="!isWishlist && !isGWP && allowSpecialInstructions" class="desktop-only">
        <CartItemInstructions
          :productId="id"
          :productQty="itemQuantity"
          :productProperties="itemProperties"
        />
      </span>
      </div>
      <header class="bag-item__header--mobile mobile-only">
        <h4 class="bag-item__title">
          <span
            v-if="item.properties?.['_is_gwp'] === 'true'"
            class="bag-item__title-text"
          >
            {{ title }}
          </span>
          <a
            v-else
            class="bag-item__title-link"
            :href="url"
          >
            {{ title }}
          </a>
        </h4>
        <p class="bag-item__price">
          <span class="bag-item__original-price">
            {{ itemProperties.customAmount ? `$${itemProperties.customAmount}` : moneyPrice}}
          </span>
          <PriceBreakdownTooltip
            v-if="frameSpec || bundlePricing"
            :frameSpec="frameSpec"
            :pricing="bundlePricing"
          />
        </p>
        <p class="bag-item__sku screenreader" alt-text="product sku">#{{ sku }}</p>
      </header>
    </div>
    <div class="bag-item__info">
      <div class="bag-item__desc">
        <header class="bag-item__header desktop-only">
          <h4 class="bag-item__title">
            <span
              v-if="item.properties?.['_is_gwp'] === 'true'"
              class="bag-item__title-text"
            >
              {{ title }}
            </span>
            <a
              v-else
              class="bag-item__title-link"
              :href="url"
            >
              {{ title }}
            </a>
          </h4>
          <p class="bag-item__price">
            <span class="bag-item__original-price">
              {{ itemProperties.customAmount ? `$${itemProperties.customAmount}` : moneyPrice}}
            </span>
            <PriceBreakdownTooltip
              v-if="frameSpec || bundlePricing"
              :frameSpec="frameSpec"
              :pricing="bundlePricing"
            />
          </p>
          <p class="bag-item__sku screenreader" alt-text="product sku">#{{ sku }}</p>
        </header>
        <div v-if="!isGWP" class="bag-item__accordion">
          <div
            class="bag-item__accordion-wrapper"
            :class="(!mainDetails && !itemDetails ? 'bag-item__accordion-wrapper--no-details' : '')"
          >
            <ul
              v-if="mainDetails"
              class="bag-item__accordion-fields list-reset"
            >
              <li
                v-for="(detail) in Object.entries(mainDetails)"
                :key="detail[0]"
                class="bag-item__accordion-field"
              >
                <p
                  v-if="detail[0] && detail[1]"
                  class="bag-item__accordion-value bag-item__accordion-value--bold"
                >
                  {{ detail[0] }}:
                </p>
                <p
                  v-if="detail[0] && detail[1]"
                  class="bag-item__accordion-value"
                >
                  {{ detail[1] }}
                </p>
              </li>
            </ul>
            <VueAccordion
              v-if="itemDetails"
              :ariaTitle="variantTitle"
              :buttonPlacement="'bottom'"
              :buttonClass="'bag-item__accordion-button'"
            >
              <ul
                class="bag-item__accordion-fields list-reset"
              >
                <li
                  v-for="(subDetail) in Object.entries(itemDetails)"
                  :key="subDetail[0]"
                  class="bag-item__accordion-field"
                >
                  <p
                    v-if="subDetail[0]  && subDetail[1]"
                    class="bag-item__accordion-value"
                  >
                    {{  subDetail[0] }}:
                  </p>
                  <p
                    v-if="subDetail[0]  && subDetail[1]"
                    class="bag-item__accordion-value"
                  >
                    {{  subDetail[1] }}
                  </p>
                </li>
              </ul>
              <template #opened-icon>
                <span class="bag-item__accordion-button--open">
                  Minimize Details
                </span>
              </template>

              <template #closed-icon>
                <span  class="bag-item__accordion-button--closed">
                  View All Details
                </span>
              </template>
            </VueAccordion>
          </div>
        </div>
      </div>

      <div
        v-if="!isGWP"
        class="bag-item__actions"
        :class="[
          isDigitalItem ? 'bag-item__actions--digital' : '',
          isPhysical && !isWishlist ? 'bag-item__actions--physical' : '',
        ]"
      >
        <div
          v-if="isDigitalItem || enableQuantitySelector"
          class="bag-item__qty increment js-increment"
        >
          <input
            :value="itemQuantity"
            @input="onQuantityInputChanged"
            type="number"
            name="quantity"
            size="3" class="increment__input" tabindex="-1"
            aria-label="Item Quantity"
          />
          <button
            type="button"
            class="increment__btn increment__add"
            data-action="add"
            @click.prevent="increment"
          >
            <i class="increment__icon increment__icon--add bag-item__qty-icon icon icon--plus">
            </i>
            <span class="screenreader">Add</span>
          </button>
          <button
            type="button"
            class="increment__btn increment__subtr"
            data-action="remove"
            :disabled="itemQuantity === 0"
            @click.prevent="decrement"
          >
            <i
              class="increment__icon increment__icon--subtr bag-item__qty-icon icon icon--minus"
            ></i>
            <span class="screenreader">Subtract</span>
          </button>
        </div>

        <div class="bag-item__actions-icons">
          <button
            v-if="!disableDuplicate"
            title="Duplicate item"
            class="bag-item__actions-btn btn-icon"
            role="button"
            :disabled="isFrameSpecLoading"
            @click.prevent="duplicateModalOpen = true"
          >
            <i class="icon icon--bag-copy"></i>
            <span class="screenreader">Duplicate Item</span>
            <div class="bag-item__actions-hover">
              <span class="bag-item__actions-hover-text">
                duplicate
              </span>
            </div>
          </button>
          <a
            v-if="!isNonEditable"
            :href="frameEditParams"
            title="Edit item"
            class="bag-item__actions-btn btn-icon"
            role="button"
          >
            <i class="icon icon--bag-edit"></i>
            <span class="screenreader">Edit Item</span>
            <div class="bag-item__actions-hover">
              <span class="bag-item__actions-hover-text">
                edit
              </span>
            </div>
          </a>
          <button
            v-if="!isWishlist"
            title="Move to Wishlist"
            class="bag-item__actions-btn btn-icon"
            @click.prevent="moveToWishlist"
          >
            <i class="icon icon--move-to-wishlist"></i>
            <span class="bag-item__remove-label screenreader">Move to Wishlist</span>
            <div class="bag-item__actions-hover">
              <span class="bag-item__actions-hover-text">
                save for later
              </span>
            </div>
          </button>
          <button
            title="Remove item"
            class="bag-item__actions-btn btn-icon"
            role="button"
            @click.prevent="deleteModalOpen = true"
          >
            <i class="icon icon--bag-remove"></i>
            <span class="bag-item__remove-label screenreader">Remove Item</span>
            <div class="bag-item__actions-hover">
              <span class="bag-item__actions-hover-text">
                remove
              </span>
            </div>
          </button>
          <button
            v-if="isWishlist"
            class="bag-item__actions-btn-link"
            @click.prevent="moveToCart"
          >
            Move To Cart
          </button>
        </div>
      </div>
      <section v-if="!isWishlist && isPhysical" class="bag-item__options">
        <CartItemDeliveryMethod
          :variantId="variantId"
          :itemSelector="`bagItem${variantId}-${id}`"
          :isPhysical="isPhysical"
          :selectedDropOffStore="(currentlySelectedStore || {})"
          :parentProductId="id"
          :parentProductProperties="itemProperties"
          :dropOffStores="availableDropOffStores"
          :dropOffStoreProperty="dropOffStoreProperty"
          :handle="productHandle"
          :checkoutAvailable="checkoutAvailable"
          :hasPackageError="hasPackageError"
          :togglePackingError="togglePackingError"
          @set-ship-to-us="handleSetShipToUs"
        ></CartItemDeliveryMethod>
        <span class="mobile-only">
          <p
            v-if="hasPackageError"
            class="bag__content-error"
          >
            {{ cartCheckoutPackageError }}
          </p>
        </span>
      </section>
      <span v-if="!isWishlist && !isGWP && allowSpecialInstructions" class="mobile-only">
        <CartItemInstructions
          :productId="id"
          :productQty="itemQuantity"
          :productProperties="(itemProperties)"
          :isWishlist="isWishlist"
        />
      </span>
      <div
        class="bag-item__bopis"
        v-if="showBopis && isInStoreFulfillable"
      >
        <div class="bag-item__bopis__badge-icon" v-html="bopisIconHtml"></div>
        <span class="bag-item__bopis-details" v-html="bopisDetails"></span>
      </div>
      <section
        v-if="(!isWishlist && upSellItems?.heading && upSellItems?.items.length > 0)"
        class="cart-upsell"
      >
        <h5
          v-if="upSellItems?.heading"
          class="cart-upsell__heading"
        >
          {{ upSellItems.heading }}
        </h5>

        <VueAccordion
          :ariaTitle="upSellItems?.subheading || upSellItems.heading"
          :title="upSellItems?.subheading || upSellItems.heading"
          :buttonPlacement="'top'"
          :buttonClass="'cart-upsell__subheading'"
          :defaltOpen="true"
        >
        <template #opened-icon>
          <i
          class="cart-upsell__header-icon
          cart-upsell__header-icon--upsell icon icon--chevron-up">

          </i>
          <span class="screenreader">Minimize Options</span>
        </template>
        <template #closed-icon>
          <i
            class="cart-upsell__header-icon
            cart-upsell__header-icon--upsell icon icon--chevron-down"
          >
          </i>
          <span class="screenreader">Expand Options</span>
        </template>
        <CartUpsell
          :products="upSellItems?.items"
          :parentProductId="id"
          :parentFrameSpecId="frameSpecId"
          :parentProductProperties="itemProperties"
          :parentProductQty="itemQuantity"
          :bagItems="cartItems"
          :isWishlist="isWishlist"
          :updateLoadingState="updateLoadingState"
          />
      </VueAccordion>
      </section>
    </div>
    <VueModal
      :show="(duplicateModalOpen)"
      :includeOverlay="(true)"
      @toggleModal="toggleModal"
    >
      <template #modal-content>
        <h4
          class="vue-modal__title"
        >
          {{ duplicateTitleText }}
        </h4>
        <p class="vue-modal__copy">
          {{ duplicateCopyText }}
        </p>
        <div class="vue-modal__actions">
          <button
            class="vue-modal__button btn btn--primary"
            type="button"
            @click.prevent="duplicateItem"
          >
            {{ duplicateConfirmText }}
          </button>
          <button
            class="vue-modal__button btn btn--secondary"
            type="button"
            @click.prevent="duplicateModalOpen = false"
          >
            {{ duplicateCancelText }}
          </button>
        </div>
      </template>
    </VueModal>
    <VueModal
      :show="(deleteModalOpen)"
      :includeOverlay="(true)"
      @toggleModal="toggleModal"
    >
      <template #modal-content>
        <h4
          class="vue-modal__title"
        >
          {{ isWishlist ? deleteWishlistModalTitleText : deleteModalTitleText }}
        </h4>
        <div class="vue-modal__actions">
          <button
            class="vue-modal__button btn btn--primary"
            @click.prevent="remove"
          >
            {{ isWishlist ? deleteWishlistModalConfirmText : deleteModalConfirmText }}
          </button>
          <button
            v-if="isWishlist"
            class="vue-modal__button btn btn--secondary"
            type="button"
            @click.prevent="moveToCart"
          >
            {{ deleteWishlistModalCancelText }}
          </button>
          <button
            v-else
            class="vue-modal__button btn btn--secondary"
            type="button"
            @click.prevent="moveToWishlist"
          >
            {{ deleteModalCancelText }}
          </button>
        </div>
      </template>
    </VueModal>
  </article>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import {
  computed,
  toRefs,
  ref,
  onMounted,
  toRaw,
  watch
} from 'vue';
import useCart from '../composables/cart-actions';
import CartItemGallery from './CartItemGallery';
import useFrameSpecData from '../composables/cart-frame-spec';
import CartItemInstructions from './CartItemInstructions';
import CartUpsell from '../cart-upsell/CartUpsell';
import VueAccordion from '../common/accordion/Accordion';
import CartItemDeliveryMethod from '../cart-item-delivery-method/CartItemDeliveryMethod';
import PriceBreakdownTooltip from '../price-breakdown-tooltip/PriceBreakdownTooltip';
import VueModal from '../common/modal/Modal';
import elevarTracking from '../../lib/elevar-tracking';

export default {
  name: 'CartItem',
  components: {
    CartItemDeliveryMethod,
    CartItemGallery,
    CartItemInstructions,
    CartUpsell,
    PriceBreakdownTooltip,
    VueAccordion,
    VueModal,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true,
    },
    allItems: {
      type: Array,
      default: () => [],
      required: true,
    },
    currency: {
      type: String,
      default: 'USD',
    },
    itemQuantity: {
      type: Number,
      default: 0,
    },
    itemPrice: {
      type: Number,
      default: 0,
    },
    itemProperties: {
      type: Object,
      default: () => {},
    },
    selectedDropOffStore: {
      type: Object,
      default: () => ({}),
    },
    dropOffStores: {
      type: Object,
      default: () => ({}),
    },
    selectedShipToUsStore: {
      type: Object,
      default: () => ({}),
    },
    isWishlist: {
      type: Boolean,
      default: false,
    },
    checkoutAvailable: {
      type: Boolean,
      default: false,
    },
    hasPackageError: {
      type: Boolean,
      default: false,
    },
    togglePackingError: {
      type: Function,
      default: () => {},
    },
  },
  setup(props, context) {
    const {
      frameSpec,
      getFrameSpecById,
      cloneFrameSpec,
    } = useFrameSpecData();
    const {
      handle,
      id,
      image,
      options_with_values: options,
      product_title: title,
      product_type: productType,
      product_id: productId,
      properties,
      title: variantTitle,
      sku,
      url,
      variant_id: variantId,
      variant_options: variantOptions,
      enable_cart_quantity: enableQuantitySelector,
    } = toRefs(props.item);
    const {
      item,
      selectedDropOffStore,
      selectedShipToUsStore,
      itemPrice,
      itemQuantity: qty,
      itemProperties,
      allItems,
      currency,
      isWishlist,
    } = toRefs(props);
    const {
      fetchCart,
      updateCartItemById,
      removeCartItemById,
      removeUpsellItem,
      moveItem,
      cartThemeSettings,
      cartItems,
    } = useCart();
    const {
      cartSummaryMailInServiceProductVariantId,
    } = cartThemeSettings;
    const duplicateModalOpen = ref(false);
    const deleteModalOpen = ref(false);
    const isLoading = ref(false);
    const isFrameSpecLoading = ref(false);

    const duplicateTitleText = SDG.Data.cartDuplicateModalTitle || 'Duplicate Item';
    const duplicateCopyText = SDG.Data.cartDuplicateModalCopy || 'Would you like to duplicate this item?';
    const duplicateConfirmText = SDG.Data.cartDuplicateModalConfirm || 'Duplicate';
    const duplicateCancelText = SDG.Data.cartDuplicateModalCancel || 'Nevermind';
    const deleteModalTitleText = SDG.Data.cartDeleteModalTitle || 'Delete Item';
    const deleteModalConfirmText = SDG.Data.cartDeleteModalConfirm || 'Remove';
    const deleteModalCancelText = SDG.Data.cartDeleteModalCancel || 'Nevermind';
    const deleteWishlistModalTitleText = SDG.Data.wishlistDeleteModalTitle || 'Delete Item';
    const deleteWishlistModalConfirmText = SDG.Data.wishlistDeleteModalConfirm || 'Remove';
    const deleteWishlistModalCancelText = SDG.Data.wishlistDeleteModalCancel || 'Nevermind';
    const cartCheckoutPackageError = SDG.Data.cartCheckoutPackageError || 'Please let us know how you’ll get your item(s) to us.';
    const {
      bopisDetailedCopy,
      bopisIcon,
      bopisIconSrc,
      bopisEnable,
      bopisStorefrontApiKey,
    } = SDG.Data;
    // check if user is near a retail store
    const hasRetailContent = window.dataLayer?.find((item) => item.event === 'geolocation' && item.rule === 'retail-content');
    const bopisDetails = bopisDetailedCopy?.replace('[', '<span class="bag-item__bopis-details--green-text">')?.replace(']', '</span>');
    const showBopis = computed(() => (
      bopisEnable && bopisStorefrontApiKey !== '' && bopisDetailedCopy && hasRetailContent
    ));
    const isInStoreFulfillable = ref(false);
    const allowSpecialInstructions = ref(false);
    const bopisIconHtml = ref(bopisIcon);

    // update BOPIS icon to use inline svg
    if (bopisIconSrc) {
      fetch(bopisIconSrc)
        .then((data) => data.text())
        .then((data) => {
          bopisIconHtml.value = data;
        });
    }

    const { dropOffStores } = toRefs(props);
    const availableDropOffStores = computed(() => toRaw(dropOffStores.value));
    const currentlySelectedStore = computed(() => toRaw(selectedDropOffStore.value));
    const upSellItems = ref({ heading: '', subheading: '', items: [] });
    const productHandle = handle.value;

    // eslint-disable-next-line no-underscore-dangle
    const dropOffStoreProperty = ref(itemProperties.value._dropOffAtStore);
    const shipToUsProperty = ref(itemProperties.value.shipToUs);
    const bundleSpecNumber = ref(itemProperties.value._bundle_spec_number);
    const bundleSpecAuthToken = ref(itemProperties.value._bundle_spec_authorization_token);
    const isGWP = ref(itemProperties.value?._is_gwp === 'true');

    const moneyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency?.value || 'USD',
    });
    const formatPrice = (price) => moneyFormatter.format(price).replace(/(\.|,)00$/g, '');

    const getProductMetafields = () => {
      const upsellURL = `/products/${handle.value}?view=cart-upsell`;
      fetch(upsellURL)
        .then((res) => res.json())
        .then((upsellData) => {
          const { cartUpsell, inStoreFulfillable, specialInstructions } = upsellData || {};
          if (inStoreFulfillable) {
            isInStoreFulfillable.value = inStoreFulfillable;
          }
          if (specialInstructions) {
            allowSpecialInstructions.value = specialInstructions;
          }
          if (!cartUpsell) return;

          const { heading, subheading } = cartUpsell || '';
          const variantUpsellItems = cartUpsell[variantId.value];

          if (variantUpsellItems?.length > 0) {
            upSellItems.value = {
              heading,
              subheading,
              items: variantUpsellItems,
            };
          }
        })
        .catch((err) => console.error(err));
    };

    const handleSetShipToUs = (data) => {
      shipToUsProperty.value = data;

      context.emit('setShipToUs', data);
    };

    watch([selectedDropOffStore, selectedShipToUsStore], ([newStoreValue, newShipValue]) => {
      const selectedStore = toRaw(newStoreValue);
      const selectedShip = toRaw(newShipValue);

      if (!selectedStore && !selectedShip) return;
      if (selectedStore?.storeName && id.value === selectedStore?.itemId) {
        dropOffStoreProperty.value = selectedStore.storeName;
        shipToUsProperty.value = null;
      } else if (selectedShip?.shipOption && id.value === selectedShip?.itemId) {
        dropOffStoreProperty.value = null;
        shipToUsProperty.value = selectedShip.shipOption;
      }
    });

    const shippingFeeProduct = computed(() => {
      if (item.value.properties?._is_shipping_fee === 'true' || SDG?.Data?.cartThemeSettings?.cartSummaryMailInServiceProductVariantId === item.value.variant_id) {
        return true;
      }
      return false;
    });

    onMounted(() => {
      // no need to run if shipping fee product
      if (shippingFeeProduct.value) {
        return;
      }

      getProductMetafields();

      if (frameSpecId?.value && !frameSpecId?.value.includes('SS-')) {
        isFrameSpecLoading.value = true;

        getFrameSpecById(frameSpecId.value, frameSpecAuthToken.value)
          .then(() => {
            isFrameSpecLoading.value = false;
          })
          .catch((err) => {
            console.error(err);
            isFrameSpecLoading.value = false;
          });
      }
    });
    const isPhysical = computed(() => {
      if (productType?.value && productType.value.toLowerCase().includes('art print frame')) {
        if (!frameSpec.value) {
          return false;
        }
        return frameSpec.value.artworks[0].conveyance === "shipped";
      }

      if (productType?.value && productType.value.toLowerCase().includes('gift card')) {
        return false;
      }

      return variantOptions.value.includes('Physical');
    });
    const isBundle = computed(() => {
      if (productType?.value && productType.value.toLowerCase() === 'frame bundle') {
        return true;
      }

      return false;
    });
    const bundleAdditions = computed(() => {
      if (!isBundle.value || !itemProperties.value._bundle_spec_number) {
        return [];
      }

      return allItems.value.filter(
        (item) => item?.properties?._parent_bundle_spec
          && item?.properties?._parent_bundle_spec === bundleSpecNumber?.value,
      );
    });

    const amountPrice = computed(() => {
      // eslint-disable-next-line no-underscore-dangle
      const upSellItemPrice = itemProperties.value?._upsell_price || false;
      const frameSpecPricing = frameSpec?.value?.pricing?.totalPrice || false;
      const money = itemPrice.value / 100;

      if (upSellItemPrice) {
        const additonalFee = parseInt(upSellItemPrice, 10) / 100;
        if (frameSpecPricing) {
          const totalCost = frameSpecPricing + additonalFee;

          return totalCost;
        }
        const totalCost = money + additonalFee;

        return totalCost;
      }

      if (frameSpecPricing) {
        if (qty?.value && qty.value > 0) {
          return frameSpecPricing * qty.value;
        }
        return frameSpecPricing;
      }

      if (isGWP.value) {
        return 'Free';
      }

      if (bundleAdditions.value.length) {
        const bundleAdditionsPrice = bundleAdditions.value.reduce(
          (acc, item) => acc + item.final_line_price,
          0,
        ) / 100;
        return money + bundleAdditionsPrice;
      }

      return money;
    });
    const moneyPrice = computed(() => {
      if (typeof amountPrice.value === 'number') {
        return formatPrice(amountPrice.value);
      }

      return amountPrice.value;
    });
    const bundlePricing = computed(() => {
      if (!isBundle.value || !bundleAdditions.value.length) {
        return null;
      }

      const chargesCounts = bundleAdditions.value.reduce((acc, item) => ({
        ...acc,
        [item.handle]: acc[item.handle] ? acc[item.handle] + 1 : 1,
      }), {});
      const charges = Object.values(bundleAdditions.value.reduce((acc, addition) => {
        if (acc[addition.handle]) {
          acc[addition.handle].amount += addition.final_line_price / 100;
        } else {
          acc[addition.handle] = {
            amount: addition.final_line_price / 100,
            key: addition.handle,
            presentation: `${
              addition.product_title
            } ${
              chargesCounts[addition.handle] > 1
                ? `(${chargesCounts[addition.handle]})`
                : ''
            }`,
          };
        }
        return acc;
      }, {}));

      return {
        totalPrice: amountPrice.value,
        charges: [
          {
            amount: itemPrice.value / 100,
            key: handle.value,
            presentation: title.value,
          },
          ...charges,
        ],
      };
    });
    const isDigitalItem = computed(() => options.value.some((option) => option.value === 'Digital'));
    const childProduct = computed(() => {
      const { _parent_design: parentDesign } = itemProperties.value || {};

      if (parentDesign) {
        return true;
      }

      return false;
    });
    const isNonEditable = computed(() => {
      const uneditableTypes = SDG.Data.cartUneditableTypes || 'art print frame, finished good, gallery wall consultation, shopify gift card';
      const uneditableTypesArray = uneditableTypes.split(', ');
      const isUneditable = uneditableTypesArray.includes(productType.value.toLowerCase());

      return isUneditable || isGWP.value;
    });

    const disableDuplicate = computed(() => {
      const disableDuplicateTypes = SDG.Data.cartDisableDuplicateReorderTypes || 'Frame Bundle, Designer\'s Choice, Designers Choice';
      const disableDuplicateTypesArr = disableDuplicateTypes.split(', ');
      const disableDuplicates = disableDuplicateTypesArr.includes(productType.value.toLowerCase());

      return disableDuplicates || isGWP.value;
    });

    const mainDetails = computed(() => {
      if (isNonEditable.value) {
        const optionsDetails = options.value.reduce((obj, item) => {
          if (item.name === 'Amount' && item.value === 'Custom Amount' && properties.value?.customAmount) {
            return { ...obj, [item.name]: `$${properties.value.customAmount}` };
          }
          return { ...obj, [item.name]: item.value };
        }, {});

        return optionsDetails;
      }

      if (properties.value === null) return null;

      const {
        'Final Frame Size': finalSize,
        Includes,
        'Art Type': artType,
      } = properties.value || {};

      return {
        'Art Type': artType,
        'Final Frame Size': finalSize,
        Includes,
      };
    });

    const itemDetails = computed(() => {
      if (properties.value === null) return null;

      const {
        'Frame Style': frameStyle,
        'Photo Size': photoSize,
        'Mat Style': matStyle,
        'Mat Caption': matCaption,
        'Story Pocket': storyPocket,
      } = properties.value || {};

      if (!photoSize && !matStyle && !matCaption && !storyPocket) {
        return false;
      }

      return {
        'Frame Style': frameStyle || null,
        'Photo Size': photoSize || null,
        'Mat Style': matStyle || null,
        'Mat Caption': matCaption || null,
        'Story Pocket': storyPocket || null,
      };
    });

    const frameSpecId = computed(() => {
      if (properties.value === null) return null;

      const { _frame_spec_number: frameSpecItemId } = properties.value || {};

      return frameSpecItemId || null;
    });

    const frameSpecAuthToken = computed(() => {
      if (properties.value === null) return null;

      const { _frame_spec_authorization_token: token } = properties.value || {};

      return token || null;
    });

    const increment = async (event) => {
      isLoading.value = true;
      context.emit('toggleCheckoutButton', true);

      const newQuantity = qty.value + 1;
      updateCartItemById(
        event,
        id.value,
        itemProperties.value,
        newQuantity,
        isWishlist.value,
        true,
        (updateCompleted) => {
          isLoading.value = false;

          if (updateCompleted) {
            context.emit('toggleCheckoutButton', false);
          }
        },
      );
    };

    const decrement = (event) => {
      if (qty.value === 0) return;
      const newQuantity = qty.value - 1;
      isLoading.value = true;
      context.emit('toggleCheckoutButton', true);

      updateCartItemById(
        event,
        id.value,
        itemProperties.value,
        newQuantity,
        isWishlist.value,
        true,
        (updateCompleted) => {
          isLoading.value = false;

          if (updateCompleted) {
            context.emit('toggleCheckoutButton', false);
          }
        },
      );
    };

    const remove = (event) => {
      const closeModal = (val) => {
        deleteModalOpen.value = val;
      };

      isLoading.value = true;
      __.addClass(event?.target, 'is-loading');
      removeCartItemById(
        event,
        id.value,
        isWishlist.value,
        true,
        () => {
          closeModal(false);

          // elevar tracking
          elevarTracking(props.item, 'remove');
        },
      );
    };

    const moveToWishlist = (event) => {
      const { _upsell_parent: upSellParent } = itemProperties.value || false;

      function moveWishlistItem() {
        isLoading.value = true;
        moveItem(event, id.value);
      }

      if (upSellParent && upSellParent === 'true') {
        const { _upsell_item_variant_id_list: upsellVariantList } = itemProperties.value || {};

        removeUpsellItem(
          upsellVariantList,
          id.value,
          itemProperties.value,
          0,
          true,
          moveWishlistItem,
        );
      } else {
        moveWishlistItem();
      }
    };

    const moveToCart = (event) => {
      isLoading.value = true;
      moveItem(event, id.value, true);
    };

    const toggleModal = (value) => {
      duplicateModalOpen.value = value;
      deleteModalOpen.value = value;
    };

    const duplicateItem = async (event) => {
      const closeModal = () => {
        duplicateModalOpen.value = false;

        // setTimeout is employed to prevent premature activation of the Checkout button
        // in case the user dismisses the duplication loading modal and attempts to
        // immediately interact with the Checkout button
        setTimeout(() => {
          context.emit('toggleCheckoutButton', false);
        }, 500);
      };

      context.emit('toggleCheckoutButton', true);

      if (!variantId?.value) {
        console.error('No variantId data');
        return;
      }
      const frameSpecReorderArr = ['curated design frame', 'custom frame'];
      const isFrameSpecDuplicate = frameSpecReorderArr.includes(productType.value?.toLowerCase());

      let addToCartData;

      if (!frameSpec?.value || !isFrameSpecDuplicate) {
        if (!itemProperties?.value) {
          addToCartData = {
            items: [{
              id: `${variantId.value}`,
              quantity: 1,
            }],
          };
        } else {
          addToCartData = {
            items: [{
              id: `${variantId.value}`,
              quantity: 1,
              properties: {
                ...itemProperties.value,
              },
            }],
          };
        }

        SDG.Bag.addItem(addToCartData);
        closeModal();
        return;
      }

      const clone = await cloneFrameSpec(frameSpec.value);

      if (clone) {
        // id will be assigned from atc
        clone.id = null;

        if (event?.target) {
          __.addClass(event.target, 'is-loading');
          event.target.disabled = true;
        }

        try {
          const cartUrlHost = SDG.Data.cartHost;
          const res = await clone.addToCart({ cartHost: cartUrlHost });

          if (res?.ok) {
            fetchCart();
            if (event?.target) {
              __.removeClass(event.target, 'is-loading');
              event.target.disabled = false;
            }
            closeModal();
          }
        } catch (err) {
          console.error({ message: 'Error duplicating item', error: err });

          if (event?.target) {
            __.removeClass(event.target, 'is-loading');
            event.target.disabled = false;
          }
          closeModal();
        }
      }
    };

    const updateLoadingState = (value) => {
      isLoading.value = value;
    };

    const frameEditParams = computed(() => {
      if (isBundle.value) {
        const {
          _bundle_spec_number: bundleSpecId,
          _bundle_spec_authorization_token: token,
        } = itemProperties.value || null;

        if (bundleSpecId && token) {
          return `/apps/designer/bundle/${bundleSpecId}?line_id=${id.value}&product=${productId.value}&variant=${variantId.value}&token=${token}`;
        }
      }

      let params = `frame-spec=${frameSpecId.value}&line_id=${id.value}`;

      if (frameSpecAuthToken.value) {
        params += `&fs-auth-token=${frameSpecAuthToken.value}`;
      }
      return `/apps/designer/review-your-frame?${params}`;
    });

    const onQuantityInputChanged = __.debounce((event) => {
      const newQuantity = parseInt(event.target.value, 10);
      if (!Number.isNaN(newQuantity)) {
        context.emit('toggleCheckoutButton', true);
        updateCartItemById(
          event,
          id.value,
          itemProperties.value,
          newQuantity,
          isWishlist.value,
          true,
          (updateCompleted) => {
            isLoading.value = false;

            if (updateCompleted) {
              context.emit('toggleCheckoutButton', false);
            }
          },
        );
      }
    }, 500);

    return {
      cartItems,
      childProduct,
      shippingFeeProduct,
      duplicateCancelText,
      duplicateCopyText,
      duplicateConfirmText,
      duplicateTitleText,
      duplicateModalOpen,
      disableDuplicate,
      deleteModalOpen,
      deleteModalTitleText,
      deleteModalConfirmText,
      deleteModalCancelText,
      deleteWishlistModalTitleText,
      deleteWishlistModalConfirmText,
      deleteWishlistModalCancelText,
      enableQuantitySelector,
      frameSpec,
      frameSpecId,
      id,
      image,
      itemDetails,
      isDigitalItem,
      isNonEditable,
      mainDetails,
      moneyPrice,
      bundlePricing,
      title,
      sku,
      url,
      upSellItems,
      variantId,
      variantTitle,
      duplicateItem,
      increment,
      decrement,
      remove,
      isPhysical,
      availableDropOffStores,
      dropOffStoreProperty,
      currentlySelectedStore,
      shipToUsProperty,
      moveToWishlist,
      moveToCart,
      toggleModal,
      isFrameSpecLoading,
      isLoading,
      updateLoadingState,
      productHandle,
      handleSetShipToUs,
      frameEditParams,
      bundleSpecNumber,
      bundleSpecAuthToken,
      isGWP,
      onQuantityInputChanged,
      cartCheckoutPackageError,
      bopisDetails,
      bopisIconHtml,
      showBopis,
      isInStoreFulfillable,
      allowSpecialInstructions,
    };
  },
};
</script>
