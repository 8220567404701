import { ref } from 'vue';
import FrameSpec from '@framebridge/toolbox/FrameSpec';
/**
 * useFrameSpecData
 * @description
 * A composable function to manage frame spec data from prebuilt FrameSpec objects
 */
function useFrameSpecData() {
  const frameSpec = ref(null);
  const frameSpecUrl = ref(null);
  const frameSpecPrice = ref(null);
  const isValid = ref(null);
  const showMaskRenderer = ref(false);

  async function getFrameSpecById(id, authorizationToken) {
    if (!id) return null;

    try {
      const spec = await FrameSpec.build({
        joineryHost: SDG?.Data?.joineryHost || 'https://staging.framebridge.com',
        shopifyHost: SDG?.Data?.shopifyHost || 'https://staging.framebridge.dev',
        number: id,
        token: SDG.Data.joineryToken || 'f16fb23e225e68ba0cb41dbad9a95b3abbedc3f1fa461155',
        authorizationToken,
      });
      const fsURL = await spec.urlParams({ stringify: true });
      const hasMask = spec?.variant?.maskedRenderer || false;
      frameSpec.value = spec;
      isValid.value = true;
      frameSpecUrl.value = fsURL;
      showMaskRenderer.value = hasMask;

      frameSpec.value = spec;
      return spec;
    } catch (error) {
      console.error({ message: 'Error getting frameSpec by id', error });

      return null;
    }
  }

  async function cloneFrameSpec(frameSpecItem) {
    try {
      const newFrameSpec = frameSpecItem.duplicate();
      if (!newFrameSpec) return null;

      await newFrameSpec?.updateVariantAndConstraints();

      if (newFrameSpec?.catalog?.shopifyVariantId) {
        return newFrameSpec;
      }
      console.error({ message: 'Error cloning FrameSpec', error: 'No shopify Variant Id' });

      return null;
    } catch (error) {
      console.error({ message: 'Error cloning FrameSpec', error });

      return null;
    }
  }

  const clearFrameSpec = () => {
    frameSpec.value = null;
    frameSpecUrl.value = null;
    frameSpecPrice.value = null;
    isValid.value = null;
    showMaskRenderer.value = false;
  };

  return {
    frameSpec,
    frameSpecPrice,
    frameSpecUrl,
    isValid,
    cloneFrameSpec,
    getFrameSpecById,
    clearFrameSpec,
    showMaskRenderer,
  };
}

export default useFrameSpecData;
