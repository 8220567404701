/**
 * Materials
 * @param {object} opts
 */

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Flickity from 'flickity';

function materials(opts) {
  const config = {
    dom: {
      carousel: '.js-padded-carousel',
      section: '.js-materials',
      count: '.js-materials-count',
      image: '.js-materials-image',
      images: '.js-materials-images',
      video: '.js-video',
      videoPlay: '.video__control-play--toggle:not(.playing)',
    },
    flickityOpts: {
      desktop: {
        cellAlign: 'center',
        prevNextButtons: true,
        pageDots: true,
        draggable: true,
        wrapAround: true,
        imagesLoaded: true,
      },
      mobile: {
        cellAlign: 'center',
        prevNextButtons: false,
        pageDots: true,
        draggable: true,
        wrapAround: true,
        imagesLoaded: true,
      },
    },
  };
  const c = __.extend(config, opts);

  // global elements
  const $sections = document.querySelectorAll(c.dom.section);
  const $carousels = document.querySelectorAll(c.dom.carousel);

  let view;

  /**
   * Init
   */
  function init() {
    if ($carousels.length > 0) {
      responsive();
      __.windowResize(responsive);
    }

    if (!$sections?.length) return;
    __.addClass(document.body, 'overflow-visible');
    gsap.registerPlugin(ScrollTrigger);
    $sections.forEach(($section) => {
      gsap.from($section, {
        scrollTrigger: {
          trigger: $section,
          start: 'top center',
          toggleClass: 'is-active',
        },
      });
      const $video = $section.querySelector(c.dom.video);
      const $triggers = $section.querySelectorAll(c.dom.count);
      const $imagesWrapper = $section.querySelector(c.dom.images);
      const $images = $imagesWrapper?.querySelectorAll(c.dom.image);
      const mm = gsap.matchMedia();

      if (!$images) return;

      if ($video) {
        gsap.from($video, {
          scrollTrigger: {
            trigger: $video,
            start: 'top bottom',
            end: 'bottom top',
            onEnter: () => {
              const $videoPlay = $video.querySelector(c.dom.videoPlay);
              if (!$videoPlay) return;
              $videoPlay.click();
            },
          },
        });
        mm.add('(min-width: 1025px)', () => {
          gsap.to($video, {
            scrollTrigger: {
              trigger: $images[0],
              start: 'top bottom',
              end: '+=150',
              toggleActions: 'restart play reverse reset',
            },
            opacity: 0,
            duration: 2,
          });
        });
      }

      mm.add('(min-width: 1025px)', () => {
        $triggers.forEach((trigger, index) => {
          const isLastTwo = index === $triggers.length - 1 || index === $triggers.length - 2;
          const endTrigger = isLastTwo ? trigger : $triggers[index + 1];
          const scrollTrigger = {
            trigger,
            endTrigger,
            scrub: 0.5,
            start: 'top bottom',
            end: 'top center',
          };
          const tl = gsap.timeline({ scrollTrigger });
          tl.to($images[index], { opacity: 1, duration: 0.5 });
          if (!isLastTwo) {
            tl.to($images[index], { opacity: 0, duration: 1, delay: 2 });
          }
        });
      });
    });
  }

  /**
   * Responsive functions
   */
  function responsive() {
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          makeCarousel(c.flickityOpts.desktop);
        }
      },
    });

    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
          makeCarousel(c.flickityOpts.mobile);
        }
      },
    });
  }

  /**
   * Make carousel
   */
  function makeCarousel(flickityConfig) {
    $carousels.forEach(($carousel) => {
      let flkty = Flickity.data($carousel);

      if (flkty) {
        flkty.destroy();
      }

      flkty = new Flickity($carousel, flickityConfig);
    });
  }

  return init();
}

export default materials;
