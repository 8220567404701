import Flickity from 'flickity';

/**
 * PricingChart
 * @param {object} opts
 */
function pricingChart(opts) {
  const config = {
    dom: {
      tabs: 'js-pricing-chart-tab',
      contentTabs: 'js-pricing-chart-content',
      chartItem: 'js-tab-content-chart-item',
      carousel: 'js-tout-list-carousel',
      carouselCell: 'js-tout-list-carousel-cell',
    },
    flickityOpts: {
      prevNextButtons: false,
      pageDots: false,
      draggable: true,
      wrapAround: true,
      imagesLoaded: true,
      lazyLoad: true,
    },
    cls: {
      activeTab: 'is-active',
      visibleContent: 'is-visible',
      hoveredImage: 'is-hovered',
    },
  };

  const c = __.extend(config, opts);
  const $tabs = document.querySelectorAll(`.${c.dom.tabs}`);
  const $contentTabs = document.querySelectorAll(`.${c.dom.contentTabs}`);
  const $chartItems = document.querySelectorAll(`.${c.dom.chartItem}`);
  const $carousels = document.body.querySelectorAll(`.${c.dom.carousel}`);
  let view = null;

  /**
   * Init
   */
  function init() {
    if (!$tabs?.length) return;
    setFirstTabActive();
    addEvents();
    onWindowResize();
    __.windowResize(onWindowResize);
  }

  /**
   * Set the first tab active
   */
  function setFirstTabActive() {
    if ($tabs.length > 0) {
      __.addClass($tabs[0], c.cls.activeTab);
      selectContentTab($tabs[0].id);
    }
  }

  /**
   * Add Events
   */
  function addEvents() {
    $tabs.forEach(($tab) => {
      __.addEvent({
        id: $tab,
        event: 'click',
        fn: addActiveClass,
      });
    });

    $chartItems.forEach(($item) => {
      __.addEvent({
        id: $item,
        event: 'mouseover',
        fn: addHoveredClass,
      });
    });

    $chartItems.forEach(($item) => {
      __.addEvent({
        id: $item,
        event: 'focusin',
        fn: addHoveredClass,
      });
    });

    $chartItems.forEach(($item) => {
      __.addEvent({
        id: $item,
        event: 'mouseout',
        fn: removeHoveredClass,
      });
    });

    $chartItems.forEach(($item) => {
      __.addEvent({
        id: $item,
        event: 'focusout',
        fn: removeHoveredClass,
      });
    });
  }

  /**
   * Add Active Class
   * @param {Event} event
   */
  function addActiveClass(event) {
    // Remove 'is-active' class from all tabs
    $tabs.forEach(($tab) => {
      __.removeClass($tab, c.cls.activeTab);
    });

    // Add 'is-active' class to the clicked tab
    const clickedTab = event.currentTarget;
    __.addClass(clickedTab, c.cls.activeTab);

    selectContentTab(clickedTab.id);
  }

  /**
   * Add Hovered Class
   * @param {Event} event
   */
  function addHoveredClass(event) {
    removeHoveredClass();

    // Add 'is-hovered' class to the hovered chart item
    const hoveredTab = event.currentTarget;
    __.addClass(hoveredTab, c.cls.hoveredImage);
  }

  /**
  * Remove Hovered Class
  */
  function removeHoveredClass() {
    // Remove 'is-hovered' class from all chart items
    $chartItems.forEach(($item) => {
      __.removeClass($item, c.cls.hoveredImage);
    });
  }

  /**
   * Select Content Tab
   * @param {string} tabId
   */
  function selectContentTab(tabId) {
    $contentTabs.forEach(($contentTab) => {
      if ($contentTab.id === tabId) {
        __.addClass($contentTab, c.cls.visibleContent);
      } else {
        __.removeClass($contentTab, c.cls.visibleContent);
      }
    });
  }

  /**
  * Responsive functions
  */
  function onWindowResize() {
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          destroyCarousel();
        }
      },
    });

    __.mq({
      view: 'tablet_only',
      callback: () => {
        if (view !== 'tablet_only') {
          view = 'tablet_only';
          const flickityOpts = __.extend(c.flickityOpts, { wrapAround: false, contain: true });
          createCarousel(flickityOpts);
        }
      },
    });

    __.mq({
      view: 'phone',
      callback: () => {
        if (view !== 'phone') {
          view = 'phone';
          createCarousel(c.flickityOpts);
        }
      },
    });
  }

  /**
   * Create Carousel
   * @param {object} opts.flickityOpts
   */
  function createCarousel(flickityOpts) {
    if (!$carousels?.length) return;

    $carousels.forEach(($carousel) => {
      let flickity = Flickity.data($carousel);
      if (flickity) flickity.destroy();

      setTimeout(() => {
        flickity = new Flickity($carousel, flickityOpts);
      }, 500);
    });
  }

  /**
   * Destroy Carousel
   */
  function destroyCarousel() {
    $carousels.forEach(($carousel) => {
      const flickity = Flickity.data($carousel);
      if (flickity) flickity.destroy();
    });
  }

  return init();
}

export default pricingChart;
