<template>
  <Teleport
    v-if="cartItemAdded"
    to="#bagContent"
  >
    <div
      class="bag__content-item js-bag-content-item"
      :data-product-id="cartItemAdded.product_id"
      :data-variant-id="cartItemAdded.variant_id"
    >
      <div
        v-if="!frameSpecId || frameSpecId.includes('SS-') && cartItemAdded.featured_image"
        class="bag-added__image"
      >
        <ResponsiveImage
          :image="cartItemAdded.featured_image.url"
          :image-alt="cartItemAdded.featured_image.alt"
          :inlineIr="true"
          wrapper-class="bag-added__image-wrapper"
        />
        <div class="bag-added__image-label">
          <span class="bag-added__image-label-text">
            {{cartItemAdded.product_type.toLowerCase().includes('consultation')
              ? 'Consultation'
              : 'Item'
            }} Added!
          </span>
        </div>
      </div>
      <div
        v-else-if="frameSpec"
        class="bag-added__image-wrapper pv-gallery__item-upload--artwork
        pv-gallery__item-upload--artwork-added"
      >
        <SingleFramePreviewSvg
          :frameSpec="frameSpec"
          class="content"
        />
      </div>

      <h2 class="bag-added__header">
        {{cartItemAdded.product_type.toLowerCase().includes('consultation')
        || cartItemAdded.product_type.toLowerCase().includes('gift card')
          ? cartItemAdded.product_title
          : cartItemAdded.title
        }} - ${{ cartItemAdded.properties && cartItemAdded.properties.customAmount ?
        cartItemAdded.properties.customAmount : cartItemAdded.price / 100 }}
      </h2>

      <div
        v-if="cartItemAdded.product_type.toLowerCase().includes('consultation')"
        class="bag__gallery-wall-consultation"
      >
        <span class="bag__gallery-wall-consultation-title">
          Design Services for {{ cartItemAdded.title }}
        </span>
        <span class="bag__gallery-wall-consultation-text">
          Thanks! We can't wait to personalize a gallery wall for you. After you check out, we'll
          match you with one of our talented designers and email you the next steps within 48 hours.
        </span>
      </div>
      <div
        v-else-if="(
          cartItemAdded.product_type.toLowerCase() === 'designer\'s choice'
          || cartItemAdded.product_type.toLowerCase() === 'designers choice'
        )"
        class="bag__designers-choice"
      >
        <span class="bag__designers-choice-title">
          + Designer's Choice (Free)
        </span>
        <span class="bag__designers-choice-text">
          Thanks for your notes! Once you checkout, you'll receive directions
          & 4 custom frame options choose from within 48 hours.
        </span>
      </div>
      <div
        v-else-if="cartItemAdded.product_type.toLowerCase().includes('gift card')"
        class="bag__gift-card"
      >
        <div v-if="cartItemAdded.properties?.senderName">
          <span class="bag__gift-card-title">Sender's Name: </span>
          <span class="bag__gift-card-text">
            {{ cartItemAdded.properties.senderName }}
          </span>
        </div>
        <div v-if="cartItemAdded.properties?.senderEmail">
          <span class="bag__gift-card-title">Sender's Email: </span>
          <span class="bag__gift-card-text">
            {{ cartItemAdded.properties.senderEmail }}
          </span>
        </div>
        <div v-if="cartItemAdded.properties?.recipientName">
          <span class="bag__gift-card-title">Recipient's Name: </span>
          <span class="bag__gift-card-text">
            {{ cartItemAdded.properties.recipientName }}
          </span>
        </div>
        <div v-if="cartItemAdded.properties?.recipientEmail">
          <span class="bag__gift-card-title">Recipient's Email: </span>
          <span class="bag__gift-card-text">
            {{ cartItemAdded.properties.recipientEmail }}
          </span>
        </div>
        <div v-if="cartItemAdded.properties?.recipientNumber">
          <span class="bag__gift-card-title">Recipient's Phone number: </span>
          <span class="bag__gift-card-text">
            {{ cartItemAdded.properties.recipientNumber }}
          </span>
        </div>
        <div
          v-if="(cartItemAdded.properties?.sendDate
            && cartItemAdded.variant_options.includes('Send Via Email')
          )"
        >
          <span class="bag__gift-card-title">Send Date: </span>
          <span class="bag__gift-card-text">
            {{ formatDate(cartItemAdded.properties.sendDate) }}
          </span>
        </div>
        <div v-if="cartItemAdded.properties?.recipientGiftNote">
          <span class="bag__gift-card-title">Gift Note: </span>
          <span class="bag__gift-card-text">
            {{ cartItemAdded.properties.recipientGiftNote }}
          </span>
        </div>
      </div>

      <div
        v-else-if="(upSellItems?.items?.length && cartItemAddedLineId)"
        class="bag-upsell"
        :class="isLoading ? 'is-loading' : ''"
      >
        <CartUpsell
          :products="upSellItems?.items"
          :parentProductId="cartItemAddedLineId"
          :parentFrameSpecId="frameSpecId"
          :parentProductProperties="itemProperties"
          :bagItems="cartItems"
          :updateLoadingState="updateLoadingState"
        />
      </div>
    </div>
  </Teleport>
</template>

<script>
import {
  Teleport,
  ref,
  watch,
  onMounted,
} from 'vue';
import SingleFramePreviewSvg from '@framebridge/toolbox/SingleFramePreviewSvg';
import useCart from '../composables/cart-actions';
import useFrameSpecData from '../composables/frame-spec';
import CartUpsell from '../cart-upsell/CartUpsell';
import ResponsiveImage from '../common/responsive-image/ResponsiveImage';
import trapFocus from '../../lib/trap-focus';

export default {
  name: 'CartApp',
  components: {
    CartUpsell,
    ResponsiveImage,
    SingleFramePreviewSvg,
    Teleport,
  },
  props: {
    itemProperties: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const {
      cartItems,
      cartLoading,
      cartTotalPrice,
      checkoutUrl,
      currency,
      displayItemAdded,
      error,
    } = useCart();
    const { frameSpec, getFrameSpecById } = useFrameSpecData();

    const cartItemAdded = ref(null);
    const cartItemAddedLineId = ref(null);
    const frameSpecId = ref(null);
    const isLoading = ref(false);
    const upSellItems = ref({ heading: '', subheading: '', items: [] });

    const storeSelectorBtn = () => {
      const $body = document.body;
      __.addClass($body, 'is-store-selector-exposed');
      const $storeSelectorModal = document.getElementById('storeSelectorModal');

      setTimeout(() => {
        trapFocus($storeSelectorModal);
      }, 150);
    };

    const getProductMetafields = (addedItem) => {
      const { handle, variant_id: variantId } = addedItem;
      const upsellURL = `/products/${handle}?view=cart-upsell`;
      fetch(upsellURL)
        .then((res) => res.json())
        .then((upsellData) => {
          const { cartUpsell } = upsellData || {};
          if (!cartUpsell) return;

          const { heading, subheading } = cartUpsell || '';
          const variantUpsellItems = cartUpsell[variantId];

          if (variantUpsellItems?.length > 0) {
            upSellItems.value = {
              heading,
              subheading,
              items: variantUpsellItems,
            };
          }
        })
        .catch((err) => console.error(err));
    };

    watch(cartItems, () => {
      cartItemAdded.value = displayItemAdded.value !== null
        ? cartItems.value.find((item) => item.variant_id === displayItemAdded.value)
        : null;
      if (!cartItemAdded.value) return;
      const { id, properties } = cartItemAdded.value || {};

      // frameSpec images are teleported from OrderItem and OrderDetailsItem components
      // eslint-disable-next-line no-underscore-dangle
      if (properties?._frame_spec_number) frameSpecId.value = properties?._frame_spec_number;

      if (frameSpecId.value) {
        getFrameSpecById(frameSpecId.value);
      }

      if (id && id !== '') {
        cartItemAddedLineId.value = id;
      }

      getProductMetafields(cartItemAdded.value);

      // minor bag drawer adjustments based on product data
      const $bagCartBtn = document.body.querySelector('.js-bag-cart-btn');
      const $bagCheckoutBtn = document.body.querySelector('.js-bag-checkout-btn');
      const isPhysicalConveyance = cartItemAdded.value.options_with_values.find((options) => (
        options.name.includes('Physical') || options.value.includes('Physical')
      ));

      // set the FrameBridge checkout URL
      if ($bagCheckoutBtn && $bagCheckoutBtn.href !== checkoutUrl.value) {
        $bagCheckoutBtn.href = checkoutUrl.value;
      }

      // hide "Buy Now" button for Physical Conveyance products
      if (isPhysicalConveyance) {
        if ($bagCartBtn) __.addClass($bagCartBtn, 'bag__cart--full');
        if ($bagCheckoutBtn) __.addClass($bagCheckoutBtn, 'hide');
      } else {
        if ($bagCartBtn) __.removeClass($bagCartBtn, 'bag__cart--full');
        if ($bagCheckoutBtn) __.removeClass($bagCheckoutBtn, 'hide');
      }
    });

    const updateLoadingState = (value) => {
      isLoading.value = value;
    };

    const formatDate = (date) => {
      const dateArr = date.split('-');
      const year = dateArr[0];
      const month = dateArr[1];
      const day = dateArr[2];
      return `${month}/${day}/${year}`;
    };

    onMounted(() => {
      if (frameSpecId.value) {
        getFrameSpecById(frameSpecId.value);
      }
    });

    return {
      cartItemAdded,
      cartItemAddedLineId,
      cartItems,
      cartLoading,
      cartTotalPrice,
      checkoutUrl,
      currency,
      error,
      frameSpec,
      frameSpecId,
      isLoading,
      upSellItems,
      storeSelectorBtn,
      updateLoadingState,
      formatDate,
    };
  },
};
</script>
