/**
 * Progress bar for article
 * @param {Object} opts - Options
 */

function articleProgressBar(opts) {
  const config = {
    dom: {
      article: 'js-blog-article',
      progressContainer: 'js-blog-article-progress-container',
      progressBar: 'js-blog-article-progress-bar',
    },
    cls: {
      scrolled: 'is-scrolled',
    },
  };

  const c = __.extend(config, opts);
  const $article = document.body.querySelector(`.${c.dom.article}`);
  const $progressContainer = document.body.querySelector(`.${c.dom.progressContainer}`);
  const $progressBar = $progressContainer?.querySelector(`.${c.dom.progressBar}`);

  /**
   * Init
   */
  function init() {
    if (!$article || !$progressContainer || !$progressBar) return;
    addScrollEvents();
  }

  /**
   * Add Scroll Events
   */
  function addScrollEvents() {
    __.addEvent({
      id: document,
      event: 'scroll',
      fn: () => {
        __.debounce(handleProgressBar(), 100);
      },
    });

    // trigger initially on page load, before scroll
    handleProgressBar();
  }

  /**
   * Handle Progress Bar
   */
  function handleProgressBar() {
    if ($progressContainer) {
      __.addClass($progressContainer, c.cls.scrolled);
    }

    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const { clientHeight } = document.documentElement;
    const articleHeight = $article.scrollHeight - clientHeight;

    // Hide progress bar if article height is less than window height
    // and doesn't require scrolling to read it fully
    if (articleHeight <= 0) {
      __.hide($progressContainer);
      return;
    }

    // Add 20% vertical offset to article height
    const offset = articleHeight * 0.2;
    const scrolled = (scrollTop / (articleHeight + offset)) * 100;
    $progressBar.style.width = `${scrolled}%`;
  }

  return init();
}

export default articleProgressBar;
