<template>
  <div class="bag__thermometer">
    <div class="bag__thermometer-inner">
      <ResponsiveImage
        :image="icon"
        class="threshold-icon"
        image-alt="Free Shipping icon"
      />
      <span v-html="computedThermometerCopy" class="bag__thermometer-text"></span>
    </div>
    <CartProgressBar v-if="difference > 0" :percentage="differencePercentage" />
  </div>
</template>

<script>
import {
  ref,
  computed,
  watch,
} from 'vue';
import useCart from '../composables/cart-actions';
import CartProgressBar from './CartProgressBar';
import ResponsiveImage from '../common/responsive-image/ResponsiveImage';

export default {
  name: 'CartThemometer',
  components: {
    CartProgressBar,
    ResponsiveImage,
  },
  props: {
    requiresNoShipping: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const belowThresholdThermometerCopy = ref(SDG.Data.cart.below_threshold_thermometer_copy);
    const aboveThresholdThermometerCopy = ref(SDG.Data.cart.above_threshold_thermometer_copy);
    const cartThresholdMinimum = ref(Number(SDG.Data.cart.cart_threshold_minimum.replace('.', '')));
    const cartBelowIcon = ref(SDG.Data.cart.below_threshold_cart_thermometer_icon);
    const cartAboveIcon = ref(SDG.Data.cart.above_threshold_cart_thermometer_icon);
    const {
      itemsSubtotalPrice,
    } = useCart();

    const difference = computed(() => {
      if (props.requiresNoShipping) {
        return 0;
      }
      return cartThresholdMinimum.value - itemsSubtotalPrice.value;
    });
    const isAboveThreshold = computed(() => difference.value <= 0);
    const computedThermometerCopy = computed(() => {
      if (difference.value <= 0 || props.requiresNoShipping) {
        return aboveThresholdThermometerCopy.value;
      }
      return belowThresholdThermometerCopy.value.replace('{{amount}}', __.formatPriceDisplay(difference.value));
    });

    const differencePercentage = computed(() => {
      const percentage = (itemsSubtotalPrice.value / cartThresholdMinimum.value) * 100;
      return percentage;
    });

    const icon = computed(() => (difference.value > 0 ? cartBelowIcon.value : cartAboveIcon.value));
    emit('update:isAboveThreshold', isAboveThreshold.value);
    watch(isAboveThreshold, (newValue) => {
      emit('update:isAboveThreshold', newValue);
    });

    return {
      belowThresholdThermometerCopy,
      computedThermometerCopy,
      CartProgressBar,
      difference,
      differencePercentage,
      cartBelowIcon,
      icon,
      isAboveThreshold,
    };
  },
};
</script>
