/**
 * Footer Video Modal
 */
function videoEmbed(opts) {
  const config = {
    dom: {
      wrapper: 'js-video-embed-wrapper',
      video_embed: 'js-video-embed',
      video_embed_poster_img: 'js-video-embed-poster-image',
      video_embed_iframe: 'js-video-embed-iframe',
      play_button: 'js-video-embed-play',
    },
  };

  const c = __.extend(config, opts);
  const $wrappers = document.querySelectorAll(`.${c.dom.wrapper}`);
  if (!$wrappers.length) return;

  /**
   * init
   */
  function init() {
    $wrappers.forEach(($wrapper) => {
      addEvents($wrapper);
    });
  }

  function addEvents($wrapperEl) {
    // add event listener to trigger play on video cta button
    __.addEvent({
      id: $wrapperEl,
      className: c.dom.play_button,
      event: 'click',
      fn: () => {
        playVideo($wrapperEl);
      },
    });
  }

  /**
   * Plays iFrame Video and hides poster image and play button
   */
  function playVideo($wrapper) {
    const $iframe = $wrapper.querySelector(`.${c.dom.video_embed_iframe}`);
    const $playButton = $wrapper.querySelector(`.${c.dom.play_button}`);
    const $posterImage = $wrapper.querySelector(`.${c.dom.video_embed_poster_img}`);
    const iframeSrc = $iframe.getAttribute('data-src');
    $posterImage.style.display = 'none';
    $iframe.style.display = 'block';
    $iframe.setAttribute('src', `${iframeSrc}&autoplay=1`);
    setTimeout(() => {
      $playButton.style.display = 'none';
    }, 1000);
  }

  init();
}

export default videoEmbed;
