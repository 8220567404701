<template>
  <div class="bag__progress-bar">
    <div class="bag__progress-bar--fill" :style="{ width: fillPercentage + '%' }"></div>
  </div>
</template>

<script>
export default {
  name: 'CartProgressBar',
  props: {
    percentage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    fillPercentage() {
      return Math.max(0, Math.min(100, this.percentage));
    },
  },
};
</script>
