import Flickity from 'flickity';

/*
* Go Custom
* @param {object} opts
*/

function threeUpCarousel(opts) {
  const config = {
    dom: {
      carousel: 'js-three-up-carousel',
    },
    flickityOpts: {
      prevNextButtons: false,
      pageDots: true,
      draggable: true,
      wrapAround: true,
      imagesLoaded: true,
      lazyLoad: true,
      adaptiveHeight: true,
    },
  };

  const c = __.extend(config, opts);
  const $carousels = document.querySelectorAll(`.${c.dom.carousel}`);
  let view = null;

  /**
   * Init
   */
  function init() {
    if (!$carousels?.length) return;
    onWindowResize();
    __.windowResize(onWindowResize);
  }

  /**
  * Responsive functions
  */
  function onWindowResize() {
    __.mq({
      view: 'tablet',
      callback: () => {
        if (view !== 'tablet') {
          view = 'tablet';
          destroyCarousel();
        }
      },
    });

    __.mq({
      view: 'phone',
      callback: () => {
        if (view !== 'phone') {
          view = 'phone';
          initializeCarousel();
        }
      },
    });
  }

  /**
   * Initialize three up carousel
   */
  function initializeCarousel() {
    $carousels.forEach(($carousel) => {
      let flickity = Flickity.data($carousel);
      if (flickity) flickity.destroy();

      setTimeout(() => {
        flickity = new Flickity($carousel, c.flickityOpts);
      }, 500);
    });
  }

  /**
   * Destroy three up carousel
   */
  function destroyCarousel() {
    $carousels.forEach(($carousel) => {
      const flickity = Flickity.data($carousel);
      if (flickity) flickity.destroy();
    });
  }

  init();
}

export default threeUpCarousel;
