/**
 * FourSixty UGC
 */

import Flickity from 'flickity';

function fourSixtyUGC(opts) {
  const config = {
    dom: {
      foursixtyWrapper: 'js-foursixty-ugc',
      items: 'fs-entry-container',
      modal: 'fs-prepended-detail',
      pageDots: 'flickity-page-dots',
      pageDot: 'dot',
      timeline: 'fs-timeline',
      timelineEntry: 'fs-timeline-entry',
      ugc: 'ugc',
    },
    flickityOpts: {
      contain: true,
      cellAlign: 'center',
      adaptiveHeight: false,
      prevNextButtons: true,
      pageDots: true,
      draggable: true,
      dragThreshold: 10,
      wrapAround: true,
      imagesLoaded: true,
      lazyLoad: true,
      resize: true,
    },
    cls: {
      dotDark: 'dot--dark',
      dotLight: 'dot--light',
      fullHeight: 'full-height',
      isSelected: 'is-selected',
    },
  };

  // Shorthand config
  const c = __.extend(config, opts); // Extend defaults with passed options

  // cached global(s)
  const $foursixtyWrapper = document.querySelector(`.${c.dom.foursixtyWrapper}`);
  const $ugcs = document.querySelectorAll(`.${c.dom.ugc}`);
  const $modal = document.querySelector(`.${c.dom.modal}`);
  let view;

  /**
   * Init
   */
  function init() {
    if (!$ugcs && !$ugcs.length > 0) return;

    responsive();
    __.windowResize(responsive);
  }

  /**
   * Make carousel
   */
  function makeCarousel() {
    $ugcs.forEach(($ugc) => {
      const $timelines = $ugc.querySelectorAll(`.${c.dom.timeline}`);

      $timelines.forEach(($timeline) => {
        // wait for content to be populated
        const timelineCallback = function (mutationsList) {
          mutationsList.forEach((mutation) => {
            if (mutation.type === 'childList') {
              const $timelineItems = $timeline.querySelectorAll(`.${c.dom.items}`);

              $timelineItems.forEach(($timelineItem) => {
                const $timelineEntry = $timelineItem.querySelector(`.${c.dom.timelineEntry}`);
                const { foursixtyCategory } = $foursixtyWrapper ? $foursixtyWrapper.dataset : null;

                if (!$timelineEntry) return;
                const $timelineEntryContent = `
                  <div class="fs-border-wrapper">
                    <div class="fs-border-top"></div>
                    <div class="fs-border-left"></div>
                    <div class="fs-border-right"></div>
                    <div class="fs-border-bottom">
                      <p class="fs-category">${foursixtyCategory}</p>
                      <span class="fs-explore-icon icon icon--chevron-right hide-mobile"></span>
                      <p class="fs-explore-text hide-desktop">Explore</p>
                    </div>
                  </div>
                `;
                $timelineEntry.innerHTML = $timelineEntryContent;
              });

              if (!__.hasClass($timeline, c.dom.enabled) && $timelineItems.length > 0) {
                __.removeClass($ugc, c.dom.hide);

                let prevNextButtons = $timelineItems.length > 3;

                __.mq({
                  view: 'mobile',
                  callback: () => {
                    prevNextButtons = $timelineItems.length > 1;
                  },
                });

                let flickityOpts = __.extend(c.flickityOpts, {
                  prevNextButtons,
                  on: {
                    change: () => {
                      // FourSixty uses scrolling to lazyload images 😥
                      // so we need to simulate scrolling for the images
                      // to load on slide change
                      window.scrollBy(0, 1);
                      window.scrollBy(0, -1);
                    },
                    settle: () => {
                      // FourSixty uses scrolling to lazyload images 😥
                      // so we need to simulate scrolling for the images
                      // to load on slide settle
                      window.scrollBy(0, 1);
                      window.scrollBy(0, -1);
                    },
                    dragEnd: () => {
                      // FourSixty keeps registering the dragEnd as a click
                      // so we need to hide the modal on dragEnd
                      __.addClass($modal, c.dom.hide);
                    },
                    staticClick: () => {
                      // remove hide class when item is clicked
                      __.removeClass($modal, c.dom.hide);
                    },
                  },
                });

                let offsetDivisor;
                if (view === 'desktop') {
                  offsetDivisor = 1.66;
                }

                if (view === 'mobile') {
                  offsetDivisor = 1.5;
                }

                const $flickityCell = $timeline.querySelector(`.${c.dom.items}`);
                const leftOffset = setFlickityOffset($flickityCell.offsetWidth, offsetDivisor);
                const timelineOffset = $timeline.offsetWidth;
                const cellAlign = leftOffset / timelineOffset;
                flickityOpts = __.extend(flickityOpts, { cellAlign });

                /* eslint-disable-next-line */
                const carousel = new Flickity($timeline, flickityOpts);
              }
            }
          });
        };
        const timelineObserver = new MutationObserver(timelineCallback);
        timelineObserver.observe($timeline, { childList: true });
      });
    });
  }

  /**
   * Responsive functions
   */
  function responsive() {
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          makeCarousel();
        }
      },
    });

    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
          makeCarousel();
        }
      },
    });
  }

  /**
   * Set the flickity carousel offset
   */
  function setFlickityOffset(flickityCellOffsetWidth, flickityOffsetDivisor) {
    Flickity.Cell.prototype.setDefaultTarget = () => {
      const marginProperty = this.parent.originSide === 'left' ? 'marginLeft' : 'marginRight';
      this.target = this.x + this.size[marginProperty];
    };

    return flickityCellOffsetWidth / flickityOffsetDivisor;
  }

  return init();
}

export default fourSixtyUGC;
