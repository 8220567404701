/**
 * Quote
 * @param {object} opts
 */
function quote(opts) {
  const config = {
    dom: {
      dynamic: 'js-quote-dynamic',
      dynamicBottom: 'quote__content-dynamic--bottom',
      mainText: 'js-quote-main-text',
      text: 'js-quote-text',
      wrapper: 'js-quote',
    },
    cls: {
      scrolled: 'is-scrolled',
    },
  };
  const c = __.extend(config, opts);

  // global elements
  const $wrappers = document.body.querySelectorAll(`.${c.dom.wrapper}`);
  let lastScrollPos;
  let view;

  /**
   * Init
   */
  function init() {
    if (!$wrappers?.length) return;
    onWindowResize();
    __.windowResize(onWindowResize);
  }

  /**
   * On Window Resize
   */
  function onWindowResize() {
    // desktop
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          addScrollEvents();
        }
      },
    });

    // mobile
    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
          addScrollEvents();
        }
      },
    });
  }

  /**
   * Add Scroll Events
   */
  function addScrollEvents() {
    __.addEvent({
      id: document,
      event: 'scroll',
      fn: () => {
        __.debounce(handleScrollEvent(), 100);
      },
    });

    // trigger initially on page load, before scroll
    handleScrollEvent(true);
  }

  /**
   * Handle Scroll Event
   * @param {Boolean} isPageLoad
   */
  function handleScrollEvent(isPageLoad) {
    const offset = window.innerHeight / 2;
    const scrollingUp = window.scrollY < lastScrollPos;
    lastScrollPos = window.scrollY;

    [...$wrappers].forEach(($wrapper) => {
      const $dynamicEls = $wrapper.querySelectorAll(`.${c.dom.dynamic}`);
      const $textEls = $wrapper.querySelectorAll(`.${c.dom.text}`);
      const $mainText = $wrapper.querySelector(`.${c.dom.mainText}`);
      const { top, bottom } = $wrapper.getBoundingClientRect();
      const sectionInView = top - window.innerHeight <= 0 && bottom >= 0;
      if (!sectionInView && !isPageLoad) return;

      // toggle main text content
      const { top: mainTextTop } = $mainText.getBoundingClientRect();
      const mainTextInView = mainTextTop - (offset / (view === 'mobile' ? 3 : 2)) <= 0;
      if (mainTextInView && !__.hasClass($mainText, c.cls.scrolled) && !scrollingUp) {
        __.addClass($mainText, c.cls.scrolled);
      } else if (!mainTextInView && __.hasClass($mainText, c.cls.scrolled) && scrollingUp) {
        __.removeClass($mainText, c.cls.scrolled);
      }

      // toggle quote text content visibility and transitions
      [...$textEls].forEach(($el, index) => {
        const textClass = `.${c.dom.text}-${view === 'desktop' ? 'd' : 'm'}${index + 1}`;
        const $textEl = $wrapper.querySelector(textClass);
        if (!$textEl) return;

        const { top: textTop } = $textEl.getBoundingClientRect();
        const textOffset = (offset * (view === 'mobile' ? 2.5 : 1.75)) - (index * 100);
        const inView = textTop - textOffset <= 0;

        if (inView && !__.hasClass($textEl, c.cls.scrolled) && !scrollingUp) {
          __.addClass($textEl, c.cls.scrolled);
        } else if (!inView && __.hasClass($textEl, c.cls.scrolled) && scrollingUp) {
          __.removeClass($textEl, c.cls.scrolled);
        }
      });

      // toggle dynamic content beneath quote text
      [...$dynamicEls].forEach(($el, index) => {
        const dynamicClass = `.${c.dom.dynamic}-${view === 'desktop' ? 'd' : 'm'}${index + 1}`;
        const $dynamicEl = $wrapper.querySelector(dynamicClass);
        if (!$dynamicEl) return;

        const isDesktopBottom = view === 'desktop' && __.hasClass($dynamicEl, c.dom.dynamicBottom);
        const { top: dynamicTop } = $dynamicEl.getBoundingClientRect();
        const dynamicOffset = index === 0
          ? offset * 1.5
          : offset * (isDesktopBottom ? 2.5 : 2);
        const inView = dynamicTop - dynamicOffset <= 0;
        const timeoutTime = index * (view === 'mobile' ? 200 : 400);

        setTimeout(() => {
          if (inView && !__.hasClass($dynamicEl, c.cls.scrolled) && !scrollingUp) {
            __.addClass($dynamicEl, c.cls.scrolled);
          } else if (!inView && __.hasClass($dynamicEl, c.cls.scrolled) && scrollingUp) {
            __.removeClass($dynamicEl, c.cls.scrolled);
          }
        }, timeoutTime);
      });
    });
  }

  return init();
}

export default quote;
