import Flickity from 'flickity';

/**
 * Promo Carousel
 * @param {object} opts
 */
function promoCarousel(opts) {
  const config = {
    dom: {
      carousel: 'js-promo-carousel-el',
      media: 'js-promo-carousel-media',
      media_wrapper: 'js-promo-carousel-media-wrapper',
      wrapper: 'js-promo-carousel',
    },
    cls: {
      active: 'is-active',
    },
  };
  const c = __.extend(config, opts);

  const flickityOpts = {
    contain: true,
    draggable: true,
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
  };

  // global elements
  const $wrappers = document.body.querySelectorAll(`.${c.dom.wrapper}`);
  const flickityCarousels = [];
  let view;

  /**
   * Init
   */
  function init() {
    if (!$wrappers?.length) return;
    onWindowResize();
    __.windowResize(onWindowResize);
  }

  /**
   * On Window Resize
   */
  function onWindowResize() {
    // desktop
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          rebuildFlickity();
        }
      },
    });

    // mobile
    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
          rebuildFlickity();
        }
      },
    });
  }

  /**
   * rebuild flickity
   */
  function rebuildFlickity() {
    [...$wrappers].forEach(($wrapper, index) => {
      const activeCarousel = flickityCarousels[index];
      if (activeCarousel) activeCarousel.destroy();
      const $carousel = $wrapper.querySelector(`.${c.dom.carousel}`);
      if (!$carousel) return;

      setTimeout(() => {
        const flickityCarousel = new Flickity($carousel, flickityOpts);

        // add change callback for desktop Flickity carousels
        if (view === 'desktop') {
          flickityCarousel.on('change', (changeIndex) => {
            const $mediaWrapper = $wrapper.querySelector(`.${c.dom.media_wrapper}`);
            const $mediaEls = $mediaWrapper.querySelectorAll(`.${c.dom.media}`);
            [...$mediaEls].forEach(($el, mediaIndex) => {
              if (mediaIndex === changeIndex) {
                __.addClass($el, c.cls.active);
              } else if (__.hasClass($el, c.cls.active)) {
                __.removeClass($el, c.cls.active);
              }
            });
          });
        }

        if (activeCarousel) {
          flickityCarousels[index] = flickityCarousel;
        } else {
          flickityCarousels.push(flickityCarousel);
        }
      }, 500); // short timeout for Flickity cell sizing
    });
  }

  return init();
}

export default promoCarousel;
