import { ref, onMounted } from 'vue';

function useCartPromotion() {
  const enableTile = SDG.Data.cartPromoTile || false;
  const url = '/cart?view=cart-promotion';
  const cartPromotion = ref(false);

  onMounted(() => {
    if (enableTile) getPromotion();
  });

  function getPromotion() {
    fetch(url)
      .then((resp) => resp.text())
      .then((html) => {
        cartPromotion.value = html;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return {
    cartPromotion,
  };
}

export default useCartPromotion;
