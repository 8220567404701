/**
* bag
* @requires [lib/util.js,lib/bag-config.js,lib/bag-refresh.js,lib/bag-init.js]
*
* bag namespace
* bag config
* add to bag config
* extend bag config
* form
* add item
* on error
* on success
*/

import elevarTracking from './elevar-tracking';
import trackingAddToCart from './tracking-add-to-cart';

/**
 * bag namespace
 * @type {Object}
 */
SDG.Bag = SDG.Bag || {};

/**
 * bag config
 * @type {Object}
 */
SDG.Bag.config = SDG.Bag.config || {};

/**
 * add to bag config
 * @type {Object}
 */
SDG.Bag.config_addto = {
  dom: {
    add_form: 'addToBagForm',
    bag_btn: 'btnAddToBag',
    bag_btn_text: 'btnAddToBagText',
    id: 'id',
    hidden_delivery: 'deliveryInput',
    hidden_fs_number: 'frameSpecNumber',
    quantity: 'quantity',
    quantity_messages: 'quantityMessages',
  },
  text: {
    bag_default: 'Add to Bag',
    bag_adding: 'Adding...',
    bag_added: 'Added!',
  },
  timer: {
    reset_text: 600,
  },
};

/**
 * extend bag config
 * @type {Object}
 */
SDG.Bag.config = __.extend(SDG.Bag.config, SDG.Bag.config_addto);

/**
 * form
 */
SDG.Bag.form = function () {
  const c = SDG.Bag.config;

  /**
   * init
   */
  function init() {
    __.addEvent({
      id: c.dom.add_form,
      event: 'submit',
      fn: addToBag,
    });
  }

  /**
   * add to bag
   * @type {Function}
   */
  function addToBag(e) {
    e.preventDefault();

    // form values
    const id = this[c.dom.id].value;
    const qty = parseInt(this[c.dom.quantity].value, 10);

    // variant and qty
    const { productJson } = SDG.Data;
    const variant = SDG.Bag.variant(id, productJson.variants);
    const variantData = variant.getData();
    const {
      inventory_quantity: variantMaxQty,
      inventory_management: variantInventoryManagement,
    } = variantData;
    const isMax = variantInventoryManagement !== null ? variant.isMax(qty) : false;

    // message
    const message = SDG.Messages.init({
      id: c.dom.quantity_messages,
      msgs: {
        error: `<p>Sorry, ${variantMaxQty} is the max quantity available in this size.</p>`,
      },
    });

    // other
    const $btn = document.getElementById(c.dom.bag_btn);
    const $btnText = document.getElementById(c.dom.bag_btn_text);
    let data;

    // if max
    if (isMax) {
      // flag user
      message.error();
    } else {
      // clear messages
      message.clear();

      const $hiddenSelect = this.querySelector(c.dom.hidden_id);
      const $hiddenDelivery = this.querySelector(`#${c.dom.hidden_delivery}`) || null;
      const $hiddenFSNumber = this.querySelector(`#${c.dom.hidden_fs_number}`) || null;
      const variantId = $hiddenSelect?.value;
      const validFSNumber = ($hiddenFSNumber?.value && $hiddenFSNumber?.value?.includes('FS-')) || false;

      if (!variantId) {
        console.error('bag-add-to error, no variantId');
        return;
      }
      $btn.disabled = true;
      if ($btnText) {
        $btnText.innerHTML = c.text.bag_adding;
      }

      if ($hiddenDelivery && $hiddenDelivery?.value !== '') {
        const estimatedDateText = $hiddenDelivery.value;

        if (validFSNumber) {
          data = {
            items: [{
              id: `${variantId}`,
              quantity: 1,
              properties: {
                _delivery_estimate: estimatedDateText,
                _frame_spec_number: $hiddenFSNumber?.value,
              },
            }],
          };
        } else {
          data = {
            items: [{
              id: `${variantId}`,
              quantity: 1,
              properties: {
                _delivery_estimate: estimatedDateText,
              },
            }],
          };
        }
      } else if (validFSNumber) {
        data = {
          items: [{
            id: `${variantId}`,
            quantity: 1,
            properties: {
              _frame_spec_number: $hiddenFSNumber?.value,
            },
          }],
        };
      } else {
        data = {
          items: [{
            id: `${variantId}`,
            quantity: 1,
          }],
        };
      }

      SDG.Bag.addItem(data);
    }
  }

  return init();
};

/**
 * add item
 * @param {Object} data
 * @param {number} variantId
 */
SDG.Bag.addItem = function (data, cb = false) {
  const { id: variantId } = data.items[0];
  const c = SDG.Bag.config;
  const cidValue = SDG.Data.cid;
  const addEndpoint = `${c.url.add}?cid=${cidValue}`;

  __.ajax({
    url: addEndpoint,
    dataObject: JSON.stringify(data),
    contentType: 'application/json',
    type: 'POST',
    error: SDG.Bag.onError,
    success: (itemData) => {
      SDG.Bag.onSuccess(itemData, variantId, cb);

      // elevar tracking
      const addedItem = itemData.items.find((item) => (
        item.variant_id === `${variantId}`
      ));
      elevarTracking(addedItem, 'add');
    },
  });
};

/**
 * on error
 */
SDG.Bag.onError = function () {
  console.warn('There has been an error.');
};

/**
 * on success
 * @param {Object} data
 * @param {Number} variantId
 */
SDG.Bag.onSuccess = function (data, variantId, cb = false) {
  const c = SDG.Bag.config;
  const { enableItemAddedDrawer } = SDG.Data;
  const $btn = document.getElementById(c.dom.bag_btn);
  const $btnText = document.getElementById(c.dom.bag_btn_text);

  if ($btnText) {
    $btnText.innerHTML = c.text.bag_added;

    setTimeout(() => {
      $btn.disabled = false;
      $btnText.innerHTML = c.text.bag_default;
    }, c.timer.reset_text);
  } else if ($btn) {
    $btn.disabled = false;
  }

  // tracking
  trackingAddToCart(data);

  if (!enableItemAddedDrawer) {
    // send user to cart page
    window.location = '/cart';
    return;
  }

  // refresh bag (Item Added! display)
  SDG.Bag.refresh(data, variantId, cb);
};

export default SDG.Bag.form;
