/**
 * Navigation
 * @param {object} opts
 */
function navigation(opts) {
  const config = {
    dom: {
      header: 'header',
      nav: 'navigation',
      navOverlay: 'navigationOverlay',
      navMenu: 'js-nav-menu',
      navMenuBtn: 'js-nav-menu-btn',
      navPromo: 'js-nav-promo',
      navPromoMain: 'js-nav-promo-main',
      navSubmenu: 'js-nav-submenu',
      navSubmenuBtn: 'js-nav-submenu-btn',
      navSubmenuLink: 'js-nav-submenu-link',
      navTile: 'js-nav-tile',
      navTileLink: 'navigation-tile__link',
      mobileMenu: 'mobileMenu',
      mobileMenuAcc: 'mobileMenuAcc',
      mobileMenuAccBtn: 'js-acc-btn',
      mobileMenuAccMenu: 'js-acc-menu',
      mobileMenuBtn: 'mobileMenuBtn',
      mobileMenuCloseBtn: 'mobileMenuCloseBtn',
      mobileMenuPromos: 'mobileMenuPromos',
      mobileMenuSubmenu: 'mobile-menu__submenu-menu--submenu',
      mobileMenuSubmenuBtn: 'mobile-menu__submenu-menu-btn',
      search: 'searchNavigation',
      searchOverlay: 'searchOverlay',
      promoBar: 'promoBar',
    },
    cls: {
      active: 'is-active',
      hide: 'hide',
      open: 'is-open',
      scrolled: 'page-scrolled',
      scrolledPromo: 'page-scrolled--promo',
      navTall: 'navigation--tall',
    },
  };

  const c = __.extend(config, opts);
  const $header = document.getElementById(c.dom.header);
  const $promoBar = document.getElementById(c.dom.promoBar);
  const $nav = document.getElementById(c.dom.nav);
  const $navOverlay = document.getElementById(c.dom.navOverlay);
  const $mobileMenu = document.getElementById(c.dom.mobileMenu);
  const $mobileMenuAcc = document.getElementById(c.dom.mobileMenuAcc);
  const $mobileMenuBtn = document.getElementById(c.dom.mobileMenuBtn);
  const $mobileMenuCloseBtn = document.getElementById(c.dom.mobileMenuCloseBtn);
  const $mobileMenuPromos = document.getElementById(c.dom.mobileMenuPromos);
  const $search = document.getElementById(c.dom.search);
  const $searchOverlay = document.getElementById(c.dom.searchOverlay);
  let scrollTicking = false;
  let previousScrollPosition = getScrollPosition();
  let view;

  /**
   * Init
   */
  function init() {
    if (!$header) return;

    responsive();
    __.windowResize(responsive);
    addEvents();
  }

  /**
   * Responsive
   * @type {Function}
   */
  function responsive() {
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view === 'desktop') return;
        view = 'desktop';
        resetNavMenus();
        handleHeaderStyling();
      },
    });
    __.mq({
      view: 'mobile',
      callback: () => {
        if (view === 'mobile') return;
        view = 'mobile';
        resetNavMenus();
        initAccordions();
      },
    });
  }

  /**
   * Add events
   */
  function addEvents() {
    // Desktop scroll event
    __.addEvent({
      id: document,
      event: 'scroll',
      fn: handleOnScroll,
    });

    // Desktop open navigation drawer
    __.addEvent({
      id: $header,
      className: c.dom.navMenuBtn,
      event: 'mouseenter',
      fn: handleNavDrawer,
    });

    // Desktop close navigation drawer
    __.addEvent({
      id: $nav,
      event: 'mouseleave',
      fn: (e) => handleNavDrawer(e, false),
    });

    // Desktop submenu click event
    __.addEvent({
      id: $nav,
      className: c.dom.navSubmenuBtn,
      event: 'click',
      fn: handleNavMenu,
    });

    // Desktop submenu hover event
    __.addEvent({
      id: $nav,
      className: c.dom.navSubmenuBtn,
      event: 'mouseenter',
      fn: handleNavMenu,
    });

    // Desktop submenu focus event
    __.addEvent({
      id: $nav,
      className: c.dom.navSubmenuBtn,
      event: 'focus',
      fn: handleNavMenu,
    });

    // Desktop keyboard open navigation drawer
    __.addEvent({
      id: $header,
      className: c.dom.navMenuBtn,
      event: 'focus',
      fn: handleNavDrawer,
    });

    // Desktop keyboard navigation drawer tab focus
    __.addEvent({
      id: $header,
      className: c.dom.navMenuBtn,
      event: 'keydown',
      fn: handleNavKeydown,
    });

    // Mobile menu open event
    __.addEvent({
      id: $mobileMenuBtn,
      event: 'click',
      fn: handleMobileMenuVisibility,
    });

    // Mobile menu close event
    __.addEvent({
      id: $mobileMenuCloseBtn,
      event: 'click',
      fn: () => handleMobileMenuVisibility(false),
    });
  }

  /**
   * Handle On Scroll
   */
  function handleOnScroll() {
    if (!scrollTicking) {
      requestAnimationFrame(handleHeaderStyling);
      scrollTicking = true;
    }
  }

  /**
   * Get Scroll Position
   * Helper function to get scroll position
   */
  function getScrollPosition() {
    return Math.max(0, window.pageYOffset || document.documentElement.scrollTop);
  }

  /**
   * Handle Header Styling
   * depending on scroll state
   */
  function handleHeaderStyling() {
    const newScrollPosition = getScrollPosition();
    const isScrollDown = previousScrollPosition < newScrollPosition;
    const mobileMenuActive = __.hasClass($mobileMenu, c.cls.active);
    const bodyScrollDown = !mobileMenuActive && newScrollPosition != 0 && newScrollPosition > 89 && isScrollDown ? isScrollDown : false;

    document.body.setAttribute('data-scroll-down', bodyScrollDown);

    /* eslint-disable */
    if (newScrollPosition > 0) {
      if ($promoBar) {
        __.addClass(document.body, c.cls.scrolledPromo);
      } else {
        __.addClass(document.body, c.cls.scrolled);
      }
    } else {
      if ($promoBar) {
        __.removeClass(document.body, c.cls.scrolledPromo);
      } else {
        __.removeClass(document.body, c.cls.scrolled);
      }
    }
    /* eslint-enable */

    previousScrollPosition = newScrollPosition;
    scrollTicking = false;
  }

  /**
   * Handle Mobile Menu Visibility
   * @param {Boolean} shouldOpen
   */
  function handleMobileMenuVisibility(shouldOpen = true) {
    if (shouldOpen) {
      __.removeClass($mobileMenuCloseBtn, c.cls.hide);
      __.addClass($mobileMenuBtn, c.cls.hide);
      __.removeClass($mobileMenu, c.cls.hide);
      // timeout to wait for mobile menu to unhide
      setTimeout(() => __.addClass($mobileMenu, c.cls.active), 10);
    } else {
      __.addClass($mobileMenuCloseBtn, c.cls.hide);
      __.removeClass($mobileMenuBtn, c.cls.hide);
      __.removeClass($mobileMenu, c.cls.active);
      // timeout to wait for mobile menu close animation
      setTimeout(() => __.addClass($mobileMenu, c.cls.hide), 500);
    }
  }

  /**
   * Handle Menu Drawer
   * @param {Object} e - mouseenter or mouseleave event
   * @param {Boolean} shouldOpen
   */
  function handleNavDrawer(e, shouldOpen = true) {
    const $navMenuBtn = e?.target;
    const navMenu = $navMenuBtn?.dataset?.navMenu;
    const $activeMenuBtn = $header.querySelector(`.${c.dom.navMenuBtn}.${c.cls.active}`);
    const $activeNavMenu = $nav.querySelector(`.${c.dom.navMenu}:not(.hide)`);
    const $newNavMenu = navMenu
      ? $nav.querySelector(`.${c.dom.navMenu}[data-nav-menu="${navMenu}"]`)
      : null;

    // hide currently active nav menu
    if ($activeNavMenu) {
      __.addClass($activeNavMenu, c.cls.hide);
    }

    // disable currently active nav menu button
    if ($activeMenuBtn) {
      __.removeClass($activeMenuBtn, c.cls.active);
    }

    // enable active state for new nav menu button
    if ($navMenuBtn && navMenu) {
      __.addClass($navMenuBtn, c.cls.active);
    }

    // open nav drawer with new nav menu
    if ($newNavMenu && shouldOpen) {
      __.removeClass($newNavMenu, c.cls.hide);
      __.addClass($nav, c.cls.active);
      __.addClass($navOverlay, c.cls.active);
      // hide search
      __.removeClass($search, c.cls.active);
      __.removeClass($searchOverlay, c.cls.active);
      $search.setAttribute('aria-hidden', 'true');
    } else { // close nav drawer
      __.removeClass($nav, c.cls.active);
      __.removeClass($navOverlay, c.cls.active);
      resetNavSubmenu();
    }
  }

  /**
   * Reset Nav Submenu
   */
  function resetNavSubmenu() {
    handleNavMenu();
    __.removeClass($nav, c.cls.navTall);
  }

  /**
   * Handle Menu Menu
   * @param {Object} e - click event
   */
  function handleNavMenu(e) {
    const $navSubmenuBtn = e?.target;
    const navSubmenu = $navSubmenuBtn?.dataset?.navSubmenu;
    const $activeSubmenuBtn = $nav.querySelector(`.${c.dom.navSubmenuBtn}.${c.cls.active}`);
    const $activeSubmenu = $nav.querySelector(`.${c.dom.navSubmenu}:not(.${c.cls.hide})`);
    const $newSubmenuBtn = $navSubmenuBtn || $nav.querySelector(`.${c.dom.navSubmenuBtn}`);
    const $newSubmenu = navSubmenu
      ? $nav.querySelector(`.${c.dom.navSubmenu}[data-nav-submenu="${navSubmenu}"]`)
      : $nav.querySelector(`.${c.dom.navSubmenu}`);
    const sameSubmenu = $activeSubmenu && $activeSubmenu === $newSubmenu;
    if (sameSubmenu) return;

    const $submenuTiles = $newSubmenu.querySelectorAll(`.${c.dom.navTile}`);
    const submenuTileCount = [...$submenuTiles].length;

    // apply "--tall" class for large submenus
    if (submenuTileCount > 5) {
      __.addClass($nav, c.cls.navTall);
    } else if (__.hasClass($nav, c.cls.navTall)) {
      __.removeClass($nav, c.cls.navTall);
    }

    // hide currently active submenu
    if ($activeSubmenu) {
      __.addClass($activeSubmenu, c.cls.hide);
    }

    // disable currently active submenu button
    if ($activeSubmenuBtn) {
      __.removeClass($activeSubmenuBtn, c.cls.active);
    }

    // open new nav submenu
    if ($newSubmenu) {
      __.removeClass($newSubmenu, c.cls.hide);
    }

    // enable active state for new submenu button
    if ($newSubmenuBtn) {
      __.addClass($newSubmenuBtn, c.cls.active);
    }
  }

  /**
   * Reset Nav Menus
   */
  function resetNavMenus() {
    handleNavDrawer(null, false);
    handleMobileMenuVisibility(false);
  }

  /**
   * Handle Accordion Toggle
   */
  function handleAccordionToggle() {
    if (!$mobileMenuPromos) return;

    setTimeout(() => {
      const $activePromo = $mobileMenuPromos.querySelector(`.${c.dom.navTile}:not(.${c.cls.hide})`);
      const $mainPromoEl = $mobileMenuPromos.querySelector(`.${c.dom.navPromoMain}`);
      const $mainPromo = $mainPromoEl ? __.parents($mainPromoEl, `.${c.dom.navTile}`)[0] : null;
      const $activeMenu = $mobileMenu.querySelector(`.${c.dom.mobileMenuAccMenu}.${c.cls.open}`);
      if (!$activeMenu) {
        __.addClass($activePromo, c.cls.hide);
        __.removeClass($mainPromo, c.cls.hide);
        return;
      }

      const $activeSubmenu = $activeMenu.querySelector(`.${c.dom.mobileMenuSubmenu}.${c.cls.open}`);
      const $newPromoEl = $activeSubmenu
        ? $activeSubmenu.querySelector(`.${c.dom.navPromo}`)
        : $activeMenu.querySelector(`.${c.dom.navPromo}`);

      // remainder of code handles new promo display
      if (!$newPromoEl) return;

      const promoInSubmenu = __.parents($newPromoEl, `.${c.dom.mobileMenuSubmenu}`)?.length > 0;
      if (!$activeSubmenu && promoInSubmenu) {
        __.addClass($activePromo, c.cls.hide);
        __.removeClass($mainPromo, c.cls.hide);
        return;
      }

      const { promoHandle } = $newPromoEl.dataset;
      const $newPromo = $mobileMenuPromos
        .querySelector(`.${c.dom.navPromo}[data-promo-handle="${promoHandle}"]`);
      if (!$newPromo) return;
      const $newPromoWrapper = __.parents($newPromo, `.${c.dom.navTile}`)[0];
      if (!$newPromoWrapper || $newPromoWrapper === $activePromo) return;
      __.addClass($activePromo, c.cls.hide);
      __.removeClass($newPromoWrapper, c.cls.hide);
    }, 25); // short timeout to wait for accordion classes
  }

  /**
   * Init Accordions
   */
  function initAccordions() {
    const accordions = SDG.accordion({
      dom: {
        id: c.dom.mobileMenuAcc,
      },
      cb: {
        open: handleAccordionToggle,
        close: handleAccordionToggle,
      },
    });

    setTimeout(() => accordions.init(), 100);
  }

  /**
   * Handle Nav Keydown
   * @param {Object} e Keydown event
   */
  function handleNavKeydown(e) {
    const { keyCode, shiftKey, target } = e;
    const { navMenu } = target.dataset;
    const isTabKey = !shiftKey && keyCode === 9;
    if (!isTabKey || !navMenu) return;

    const $navMenu = $nav.querySelector(`.${c.dom.navMenu}[data-nav-menu="${navMenu}"]`);
    const $clickableEls = $navMenu.querySelectorAll('a, button');
    if (!$clickableEls.length) return;

    const $submenuBtns = [...$clickableEls].filter((el) => (
      __.hasClass(el, c.dom.navSubmenuBtn) || __.hasClass(el, c.dom.navSubmenuLink)
    ));
    const $submenuTiles = [...$clickableEls].filter((el) => __.hasClass(el, c.dom.navTileLink));

    if ($submenuBtns?.length) {
      [...$submenuBtns].forEach(($submenuBtn, index) => {
        const { navSubmenu } = $submenuBtn.dataset;
        const isFirst = index === 0;
        const isLast = index === $submenuBtns.length - 1;

        if (isFirst) {
          e.preventDefault();
          $submenuBtn.focus();
        }

        __.addEvent({
          id: $submenuBtn,
          event: 'keydown',
          fn: (event) => (
            __.debounce(
              handleNavLinkKeydown(event, navSubmenu, navMenu, $navMenu, isFirst, isLast),
              100,
            )
          ),
        });
      });
    }

    if ($submenuTiles?.length) {
      [...$submenuTiles].forEach(($submenuTile, index) => {
        const isFirst = index === 0;
        const isLast = index === $submenuTiles.length - 1;

        if (isFirst && !$submenuBtns?.length) {
          e.preventDefault();
          $submenuTile.focus();
        }

        __.addEvent({
          id: $submenuTile,
          event: 'keydown',
          fn: (event) => (
            __.debounce(
              handleNavLinkKeydown(event, null, navMenu, $navMenu, isFirst, isLast),
              100,
            )
          ),
        });
      });
    }
  }

  /**
   * Handle Nav Link Keydown
   * @param {Object} e Keydown event
   * @param {String} navSubmenu
   * @param {String} navMenu
   * @param {Element} $navMenu
   * @param {Boolean} isFirstParam
   * @param {Boolean} isLastParam
   */
  function handleNavLinkKeydown(e, navSubmenuParam, navMenu, $navMenu, isFirstParam, isLastParam) {
    const { keyCode, shiftKey, target } = e;
    if (keyCode !== 9) return; // keyCode for tab key

    let navSubmenu = navSubmenuParam;
    let isFirst = isFirstParam;
    let isLast = isLastParam;

    if (!navSubmenu) {
      const navSubmenuParents = __.parents(target, `.${c.dom.navSubmenu}`);
      const $navSubmenu = navSubmenuParents?.length ? navSubmenuParents[0] : null;
      navSubmenu = $navSubmenu?.dataset?.navSubmenu;
    }

    const $allNavSubmenus = $navMenu.querySelectorAll(`.${c.dom.navSubmenu}`);
    const $navSubmenu = $navMenu.querySelector(`.${c.dom.navSubmenu}:not(.${c.cls.hide})`);
    const $navMenuBtns = $header.querySelectorAll(`.${c.dom.navMenuBtn}`);
    const $navSubmenuBtns = $navMenu?.querySelectorAll(`.${c.dom.navSubmenuBtn}`);
    const $navSubmenuTiles = $navSubmenu?.querySelectorAll(`.${c.dom.navTile}`);

    if ($navSubmenuTiles?.length) {
      const $firstTile = $navSubmenuTiles[0];
      const $firstTileLink = $firstTile?.querySelector('a');
      const $lastTile = $navSubmenuTiles[$navSubmenuTiles.length - 1];
      const $lastTileLink = $lastTile?.querySelector('a');
      if ($firstTileLink === target) isFirst = true;
      if ($lastTileLink === target) isLast = true;
    }

    const currentNavItemIndex = [...$navMenuBtns].findIndex(($el) => (
      $el.dataset.navMenu === navMenu
    ));
    const currentNavSubItemIndex = $navSubmenuTiles?.length
      ? [...$navSubmenuBtns].findIndex(($el) => (
        $el.dataset.navSubmenu === navSubmenu
      )) : -1;
    const navItemIndex = isFirst && shiftKey ? currentNavItemIndex : (currentNavItemIndex + 1);
    const navSubItemIndex = isFirst && shiftKey
      ? currentNavSubItemIndex
      : (currentNavSubItemIndex + 1);

    const escapeNavMenu = (isFirst && shiftKey) || (isLast && !shiftKey);
    const isNavSubmenu = __.hasClass(target, c.dom.navSubmenuBtn);
    const isLastSubmenu = $allNavSubmenus?.length
      ? $navSubmenu === [...$allNavSubmenus][$allNavSubmenus.length - 1]
      : false;

    // determine next focus element
    let $focusNavItem;
    if (escapeNavMenu) {
      if ($navSubmenuBtns?.length) {
        if (isFirst) {
          if (isNavSubmenu) {
            $focusNavItem = $navMenuBtns[navItemIndex];
          } else {
            $focusNavItem = $navSubmenuBtns[navSubItemIndex];
          }
        } else if (isLast) {
          if (!isNavSubmenu && isLastSubmenu) {
            $focusNavItem = $navMenuBtns[navItemIndex];
          } else {
            $focusNavItem = $navSubmenuBtns[navSubItemIndex];
          }
        }
      } else if ($navMenuBtns?.length) {
        $focusNavItem = $navMenuBtns[navItemIndex];
      }
    } else if ($navSubmenuTiles?.length && isNavSubmenu && !shiftKey) {
      $focusNavItem = $navSubmenuTiles[0].querySelector('a, button');
    }

    // only force focus when needed
    if ($focusNavItem && (escapeNavMenu || isNavSubmenu)) {
      e.preventDefault();
      $focusNavItem.focus();
    }
  }

  return init();
}

export default navigation;
