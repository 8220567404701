import Flickity from 'flickity';

/**
 * Storytelling
 * @param {object} opts
 */
function storytelling(opts) {
  const config = {
    dom: {
      wrapper: 'js-storytelling',
      button: 'js-storytelling-button',
      carousel: 'js-storytelling-carousel',
      item: 'js-storytelling-item',
      quickView: 'js-quickview-button',
    },
    cls: {
      scrolled: 'is-scrolled',
    },
  };
  const c = __.extend(config, opts);

  const flickityOpts = {
    contain: true,
    draggable: true,
    pageDots: true,
    prevNextButtons: false,
    wrapAround: true,
  };

  // global elements
  const $wrappers = document.body.querySelectorAll(`.${c.dom.wrapper}`);
  const flickityCarousels = [];
  let view;

  /**
   * Init
   */
  function init() {
    if (!$wrappers?.length) return;
    onWindowResize();
    __.windowResize(onWindowResize);
  }

  /**
   * On Window Resize
   */
  function onWindowResize() {
    // desktop
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          rebuildFlickity(flickityOpts);
          addScrollEvents();
          addQuickviewEvents();
        }
      },
    });

    // mobile
    __.mq({
      view: 'tablet_only',
      callback: () => {
        if (view !== 'tablet_only') {
          view = 'tablet_only';
          const extendedFlickityOpts = __.extend(
            flickityOpts,
            {
              wrapAround: false,
              contain: true,
              pageDots: false,
              initialIndex: 1,
            },
          );
          rebuildFlickity(extendedFlickityOpts);
          addQuickviewEvents();
        }
      },
    });

    // mobile
    __.mq({
      view: 'phone',
      callback: () => {
        if (view !== 'phone') {
          view = 'phone';
          rebuildFlickity(flickityOpts);
          addQuickviewEvents();
        }
      },
    });
  }

  /**
   * Rebuild Flickity
   */
  function rebuildFlickity(flickityOptions) {
    [...$wrappers].forEach(($wrapper, index) => {
      const activeCarousel = flickityCarousels[index];
      if (activeCarousel) activeCarousel.destroy();
      const $carousel = $wrapper.querySelector(`.${c.dom.carousel}`);
      if (!$carousel || (view !== 'phone' && view !== 'tablet_only')) return;

      setTimeout(() => {
        const flickityCarousel = new Flickity($carousel, flickityOptions);

        if (activeCarousel) {
          flickityCarousels[index] = flickityCarousel;
        } else {
          flickityCarousels.push(flickityCarousel);
        }
      }, 500); // short timeout for Flickity cell sizing
    });
  }

  /**
   * Add Quickview Events
   */
  function addQuickviewEvents() {
    [...$wrappers].forEach(($wrapper) => {
      const quickview = SDG.quickview({
        type: 'single',
        wrapper: $wrapper,
      });
      quickview.init();

      __.addEvent({
        id: $wrapper,
        event: 'click',
        className: c.dom.quickView,
        fn: quickview.open,
      });
    });
  }

  /**
   * Add Scroll Events
   */
  function addScrollEvents() {
    __.addEvent({
      id: document,
      event: 'scroll',
      fn: () => {
        __.debounce(handleScrollEvent(), 100);
      },
    });
  }

  /**
   * Handle Scroll Event
   */
  function handleScrollEvent() {
    [...$wrappers].forEach(($wrapper) => {
      const $items = $wrapper.querySelectorAll(`.${c.dom.item}`);

      [...$items].forEach(($item) => {
        const $button = $item.querySelector(`.${c.dom.button}`);
        const { top } = $item.getBoundingClientRect();
        const offset = window.innerHeight / 2;
        const inView = top - window.innerHeight + offset <= 0;
        const inViewWithLessOffset = top - window.innerHeight + (offset / 2) <= 0;

        if (inViewWithLessOffset && !__.hasClass($item, c.cls.scrolled)) {
          __.addClass($item, c.cls.scrolled);
        } else if (inView && !__.hasClass($button, c.cls.scrolled)) {
          __.addClass($button, c.cls.scrolled);
        }
      });
    });
  }

  return init();
}

export default storytelling;
