/*
* Brand Hero
* @param {object} opts
*/

function brandHero(opts) {
  const config = {
    dom: {
      section: 'js-brand-hero',
      waterfallMobileVideo: 'js-brand-hero-waterfall-video-mobile',
      waterfallDesktopVideo: 'js-brand-hero-waterfall-video-desktop',
      waterfallVideoWrapper: 'js-brand-hero-waterfall-video-wrapper',
      mainContent: 'js-brand-hero-main-content',
      mainHeader: 'js-brand-hero-main-header',
      mainVideoWrapper: 'js-brand-hero-main-video-wrapper',
      mainVideo: 'js-brand-hero-main-video',
      ctaButton: 'js-brand-hero-cta-button',
    },
    cls: {
      textRevealAnimation: 'text-reveal-animation',
      textSizeAnimation: 'text-size-animation',
      textPositionAnimation: 'text-position-animation',
      revealAnimation: 'reveal-animation',
    },
  };

  const c = __.extend(config, opts);
  const $sections = document.querySelectorAll(`.${c.dom.section}`);
  const $waterfallVideoWrapper = document.querySelector(`.${c.dom.waterfallVideoWrapper}`);
  const $waterfallMobileVideo = document.querySelector(`.${c.dom.waterfallMobileVideo}`);
  const $waterfallDesktopVideo = document.querySelector(`.${c.dom.waterfallDesktopVideo}`);
  const $mainHeader = document.querySelector(`.${c.dom.mainHeader}`);
  const $ctaButtons = document.querySelectorAll(`.${c.dom.ctaButton}`);
  const $mainVideoWrapper = document.querySelector(`.${c.dom.mainVideoWrapper}`);
  const $mainVideo = document.querySelector(`.${c.dom.mainVideo}`);
  let view = null;

  /**
   * Init
   */
  function init() {
    if (!$sections?.length) return;
    onWindowResize();
    __.windowResize(onWindowResize);
  }

  /**
  * Responsive functions
  */
  function onWindowResize() {
    __.mq({
      view: 'tablet',
      callback: () => {
        if (view !== 'tablet') {
          view = 'tablet';
          if ($waterfallDesktopVideo) {
            enableFullDesktopAnimation();
          } else {
            disableAnimation();
            setTimeout(() => {
              enableAnimation();
            }, 100);
          }
        }
      },
    });

    __.mq({
      view: 'phone',
      callback: () => {
        if (view !== 'phone') {
          view = 'phone';

          if ($waterfallMobileVideo) {
            enableFullMobileAnimation();
          } else {
            disableAnimation();
            setTimeout(() => {
              enableAnimation();
            }, 100);
          }
        }
      },
    });
  }

  function enableFullMobileAnimation() {
    disableAnimation();
    $waterfallMobileVideo.play();

    __.addEvent({
      id: $waterfallMobileVideo,
      event: 'ended',
      fn: enableAnimation,
    });
  }

  function enableFullDesktopAnimation() {
    disableAnimation();
    $waterfallDesktopVideo.play();

    __.addEvent({
      id: $waterfallDesktopVideo,
      event: 'ended',
      fn: enableAnimation,
    });
  }

  function enableAnimation() {
    if (!$mainHeader) return;

    __.hide($waterfallVideoWrapper);

    __.addClass($mainHeader, c.cls.textRevealAnimation);
    __.addClass($mainHeader, c.cls.textSizeAnimation);
    __.addClass($mainHeader.parentElement, c.cls.textPositionAnimation);
    $ctaButtons.forEach(($ctaButton) => {
      __.addClass($ctaButton, c.cls.revealAnimation);
    });

    if ($mainVideo) {
      __.addClass($mainVideoWrapper, c.cls.revealAnimation);
      __.addClass($mainVideo, c.cls.revealAnimation);

      setTimeout(() => {
        $mainVideo.play();
      }, 4000);
    }
  }

  function disableAnimation() {
    if (!$mainHeader) return;

    if ($mainVideo) {
      $mainVideo.load();
      $mainVideo.pause();
    }

    if ($waterfallVideoWrapper) {
      __.show($waterfallVideoWrapper);
    }

    __.removeClass($mainHeader, c.cls.textRevealAnimation);
    __.removeClass($mainHeader, c.cls.textSizeAnimation);
    __.removeClass($mainHeader.parentElement, c.cls.textPositionAnimation);
    $ctaButtons.forEach(($ctaButton) => {
      __.removeClass($ctaButton, c.cls.revealAnimation);
    });

    if ($mainVideo) {
      __.removeClass($mainVideoWrapper, c.cls.revealAnimation);
      __.removeClass($mainVideo, c.cls.revealAnimation);
    }
  }

  init();
}

export default brandHero;
