<template>
  <div style="position:relative;" class="bag__cart-delivery-estimate">
    <VueAccordion
      v-if="cartItems?.length > 1 && zipcode"
      :ariaTitle="'Cart Delivery Estimate'"
      :htmlTitle="true"
      :htmlWrapperClass="'bag__cart-delivery-wrapper'"
      :buttonClass="'bag__cart-delivery bag__cart-delivery--button'"
      :buttonPlacement="'top'"
      :defaltOpen="true"
    >
      <template #html-title>
        <div class="bag__cart-delivery-image"></div>
        <p
          v-html="currentRecieveByDate"
          class="bag__cart-delivery-text"
        >
        </p>
      </template>
      <template #opened-icon>
        <i
         class="cart-upsell__header-icon cart-upsell__header-icon--gift
         icon icon--chevron-up"
        >
        </i>
        <span class="screenreader">Minimize Options</span>
      </template>
      <template #closed-icon>
        <i
         class="cart-upsell__header-icon cart-upsell__header-icon--gift
         icon icon--chevron-down"
        >
        </i>
        <span class="screenreader">Expand Options</span>
      </template>
      <p
        class="bag__cart-delivery-date bag__cart-delivery-date--multi "
      >
        Shipping to
        <button
          ref="popupTrigger"
          class="bag__cart-delivery-date--underline bag__cart-delivery-date--underline-button"
          @click="showPopUp = !showPopUp"
        >
          {{ zipcode }}
        </button>
      </p>
      <ul class="list-reset bag__cart-delivery-list">
        <li
          v-for="(item, index) in visibleCartItems"
          :key="item.id"
          class="bag__cart-delivery-list-item"
        >
          <CartDeliveryDataItem
            :item="item"
            :position="index"
            :currentDate="currentRecieveByDate"
          />
        </li>
      </ul>
    </VueAccordion>
    <section
      v-else-if="cartItems?.length === 1 && zipcode"
      class="bag__cart-delivery">
      <div class="bag__cart-delivery-wrapper">
        <div class="bag__cart-delivery-image"></div>
        <div>
          <p
            v-html="currentRecieveByDate"
            class="bag__cart-delivery-text"
          >
          </p>
          <p
            class="bag__cart-delivery-date"
          >
            Shipping to
            <button
              ref="popupTrigger"
              class="bag__cart-delivery-date--underline bag__cart-delivery-date--underline-button"
              @click="showPopUp = !showPopUp"
            >
              {{ zipcode }}
            </button>
          </p>
        </div>
      </div>
    </section>
    <section
      v-if="showPopUp"
      ref="popup"
      class="delivery-estimate__update-zip"
      :aria-hidden="!showPopUp"
    >
      <label id="zipcodeLabel" for="zipcode" class="delivery-estimate__label">
        Shipping Zip code
      </label>
      <div class="delivery-estimate__input">
        <input
          ref="newZipInput"
          type="text"
          name='zipcode'
          placeholder="Enter Zipcode"
          aria-labelledby="zipcodeLabel"
          pattern="\d{5,5}(-\d{4,4})?"
          @input="clearError"
        />
        <button
          id="submitZipcode"
          class="delivery-estimate__button btn"
          type="button"
          :class="{'is-loading': isLoading}"
          :disabled="isLoading || isError"
          @click="updateNewZipcode"
        >
          Submit
        </button>
      </div>
      <div class="delivery-estimate__facts">
        <p
          v-if="isError"
          class="delivery-estimate__copy delivery-estimate__copy--error"
        >
          {{ errorMessage }}
        </p>
        <div class="delivery-estimate__row">
          <div class="bag__cart-delivery-location"></div>
          <p
            v-if="termsText"
            class="delivery-estimate__copy"
          >
            {{ termsText }}
          </p>
        </div>
        <div class="delivery-estimate__row">
          <div class="bag__cart-delivery-store"></div>
          <p
            v-if="infoText"
            class="delivery-estimate__copy"
          >
            {{ infoText }}
          </p>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  onUpdated,
} from 'vue';
import useCart from '../composables/cart-actions';
import useDelivery from '../composables/delivery-estimator';
import CartDeliveryDataItem from './CartDeliveryDataItem';
import VueAccordion from '../common/accordion/Accordion';

export default {

  name: 'CartDeliveryEstimate',
  components: {
    CartDeliveryDataItem,
    VueAccordion,
  },
  setup() {
    const termsText = SDG.Data.cartDeliveryTerms || '';
    const infoText = SDG.Data.cartDeliveryInfo || '';
    const {
      cartItems,
    } = useCart();
    const {
      zipcode,
      currentRecieveBy,
      updateZipcode,
      fetchDeliveryEstimate,
    } = useDelivery();
    const popup = ref(null);
    const popupTrigger = ref(null);
    const showPopUp = ref(false);
    const newZipInput = ref(false);
    const isLoading = ref(false);
    const isError = ref(false);
    const errorMessage = ref('');
    const conveyance = ref(null);

    const handleDeliveryEstimateFetch = () => {
      const anyPhysical = cartItems.value.some((item) => {
        const isGiftCard = item.product_type.toLowerCase().includes('gift card');

        return item.options_with_values.some((options) => (
          !isGiftCard && options.value.toLowerCase().includes('physical')
        ));
      });

      conveyance.value = anyPhysical ? 'mailin' : 'upload';
      fetchDeliveryEstimate(conveyance.value);
    };

    onMounted(() => {
      handleDeliveryEstimateFetch();

      __.addEvent({
        id: document,
        event: 'click',
        fn: (e) => clickOutside(e),
      });
    });

    onUpdated(() => {
      handleDeliveryEstimateFetch();
    });

    onUnmounted(() => {
      // cleanup
      __.removeEvent({
        id: document,
        event: 'click',
        fn: (e) => clickOutside(e),
      });
    });

    function clickOutside(event) {
      if (!showPopUp.value || !popupTrigger.value) {
        return;
      }

      if (!popupTrigger.value.contains(event.target)) {
        if (popup.value && !popup.value.contains(event.target)) {
          showPopUp.value = false;
        }
      }
    }

    function updateNewZipcode() {
      const zip = newZipInput?.value?.value || false;

      if (!zip || zip === '') {
        return;
      }

      if (!newZipInput?.value?.checkValidity()) {
        isError.value = true;
        errorMessage.value = 'Please enter a valid zipcode';

        return;
      }

      isLoading.value = true;

      if (zip) {
        fetchDeliveryEstimate(conveyance.value, zip)
          .then(() => {
            showPopUp.value = false;
            isLoading.value = false;
          })
          .catch((error) => {
            console.error({ message: 'Error updating zipcode', error });
            errorMessage.value = 'Error updating zipcode, please try again';
          });
      }
    }

    const currentRecieveByDate = computed(() => {
      if (!currentRecieveBy.value) return '';
      const currentDate = currentRecieveBy.value;

      if (currentDate) {
        const html = `Place your order today & get it by: ${currentDate}`;

        return html;
      }

      return '';
    });

    const visibleCartItems = computed(() => {
      const items = cartItems.value.filter((item) => {
        const { properties, product_type: productType } = item || {};
        if (productType === 'Addition') {
          return null;
        }

        // eslint-disable-next-line no-underscore-dangle
        if (properties && properties._upsell_item) {
          return null;
        }

        return item;
      });

      return items;
    });

    function setPosition(val) {
      const position = parseInt(val, 10) + 1;

      return position;
    }

    function clearError() {
      isError.value = false;
      errorMessage.value = '';
    }

    return {
      cartItems,
      currentRecieveBy,
      currentRecieveByDate,
      errorMessage,
      infoText,
      isError,
      isLoading,
      popup,
      showPopUp,
      termsText,
      popupTrigger,
      newZipInput,
      visibleCartItems,
      zipcode,
      clearError,
      fetchDeliveryEstimate,
      setPosition,
      updateNewZipcode,
      updateZipcode,
    };
  },
};
</script>
