import { createApp } from 'vue';
import ProductConfigurator from '../components/product-configurator/ProductConfigurator';

function productConfigurator(opts) {
  const config = {
    dom: {
      product: '.js-product-configurator',
    },
  };
  const c = __.extend(config, opts);
  const $products = document.querySelectorAll(c.dom.product);

  if (!$products && !$products?.length > 0) return;

  const initVueApp = () => {
    [...$products].forEach(($product) => {
      const productDataString = document.getElementById(`${$product.id}-data`).textContent;

      if (productDataString && typeof productDataString === 'string') {
        try {
          const parsedData = JSON.parse(productDataString);

          if (parsedData) {
            const app = createApp(ProductConfigurator, {
              data: parsedData,
            });

            // set Joinery host
            app.config.globalProperties.$config = {
              joineryHost: SDG.Data.joineryHost || 'https://staging.framebridge.com',
            };

            app.mount($product);
          }
        } catch (error) {
          console.error({ message: 'Error launching Product Configurator', error });
        }
      }
    });
  };

  const init = () => {
    initVueApp();
  };

  init();
}

export default productConfigurator;
