import Flickity from 'flickity';

/*
* Material Touts
* @param {object} opts
*/

function materialTouts(opts) {
  const config = {
    dom: {
      section: 'js-material-touts',
      toutListCarousel: 'js-material-tout-list',
      toutListCarouselCell: 'js-material-tout',
    },
    flickityOpts: {
      prevNextButtons: false,
      pageDots: false,
      draggable: true,
      wrapAround: true,
      imagesLoaded: true,
      lazyLoad: true,
    },
    cls: {
      carouselMode: 'carousel-mode',
    },
  };

  const c = __.extend(config, opts);
  const $sections = document.querySelectorAll(`.${c.dom.section}`);
  const $carousels = document.querySelectorAll(`.${c.dom.toutListCarousel}`);

  /**
   * Init
   */
  function init() {
    if (!$sections?.length) return;
    toggleCarousel();

    __.windowResize(toggleCarousel);
  }

  /**
   * Toggle Carousel
   */
  function toggleCarousel() {
    if (!$carousels?.length) return;
    const windowWidth = window.innerWidth;

    $carousels.forEach(($carousel) => {
      const carouselWidth = calculateCarouselWidth($carousel);
      let flickity = Flickity.data($carousel);

      if (windowWidth <= carouselWidth) {
        __.addClass($carousel, c.cls.carouselMode);
        if (flickity) flickity.destroy();
        flickity = new Flickity($carousel, c.flickityOpts);
      } else {
        __.removeClass($carousel, c.cls.carouselMode);
        if (flickity) flickity.destroy();
      }
    });
  }

  /**
   * Calculate Carousel Width
   */
  function calculateCarouselWidth($carousel) {
    const $cells = $carousel.querySelectorAll(`.${c.dom.toutListCarouselCell}`);
    // account for gap between cells
    let cellsWidth = ($cells.length - 1) * 40;

    $cells.forEach((cell) => {
      cellsWidth += cell.offsetWidth;
    });

    return cellsWidth;
  }

  init();
}

export default materialTouts;
