import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

/**
 * Vertical Text + Image
 * @param {object} opts
 */

function verticalTextImage(opts) {
  const config = {
    dom: {
      contentSection: 'js-vertical-content',
      copy: 'js-vertical-content-copy',
      imagesSection: 'js-vertical-images',
      image: 'js-vertical-image',
      nextContent: 'next-content',
      resizeImage: 'js-resize-image',
      section: 'js-vertical-text-image-section',
      tout: 'js-vertical-image-tout',
    },
  };

  // Globals
  const c = __.extend(config, opts);
  const $sections = document.querySelectorAll(`.${c.dom.section}`);
  const $images = document.querySelectorAll(`.${c.dom.image}`);

  /**
   * Init
   */
  function init() {
    if (!$sections || $sections.length === 0) return;

    runDesktopScrollTrigger();
  }

  /**
   * Run ScrollTrigger Greensock Animations for desktop
   */
  function runDesktopScrollTrigger() {
    [...$sections].forEach(($section) => {
      const mm = gsap.matchMedia();
      gsap.registerPlugin(ScrollTrigger);

      mm.add('(min-width: 1025px)', () => {
        const imageEnd = $images.length * 100;
        const $imageSection = $section.querySelector(`.${c.dom.imagesSection}`);
        const $contentSection = $section.querySelector(`.${c.dom.contentSection}`);
        const tl = gsap.timeline(
          {
            scrollTrigger: {
              trigger: $section.parentNode,
              start: 'top -30px',
              /**
               * Makes the height of the scrolling (while pinning) match the width,
               * thus the speed remains constant (vertical/horizontal)
               */
              end: () => `+=${imageEnd}%`,
              scrub: true,
              pin: true,
              anticipatePin: 1,
            },
            defaults: {
              ease: 'none',
            },
          },
        );

        const $contentItems = $contentSection.querySelectorAll(`.${c.dom.copy}`);
        const $resizeImageItems = $imageSection.querySelectorAll(`.${c.dom.resizeImage}`);
        const $touts = $imageSection.querySelectorAll(`.${c.dom.tout}`);

        // Animate the images to slide out to left
        $resizeImageItems.forEach(($resizeImage, index) => {
          tl.fromTo(
            $resizeImage,
            {
              width: '100%',
              duration: 0.5,
            },
            {
              width: '0%',
              delay: 0.35,
            },
            index,
          );
        });

        // Animate the touts to appear
        $touts.forEach(($tout, index) => {
          if (index !== $contentItems.length - 1) {
            tl.fromTo(
              $tout,
              {
                opacity: 0,
                ease: 'power3.in',
              },
              {
                opacity: 1,
              },
              index - 0.25,
            )
              .to(
                $tout,
                {
                  opacity: 0,
                },
                '>',
              );
          }

          // Ensure last tout fades in and stays visible
          if (index === $touts.length - 1) {
            tl.fromTo(
              $tout,
              {
                opacity: 0,
                ease: 'power3.in',
              },
              {
                opacity: 1,
              },
              index,
            );
          }
        });

        // Animate the content to slide up/out and fade in/out
        $contentItems.forEach(($content, index) => {
          // Ensure the first text block is visible at the start and slides up
          if (index === 0) {
            tl.to(
              $content,
              {
                opacity: 0,
                zIndex: 0,
                yPercent: -20,
                ease: 'power3.out',
                duration: 0.35,
                delay: 0.35,
              },
              index,
            );
          }

          // Ensure all but first text blocks slide up/out and fade in/out
          if (index > 0) {
            tl.fromTo(
              $content,
              {
                opacity: 0,
                zIndex: 0,
                yPercent: 20,
                ease: 'power3.in',
              },
              {
                opacity: 1,
                zIndex: 110,
                yPercent: 0,
                duration: 0.5,
              },
              index - 0.5,
            );

            // Ensure last text blocks slides up/fades in and stays visible
            if (index !== $contentItems.length - 1) {
              tl.to(
                $content,
                {
                  opacity: 0,
                  zIndex: 0,
                  yPercent: -20,
                  ease: 'power3.out',
                  duration: 0.5,
                  delay: 1,
                },
                index - 0.75,
              );
            }
          }
        });
      });
    });
  }

  return init();
}

export default verticalTextImage;
