<template>
  <section
    class="product-configurator__additions"
    :class="{ 'product-configurator__additions--upload': frameSpec }"
  >
    <div class="product-configurator__additions-heading">
      <h3 class="product-configurator__additions-title">
        {{ title }}
      </h3>
      <div class="product-configurator__additions-subtitle" v-html="description" />
    </div>
    <div class="product-configurator__additions-inner">
      <div v-for="(tout, index) in touts" :key="`tout-${index}`">
        <button
          v-if="tout"
          class="product-configurator__additions-tout"
          type="button"
          @click="handleAdditonClick(tout)"
        >
          <div
            v-if="tout?.product?.featured_image"
            class="product-configurator__additions-tout-image-wrapper"
          >
            <ResponsiveImage
              :image="tout?.product?.featured_image"
              :image-alt="tout?.heading"
              :modifier="'square'"
              image-class="product-configurator__additions-tout-image"
            />
          </div>
          <div class="product-configurator__additions-tout-content">
            <p
              v-if="tout?.heading && tout?.product?.price"
              class="product-configurator__additions-tout-title"
            >
              {{ tout?.heading }}
              <span>+ {{ formatPrice(tout.product.price) }}</span>
            </p>
          </div>
        </button>
      </div>
    </div>
    <div v-if="isModalOpen" class="product-configurator__additions-modal">
      <div class="product-configurator__additions-modal-header">
        <button
          class="product-configurator__additions-modal-close"
          type="button"
          @click="closeModal"
        >
          <span class="screenreader">Close</span>
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6 6L19.37 19.37"
              stroke="#1D2019"
              stroke-width="1.22"
              stroke-linecap="round"
              stroke-linejoin="bevel"
            />
            <path
              d="M19.37 6L6 19.37"
              stroke="#1D2019"
              stroke-width="1.22"
              stroke-linecap="round"
              stroke-linejoin="bevel"
            />
          </svg>
        </button>
      </div>
      <div
        ref="modalInner"
        class="product-configurator__additions-modal-inner"
      >
        <p class="product-configurator__additions-modal-title">
          {{ activeTout?.heading }}
          <span v-if="activeTout?.product?.price">
            + {{ formatPrice(activeTout?.product?.price) }}
          </span>
        </p>
        <p
          v-if="activeTout?.copy"
          class="product-configurator__additions-modal-description"
        >
          {{ activeTout?.copy }}
        </p>
        <ResponsiveImage
          v-if="activeTout?.asset && typeof activeTout.asset === 'string'"
          :image="activeTout.asset"
          :image-alt="activeTout?.heading || 'Product Addition'"
          :inlineIr="true"
          image-class="product-configurator__additions-modal-image"
        />
        <div
          v-else-if="activeTout?.asset && typeof activeTout.asset === 'object'"
          class="product-configurator__additions-tout-video"
        >
          <Video
            :asset="activeTout.asset"
            :autoplay="true"
            :controls="false"
            :coverImage="activeTout.asset.preview_image"
            :modifier="'product-configurator-video'"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import {
  computed,
  ref,
  toRefs,
  toRaw,
  watch,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import ResponsiveImage from '../common/responsive-image/ResponsiveImage';
import useUploadedArtwork from '../composables/product-configurator';
import Video from '../common/video/Video';

const props = defineProps({
  variant: {
    type: Object,
    required: true,
  },
  lowestPricedVariant: {
    type: Object,
    required: true,
  },
  frameSpec: {
    type: Object,
    required: true,
  },
  canprintArtwork: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// Reactive properties
const { variant, lowestPricedVariant, frameSpec } = toRefs(props);
const selected = ref(variant.value || lowestPricedVariant.value);
const activeTout = ref(null);
const isModalOpen = ref(false);
const modalInner = ref(null);

// Computed properties
const title = computed(() => {
  const personalized = selected.value?.personalize_component || {};
  if (personalized?.header_copy) {
    return personalized.header_copy;
  }
  return 'Make it Special';
});

const description = computed(() => {
  const personalized = selected.value?.personalize_component || {};
  if (personalized?.post_upload_copy && frameSpec?.value) {
    return personalized.post_upload_copy;
  }
  if (personalized?.description) {
    return personalized.description;
  }
  return 'Add-ons.';
});

const touts = computed(() => {
  const personalized = selected.value?.personalize_component || {};
  if (personalized?.touts) {
    return personalized.touts;
  }
  return [];
});

// Methods
const { formatPrice } = useUploadedArtwork();
const handleAdditonClick = (tout) => {
  if (!tout) return;

  const toutData = toRaw(tout);
  if (toutData) {
    activeTout.value = toutData;
    openModal();
  }
};

const openModal = () => {
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
};
const handleClickOutside = (event) => {
  if (isModalOpen.value && modalInner.value && !modalInner.value.contains(event.target)) {
    closeModal();
  }
};

// Watch the variant prop for changes and update selected
watch(
  variant,
  (newVariant) => {
    if (newVariant) {
      selected.value = newVariant;
    }
  },
  { immediate: true },
);

// Lifecycle hooks
onMounted(() => {
  document.addEventListener('mousedown', handleClickOutside);
  document.addEventListener('touchstart', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('mousedown', handleClickOutside);
  document.removeEventListener('touchstart', handleClickOutside);
});
</script>
