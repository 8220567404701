<template>
  <div
    v-if="(productItems?.length > 0)"
    class="cart-upsell__inner"
  >
    <button
      v-for="(product) in productItems"
      :key="product.variantId"
      :title="(`Add ${product.title} to your order`)"
      type="button"
      class="cart-upsell__item"
      :class="(activeUpsellItems.includes(`${product.variantId}`) ? 'is-active' : '')"
      :data-variant-id="product.variantId"
      :disabled="!product.available"
      @click.prevent="handleUpsellClick(product)"
    >
      <div class="cart-upsell__item--active">
        <i class="icon icon--yellow-checkmark cart-upsell__item-icon"></i>
        <div class="cart-upsell__item-image">
          <ResponsiveImage
            :image="product.image"
            :image-alt="product.title"
            modifier="square"
          />
        </div>
      </div>
      <div class="cart-upsell__item-details">
        <p>{{ product.title }}</p>
        <p>+ {{ product.moneyPrice}}</p>
      </div>
      <span class="screenreader">{{ `Add ${product.title} to your order` }}</span>
    </button>
  </div>
</template>
<script>
import {
  computed,
  ref,
  toRefs,
  onBeforeMount,
} from 'vue';
import useCart from '../composables/cart-actions';
import ResponsiveImage from '../common/responsive-image/ResponsiveImage';

export default {

  name: 'CartUpsell',
  components: {
    ResponsiveImage,
  },
  props: {
    parentProductId: {
      type: String,
      default: '',
    },
    parentFrameSpecId: {
      type: String,
      default: '',
    },
    parentProductProperties: {
      type: Object,
      default: () => ({}),
    },
    parentProductQty: {
      type: Number,
      default: 1,
    },
    products: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: 'USD',
    },
    isWishlist: {
      type: Boolean,
      default: false,
    },
    updateLoadingState: {
      type: Function,
      default: () => {},
    },
  },

  setup(props) {
    const {
      addUpsellItem,
      removeUpsellItem,
    } = useCart();
    const {
      parentProductQty,
      parentProductProperties,
      parentProductId,
      parentFrameSpecId,
      products,
      currency,
      isWishlist,
    } = toRefs(props);

    onBeforeMount(() => {
      const {
        _upsell_parent: upsellParent,
        _upsell_item_variant_id_list: upsellVariantList,
      } = parentProductProperties.value;

      if (upsellParent && upsellParent === 'true' && upsellVariantList) {
        upsellVariantList.split(',').forEach((item) => {
          activeUpsellItems.value.push(item);
        });
      }
    });

    // eslint-disable-next-line no-underscore-dangle
    const activeUpsellItems = ref([]);
    const moneyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency?.value || 'USD',
    });

    const productItems = computed(() => products.value.map((product) => {
      const {
        available,
        id,
        title,
        price,
        featured_image: image,
        variants,
      } = product || {};

      if (!title || !price || !image || !variants) {
        return {};
      }
      const money = price / 100;
      const moneyPrice = moneyFormatter.format(money);
      const variantId = variants[0].id;

      return {
        available,
        id,
        image,
        moneyPrice,
        price,
        title,
        variantId,
      };
    }));

    /**
     * Handle Upsell Click
     * @param {Object} product
     * @returns {void}
     */
    const handleUpsellClick = (product) => {
      const disableLoading = () => {
        props.updateLoadingState(false);
      };
      const toggleUpSellActive = () => {
        activeUpsellItems.value.push(`${product.variantId}`);
        disableLoading();
      };
      const setInactive = () => {
        const upsellIndex = activeUpsellItems.value.findIndex((i) => i === `${product.variantId}`);
        activeUpsellItems.value.splice(upsellIndex, 1);
        disableLoading();
      };

      props.updateLoadingState(true);

      if (activeUpsellItems.value.includes(`${product.variantId}`)) {
        removeUpsellItem(
          product.variantId,
          parentProductId.value,
          parentProductProperties.value,
          0,
          isWishlist.value,
          setInactive,
          product.price,
        );
      } else {
        addUpsellItem(
          product.variantId,
          product.price,
          parentProductId.value,
          parentFrameSpecId.value,
          parentProductProperties.value,
          parentProductQty.value,
          isWishlist.value,
          toggleUpSellActive,
        );
      }
    };

    return {
      productItems,
      activeUpsellItems,
      handleUpsellClick,
    };
  },
};
</script>
