/**
 * format price display
 * @param  {number} price e.g. "1200"
 * @param  {string} modifier e.g. "money" or "money_without_trailing_zeros"
 * @return {string}       e.g. "$12"
 */
__.formatPriceDisplay = function(price, modifier = 'money_without_trailing_zeros') {
  if (modifier === 'money_without_trailing_zeros') {
    return `$${__.formatPrice(price).replace(/\.00$/, '')}`;
  }

  // if (!modifier || modifier === 'money')
  return `$${__.formatPrice(price)}`;
};
