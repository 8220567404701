/**
 * Tabs
 * @param  {obj}  Config object
 * @requires [lib/util.js]
 */
SDG.tabs = function (opts) {
  const config = {
    cb: {
      show: null, // Callback on Open
      hide: null, // Call back on Close
      on_default_show: null, // Callback on Default Open
    },
    cls: {
      active: 'is-active', // Button active class
    },
    dom: {
      tab: 'js-tab', // toggle trigger
      id: 'tabs', // id of the accordion
      panel: 'js-tab-panel', // class to target the menu
    },
    active_option: null, // {number}
    responsive: false, // Use responsive?
    views: {
      active: null, // {array}
      inactive: null, // {array}
      current: null, // {string}
    },
  };

  const c = __.extend(config, opts); // Extend defaults with passed options

  const $tab = document.getElementById(c.dom.id); // Object that all functions are bound to.

  /**
   * Init
   * @type {Function}
   */
  function init() {
    if (c.responsive) {
      responsive();
      __.windowResize(responsive);
    } else {
      bindEvents();
    }

    // active option
    if (c.active_option !== null) {
      activeOption(c.active_option);
    }

    callbacks();
  }

  /**
   * bindEvents
   * @type {Function}
   * @param {el} Element to bind events to
   */
  function bindEvents() {
    if ($tab) {
      __.addEvent({
        id: c.dom.id,
        className: c.dom.tab,
        event: 'click',
        fn: checkState,
      });

      __.windowResize(() => {
        resetActive(false);
      });
    }
  }

  /**
   * unbindEvents
   * @type {Function}
   * @param {el} Element to bind events to
   */
  function unbindEvents() {
    __.removeEvent({
      id: c.dom.id,
      className: c.dom.tab,
      event: 'click',
      fn: checkState,
    });
  }

  /**
   * checkActive
   * @type {Function}
   */
  function checkState() {
    const isActive = __.hasClass(this, c.cls.active);
    const $parentElements = __.parents(this);
    const $parentContainer = $parentElements.find((el) => el.id === c.dom.id);
    const $activeTab = $parentContainer?.querySelector(`.${c.dom.tab}.is-active`);

    if (!$activeTab) return;

    if (!isActive) {
      showTab(this, $activeTab, $parentContainer);
    }
  }

  /**
   * hideTab
   * @type {Function}
   * @param {el} - Tab to hide
   * @param {parentContainer} - Parent container
   */
  function hideTab(el, parentContainer) {
    const $activeTabs = parentContainer.querySelectorAll(`.${c.dom.tab}.${c.cls.active}`);

    if ($activeTabs.length > 0) {
      $activeTabs.forEach(($activeTab) => {
        const $activePanelId = $activeTab.dataset.panelId;
        const $activePanel = document.getElementById($activePanelId);
        __.removeClass($activeTab, c.cls.active);
        __.removeClass($activePanel, c.cls.active);
        $activeTab.setAttribute('aria-selected', false);
      });
    }
  }

  /**
   * showTab
   * @param {el} - Tab to show
   * @param {activeEl} - Tab to hide
   * @param {parentContainerEl} - Parent container to hide
   */
  function showTab(el, activeEl, parentContainerEl) {
    const $currentTab = el;
    const $activeTab = activeEl;
    const $panelId = $currentTab.dataset.panelId;
    const $currentPanel = document.getElementById($panelId);

    hideTab($activeTab, parentContainerEl);

    __.addClass($currentTab, c.cls.active);
    __.addClass($currentPanel, c.cls.active);
    $currentTab.setAttribute('aria-selected', true);
  }

  /**
   * responsive
   * @type {Function}
   */
  function responsive() {
    const activeViews = c.views.active;
    const inactiveViews = c.views.inactive;
    let view;
    let i;

    for (i = 0; i < activeViews.length; i += 1) {
      view = activeViews[i];

      if (c.views.current !== view) {
        __.mq({
          view,
          callback: activeCb,
        });
      }
    }

    for (i = 0; i < inactiveViews.length; i += 1) {
      view = inactiveViews[i];

      if (c.views.current !== view) {
        __.mq({
          view,
          callback: inactiveCb,
        });
      }
    }
  }

  /**
   * active callback
   * @return {Function}
   */
  function activeCb() {
    const { view } = this;
    c.views.current = view;
    bindEvents();
  }

  /**
   * inactive callback
   * @return {Function}
   */
  function inactiveCb() {
    const { view } = this;
    c.views.current = view;
    unbindEvents();
    resetActive(true);
  }

  /**
   * active callback
   * @return {Function}
   */
  function resetActive(fullReset) {
    const $activeTriggers = $tab.querySelectorAll(`.${c.dom.tab}.${c.cls.active}`);
    let $trigger;
    let $activePanel;
    let i;

    if ($activeTriggers.length > 0) {
      for (i = 0; i < $activeTriggers.length; i += 1) {
        $trigger = $activeTriggers[i];
        $activePanel = $trigger.nextElementSibling;

        if (fullReset) {
          __.removeClass($trigger, c.cls.active);
          __.removeClass($activePanel, c.cls.active);
          $trigger.setAttribute('aria-selected', false);
        }
      }
    }
  }

  /**
   * open option
   * @param {number} option
   */
  function activeOption(option) {
    const $activeTriggers = $tab.querySelectorAll(`.${c.dom.tab}`);
    let $trigger;
    let $activePanel;

    if ($activeTriggers.length) {
      $trigger = $activeTriggers[option];
      $activePanel = $trigger.nextElementSibling;

      showTab($activePanel);
      __.addClass($activePanel, c.cls.active);
      __.addClass($trigger, c.cls.active);
      $trigger.setAttribute('aria-selected', true);

      if (typeof c.cb.on_default_show === 'function') {
        c.cb.on_default_show();
      }
    }
  }

  /**
   * callbacks
   */
  function callbacks() {
    __.addEvent({
      id: $tab,
      event: 'openCb',
      fn() {
        if (typeof c.cb.show === 'function') {
          c.cb.show();
        }
        return undefined;
      },
    });

    __.addEvent({
      id: $tab,
      event: 'closeCb',
      fn() {
        if (typeof c.cb.hide === 'function') {
          c.cb.hide();
        }
        return undefined;
      },
    });
  }

  /**
   * return
   * @type {Object}
   */
  return {
    init,
    hideTab,
    unbindEvents,
  };
};
