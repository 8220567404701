import trapFocus from './trap-focus';

/**
 * Store Selector Modal
 * @param {object} opts
 */
function storeSelector(opts) {
  const config = {
    dom: {
      closeModalBtn: 'js-modal-close',
      dropdownHeading: 'dropdown-heading',
      dropdownItem: 'js-dropdown-list-item',
      dropdownMenu: 'js-store-selector-dropdown-menu',
      dropdownWrapper: 'storeSelectorDropdownWrapper',
      labelButton: 'dropdown-list__item-btn',
      labelText: 'js-store-selector-trigger-text',
      optionsTrigger: 'js-store-selector-trigger',
      optionsLabelIcon: 'options-label__icon',
      optionsWrapper: 'storeSelectorDropdownWrapper',
      overlay: 'modal-overlay',
    },
    cls: {
      active: 'is-active',
      closing: 'is-store-selector-closing',
      expose: 'is-store-selector-exposed',
      fullHeight: 'full-height',
      hide: 'hide',
      hover: 'is-hovered',
      open: 'is-open',
      optionSelected: 'option-selected',
    },
  };
  const c = __.extend(config, opts);

  // global elements
  const $body = document.body;
  const $optionsWrapper = document.getElementById(c.dom.optionsWrapper);
  const $dropdownWrapper = document.getElementById(c.dom.dropdownWrapper);
  const $selectCustomOpts = document.querySelectorAll(`.${c.dom.dropdownItem}`);
  const $section = document.getElementById(c.dom.dropdownMenu);
  const $labelTextElement = $section?.querySelector(`.${c.dom.labelText}`);
  const $optionsTrigger = $section?.querySelector(`.${c.dom.optionsTrigger}`);

  const optionsCount = $selectCustomOpts?.length;
  let optionHoveredIndex = -1;
  let view;

  function init() {
    const $storeSelectorModal = document.getElementById('storeSelectorModal');
    if ($storeSelectorModal) {
      const selectorPlaceholder = SDG.placeholder({
        id: 'storeSelectorModal',
      });

      // Cart placeholder
      selectorPlaceholder.init();
    }

    if (!$optionsWrapper || !$optionsTrigger) return;

    addEvents();
    responsive();
    __.windowResize(responsive);
  }

  /**
   * Add Events
   */
  function addEvents() {
    __.addEvent({
      id: document.body,
      event: 'click',
      className: c.dom.optionsTrigger,
      fn: toggleDropdown,
    });

    __.addEvent({
      id: document.body,
      event: 'click',
      className: c.dom.dropdownItem,
      fn: (e) => {
        const { currentTarget } = e;
        handleItemClick(currentTarget);
      },
    });

    __.addEvent({
      id: document.body,
      event: 'click',
      className: c.dom.closeModalBtn,
      fn: closeStoreSelector,
    });

    __.addEvent({
      id: document.body,
      event: 'click',
      className: c.dom.overlay,
      fn: closeStoreSelector,
    });
  }

  /**
   * Close Store
   */
  function closeStoreSelector() {
    // Close selector
    __.addClass($body, c.cls.closing);
    __.removeClass($body, c.cls.expose);

    // full hide quickview drawer after close animation and remove content
    setTimeout(() => {
      __.removeClass($body, c.cls.closing);
    }, 500);
  }

  /**
   * Responsive functions
   */
  function responsive() {
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
        }
      },
    });

    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
        }
      },
    });
  }

  /**
   * Set keyboard accessibility for options
   * @param {object} e
   */
  function supportKeyboardNavigation(e) {
    // press down -> go next
    if (e.keyCode === 40 && optionHoveredIndex < optionsCount - 1) {
      e.preventDefault(); // prevent page scrolling
      updateCustomSelectHovered(optionHoveredIndex + 1);
    }

    // press up -> go previous
    if (e.keyCode === 38 && optionHoveredIndex > 0) {
      e.preventDefault(); // prevent page scrolling
      updateCustomSelectHovered(optionHoveredIndex - 1);
    }
    // press Enter or space -> select the option
    if (e.keyCode === 13 || e.keyCode === 32) {
      e.preventDefault();
      const option = $selectCustomOpts[optionHoveredIndex];
      if (option) {
        __.removeClass(option, c.cls.hover);
        handleItemClick(option);
      }
    }
  }

  /**
   * Set hover state on options
   * @param {number} newIndex
   */
  function updateCustomSelectHovered(newIndex) {
    const $prevOption = $selectCustomOpts[optionHoveredIndex];
    const $option = $selectCustomOpts[newIndex];

    if ($prevOption) {
      __.removeClass($prevOption, c.cls.hover);
    }
    if ($option) {
      __.addClass($option, c.cls.hover);
    }

    optionHoveredIndex = newIndex;
  }

  /**
   * Set dropdown text to selected option
   * @param {HTMLElement} currentTarget
   */
  function handleItemClick(currentTarget) {
    const $listItems = document.body.querySelectorAll(`.${c.dom.dropdownItem}`);
    const { text } = currentTarget.dataset;
    const { value } = currentTarget.dataset;

    [...$listItems].forEach((item) => {
      __.removeClass(item, c.cls.active);
    });

    __.addClass(currentTarget, c.cls.active);
    setTimeout(() => {
      toggleDropdown(currentTarget);
    }, 150);

    $labelTextElement.setAttribute('data-value', value);
    $labelTextElement.innerText = text;
    __.addClass($optionsTrigger, c.cls.optionSelected);
  }

  /**
   * Update options and option state on toggle
   * @param {HTMLElement} currentTarget
   */
  function toggleDropdown(currentTarget) {
    $dropdownWrapper.classList.toggle(c.cls.active);
    const target = currentTarget.target || currentTarget;

    const $optionsLabelParents = __.parents(target);
    const $dropdownMenu = $optionsLabelParents
      ? $optionsLabelParents.find(($parent) => $parent.classList.contains(c.dom.dropdownMenu))
      : null;
    const $dropdownHeading = $dropdownMenu
      ? $dropdownMenu.querySelector(`.${c.dom.dropdownHeading}`)
      : null;
    const $optionsLabelTrigger = $dropdownHeading
      ? $dropdownHeading.querySelector(`.${c.dom.optionsTrigger}`)
      : null;
    const $optionsLabelIcon = $optionsLabelTrigger
      ? $optionsLabelTrigger.querySelector(`.${c.dom.optionsLabelIcon}`)
      : null;

    if (__.hasClass($dropdownWrapper, c.cls.active)) {
      $dropdownWrapper.focus();
      $optionsLabelTrigger.setAttribute('aria-expanded', 'true');
      $optionsLabelTrigger.setAttribute('aria-label', 'Close dropdown menu');
      __.addClass($optionsLabelIcon, c.cls.open);
      trapFocus($dropdownMenu);

      __.addEvent({
        id: $dropdownWrapper,
        event: 'keydown',
        fn: supportKeyboardNavigation,
      });
    } else {
      $dropdownWrapper.blur();
      $optionsLabelTrigger.focus();
      $optionsLabelTrigger.setAttribute('aria-expanded', 'false');
      $optionsLabelTrigger.setAttribute('aria-label', 'Open dropdown menu');
      __.removeClass($optionsLabelIcon, c.cls.open);

      __.removeEvent({
        id: $dropdownWrapper,
        event: 'keydown',
        fn: supportKeyboardNavigation,
      });
    }

    resetOptions();
    const $button = target.querySelector(`.${c.dom.labelButton}`);
    $button?.setAttribute('aria-pressed', 'true');
  }

  /**
   * Reset the state of all options
   */
  function resetOptions() {
    if (!$selectCustomOpts || $selectCustomOpts.length === 0) return;

    $selectCustomOpts.forEach(($option) => {
      const $button = $option.querySelector(`.${c.dom.labelButton}`);
      $button?.setAttribute('aria-pressed', 'false');
    });
  }

  return init();
}

export default storeSelector;
