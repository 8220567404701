/**
 * Collection Pagination
 */
function articlesPagination() {
  const c = {
    dom: {
      collection: 'articlesTab',
      collectionProducts: 'articlesWrapper',
      articlesPagination: '.js-articles-pagination',
      collectionItem: '.search__results-tiles-item',
      productsGrid: 'productsGrid',
      footer: 'footer',
      next: '.next a',
    },
    cls: {
      hide: 'hide',
    },
    isIntersecting: false,
    observer: null,
  };

  // Globals
  const $collection = document.getElementById(c.dom.collection);
  const $articlesGrid = document.getElementById(c.dom.collectionProducts);
  const $articlesPagination = $collection?.querySelector(c.dom.articlesPagination);
  const $footer = document.getElementById(c.dom.footer);
  let $next = $articlesPagination?.querySelector(c.dom.next);

  /**
   * Init
   */
  function init() {
    if (!$collection || !$footer || !$next) return;
    c.observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          fetchMoreData();
        }
      },
    );
    c.observer.observe($footer);
  }

  /**
   * Fetch more data
   */
  function fetchMoreData() {
    __.ajax({
      error: onError,
      success: onSuccess,
      type: 'GET',
      url: `${$next.getAttribute('href')}`,
    });
  }

  /**
   * Handle error
   */
  function onError(error) {
    console.warn('onError', error);
  }

  /**
   * Handle success
   */
  function onSuccess(data) {
    if (data) {
      const $nextCollection = new DOMParser().parseFromString(data, 'text/html');
      const $nextCollectionProducts = $nextCollection.getElementById(c.dom.productsGrid);
      const $nextCollectionPagination = $nextCollection.querySelector(c.dom.collectionPagination);
      const $newNext = $nextCollectionPagination?.querySelector(c.dom.next);

      if ($articlesGrid
          && $nextCollectionProducts
          && $nextCollectionProducts.querySelectorAll(c.dom.collectionItem).length > 0
      ) {
        $articlesGrid.append(...$nextCollectionProducts.childNodes);
      }

      if ($newNext) {
        $next = $newNext;
      } else {
        c.observer.unobserve($footer);
      }
    }
  }

  return init();
}

export default articlesPagination;
