import Flickity from 'flickity';

/*
* Go Custom
* @param {object} opts
*/
function goCustom(opts) {
  const config = {
    dom: {
      section: 'js-go-custom',
      shuffleButton: 'js-go-custom-shuffle-button',
      block: 'js-go-custom-frame-block',
      carousel: 'js-frame-content-carousel',
      carouselCell: 'js-frame-content-carousel-cell',
      flickityButton: 'flickity-button',
    },
    flickityOpts: {
      prevNextButtons: true,
      pageDots: false,
      draggable: true,
      wrapAround: true,
      fade: true,
      imagesLoaded: true,
      lazyLoad: true,
      adaptiveHeight: true,
    },
  };

  const c = __.extend(config, opts);
  const $sections = document.querySelectorAll(`.${c.dom.section}`);
  const $shuffleButton = document.querySelector(`.${c.dom.shuffleButton}`);
  const $blocks = document.querySelectorAll(`.${c.dom.block}`);
  const $carousels = document.querySelectorAll(`.${c.dom.carousel}`);

  let previousBlockIndex = 0;
  let activeCellIndex = 0;
  let view = null;

  /**
   * Init
   */
  function init() {
    if (!$sections?.length) return;
    onWindowResize();
    __.windowResize(onWindowResize);
  }

  /**
  * Responsive functions
  */
  function onWindowResize() {
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          if ($carousels?.length) initializeCarousel(previousBlockIndex);
          initializeShuffle();
        }
      },
    });

    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
          if ($carousels?.length) initializeCarousel(previousBlockIndex);
          initializeShuffle();
        }
      },
    });
  }

  /**
   * Initialize block shuffling
   */
  function initializeShuffle() {
    $blocks.forEach((block) => {
      block.style.display = 'none';
    });

    $blocks[previousBlockIndex].style.display = 'block';

    __.addEvent({
      id: $shuffleButton,
      event: 'click',
      fn: shuffleBlocks,
    });
  }

  /**
   * Shuffle blocks
   */
  function shuffleBlocks() {
    const nextBlockIndex = getRandomIndex(previousBlockIndex, $blocks.length);
    previousBlockIndex = nextBlockIndex;

    // Hide all blocks before showing the next one
    $blocks.forEach((block) => {
      block.style.display = 'none';
    });

    // Show current block
    $blocks[nextBlockIndex].style.display = 'block';

    // Initialize carousel for the current block
    initializeCarousel(nextBlockIndex);
  }

  /**
   * Initialize carousel in the current block
   * @param {number} blockIndex - The index of the active block
   */
  function initializeCarousel(blockIndex) {
    const $carousel = $carousels[blockIndex];
    let flickity = Flickity.data($carousel);
    if (flickity) flickity.destroy();

    // Set the initial active slide cell index
    c.flickityOpts.initialIndex = activeCellIndex;

    flickity = new Flickity($carousel, c.flickityOpts);

    // Add event listeners to the carousel buttons to update the active cell index
    const $flickityButtons = $carousel.querySelectorAll(`.${c.dom.flickityButton}`);
    $flickityButtons.forEach(($button) => {
      __.addEvent({
        id: $button,
        event: 'click',
        fn: () => {
          // Update the active cell index on each button click
          activeCellIndex = getActiveCellIndex($carousel);
        },
      });
    });
  }

  /**
   * Set active cell index
   * @param {object} carousel
   */
  function getActiveCellIndex(carousel) {
    const $activeCell = carousel.querySelector(`.${c.dom.carouselCell}.is-selected`);

    if ($activeCell) {
      return Array.from($activeCell.parentElement.children).indexOf($activeCell);
    }

    return 0;
  }

  /**
   * Get random index for the next block during shuffling
   * @param {number} previousIndex - The previous block index
   * @param {number} maxIndex - The maximum blocks amount
   */
  function getRandomIndex(previousIndex, maxIndex) {
    const nextBlockIndex = Math.floor(Math.random() * maxIndex);

    // Reshuffle if the next block index is the same as the previous one
    if (nextBlockIndex === previousIndex) {
      return getRandomIndex(previousIndex, maxIndex);
    }

    return nextBlockIndex;
  }

  init();
}

export default goCustom;
