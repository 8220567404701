/**
 * Madlib
 * @param {object} opts
 */

function madlib(opts) {
  const config = {
    dom: {
      section: 'js-madlib-section',
      heading: 'js-madlib-heading',
      link: 'js-madlib-cta-link',
      dropdown: 'js-madlib-dropdown',
      dropdownButton: 'js-madlib-dropdown-button',
      dropdownButtonText: 'js-madlib-dropdown-button-text',
      dropdownList: 'js-madlib-dropdown-list',
      dropdownOption: 'js-madlib-dropdown-option',
      selectorIcon: 'js-madlib-selector-icon',
    },
    cls: {
      open: 'is-open',
    },
  };

  const c = __.extend(config, opts);
  const $sections = document.querySelectorAll(`.${c.dom.section}`);

  /**
   * Initialize the Madlib component
   * @returns {void}
   */
  function init() {
    if (!$sections.length) return;

    $sections.forEach(($section) => {
      const dataObject = JSON.parse(atob($section.dataset.tableData));
      const { options, combinations } = dataObject;
      const $heading = $section.querySelector(`.${c.dom.heading}`);
      const $link = $section.querySelector(`.${c.dom.link}`);
      const $linkDefault = $link.dataset.defaultLink;
      const selectedOptions = {};
      let modifiedTemplate = $heading.dataset.headingTemplate;

      // Replace [option_X] with a dropdown for the corresponding option
      modifiedTemplate = modifiedTemplate.replace(/\[option_(\d+)\]/g, (match, optionNumber) => {
        const optKey = `option_${optionNumber}`;
        return createDropdown(options[optKey], optKey);
      });
      $heading.innerHTML = modifiedTemplate;

      const $dropdowns = $heading.querySelectorAll(`.${c.dom.dropdown}`);
      adjustOptionsBasedOnSelectors(options, $dropdowns.length);
      handleDropdowns($dropdowns, selectedOptions, combinations, $link, $linkDefault);
      initializeCustomDropdowns($heading);
    });
  }

  /**
   * Create a dropdown element
   * @param {array} optionList
   * @param {string} optionKey
   * @returns {string}
   */
  function createDropdown(optionList, optionKey) {
    const buttonId = `button-${optionKey}`;

    return `
      <div class="madlib__dropdown js-madlib-dropdown">
        <button id="${buttonId}" class="madlib__dropdown-button js-madlib-dropdown-button" data-option-key="${optionKey}" data-selected="" aria-haspopup="true" aria-expanded="false">
          <span class="js-madlib-dropdown-button-text">${optionList[0]}</span>
          <span class="madlib__icon js-madlib-selector-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <ellipse cx="10" cy="10" rx="10" ry="10" transform="matrix(-1 8.74228e-08 8.74228e-08 1 21 1)" fill="#FFF9F4" stroke="#1D2019"/>
              <path d="M7 9.5L11.0027 13.5L15 9.5" stroke="#1D2019" stroke-linecap="round" stroke-linejoin="bevel"/>
            </svg>
          </span>
        </button>
        <ul class="madlib__dropdown-list js-madlib-dropdown-list" aria-labelledby="${buttonId}" style="display: none;" role="menu">
          ${optionList.map((option) => `<li class="madlib__dropdown-option js-madlib-dropdown-option" data-value="${option}" tabindex="0" role="menuitem">${option}</li>`).join('')}
        </ul>
      </div>
    `;
  }

  /**
   * Initialize custom dropdowns
   * @param {HTMLElement} container
   * @returns {void}
   */
  function initializeCustomDropdowns(container) {
    const dropdowns = container.querySelectorAll(`.${c.dom.dropdown}`);
    dropdowns.forEach((dropdown) => {
      const button = dropdown.querySelector(`.${c.dom.dropdownButton}`);
      const list = dropdown.querySelector(`.${c.dom.dropdownList}`);
      const icon = button.querySelector(`.${c.dom.selectorIcon}`);
      const buttonText = button.querySelector(`.${c.dom.dropdownButtonText}`);

      // Dropdown button click event
      __.addEvent({
        id: button,
        event: 'click',
        fn: () => {
          const expanded = button.getAttribute('aria-expanded') === 'true';
          button.setAttribute('aria-expanded', !expanded);
          if (expanded) {
            list.style.display = 'none';
            __.removeClass(icon, `${c.cls.open}`);
          } else {
            list.style.display = 'block';
            __.addClass(icon, `${c.cls.open}`);
            adjustDropdownPosition(list, button);
          }
        },
      });

      // Dropdown button keyboard navigation
      __.addEvent({
        id: button,
        event: 'keydown',
        fn: (event) => {
          if (event.key === 'ArrowDown' || event.key === 'Enter' || event.key === ' ') { // Open dropdown on ArrowDown, Enter or Space key
            event.preventDefault();
            const expanded = button.getAttribute('aria-expanded') === 'true';
            button.setAttribute('aria-expanded', !expanded);
            if (!expanded) {
              list.style.display = 'block';
              __.addClass(icon, `${c.cls.open}`);
              list.querySelector('li').focus();
              adjustDropdownPosition(list, button);
            }
          } else if (event.key === 'Escape') {
            closeDropdown(button, list, icon);
          }
        },
      });

      const options = list.querySelectorAll(`.${c.dom.dropdownOption}`);
      options.forEach((option, index) => {
        // Dropdown options select event
        __.addEvent({
          id: option,
          event: 'click',
          fn: (event) => {
            selectOption(event, option, button, buttonText, list, icon);
          },
        });

        // Dropdown options keyboard navigation
        __.addEvent({
          id: option,
          event: 'keydown',
          fn: (event) => {
            if (event.key === 'Enter') { // Select option on Enter key
              event.preventDefault();
              selectOption(event, option, button, buttonText, list, icon);
            } else if (event.key === 'ArrowDown') { // Focus on the next option on ArrowDown key
              event.preventDefault();
              const nextOption = options[index + 1] || options[0];
              nextOption.focus();
            } else if (event.key === 'ArrowUp') { // Focus on the previous option on ArrowUp key
              event.preventDefault();
              const prevOption = options[index - 1] || options[options.length - 1];
              prevOption.focus();
            } else if (event.key === 'Escape') { // Close dropdown on Escape key
              closeDropdown(button, list, icon);
              button.focus();
            }
          },
        });
      });

      // Close dropdown when clicking outside
      __.addEvent({
        id: document,
        event: 'click',
        fn: (event) => {
          if (!dropdown.contains(event.target)) {
            closeDropdown(button, list, icon);
          }
        },
      });

      // Close dropdown when tabbing outside using focusout event
      __.addEvent({
        id: dropdown,
        event: 'focusout',
        fn: (event) => {
          if (!dropdown.contains(event.relatedTarget)) {
            closeDropdown(button, list, icon);
          }
        },
      });
    });
  }

  /**
   * Adjust dropdown position to fit in the viewport
   * @param {HTMLElement} list
   * @param {HTMLElement} button
   * @returns {void}
   */
  function adjustDropdownPosition(list, button) {
    // Reset styles
    list.style.left = '50%';
    list.style.transform = 'translateX(-50%)';
    list.style.right = 'auto';
    list.style.top = 'calc(100% + 8px)';

    const rect = list.getBoundingClientRect();
    const buttonRect = button.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Adjust left position if the list is overflowing the viewport on the right
    if (rect.right > viewportWidth) {
      const overflowRight = rect.right - viewportWidth + 10;
      list.style.left = `calc(50% - ${overflowRight}px)`;
      list.style.transform = 'translateX(-50%)';
    }

    // Adjust left position if the list is overflowing the viewport on the left
    if (rect.left < 0) {
      list.style.left = '10px';
      list.style.transform = 'none';
    }

    // Adjust top position if the list is overflowing the viewport at the bottom
    if (rect.bottom > viewportHeight) {
      list.style.top = `calc(100% - ${rect.height + buttonRect.height + 8}px)`;
    }
  }

  /**
   * Close the dropdown
   * @param {HTMLElement} button
   * @param {HTMLElement} list
   * @param {HTMLElement} icon
   * @returns {void}
   */
  function closeDropdown(button, list, icon) {
    list.style.display = 'none';
    button.setAttribute('aria-expanded', 'false');
    __.removeClass(icon, `${c.cls.open}`);
  }

  /**
   * Select an option
   * @param {Event} event
   * @param {HTMLElement} option
   * @param {HTMLElement} button
   * @param {HTMLElement} buttonText
   * @param {HTMLElement} list
   * @param {HTMLElement} icon
   * @returns {void}
   */
  function selectOption(event, option, button, buttonText, list, icon) {
    const value = option.getAttribute('data-value');
    const key = button.getAttribute('data-option-key');

    buttonText.textContent = value;
    button.setAttribute('data-selected', value);
    button.setAttribute('aria-expanded', 'false');

    list.style.display = 'none';
    __.removeClass(icon, `${c.cls.open}`);

    button.dispatchEvent(new CustomEvent('selectionchange', {
      detail: { key, value },
    }));

    if (event.type === 'keydown') {
      button.focus();
    }
  }

  /**
   * Adjust options based on the number of selectors
   * @param {object} options
   * @param {number} numberOfSelectors
   * @returns {void}
   */
  function adjustOptionsBasedOnSelectors(options, numberOfSelectors) {
    if (numberOfSelectors === 1) {
      options.option_2 = [null];
      options.option_3 = [null];
    } else if (numberOfSelectors === 2) {
      options.option_3 = [null];
    }
  }

  /**
   * Update the CTA link based on the selected options
   * @param {NodeList} dropdowns
   * @param {object} selectedOptions
   * @param {array} combinations
   * @param {HTMLElement} $link
   * @param {string} defaultLink
   * @returns {void}
   */
  function handleDropdowns(dropdowns, selectedOptions, combinations, $link, defaultLink) {
    dropdowns.forEach((dropdown) => {
      const button = dropdown.querySelector(`.${c.dom.dropdownButton}`);
      const key = button.getAttribute('data-option-key');
      const buttonText = button.querySelector(`.${c.dom.dropdownButtonText}`);
      selectedOptions[key] = button.getAttribute('data-selected') || buttonText.textContent;
      let newLink = getCombinationURL(selectedOptions, combinations, defaultLink);
      updateCTALink($link, newLink);

      // Dropdown selection change event
      __.addEvent({
        id: button,
        event: 'selectionchange',
        fn: (event) => {
          const { key: optionKey, value } = event.detail;
          selectedOptions[optionKey] = value;
          newLink = getCombinationURL(selectedOptions, combinations, defaultLink);
          updateCTALink($link, newLink);
        },
      });
    });
  }

  /**
   * Update the link's href and title attributes
   * @param {HTMLElement} link
   * @param {string} newLink
   * @returns {void}
   */
  function updateCTALink(link, newLink) {
    link.setAttribute('href', newLink);
    link.setAttribute('title', newLink);
  }

  /**
   * Get the URL based on the selected options
   * @param {object} selectedOptions
   * @param {array} combinations
   * @param {string} defaultLink
   * @returns {string}
   */
  function getCombinationURL(selectedOptions, combinations, defaultLink) {
    if (!selectedOptions.option_2) selectedOptions.option_2 = null;
    if (!selectedOptions.option_3) selectedOptions.option_3 = null;

    const foundCombination = combinations.find((combination) => Object
      .entries(selectedOptions).every(([key, value]) => combination[key] === value));

    return foundCombination ? foundCombination.url : defaultLink;
  }

  return init();
}

export default madlib;
