<template>
  <VueModal
    :anchor="'#productConfiguratorGalleryModal'"
    :show="modalOpen"
    :isFullscreen="true"
    :includeOverlay="true"
    @toggleModal="toggleModal"
  >
    <template #modal-content>
      <div
        ref="carousel"
        class="carousel product-configurator__gallery"
      >
        <div
          v-for="(image, index) in images"
          :key="index"
          class="carousel-cell product-configurator__gallery-image"
        >
        <div class="product-configurator__gallery-image-wrapper">
          <MaskedRenderer
            v-if="(index === 0 && frameSpec && hasMaskRender)"
            :frameSpec="frameSpec"
            :artwork="frameSpecUrl"
            class="content single-frame-preview-svg product-configurator__pv-image"
          />
          <SingleFramePreviewSvg
            v-else-if="(index === 0 && frameSpec)"
            :frameSpec="frameSpec"
            class="content single-frame-preview-svg product-configurator__pv-image"
          />
          <ResponsiveImage
            v-else
            :image="image?.src"
            :image-alt="image?.alt || 'Product image'"
            :modifier="'square'"
          />
        </div>
        </div>
      </div>
    </template>
  </VueModal>
</template>

<script setup>
import {
  computed,
  ref,
  onMounted,
  onBeforeUnmount,
  watch,
  toRefs,
  nextTick,
} from 'vue';
import Flickity from 'flickity';
import SingleFramePreviewSvg from '@framebridge/toolbox/SingleFramePreviewSvg';
import MaskedRenderer from '@framebridge/toolbox/MaskedRenderer';
import ResponsiveImage from '../common/responsive-image/ResponsiveImage';
import VueModal from '../common/modal/Modal';

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  variant: {
    type: Object,
    required: false,
    default: null,
  },
  frameSpec: {
    type: Object,
    required: false,
    default: null,
  },
  hasMaskRender: {
    type: Boolean,
    required: false,
    default: false,
  },
  modalOpen: {
    type: Boolean,
    required: true,
  },
  toggleModal: {
    type: Function,
    required: true,
  },
  mainImage: {
    type: Object,
    required: false,
    default: null,
  },
});

const {
  product,
  variant,
  modalOpen,
  mainImage,
} = toRefs(props);

const carousel = ref(null);
let flickityInstance = null;

const images = computed(() => {
  const productImages = product?.value?.productJson?.media || [];

  if (mainImage?.value) {
    const firstImage = {
      src: mainImage.value,
      alt: variant.value?.variantJson?.title || 'Product image',
    };
    // remove duplicates
    productImages.forEach((image, index) => {
      if (image?.src === mainImage?.value) {
        productImages.splice(index, 1);
      }
    });
    return [firstImage, ...productImages];
  }
  return productImages;
});

const initCarousel = () => {
  if (flickityInstance) {
    flickityInstance.destroy();
  }

  flickityInstance = new Flickity(carousel.value, {
    cellAlign: 'left',
    pageDots: false,
    groupCells: 1,
    setGallerySize: true,
  });
};

onMounted(() => {
  watch(modalOpen, async (newVal) => {
    if (newVal) {
      await nextTick();
      initCarousel();
    } else if (flickityInstance) {
      flickityInstance.destroy();
      flickityInstance = null;
    }
  });
});

onBeforeUnmount(() => {
  if (flickityInstance) {
    flickityInstance.destroy();
    flickityInstance = null;
  }
});

</script>
