async function setUserMarketByGeo() {
  const coords = localStorage.getItem('geolocationCoords') || null;
  const market = localStorage.getItem('nearestMarket') || null;
  // if storage has nearestMarket, set it on DataLayer
  if (market !== null) {
    setMarketOnDatalayer(market);
  // if storage has coords, set group by coords
  } else if (coords !== null) {
    const { latitude, longitude } = JSON.parse(coords);
    setGroupByCoords(latitude, longitude);
  // if storage has neither, get coords from geolocation
  } else if ('geolocation' in navigator) {
    // Get geolocation data from browser and set coords
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      const { latitude, longitude } = position.coords;
      const geolocationCoords = { latitude, longitude };
      localStorage.setItem('geolocationCoords', JSON.stringify(geolocationCoords));
      setGroupByCoords(latitude, longitude);
    } catch (err) {
      console.error(err);
      // if geolocation fails, get coords from IPStack
      getCoordsFromIPStack();
    }
  }
}

/**
 * Get Coordinates from IPStack
 */
async function getCoordsFromIPStack() {
  const {
    ipStackApiKey,
  } = SDG.Data;

  await fetch(`http://api.ipstack.com/check?access_key=${ipStackApiKey}`)
    .then((resp) => resp.json())
    .then(async (ipStackResp) => {
      const { latitude, longitude } = ipStackResp;
      const geolocationCoords = { latitude, longitude };
      localStorage.setItem('geolocationCoords', JSON.stringify(geolocationCoords));
      setGroupByCoords(latitude, longitude);
    })
    .catch((err) => console.error(err));
}

/**
 * Set Market Group By Coordinates
 * @param {number} userLat users latitude
 * @param {number} userLong users longitude
 */
function setGroupByCoords(userLat, userLong) {
  const {
    geoMarketOptions,
  } = SDG.Data;

  const markets = JSON.parse(geoMarketOptions).geoRules.rules;
  const marketsByDistance = {};
  markets.forEach((market) => {
    const { latitude: marketLat, longitude: marketLong, radiusMiles: radius } = market.locations[0];
    const groupId = market.contentId;
    const distance = calculateDistance(userLat, userLong, marketLat, marketLong);

    if (distance <= radius) {
      marketsByDistance[groupId] = distance;
    }
  });
  let nearestMarket = null;
  if (Object.keys(marketsByDistance).length > 1) {
    nearestMarket = Object.keys(marketsByDistance)
      .reduce((a, b) => (
        marketsByDistance[a] < marketsByDistance[b] ? a : b
      ));
  } else {
    [nearestMarket] = Object.keys(marketsByDistance);
  }
  if (nearestMarket) {
    localStorage.setItem('nearestMarket', nearestMarket);
  }
  setMarketOnDatalayer(nearestMarket);
}

/**
 * Calculate Distance between two points in Miles
 * @param {number} lat1Deg latitude of point 1 in degrees
 * @param {number} lon1Deg longitude of point 1 in degrees
 * @param {number} lat2Deg latitude of point 2 in degrees
 * @param {number} lon2Deg longitude of point 2 in degrees
 * @returns {number} distance in miles
 */
function calculateDistance(lat1Deg, lon1Deg, lat2Deg, lon2Deg) {
  function toRad(degree) {
    return degree * (Math.PI / 180);
  }

  const lat1 = toRad(lat1Deg);
  const lon1 = toRad(lon1Deg);
  const lat2 = toRad(lat2Deg);
  const lon2 = toRad(lon2Deg);

  const {
    sin,
    cos,
    sqrt,
    atan2,
  } = Math;

  const R = 3963.1; // earth radius in miles
  const dLat = lat2 - lat1;
  const dLon = lon2 - lon1;
  const a = sin(dLat / 2) * sin(dLat / 2)
    + cos(lat1) * cos(lat2)
    * sin(dLon / 2) * sin(dLon / 2);
  const c = 2 * atan2(sqrt(a), sqrt(1 - a));
  const d = R * c;
  return d; // distance in miles
}

/**
 * Set Market on Datalayer
 * @param {market} string market name
 */
function setMarketOnDatalayer(market) {
  window.dataLayer = window.dataLayer || [];
  if (window.dataLayer) {
    if (market) {
      window.dataLayer.push(
        {
          event: 'geolocation',
          rule: 'retail-content',
          market,
        },
      );
    } else {
      window.dataLayer.push(
        {
          event: 'geolocation',
          rule: 'default-national-content',
        },
      );
    }
  }
}

export default setUserMarketByGeo;
