function materialsTooltip(opts) {
  const config = {
    dom: {
      tooltip: '.js-materials-tooltip',
      modal: '.js-tooltip-modal',
      open_button: '.js-launch-tooltip',
      close_button: '.js-tooltip-close',
      overlay: 'tooltipOverlay',
    },
    cls: {
      hidden: 'hide',
      scroll: 'overflow-hidden',
      visible: 'is-visible',
    },
  };

  const c = __.extend(config, opts);
  const $toolTipWrappers = document.querySelectorAll(c.dom.tooltip);
  const $toolTipModals = document.querySelectorAll(c.dom.modal);
  const $overlay = document.getElementById(c.dom.overlay);
  const $body = document.body || null;

  function init() {
    if (!$overlay) return;

    if ($toolTipWrappers?.length > 0) {
      [...$toolTipWrappers].forEach((tooltip) => {
        updateText(tooltip);
      });

      __.addEvent({
        id: $overlay,
        event: 'click',
        fn: clearModals,
      });
      __.windowResize(clearModals);
    }
  }

  /**
   * Update Text
   * @param {HTMLElement} tooltip
   * @returns {void}
   * @description Updates the text of the tooltip to include a button
   */
  function updateText(tooltip) {
    const text = tooltip.innerText;
    // Find the text that is wrapped in []
    const textToReplace = text.match(/\[(.*?)\]/)[0];
    const { buttonText } = tooltip.dataset;
    const html = `
      <button
        class="materials__item-button js-launch-tooltip"
        aria-label="Open tooltip"
        type="button"
      >
        ${buttonText}
      </button>
    `;

    tooltip.innerHTML = text.replace(textToReplace, html);
    addEvents(tooltip);
  }

  /**
   * Add Events
   * @param {HTMLElement} tooltip
   * @returns {void}
   * @description Adds events to the tooltip
   */
  function addEvents(tooltip) {
    const $button = tooltip.querySelector(c.dom.open_button);
    const $modal = tooltip.nextElementSibling || null;
    const $closeButton = $modal?.querySelector(c.dom.close_button) || null;

    if (!$modal || !$closeButton) return;

    /**
     * Open Modal
     * @returns {void}
     * @description Opens the modal
     */
    function openModal() {
      if ($modal) {
        __.removeClass($modal, c.cls.hidden);
        __.addClass($overlay, c.cls.visible);
      }
    }

    /**
     * Close Modal
     * @returns {void}
     * @description Closes the modal
     */
    function closeModal() {
      if ($modal) {
        __.addClass($modal, c.cls.hidden);
        __.removeClass($overlay, c.cls.visible);
      }
    }

    __.addEvent({
      id: $button,
      event: 'click',
      fn: openModal,
    });

    __.addEvent({
      id: $closeButton,
      event: 'click',
      fn: closeModal,
    });
  }

  /**
   * Clear Modals
   * @returns {void}
   * @description Clears all modals
   */
  function clearModals() {
    if (!$toolTipModals) return;

    if ($toolTipModals?.length > 0) {
      [...$toolTipModals].forEach((modal) => {
        if (!__.hasClass(modal, c.cls.hidden)) {
          __.addClass(modal, c.cls.hidden);
          __.removeClass($overlay, c.cls.visible);
        }
      });

      if (__.hasClass($body, c.cls.scroll)) {
        __.removeClass($body, c.cls.scroll);
      }
    }
  }

  return init();
}

export default materialsTooltip;
