/**
 * Promo Bar Carousel
 */

import Flickity from 'flickity';

require('flickity-fade');

function promoBarCarousel(opts) {
  const config = {
    dom: {
      carousel: 'js-promo-bar-carousel',
      carouselCell: 'js-promo-bar-carousel-cell',
      carouselCellContent: 'promo-bar__carousel-cell-content',
      carouselControls: 'promo-bar__carousel-controls',
      carouselParent: 'promo-bar__section',
      playBtnToggle: 'promo-bar__carousel-control-play--toggle',
    },
    flickityOpts: {
      contain: true,
      cellAlign: 'center',
      fade: true,
      percentPosition: false,
      adaptiveHeight: false,
      prevNextButtons: false,
      pageDots: false,
      verticalCells: true,
      draggable: true,
      dragThreshold: 3,
      wrapAround: true,
      autoPlay: 5,
    },
    cls: {
      fullHeight: 'full-height',
      isSelected: 'is-selected',
    },
  };

  // Shorthand config
  const c = __.extend(config, opts); // Extend defaults with passed options

  // cached global(s)
  const $carousel = document.querySelector(`.${c.dom.carousel}`);
  const $playBtnToggle = document.querySelectorAll(`.${c.dom.playBtnToggle}`);
  let $playBtn;
  let view;

  /**
   * Init
   */
  function init() {
    if ($carousel) {
      addEvents();
      responsive();
      __.windowResize(responsive);
    }
  }

  /**
   * Make carousel
   */
  function makeCarousel() {
    const $carouselItems = $carousel.querySelectorAll(`.${c.dom.carouselCell}`);
    let flkty = Flickity.data($carousel);

    if (flkty) {
      flkty.destroy();

      [...$carouselItems].forEach(($item) => {
        __.removeClass($item, c.cls.fullHeight);
      });
    }

    c.flickityOpts.on = {
      dragEnd: () => {
        const newFlkty = $carousel ? new Flickity(`.${c.dom.carousel}`) : null;
        const playerState = newFlkty ? newFlkty.player.state : null;
        const $currentCarouselPlayBtn = $carousel ? $carousel.querySelector(`.${c.dom.playBtnToggle}`) : null;

        if (!newFlkty || !$currentCarouselPlayBtn) return;

        if (playerState === 'stopped' || playerState === 'paused') {
          newFlkty.playPlayer();
        }
      },
    };

    const autoPlay = __.toNumber($carousel.dataset.carouselAutoplaySpeed) * 1000;
    const flickityOpts = __.extend(c.flickityOpts, { autoPlay });

    /* eslint-disable-next-line */
    flkty = new Flickity($carousel, flickityOpts);
  }

  /**
   * Handle Play Toggle
   * @param {Object} e - change event
   */
  function handlePlayToggle(e) {
    e.preventDefault();

    const $carouselParents = __.parents(e.currentTarget);
    const $carouselParent = $carouselParents
      ? $carouselParents.find(($parent) => $parent.classList.contains(c.dom.carouselParent))
      : null;
    const $flickityCarousel = $carouselParent
      ? $carouselParent.querySelector(`.${c.dom.carousel}`)
      : null;
    $playBtn = $carouselParent
      ? $carouselParent.querySelector(`.${c.dom.playBtnToggle}`)
      : null;
    const flkty = $flickityCarousel ? new Flickity(`.${c.dom.carousel}`) : null;

    if (!flkty) return;

    e.currentTarget.classList.toggle('playing');

    if ($flickityCarousel) {
      if (e.currentTarget.classList.contains('playing')) {
        flkty.playPlayer();
        $playBtn.setAttribute('aria-label', SDG?.Data?.carouselStrings?.pause);
      } else {
        flkty.stopPlayer();
        $playBtn.setAttribute('aria-label', SDG?.Data?.carouselStrings?.play);
      }
    }
  }

  /**
   * Add Events
   */
  function addEvents() {
    // Play/Pause Toggle
    $playBtnToggle.forEach(($btn) => {
      $btn.addEventListener('click', (e) => {
        handlePlayToggle(e);
      });
    });
  }

  /**
   * Responsive functions
   */
  function responsive() {
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          makeCarousel();
        }
      },
    });

    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
          makeCarousel();
        }
      },
    });
  }

  return init();
}

export default promoBarCarousel;
