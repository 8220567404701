import Flickity from 'flickity';

/*
* Meet Our Designers Carousel
* @param {object} opts
*/

function meetOurDesignersCarousel(opts) {
  const config = {
    dom: {
      carousel: 'js-meet-our-designers-carousel',
      carouselCell: 'js-meet-our-designers-carousel-cell',
    },
    flickityOpts: {
      prevNextButtons: false,
      pageDots: true,
      draggable: true,
      wrapAround: true,
      imagesLoaded: true,
      lazyLoad: true,
      adaptiveHeight: false,
    },
    cls: {
      flickityDisabled: 'flickity-disabled',
    },
  };

  const c = __.extend(config, opts);
  const $carousels = document.body.querySelectorAll(`.${c.dom.carousel}`);
  let view = null;

  /**
   * Init
   */
  function init() {
    if (!$carousels?.length) return;
    onWindowResize();
    __.windowResize(onWindowResize);
  }

  /**
  * Responsive functions
  */
  function onWindowResize() {
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          destroyCarousel();
        }
      },
    });

    __.mq({
      view: 'tablet_only',
      callback: () => {
        if (view !== 'tablet_only') {
          view = 'tablet_only';
          const flickityOpts = __.extend(c.flickityOpts, { wrapAround: false, contain: true });
          createCarousel(flickityOpts);
        }
      },
    });

    __.mq({
      view: 'phone',
      callback: () => {
        if (view !== 'phone') {
          view = 'phone';
          createCarousel(c.flickityOpts);
        }
      },
    });
  }

  /**
   * Create Carousel
   * @param {object} opts.flickityOpts
   */
  function createCarousel(flickityOpts) {
    $carousels.forEach(($carousel) => {
      let flickity = Flickity.data($carousel);
      if (flickity) flickity.destroy();

      setTimeout(() => {
        flickity = new Flickity($carousel, flickityOpts);
      }, 500);

      // Calculate and apply the maximum height to the cells
      const updateCellHeight = () => {
        let maxHeight = 0;
        const carouselCells = $carousel.querySelectorAll(`.${c.dom.carouselCell}`);
        carouselCells.forEach((cell) => {
          cell.style.height = 'auto'; // Reset the height first
          const cellHeight = cell.offsetHeight;
          if (cellHeight > maxHeight) {
            maxHeight = cellHeight;
          }
        });
        carouselCells.forEach((cell) => {
          cell.style.height = `${maxHeight}px`; // Apply the maximum height
        });
      };

      updateCellHeight(); // Initial calculation

      window.addEventListener('resize', () => {
        flickity.resize();
        updateCellHeight();
      });
    });
  }

  /**
   * Destroy Carousel
   */
  function destroyCarousel() {
    $carousels.forEach(($carousel) => {
      const flickity = Flickity.data($carousel);
      if (flickity) flickity.destroy();
    });
  }

  return init();
}

export default meetOurDesignersCarousel;
