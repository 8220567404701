/**
 * Check Window Location
 */

function checkWindowLocation() {
  // Globals
  const location = window.location.href;

  // Data
  const sessionStorage = window.sessionStorage || null;
  const sessionLocation = JSON.parse(sessionStorage.getItem('comparePanelLocation')) || null;

  /**
   * Init
   */
  function init() {
    if (!sessionLocation || !location) return;

    resetComparePanelData(location);
  }

  /**
   * Reset Compare Panel Data
   */
  function resetComparePanelData(windowLocation) {
    const ignoreTerms = ['collections', 'shortlist'];
    let removeStorageItem = true;

    ignoreTerms.forEach((term) => {
      if (windowLocation.includes(term)) {
        removeStorageItem = false;
      }
    });

    if (removeStorageItem) {
      sessionStorage.removeItem('comparePanelStorage');
      sessionStorage.removeItem('comparePanelLocation');
    }
  }

  return init();
}

export default checkWindowLocation;
