<template>
  <div class="bag-item__ship">
    <div class="bag-item__ship-header">
      <h3 class="bag-item__ship-header-title" v-html="shipInstructionText">
      </h3>
    </div>
    <div class="bag-item__ship-options-wrapper">
      <ul
        v-if="computedMailingOptions && computedMailingOptions.length > 0"
        class="bag-item__ship-options"
      >
        <li
          v-for="option in computedMailingOptions"
          :key="`${id}-${option.title.toLowerCase().replaceAll(' ', '-')}`"
          class="bag-item__ship-option-wrapper"
        >
          <div class="radio bag-item__ship-option">
            <input
              class="js-ship-radio-option"
              :id="`${id}-${option.title.toLowerCase().replaceAll(' ', '-')}`"
              :data-option="option.title"
              :data-additional-options="option.radio_button_copy"
              :data-item-id="`${id}`"
              type="radio"
              :name="`${id}-radio`"
              :data-checked="(option.title === shipToStore) || option.radio_button_copy?.includes(shipToStore) || (shipToStore === 'temp_ship' && Boolean(additionalMailingOptions?.length) && Boolean(option.radio_button_copy?.length))"
              :checked="(option.title === shipToStore) || option.radio_button_copy?.includes(shipToStore) || (shipToStore === 'temp_ship' && Boolean(additionalMailingOptions?.length) && Boolean(option.radio_button_copy?.length))"
              :disabled="checkoutLoading"
              @change="option.radio_button_copy ? setTempAdditionalMailingOptions(option.radio_button_copy, id) : handleChange(option.title, id)"
            />
            <label
              :class="`radio--${option.title.toLowerCase().replaceAll(' ', '-')}`"
              :for="`${id}-${option.title.toLowerCase().replaceAll(' ', '-')}`"
            >
              <span
                class="bag-item__ship-option-icon bag-item__ship-option-icon-default"
                aria-hidden="true"
              >
                <i
                  class="bag-item__ship-option-icon-wrapper"
                >
                  <svg
                    :class="`bag-item__${option.title.toLowerCase().replaceAll(' ', '-')}-icon`"
                    width="40"
                    height="40"
                  >
                    <image
                      :href="option.icon"
                      width="40"
                      height="40"
                    />
                  </svg>
                </i>
              </span>
              <span
                v-if="option.icon_active"
                class="bag-item__ship-option-icon bag-item__ship-option-icon-active"
                aria-hidden="true"
              >
                <i
                  class="bag-item__ship-option-icon-wrapper"
                >
                  <svg
                    :class="`bag-item__${option.title.toLowerCase().replaceAll(' ', '-')}-icon`"
                    width="40"
                    height="40"
                  >
                    <image
                      :href="option.icon_active"
                      width="40"
                      height="40"
                    />
                  </svg>
                </i>
              </span>
              <span class="bag-item__ship-option-text">
                <span class="bag-item__ship-option-title">
                  {{ option.title }}
                </span>
                <span class="bag-item__ship-option-subtitle" v-html="option.sub_title">
                </span>
              </span>
            </label>
          </div>
        </li>
      </ul>
    </div>
    <div
      v-if=" additionalMailingOptions?.length"
      class="bag-item__ship-radio-options-wrapper"
    >
      <ul class="bag-item__ship-radio-options form-inline">
        <li
          v-for="option in additionalMailingOptions"
          :key="`${id}-${option.toLowerCase().replaceAll(' ', '-')}`"
          class="bag-item__ship-radio-option
          form-item form-inline__input"
        >
          <div class="radio">
            <input
              class="js-ship-radio-option-customer"
              :id="`${id}-customer-${option.toLowerCase().replaceAll(' ', '-')}`"
              :data-parent-option="option"
              :data-option="option"
              :data-is-additional-option="true"
              :data-item-id="`${id}`"
              type="radio"
              :name="`${id}-customer-radio`"
              :checked="option === shipToStore"
              :disabled="checkoutLoading"
              @change="handleChange(option, id)"
            />
            <label
              class="radio--customer"
              :for="`${id}-customer-${option.toLowerCase().replaceAll(' ', '-')}`"
            >
              <span class="bag-item__ship-radio-option-text">
                {{ option }}
              </span>
            </label>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import {
  ref,
  toRefs,
  toRaw,
  computed,
  watch,
  onMounted,
} from 'vue';
import useCart from '../composables/cart-actions';

export default {

  name: 'CartItemShip',
  props: {
    itemSelector: {
      type: String,
      default: '',
    },
    productId: {
      type: String,
      default: '',
    },
    productProperties: {
      type: Object,
      default: () => ({}),
    },
    shipToStoreProperty: {
      type: String,
      default: '',
    },
    shippingOptions: {
      type: Array,
      default: () => ([]),
    },
    packagingOptions: {
      type: Array,
      default: () => ([]),
    },
    setProductProperty: {
      type: Function,
      default: () => {},
    },
  },

  setup(props, context) {
    const {
      addShipToUs,
      checkoutLoading,
    } = useCart();

    const {
      productId,
      productProperties,
      shipToStoreProperty,
      shippingOptions,
      packagingOptions,
      itemSelector,
    } = toRefs(props);
    const id = toRaw(productId.value);
    const shipInstructionText = ref(SDG.Data.cart.ship_instruction_text);
    const shipToStore = ref(shipToStoreProperty.value);
    const tempAdditionalMailingOptions = ref(null);
    const $cartItem = ref(null);

    onMounted(() => {
      $cartItem.value = document.getElementById(itemSelector.value);
    });

    watch(shipToStoreProperty, (newShipToStoreProperty) => {
      shipToStore.value = toRaw(newShipToStoreProperty);

      // fix radio option being unchecked after data updates
      setTimeout(() => {
        const $shipRadioOption = $cartItem.value?.querySelector('.js-ship-radio-option[data-checked="true"]');
        if ($shipRadioOption) {
          $shipRadioOption.checked = true;
        }
      });
    });

    const computedMailingOptions = computed(() => {
      const allShippingOptions = toRaw(shippingOptions.value);
      const options = packagingOptions.value.map(
        (packagingOption) => allShippingOptions.filter(
          (shippingOption) => shippingOption.type === packagingOption,
        )[0],
      );

      return options;
    });

    const additionalMailingOptions = computed(() => {
      const selectedOption = computedMailingOptions.value.find((option) => {
        // eslint-disable-next-line max-len
        return option.title === shipToStore.value || option.radio_button_copy?.includes(shipToStore.value);
      });
      return selectedOption?.radio_button_copy || [];
    });

    const handleChange = (option, itemId) => {
      if (option !== 'temp_ship') {
        setTempAdditionalMailingOptions(null);
      }

      if ((option === productProperties.value.shipToUs || option === shipToStore.value) && option !== 'temp_ship') return;

      if (option) {
        // We need to update line item property locally so that we can keep track of it
        // This is needed for the case when the user changes the shipping option
        props.setProductProperty('shipToUs', option);

        addShipToUs(itemId, productProperties.value, option);

        const newShipData = {
          shipOption: option,
          itemId,
        };

        context.emit('setShipToUs', newShipData);
      }
    };

    const setTempAdditionalMailingOptions = (data, itemId) => {
      tempAdditionalMailingOptions.value = data;
      if (data) {
        handleChange('temp_ship', itemId);
      }
    };

    // eslint-disable-next-line max-len
    const computedAdditionalMailingOptions = computed(() => tempAdditionalMailingOptions.value || additionalMailingOptions.value);

    return {
      id,
      computedMailingOptions,
      additionalMailingOptions: computedAdditionalMailingOptions,
      setTempAdditionalMailingOptions,
      handleChange,
      shipInstructionText,
      shipToStore,
      checkoutLoading,
    };
  },
};
</script>
