import Flickity from 'flickity';

/*
* Blog Post Carousel
* @param {object} opts
*/

function blogPostCarousel(opts) {
  const config = {
    dom: {
      carousel: 'js-blog-post-carousel',
      carouselCell: 'js-blog-post-carousel-cell',
    },
    flickityOpts: {
      desktop: {
        prevNextButtons: true,
        pageDots: true,
        draggable: true,
        wrapAround: true,
        imagesLoaded: true,
        lazyLoad: true,
        adaptiveHeight: false,
      },
      mobile: {
        prevNextButtons: false,
        pageDots: true,
        draggable: true,
        wrapAround: true,
        imagesLoaded: true,
        lazyLoad: true,
        adaptiveHeight: false,
      },
    },
  };

  const c = __.extend(config, opts);
  const $carousels = document.body.querySelectorAll(`.${c.dom.carousel}`);
  let view = null;

  /**
   * Init
   */
  function init() {
    if (!$carousels?.length) return;
    onWindowResize();
    __.windowResize(onWindowResize);
  }

  /**
  * Responsive functions
  */
  function onWindowResize() {
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          createCarousel(c.flickityOpts.desktop);
        }
      },
    });

    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
          createCarousel(c.flickityOpts.mobile);
        }
      },
    });
  }

  /**
   * Create Carousel
   * @param {object} opts.flickityOpts
   */
  function createCarousel(flickityOpts) {
    $carousels.forEach(($carousel) => {
      let flickity = Flickity.data($carousel);
      if (flickity) flickity.destroy();

      flickity = new Flickity($carousel, flickityOpts);

      // Calculate and apply the maximum height to the cells
      const updateCellHeight = () => {
        let maxHeight = 0;
        const carouselCells = $carousel.querySelectorAll(`.${c.dom.carouselCell}`);
        carouselCells.forEach((cell) => {
          cell.style.height = 'auto'; // Reset the height first
          const cellHeight = cell.offsetHeight;
          if (cellHeight > maxHeight) {
            maxHeight = cellHeight;
          }
        });
        carouselCells.forEach((cell) => {
          cell.style.height = `${maxHeight}px`; // Apply the maximum height
        });
      };

      updateCellHeight(); // Initial calculation

      window.addEventListener('resize', () => {
        flickity.resize();
        updateCellHeight(); // Initial calculation
      });
    });
  }

  return init();
}

export default blogPostCarousel;
