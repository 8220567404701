/**
 * Stockist Widget
 */

function storeLocator() {
  const c = {
    id: {
      footer: 'footer',
    },
    dom: {
      footer: 'js-footer',
      footerContent: 'js-footer-stores-content',
      footerDescription: 'js-footer-stores-description',
      footerStoresLinks: 'js-footer-stores-links',
      footerStores: 'js-footer-stores',
    },
    cls: {
      footerStoresLinksFar: 'footer__stores-links--far',
      footerStoresLinksNear: 'footer__stores-links--near',
      footerStoresContentFar: 'footer__stores-content--far',
      footerStoresContentNear: 'footer__stores-content--near',
    },
  };

  // Globals
  const $footer = document.getElementById(c.id.footer);

  // Data
  const widgetTag = SDG.Data.stockistWidgetTag || null;
  const {
    ipStackApiKey,
  } = SDG.Data;
  const storage = window.sessionStorage;
  const ipStackDataJson = storage?.getItem('ipStackData');
  const ipStackData = ipStackDataJson ? JSON.parse(ipStackDataJson) : null;
  let { state: ipStackState } = ipStackData || {};
  let stores = [];

  /**
   * Init
   */
  async function init() {
    setCustomer();
    setAllStores();

    if (!$footer) return;

    observeFooterIntersect();
  }

  function observeFooterIntersect() {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const handleIntersect = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                initFooterWidget(position);
              },
              (error) => {
                console.error(error);
                initFooterWidget();
              },
            );
          } else {
            console.warn('Geolocation is not available');
            initFooterWidget();
          }

          observer.unobserve($footer);
        }
      });
    };

    const footerObserver = new IntersectionObserver(handleIntersect, options);
    footerObserver.observe($footer);
  }

  async function setCustomer() {
    const zipcode = sessionStorage?.getItem('SDG-zip');

    if (zipcode && zipcode !== '') return;

    try {
      const ipResponse = await fetch(`http://api.ipstack.com/check?access_key=${ipStackApiKey}`) || {};
      const customerLocation = await ipResponse?.json() || null;

      if (customerLocation) {
        const { zip } = customerLocation;

        if (zip) sessionStorage?.setItem('SDG-zip', zip);
      }
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Initialize footer store locator
   */
  async function initFooterWidget(geolocationPosition = null) {
    if (storage) {
      try {
        stores = storage.getItem('nearestStoresData');

        if (stores?.length) {
          stores = JSON.parse(stores);
        }
        setStoreData(geolocationPosition);
      } catch (err) {
        console.error(err);
        stores = null;
        setDefaultStore();
      }
    } else {
      setDefaultStore();
    }
  }

  /**
   * Set the footer Stockist store data
   */
  async function setStoreData(geolocationPosition = null) {
    // Get previously saved stores, if any
    // Results are saved in SesssionStorage
    let coords = null;

    if (geolocationPosition !== null) {
      coords = {
        latitude: geolocationPosition.coords.latitude,
        longitude: geolocationPosition.coords.longitude,
      };
    }

    if (storage) {
      try {
        stores = storage.getItem('nearestStoresData');

        if (stores?.length) {
          stores = JSON.parse(stores);
        }
      } catch (e) {
        console.warn(e);
        stores = null;
      }
    }

    if (stores?.length > 0) {
      // Show the previously saved stores
      displayNearestResult(stores[0]);
    } else {
      // Set IPStack data
      await fetch(`http://api.ipstack.com/check?access_key=${ipStackApiKey}`)
        .then((resp) => resp.json())
        .then(async (ipStackResp) => {
          if (coords === null) {
            // If no Geolocation data set coords from IPStack data
            const { latitude, longitude } = ipStackResp;
            coords = { latitude, longitude };
          }

          if (ipStackState !== ipStackResp.region_code) {
            ipStackState = ipStackResp.region_code;
            storage?.setItem('ipStackData', JSON.stringify(ipStackResp));
          }

          const { latitude, longitude } = coords;

          // Get Stockist data
          await fetch(`https://stockist.co/api/v1/${widgetTag}/locations/search?latitude=${latitude}&longitude=${longitude}`)
            .then((resp) => resp.json())
            .then((searchResult) => {
              stores = searchResult.locations;
              const nearestStore = stores[0];

              if (storage) storage.setItem('nearestStoresData', JSON.stringify(stores));
              displayNearestResult(nearestStore);
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    }
  }

  /**
   * Set the footer default 'Far Store' data
   */
  async function setDefaultStore() {
    if (storage) storage.setItem('nearestStoresData', null);

    const result = await fetch(`https://stockist.co/api/v1/${widgetTag}/locations/all`)
      .then((searchResult) => searchResult.json())
      .catch((err) => console.error(err));

    buildDefaultFooterStore(result);
  }

  /**
   * Set all stores
   */
  async function setAllStores() {
    const result = await fetch(`https://stockist.co/api/v1/${widgetTag}/locations/all`)
      .then((searchResult) => searchResult.json())
      .catch((err) => console.error(err));
    if (result) {
      storage.setItem('allStores', JSON.stringify(result));
    }
  }

  /**
   * Display the footer store results
   */
  function displayNearestResult(store) {
    if (store && store.distance <= 75) {
      buildNearFooterStore(store);
    } else {
      setDefaultStore();
    }
  }

  /**
   * Build Default Footer Stores
   * @param {Object} Stockist store data
   * @return {HTMLElement} element
   */
  function buildDefaultFooterStore(data) {
    const count = data.length;
    const $stockistStores = $footer.querySelector(`.${c.dom.footerStores}`);
    const $stockistContent = $stockistStores ? $stockistStores.querySelector(`.${c.dom.footerContent}`) : null;
    const $stockistDescription = $stockistContent ? $stockistContent.querySelector(`.${c.dom.footerDescription}`) : null;
    const $stockistLinks = $stockistContent ? $stockistContent.querySelector(`.${c.dom.footerStoresLinks}`) : null;
    const { storeLocatorUrl } = $stockistContent.dataset;

    if (!$stockistDescription || !$stockistLinks) return;

    __.removeClass($stockistLinks, 'footer__stores-links--near');
    __.removeClass($stockistContent, 'footer__stores-content--near');
    __.addClass($stockistLinks, 'footer__stores-links--far');
    __.addClass($stockistContent, 'footer__stores-content--far');

    const defaultDescription = `We're not quite in your neighborhood yet. Check out our other ${count} locations.`;
    const defaultLinks = `<ul class="footer__stores-list">
      <li class="footer__stores-listitem">
        <a
          class="link__2"
          href="${storeLocatorUrl}"
          title="${storeLocatorUrl}"
          target="_blank"
        >
          Find A Store
        </a>
      </li>
    </ul>`;

    $stockistDescription.innerHTML = defaultDescription;
    $stockistLinks.innerHTML = defaultLinks;
  }

  /**
   * Build Default Footer Stores
   * @param {Object} Stockist store data
   * @return {HTMLElement} element
   */
  function buildNearFooterStore(data) {
    const storeName = data.name;
    const storeAddressLine1 = data.address_line_1;
    const storeCity = data.city;
    const storeState = data.state;
    const storeParameters = `${storeAddressLine1},+${storeCity},+${storeState}`;
    const googleMapsUrl = `https://www.google.com/maps/dir/${storeParameters}`;
    const link = data.website;

    const $stockistStores = $footer.querySelector(`.${c.dom.footerStores}`);
    const $stockistContent = $stockistStores
      ? $stockistStores.querySelector(`.${c.dom.footerContent}`)
      : null;
    const $stockistDescription = $stockistContent
      ? $stockistContent.querySelector(`.${c.dom.footerDescription}`)
      : null;
    const $stockistLinks = $stockistContent
      ? $stockistContent.querySelector(`.${c.dom.footerStoresLinks}`)
      : null;
    const { storeLocatorUrl } = $stockistContent.dataset;

    if (!$stockistDescription || !$stockistLinks) return;

    __.removeClass($stockistLinks, c.cls.footerStoresLinksFar);
    __.removeClass($stockistContent, c.cls.footerStoresContentFar);
    __.addClass($stockistLinks, c.cls.footerStoresLinksNear);
    __.addClass($stockistContent, c.cls.footerStoresContentNear);

    const defaultDescription = `Visit us at:<a href="${link}" class="footer__stores-description-store">${storeName}</a>`;
    const defaultLinks = `<ul class="footer__stores-list">
      <li class="footer__stores-listitem">
        <a
          class="link__2"
          href="${googleMapsUrl}"
          title="${googleMapsUrl}"
          target="_blank"
        >
          Get Directions
        </a>
      </li>
      <li class="footer__stores-listitem">
        <a
          class="link__2"
          href="${storeLocatorUrl}"
          title="${storeLocatorUrl}"
          target="_blank"
        >
          Find Another Store
        </a>
      </li>
    </ul>`;

    $stockistDescription.innerHTML = defaultDescription;
    $stockistLinks.innerHTML = defaultLinks;
  }

  return init();
}

export default storeLocator;
