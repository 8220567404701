/**
 * Toggle Dropdown
 */
SDG.toggleDropdown = function (opts) {
  const config = {
    cb: {
      before_open: null,
      after_open: null,
      before_close: null,
      after_close: null,
    },
    click_outside: {
      enable: true,
      exclude_selectors: '', // defaults to c.dom.btn, see 'excludedSelectors' below
    },
    cls: {
      active: 'is-active',
      visible: 'is-visible',
    },
    dom: {
      btn: 'menuShow',
      menu: 'menu',
    },
    timer: {
      close: null, // Delay for close animation
      default: 300, // Default delay
      open: null, // Delay for open animation
    },
  };

  // Shorthand config
  let c = config;
  if (opts) c = __.extend(config, opts);

  // Cached globals
  const menu = document.getElementById(c.dom.menu);
  const btn = document.getElementById(c.dom.btn);
  const excludedSelectors = c.click_outside && !c.click_outside.exclude_selectors ? `#${c.dom.btn}` : c.click_outside.exclude_selectors;

  // Check callbacks
  const hasBeforeOpenCallback = typeof c.cb.before_open === 'function';
  const hasAfterOpenCallback = typeof c.cb.after_open === 'function';
  const hasBeforeCloseCallback = typeof c.cb.before_close === 'function';
  const hasAfterCloseCallback = typeof c.cb.after_close === 'function';

  /**
   * Init
   */
  function init() {
    if (menu) {
      mapTimers();
      bindEvents();
      kbEvent();
      callbacks();
    }
  }

  function bindEvents() {
    __.addEvent({
      id: c.dom.btn,
      event: 'click',
      fn: toggleMenu,
    });

    if (c.click_outside.enable) {
      __.clickOutside({
        callback: toggleMenu,
        condition: isActive,
        exclude_selectors: excludedSelectors,
        selector: `#${c.dom.menu}`,
      });
    }
  }

  function toggleMenu() {
    if (isActive()) {
      closeMenu();
    } else {
      openMenu();
    }
  }

  function openMenu() {
    __.addClass(btn, c.cls.active);
    __.addClass(menu, c.cls.visible);

    // Trigger before open callback
    if (hasBeforeOpenCallback) {
      __.trigger(btn, 'beforeToggleOpen');
    }

    // Trigger after open callback
    if (hasAfterOpenCallback) {
      setTimeout(() => __.trigger(btn, 'afterToggleOpen'), c.timer.open);
    }
  }

  function closeMenu() {
    __.removeClass(btn, c.cls.active);
    __.removeClass(menu, c.cls.visible);

    // Trigger before close callback
    if (hasBeforeCloseCallback) {
      __.trigger(btn, 'beforeToggleClose');
    }

    // Trigger after close callback
    if (hasAfterCloseCallback) {
      setTimeout(() => __.trigger(btn, 'afterToggleClose'), c.timer.close);
    }
  }

  /**
   * keyboard event
   * @description add key board event on keyup
   */
  function kbEvent() {
    document.addEventListener('keyup', getKey);
  }

  /**
   * get key pressed
   * @description function that's executed on keypress. checks the keycode value,
   * if it matches with the escape key close all modals.
   */
  function getKey(e) {
    if (e.keyCode === 27) {
      closeMenu();
    }
  }

  function isActive() {
    return __.hasClass(menu, c.cls.visible);
  }

  /**
   * callbacks
   * @type {function}
   */
  function callbacks() {
    if (hasBeforeOpenCallback) {
      __.addEvent({
        id: c.dom.btn,
        event: 'beforeToggleOpen',
        fn: c.cb.before_open,
      });
    }

    if (hasAfterOpenCallback) {
      __.addEvent({
        id: c.dom.btn,
        event: 'afterToggleOpen',
        fn: c.cb.after_open,
      });
    }

    if (hasBeforeCloseCallback) {
      __.addEvent({
        id: c.dom.btn,
        event: 'beforeToggleClose',
        fn: c.cb.before_close,
      });
    }

    if (hasAfterCloseCallback) {
      __.addEvent({
        id: c.dom.btn,
        event: 'afterToggleClose',
        fn: c.cb.after_close,
      });
    }
  }

  /**
   * Map timers
   * @description setting close timer and open timer if they are not set
   */
  function mapTimers() {
    if (c.timer.close === null) c.timer.close = c.timer.default;
    if (c.timer.open === null) c.timer.open = c.timer.default;
  }

  return {
    init,
  };
};
