/**
 * Promo Bar
 * @param {object} opts
 */
function promoBar(opts) {
  const config = {
    dom: {
      header: 'header',
      headerContainer: 'header__container',
      main: 'main',
      nav: 'navigation',
      search: 'searchNavigation',
      promoBar: 'promoBar',
      promoBarCell: 'promo-bar__carousel-cell',
    },
    cls: {
      active: 'is-active',
      hide: 'hide',
      hasPromoBar: 'has-promo-bar',
      headerPromo: 'header--promo-bar',
      navPromo: 'navigation--promo-bar',
      mainPromo: 'page-wrap--promo-bar',
    },
  };

  const c = __.extend(config, opts);
  const $header = document.getElementById(c.dom.header);
  const $main = document.getElementById(c.dom.main);
  const $nav = document.getElementById(c.dom.nav);
  const $search = document.getElementById(c.dom.search);
  const $promoBar = document.getElementById(c.dom.promoBar);
  let view;

  /**
   * Init
   */
  function init() {
    if (!$header
      || !$promoBar
      || !$nav
      || !$main
    ) return;

    adjustHeader();
    responsive();
    __.windowResize(responsive);
  }

  /**
   * Responsive
   * @type {Function}
   */
  function responsive() {
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view === 'desktop') return;
        view = 'desktop';
        adjustHeader();
      },
    });

    __.mq({
      view: 'mobile',
      callback: () => {
        if (view === 'mobile') return;
        view = 'mobile';
        adjustHeader();
      },
    });
  }

  /**
   * Adjust Header
   */
  function adjustHeader() {
    const $headerContainer = $header.querySelector(`.${c.dom.headerContainer}`);
    const $promoBarCell = $promoBar.querySelector(`.${c.dom.promoBarCell}`);
    const promoBarHeight = $promoBarCell
      ? $promoBarCell.clientHeight
      : 0;

    __.addClass(document.body, c.cls.hasPromoBar);
    __.addClass($header, c.cls.headerPromo);
    __.addClass($nav, c.cls.navPromo);
    __.addClass($main, c.cls.mainPromo);
    if ($search) {
      __.addClass($search, c.cls.navPromo);
    }

    if ($headerContainer) $headerContainer.style.height = `calc(100% - ${promoBarHeight}px)`;
  }

  return init();
}

export default promoBar;
