/**
 * Store Email Sign Up
 */
function storeEmailSignUp() {
  const config = {
    dom: {
      checkboxes: '.store-email-sign-up__block-checkbox-input',
      imageBlocks: '.store-email-sign-up__block-image-container',
      defaultImageBlock: '.store-email-sign-up__section-default-image',
      forms: [
        'klaviyoForm1',
        'klaviyoForm2',
        'klaviyoForm3',
      ],
    },
  };

  const c = __.extend(config);

  const $checkboxes = document.querySelectorAll(c.dom.checkboxes);
  const $defaultImageBlock = document.querySelector(c.dom.defaultImageBlock);
  const $imageBlocks = document.querySelectorAll(c.dom.imageBlocks);
  const $forms = c.dom.forms.map((formId) => document.getElementById(formId));

  function init() {
    addEvents();
  }

  function addEvents() {
    $checkboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', handleCheckboxChange);
    });
  }

  function handleCheckboxChange() {
    const selectedLocation = this.getAttribute('data-location');

    // Hide default image block
    __.addClass($defaultImageBlock, 'hide');

    // Check each image block, display the one that matches the data-location and hide the others
    $imageBlocks.forEach((imageBlock) => {
      if (imageBlock.getAttribute('data-location') === selectedLocation) {
        __.removeClass(imageBlock, 'hide');
      } else {
        __.addClass(imageBlock, 'hide');
      }
    });

    // Hide all forms initially
    $forms.forEach((form) => __.addClass(form, 'hide'));

    // Display the form that matches the data-location
    $forms.forEach((form) => {
      if (form.getAttribute('data-location') === selectedLocation) {
        __.removeClass(form, 'hide');
      }
    });
  }

  // Start the setup
  init();
}

export default storeEmailSignUp;
