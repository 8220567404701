import Flickity from 'flickity';

/*
* Submit Your Art
* @param {object} opts
*/

function submitYourArt(opts) {
  const config = {
    dom: {
      section: 'js-submit-your-art',
      carousel: 'js-submit-your-art-content-wrapper',
      carouselCell: 'js-submit-your-art-content',
    },
    flickityOpts: {
      prevNextButtons: false,
      pageDots: true,
      draggable: true,
      wrapAround: true,
      imagesLoaded: true,
      lazyLoad: true,
    },
    cls: {
      carouselMode: 'carousel-mode',
    },
  };

  const c = __.extend(config, opts);
  const $sections = document.querySelectorAll(`.${c.dom.section}`);
  const $carousels = document.querySelectorAll(`.${c.dom.carousel}`);

  /**
   * Init
   */
  function init() {
    if (!$sections?.length) return;
    toggleCarousel();

    __.windowResize(toggleCarousel);
  }

  /**
  * Toggle Carousel
  */
  function toggleCarousel() {
    if (!$carousels?.length) return;
    const windowWidth = window.innerWidth;

    $carousels.forEach(($carousel) => {
      const carouselWidth = calculateCarouselWidth($carousel);
      let flickity = Flickity.data($carousel);

      if (windowWidth <= carouselWidth) {
        __.addClass($carousel, c.cls.carouselMode);

        if (!flickity) {
          flickity = new Flickity($carousel, c.flickityOpts);

          // Set height of slides to inherit after initializing Flickity
          const $cells = $carousel.querySelectorAll(`.${c.dom.carouselCell}`);
          $cells.forEach((cell) => {
            cell.style.height = 'inherit';
          });
        }
      } else {
        __.removeClass($carousel, c.cls.carouselMode);

        if (flickity) {
        // Remove height style from slides when destroying Flickity
          const $cells = $carousel.querySelectorAll(`.${c.dom.carouselCell}`);
          $cells.forEach((cell) => {
            cell.style.height = '';
          });

          flickity.destroy();
        }
      }
    });
  }

  /**
  * Calculate Carousel Width
  */
  function calculateCarouselWidth($carousel) {
    const $cells = $carousel.querySelectorAll(`.${c.dom.carouselCell}`);
    let cellsWidth = 0;

    $cells.forEach((cell) => {
      cellsWidth += cell.offsetWidth;
    });

    return cellsWidth;
  }

  return init();
}

export default submitYourArt;
