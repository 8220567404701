/**
 * get sized image url
 * @param  {string} imgUrl
 * @param  {string} size   image width, e.x. '250x'
 * @return {string}        resized image url
 */
__.getSizedImageUrl = function(imgUrl, size) {
  let i;
  let n;
  let r;

  if (! imgUrl) return '//cdn.searchspring.net/ajax_search/img/missing-image-75x75.gif';
  if (! size) return imgUrl;
  if (size === 'master') return __.removeProtocol(imgUrl);

  n = imgUrl.match(/\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif|webp)(\?v=\d+)?$/i);

  if (n !== null) {
    r = imgUrl.split(n[0]);
    i = n[0];
    return __.removeProtocol(r[0] + '_' + size + i);
  }
};
