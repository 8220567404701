function bagItem(opts) {
  const config = {
    dom: {
      bag_item: '.js-bag-item',
      bag_item_accordion: '.js-bag-item-acc',
    },
  };

  const c = __.extend(config, opts);
  const $bagItemAccordions = [...document.querySelectorAll(c.dom.bag_item_accordion)] || [];

  function init() {
    addEvents();
  }

  function addEvents() {
    if ($bagItemAccordions?.length > 0) {
      initAccordions();
    }
  }

  function initAccordions() {
    $bagItemAccordions.forEach(($bagItemAccordion) => {
      const bagItemAccordion = SDG.accordion({
        dom: {
          id: $bagItemAccordion.id,
        },
      });
      bagItemAccordion.init();
    });
  }

  return init();
}

export default bagItem;
