/**
 * Global UI
 */

import articleProgressBar from './article-progress-bar';
import loadScripts from './load-scripts';
import bagForm from './bag-add-to';
import footer from './footer';
import howItWorks from './how-it-works';
import klaviyo from './klaviyo';
import navigation from './navigation';
import brandHero from './brand-hero';
import beforeAfterCarousel from './before-after-carousel';
import blogPostCarousel from './blog-post-carousel';
import fourUpCarousel from './four-up-carousel';
import threeUpCarousel from './three-up-carousel';
import goCustom from './go-custom';
import materialTouts from './material-touts';
import pricingChart from './pricing-chart';
import promoBar from './promo-bar';
import promoBarCarousel from './promo-bar-carousel';
import promoCarousel from './promo-carousel';
import promoTab from './promo-tab';
import quote from './quote';
import search from './search';
import shopTheWallTabbed from './shop-the-wall-tabbed';
import shopTheWallSingle from './shop-the-wall-single';
import storeLocator from './store-locator';
import storytelling from './storytelling';
import testimonials from './testimonials';
import valueProps from './value-props';
import categoryCarousel from './category-carousel';
import cidCheck from './cid-check';
import frameAnything from './frame-anything';
import galleryCarousel from './gallery-carousel';
import getInspiredCarousel from './get-inspired-carousel';
import manifesto from './manifesto';
import meetOurDesignersCarousel from './meet-our-designers-carousel';
import productsCarousel from './products-carousel';
import productsFiltersCarousel from './products-filters-carousel';
import productsFiltersCarouselDropdown from './products-filters-carousel-dropdown';
import productItemApp from './product-item-app';
import relatedArticles from './related-articles';
import shortlistCreate from './shortlist-create';
import submitYourArt from './submit-your-art';
import checkCartAttributes from './check-cart-attributes';
import checkWindowLocation from './check-window-location';
import dancingFrames from './dancing-frames';
import fourSixtyUGC from './foursixty-ugc';
import howItWorksMedia from './how-it-works-media';
import verticalTextImage from './vertical-text-image';
import lottie from './lottie';
import curlyArrowAnimation from './curly-arrow-animation';
import consultationFees from './consultation-fees';
import faqAccordion from './faq-accordion';
import materials from './materials';
import materialsTooltip from './materials-tooltip';
import storeEmailSignUp from './store-email-sign-up';
import shopTheStoryCarousel from './shop-the-story-carousel';
import heroCategoryTile from './hero-category-tile';
import galleryCategoryCarousel from './gallery-category-carousel';
import hero from './hero';
import videoEmbed from './video-embed';
import madlib from './madlib';
import collectionSection from './collection-section';
import productConfigurator from './product-configurator';
import subNavigation from './sub-navigation';
import setUserMarketByGeo from './user-market-by-geo';
import hotSpot from './hot-spots';

function globalUi() {
  const placeholder = SDG.placeholder({
    id: 'main',
  });

  // Load scripts, this should be executed first
  // loadScripts();

  // Placeholder
  placeholder.init();

  // CID Check
  cidCheck(); // needs to be before bag functions

  // Bag
  SDG.Bag.init();

  // Add to bag
  bagForm();

  // Navigation
  navigation();

  // Search
  search();

  // Footer
  footer();

  // Klaviyo
  klaviyo();

  // User Geolocation
  setUserMarketByGeo();

  // Stockist Widget
  storeLocator();

  // How It Works
  howItWorks();

  // Testimonials
  testimonials();

  // Value Props
  valueProps();

  // Article Progress Bar
  articleProgressBar();

  // Before After Carousel
  beforeAfterCarousel();

  // Blog Post Carousel
  blogPostCarousel();

  // Brand Hero
  brandHero();

  // 4 Up Carousel
  brandHero();

  // 3 Up Carousel
  threeUpCarousel();

  // 4 Up Carousel
  fourUpCarousel();

  // Go Custom
  goCustom();

  // Manifesto
  manifesto();

  // Material Touts
  materialTouts();

  // Pricing Chart
  pricingChart();

  // Promo Tab
  promoTab();

  // Promo Carousel
  promoCarousel();

  // Promo Bar
  promoBar();

  // Products Carousel Products
  productItemApp({
    dom: {
      parent: 'productCarousel',
    },
  });

  // Products Carousel
  productsCarousel();

  // Products Filters Carousel Products
  productItemApp({
    dom: {
      parent: 'productFilterCarousel',
    },
  });

  // Products Filters Carousel
  productsFiltersCarousel();

  // Products Filters Carousel Dropdown Products
  productItemApp({
    dom: {
      parent: 'productFilterCarouselDropdown',
    },
  });

  // Collection Products
  productItemApp({
    dom: {
      parent: 'collection',
    },
  });

  // HotSpots Section
  hotSpot();

  // Products Filters Carousel Dropdown
  productsFiltersCarouselDropdown();

  // Quote
  quote();

  // Storytelling
  storytelling();

  // Video
  SDG.videoHTML();

  // Hero Carousel
  hero();

  // Hero + Category Tiles Carousel
  heroCategoryTile();

  // Category Carousel
  categoryCarousel();

  // Frame Anything
  frameAnything();

  // Gallery Carousel
  galleryCarousel();

  // Get Inspired Carousel
  getInspiredCarousel();

  // Meet Our Designers Carousel
  meetOurDesignersCarousel();

  // Promo Bar Carousel
  promoBarCarousel();

  // Promo Bar Countdown Timer
  const promoBarTimer = SDG.countdownTimer({
    dom: {
      id: 'promoBarCountdown',
    },
  });
  promoBarTimer.init();

  // FourSixty UGC
  fourSixtyUGC();

  // Shop The Wall Tabbed
  shopTheWallTabbed();

  // Shop The Wall Single
  shopTheWallSingle();

  // Related Articles
  relatedArticles();

  // Shortlist Page
  shortlistCreate();

  // Submit Your Art
  submitYourArt();

  // Check Cart Attributes
  checkCartAttributes();

  // Check Window Location
  checkWindowLocation();

  // Dancing Frames
  dancingFrames();

  // How It Works Media
  howItWorksMedia();

  // Vertical Text + Image
  verticalTextImage();

  // Lottie Animation
  lottie();

  // Curly Arrow Animation
  curlyArrowAnimation();

  // Consultation Fees
  consultationFees();

  // FAQ Accordion
  faqAccordion();

  // Materials
  materials();

  // Materials Tooltip
  materialsTooltip();

  // Store Email Sign Up
  storeEmailSignUp();

  // Shop The Story Carousel
  shopTheStoryCarousel();

  // Gallery Category Carousel
  galleryCategoryCarousel();

  // Video Embed
  videoEmbed();

  // Madlib
  madlib();

  // Collection Section
  collectionSection();

  // Product Configurator
  productConfigurator();

  // Sub Navigation
  subNavigation();
}

export default globalUi;
