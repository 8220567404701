/**
 * Shop The Wall – Tabbed
 * @param {object} opts
 */

function shopTheWallTabbed(opts) {
  const config = {
    dom: {
      tabs: 'js-shop-the-wall-tabbed-tabs',
      btn: 'js-shop-the-wall-tabbed-btn',
      hotspot: 'js-shop-the-wall-tabbed-hotspot',
      media: 'js-shop-the-wall-tabbed-media',
      quickview: 'js-quickview-button',
      wrapper: 'js-shop-the-wall-tabbed',
    },
    cls: {
      active: 'active',
      quickviewActive: 'quickview-active',
    },
  };
  const c = __.extend(config, opts);

  // global elements
  const $wrappers = document.body.querySelectorAll(`.${c.dom.wrapper}`);

  /**
   * Init
   */
  function init() {
    if (!$wrappers?.length) return;
    addEvents();
  }

  /**
   * Add Events
   */
  function addEvents() {
    $wrappers.forEach(($wrapper) => {
      const quickview = SDG.quickview({
        type: 'multi',
        wrapper: $wrapper,
      });
      quickview.init();

      __.addEvent({
        id: $wrapper,
        className: c.dom.btn,
        event: 'click',
        fn: handleBtnClick,
      });

      __.addEvent({
        id: $wrapper,
        className: c.dom.hotspot,
        event: 'click',
        fn: handleHotspotClick,
      });

      __.addEvent({
        id: $wrapper,
        className: c.dom.quickview,
        event: 'click',
        fn: quickview.open,
      });
    });
  }

  /**
   * Handle Btn Click
   * @param {Object} e - click event
   */
  function handleBtnClick(e) {
    const $wrapper = __.parents(e.target, `.${c.dom.wrapper}`)[0];
    const $btn = !__.hasClass(e.target, c.dom.btn)
      ? __.parents(e.target, `.${c.dom.btn}`)[0]
      : e.target;

    // active tab is being clicked
    if (__.hasClass($btn, c.cls.active)) return;

    toggleActiveContent($wrapper, $btn);
  }

  /**
   * Handle Btn Click
   * @param {Node} $wrapper - wrapper element
   * @param {Node} $btn - button element
   */
  function toggleActiveContent($wrapper, $btn) {
    const { blockId } = $btn.dataset;
    const $media = $wrapper.querySelector(`.${c.dom.media}[data-block-id="${blockId}"]`);
    const $hotspots = $media.querySelectorAll(`.${c.dom.hotspot}`);
    const $activeMedia = $wrapper.querySelector(`.${c.dom.media}.${c.cls.active}`);
    const $activeBtn = $wrapper.querySelector(`.${c.dom.btn}.${c.cls.active}`);
    const $activeHotspots = $wrapper.querySelectorAll(`.${c.dom.hotspot}.${c.cls.active}`);

    // toggle active content
    if ($btn) __.addClass($btn, c.cls.active);
    if ($media) __.addClass($media, c.cls.active);
    if ($hotspots?.length) {
      $hotspots.forEach(($hotspot) => {
        __.addClass($hotspot, c.cls.active);
      });
    }
    if ($activeMedia) __.removeClass($activeMedia, c.cls.active);
    if ($activeBtn) __.removeClass($activeBtn, c.cls.active);
    if ($activeHotspots?.length) {
      $activeHotspots.forEach(($activeHotspot) => {
        __.removeClass($activeHotspot, c.cls.active);
      });
    }
  }

  /**
   * Handle Hotspot Click
   * @param {Object} e - click event
   */
  function handleHotspotClick(e) {
    const { target } = e;
    const $wrapper = __.parents(target, `.${c.dom.wrapper}`)[0];
    const $hotspot = __.hasClass(target, c.dom.hotspot)
      ? target
      : __.parents(target, c.dom.hotspot)[0];
    const $activeHotspot = $wrapper?.querySelector(`.${c.dom.hotspot}.${c.cls.quickviewActive}`);
    if ($activeHotspot) __.removeClass($activeHotspot, c.cls.quickviewActive);
    if ($hotspot) __.addClass($hotspot, c.cls.quickviewActive);
  }

  return init();
}

export default shopTheWallTabbed;
