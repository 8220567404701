import Flickity from 'flickity';

/**
 * Product Filters Carousel
 * @param {object} opts
 */
function productsFiltersCarousel(opts) {
  const config = {
    dom: {
      wrapper: 'js-products-filters-carousel-wrapper',
      carousel: 'js-products-filters-carousel',
      carouselCell: 'js-pi',
      swatches: 'js-swatches',
      swatch: 'js-swatch',
      shopAll: 'js-shop-all',
      section: 'js-product-carousel-section',
    },
    cls: {
      loading: 'is-loading',
      active: 'is-active',
      fullHeight: 'full-height',
      hide: 'hide',
    },
  };
  const c = __.extend(config, opts);

  const flickityOpts = {
    contain: true,
    draggable: true,
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
  };

  // global elements
  const $wrappers = document.body.querySelectorAll(`.${c.dom.wrapper}`);
  const $swatchesWrapper = document.getElementById(c.dom.swatches);
  const $swatches = $swatchesWrapper?.querySelectorAll(`.${c.dom.swatch}`);
  const flickityCarousels = [];
  let view;

  function addEvents() {
    [...$swatches].forEach((swatch, index) => {
      __.addEvent({
        id: `js-swatch-${index + 1}`,
        event: 'click',
        fn: (e) => {
          e?.preventDefault();
          handleSwatchClick(e, index);
        },
      });
    });
  }

  function handleSwatchClick(e, index) {
    if (!e?.currentTarget) return;

    const $mainSection = __.parents(e.currentTarget, `.${c.dom.section}`)[0] || null;
    if (!__.hasClass(e.currentTarget, c.cls.active)) {
      const i = index + 1;
      const collections = $mainSection?.querySelectorAll(`.${c.dom.wrapper}`) || [];
      const activeCollection = collections[i];

      $swatches.forEach(($swatch) => {
        __.removeClass($swatch, c.cls.active);
      });

      __.addClass(e.currentTarget, c.cls.active);

      [...collections].forEach((collection) => {
        __.removeClass(collection, c.cls.active);
      });
      __.addClass(activeCollection, c.cls.loading);
      rebuildFlickity(activeCollection);
    }
  }

  /**
   * On Window Resize
   */
  function onWindowResize() {
    // desktop
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';

          rebuildFlickity();
        }
      },
    });

    // mobile
    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';

          rebuildFlickity();
        }
      },
    });
  }

  /**
   * rebuild flickity
   */
  function rebuildFlickity(initCarousel = false) {
    [...$wrappers].forEach(($wrapper, index) => {
      const activeCarousel = flickityCarousels[index];
      if (activeCarousel) activeCarousel.destroy();
      const $carousel = $wrapper.querySelector(`.${c.dom.carousel}`);
      const cells = $carousel.querySelectorAll(`.${c.dom.carouselCell}`);

      [...cells].forEach(($item) => {
        __.removeClass($item, c.cls.fullHeight);
      });

      // only build carousel if there are more than 4 cells
      if (!$carousel || cells.length < 4) {
        const $shopAll = $wrapper.querySelector(`.${c.dom.shopAll}`);
        if ($shopAll) __.addClass($shopAll, c.cls.hide);
        return;
      }
      flickityOpts.on = {
        ready: () => {
          [...cells].forEach(($item) => {
            __.addClass($item, c.cls.fullHeight);
          });
        },
      };

      // Set the offset divisor based on viewport size
      let offsetDivisor;
      if (view === 'desktop') {
        offsetDivisor = 1.33;
      }

      if (view === 'mobile') {
        offsetDivisor = 1.66;
      }

      const $flickityCell = $carousel.querySelector(`.${c.dom.carouselCell}`);
      const leftOffset = setFlickityOffset($flickityCell?.offsetWidth, offsetDivisor);
      const carouselOffset = $carousel.offsetWidth;
      const cellAlign = leftOffset / carouselOffset;
      const updatedFlickityOpts = __.extend(flickityOpts, { cellAlign });

      if (activeCarousel) {
        flickityCarousels[index] = new Flickity($carousel, updatedFlickityOpts);

        if (initCarousel) {
          __.addClass(initCarousel, c.cls.active);
          __.removeClass(initCarousel, c.cls.loading);
        }
      } else {
        flickityCarousels.push(new Flickity($carousel, updatedFlickityOpts));

        if (initCarousel) {
          __.addClass(initCarousel, c.cls.active);
          __.removeClass(initCarousel, c.cls.loading);
        }
      }
    });
  }

  /**
   * Set the flickity carousel offset
   */
  function setFlickityOffset(flickityCellOffsetWidth, flickityOffsetDivisor) {
    Flickity.Cell.prototype.setDefaultTarget = () => {
      const marginProperty = this.parent.originSide === 'left' ? 'marginLeft' : 'marginRight';
      this.target = this.x + this.size[marginProperty];
    };

    return flickityCellOffsetWidth / flickityOffsetDivisor;
  }

  /**
   * Init
   */
  function init() {
    if (!$wrappers?.length) return;
    addEvents();
    onWindowResize();
    __.windowResize(onWindowResize);
  }

  return init();
}

export default productsFiltersCarousel;
