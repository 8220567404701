/**
 * remove class by prefix
 * @param {element} el
 * @param {string} prefix
 */
__.removeClassByPrefix = function(el, prefix) {
  const regx = new RegExp('\\b' + prefix + '.*?\\b', 'g');
  el.className = el.className.replace(regx, '');
  return el;
}
