function collectionSection() {
  const config = {
    dom: {
      collectionSectionProductsGrid: 'js-collection-section-products-grid',
    },
    cls: {
      productsPerRowOne: 'products-per-row--one',
      productsPerRowTwo: 'products-per-row--two',
      productsPerRowThree: 'products-per-row--three',
      productsPerRowFour: 'products-per-row--four',
    },
  };

  // Globals
  const c = config;
  const $productsGrids = [...document.querySelectorAll(`.${c.dom.collectionSectionProductsGrid}`)];
  let view;

  /**
   * Init
   */
  function init() {
    if (!$productsGrids?.length) {
      return;
    }

    responsive();
    __.windowResize(responsive);
  }

  /**
   * Responsive
   */
  function responsive() {
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          $productsGrids.forEach(($productsGrid) => {
            $productsGrid.classList.remove(
              c.cls.productsPerRowOne,
              c.cls.productsPerRowTwo,
              c.cls.productsPerRowThree,
            );

            $productsGrid.classList.add(c.cls.productsPerRowFour);
          });
        }
      },
    });

    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
          $productsGrids.forEach(($productsGrid) => {
            $productsGrid.classList.remove(
              c.cls.productsPerRowOne,
              c.cls.productsPerRowThree,
              c.cls.productsPerRowFour,
            );

            $productsGrid.classList.add(c.cls.productsPerRowTwo);
          });
        }
      },
    });
  }

  return init();
}

export default collectionSection;
