<template>
  <section class="product-configurator__inner container">
    <div class="product-configurator__media desktop-only">
      <ProductConfiguratorGallery
        :canPrintArtwork="canPrintArtwork"
        :mainImage="mainImage"
        :frameSpec="frameSpec"
        :frameSpecUrl="frameSpecUrl"
        :title="title"
        :editImageUrl="editImageUrl"
        :isUploadProcessing="isUploadProcessing"
        :clearFrameSpec="clearFrameSpec"
        :toggleGallery="toggleGallery"
        :uploadPercent="uploadPercent"
        :resetVariant="reset"
      />
    </div>
    <div class="product-configurator__pv">
      <div class="product-configurator__pv-heading">
        <div>
          <h3
            v-if="title"
            class="product-configurator__pv-title"
          >
            {{ title }}
          </h3>
          <p
            v-if="subtitle"
            class="product-configurator__pv-subtitle"
          >
            {{ subtitle }}
          </p>
        </div>
        <p
          v-if="(currentPrice && !frameSpec)"
          class="product-configurator__pv-price"
        >
          {{ currentPrice }}
        </p>
        <p
          v-else-if="(frameSpec && frameSpecPrice)"
          class="product-configurator__pv-price"
        >
          ${{ frameSpecPrice }}
        </p>
      </div>
      <div class="product-configurator__media mobile-only">
        <ProductConfiguratorGallery
          :canPrintArtwork="canPrintArtwork"
          :mainImage="mainImage"
          :frameSpec="frameSpec"
          :frameSpecUrl="frameSpecUrl"
          :title="title"
          :editImageUrl="editImageUrl"
          :isUploadProcessing="isUploadProcessing"
          :toggleGallery="toggleGallery"
          :uploadPercent="uploadPercent"
          :resetVariant="reset"
        />
      </div>
      <div class="product-configurator__pv-swatches-wrapper">
        <div class="product-configurator__pv-selected">
          <span
            v-if="swatchHeading"
            class="product-configurator__pv-selected-heading"
          >
            {{ swatchHeading }}</span>
          <span
            v-if="selectedColorVariant"
            class="product-configurator__pv-selected-value"
          >
            {{ selectedColorVariant }}</span>
        </div>
        <ProductConfiguratorSwatches
          :colors="colors"
          :noImage="''"
          :selectedColor="selectedColorVariant"
          :isUploadProcessing="isUploadProcessing"
          :onSwatchClick="onSwatchClick"
        />
      </div>
      <div class="product-configurator__pv-size-wrapper">
        <div class="product-configurator__pv-selected product-configurator__pv-selected--size">
          <span
            v-if="sizeHeading"
            class="product-configurator__pv-selected-heading"
          >
            {{ sizeHeading }}
          </span>
          <span
            v-if="selectedVariant?.name"
            class="product-configurator__pv-selected-value"
          >
            {{ selectedVariant.name }}
          </span>
        </div>
        <div
          v-if="(sizes?.length > 0)"
          class="product-configurator__pv-sizes-wrapper"
        >
          <ProductConfiguratorSizes
            :sizes="sizes"
            :selectedSize="selectedVariant"
            :isUploadProcessing="isUploadProcessing"
            :includeLandscape="includeLandscape"
            :includePortrait="includePortrait"
            :onSizeClick="onSizeClick"
          />
        </div>
        <form
          v-if="upsellVariant?.upsell"
          class="product-configurator__upsell"
          autocomplete="off"
        >
          <input
            class="product-configurator__upsell-checkbox"
            type="checkbox"
            id="upsell"
            v-model="addUpsellItem"
          />
          <span class="checkmark"></span>
          <ResponsiveImage
            v-if="(upsellVariant?.upsell_variant?.featured_image
              || upsellVariant?.upsell_product?.featured_image)"
            :image="(upsellVariant?.upsell_variant?.featured_image
              || upsellVariant.upsell_product?.featured_image)"
            :imageAlt="upsellVariant?.upsell?.display_name || 'Add on item'"
            wrapperClass="product-configurator__upsell-image"
            inlineIr="true"
          />
          <label
            v-if="(upsellVariant?.upsell?.display_name)"
            for="upsell"
            class="product-configurator__upsell-price"
          >
            <span class="product-configurator__upsell-price-title">
              {{ updateUpsellDisplayName(upsellVariant?.upsell?.display_name) }}
            </span>
            <br>
            <span
              v-if="(upsellVariant?.upsell_variant?.price)"
              class="product-configurator__upsell-price-value"
            >
              + {{ formatPrice(upsellVariant?.upsell_variant?.price) }}
            </span>
          </label>
        </form>
      </div>
      <section class="product-configurator__actions">
        <span
          v-if="isUploadError || (canPrintArtwork === false)"
          class="product-configurator__actions-error"
        >
          <i class="icon icon--upload-error"></i>
          <span v-html="errorMessage"></span>
        </span>
        <span v-if="(frameSpec && !canPrintArtwork) || !frameSpec">
          <ProductConfiguratorUpload
            :buttonText="uploadButtonText"
            :product="product.productJson"
            :variant="selectedVariant"
            :initArtworkUpload="initArtworkUpload"
            :setErrorMessage="handleUploadError"
            :onUploading="onUploading"
            :sizes="sizes"
            :onSizeClick="onSizeClick"
          />
        </span>
        <span class="product-configurator__actions-items" v-if="frameSpec && canPrintArtwork">
          <button
            ref="addToCartButton"
            class="product-configurator__actions-atc btn btn--primary btn--full"
            @click="addToCart($event)"
          >
            Add to Cart - ${{ frameSpecPrice }}
          </button>
          <a
            v-if="includePersonalization"
            :href="personalizeUrl"
            class="product-configurator__actions-edit btn btn--secondary btn--full"
          >
            <i class="icon product-configurator__actions-edit-icon icon--sparkle-dark"></i>
            <i class="icon product-configurator__actions-edit-icon icon--sparkle-light"></i>
            Personalize
          </a>
        </span>
      </section>
      <ProductConfiguratorAdditions
        v-if="includePersonalization"
        :canPrintArtwork="canPrintArtwork"
        :variant="selectedVariant"
        :lowestPricedVariant="lowestPricedVariant"
        :frameSpec="frameSpec || null"
      />
    </div>
    <ProductConfiguratorGalleryZoom
      :variant="selectedVariant"
      :product="product"
      :frameSpec="frameSpec"
      :hasMaskRender="hasMaskRender"
      :modalOpen="galleryOpen"
      :toggleModal="toggleGallery"
      :mainImage="mainImage"
    />
  </section>
</template>

<script setup>
import {
  computed,
  ref,
  toRefs,
  onMounted,
  onUnmounted,
  toRaw,
  watch,
} from 'vue';
import ResponsiveImage from '../common/responsive-image/ResponsiveImage';
import ProductConfiguratorSwatches from './ProductConfiguratorSwatches';
import ProductConfiguratorSizes from './ProductConfiguratorSizes';
import ProductConfiguratorUpload from './ProductConfiguratorUpload';
import ProductConfiguratorAdditions from './ProductConfiguratorAdditions';
import ProductConfiguratorGallery from './ProductConfiguratorGallery';
import ProductConfiguratorGalleryZoom from './ProductConfiguratorGalleryZoom';
import useUploadedArtwork from '../composables/product-configurator';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

// Reactive properties
const { data } = toRefs(props);
const includePersonalization = ref(data?.value?.include_personalization || false);
const includeLandscape = ref(data?.value?.include_landscape || false);
const includePortrait = ref(true);
const mainImage = ref(data?.value?.product?.productJson?.featured_image || '');
const defaultSelectedColorVariant = data?.value?.product?.variants?.[0];
const addUpsellItem = ref(defaultSelectedColorVariant?.upsell?.upsell_preselected);
const selectedColorVariant = ref(defaultSelectedColorVariant?.variantJson?.option1 || '');
const lowestPriceVariantId = ref(data?.value?.lowest_price_variant);
const sizes = ref([]);
const selectedVariant = ref(null);
const uploadButtonText = ref(data?.value?.upload_button_text || 'Upload Your Artwork');
const errorMessage = ref(data?.value?.error_message || 'Your photo is too small to be printed in this frame. Please <u>upload another photo</u> or try a smaller frame.');
const isUploadError = ref(false);
const uploadPercent = ref(0);
const cartUrlHost = SDG.Data.cartHost || 'https://cart.staging.framebridge.io';
const galleryOpen = ref(false);
const addToCartButton = ref(null);

// Computed properties
const upsellVariant = computed(() => selectedVariant?.value || defaultSelectedColorVariant);
const upsellPrice = computed(() => {
  let upPrice = 0;
  if (addUpsellItem.value && upsellVariant.value?.upsell) {
    upPrice = upsellVariant?.value?.upsell_variant?.price || 0;
  }
  return upPrice;
});
const currentPrice = computed(() => {
  let price = '';
  // setup init price
  if (lowestPrice?.value && highestPrice?.value) {
    price = `${lowestPrice.value} - ${highestPrice.value}`;
  }
  if (!lowestPrice?.value && highestPrice?.value) {
    price = highestPrice.value;
  }
  if (!highestPrice?.value && lowestPrice?.value) {
    price = lowestPrice.value;
  }
  const variant = toRaw(selectedVariant.value);
  const variantPrice = variant?.variantJson?.price || null;

  if (variantPrice) {
    const formattedPrice = __.formatPriceDisplay(variantPrice + upsellPrice.value);
    price = formattedPrice;
  }
  return price;
});
const swatchHeading = computed(() => data?.value?.swatch_heading) || 'Frame Syle';
const sizeHeading = computed(() => data?.value?.size_heading) || 'Frame Size';
const product = computed(() => data?.value?.product) || {};
const variants = computed(() => product?.value?.variants) || [];
const title = computed(() => product?.value?.productJson?.title) || '';
const subtitle = computed(() => product?.value?.metafields?.subtitle) || '';
const lowestPrice = computed(() => product?.value?.price_list?.min_price) || 0;
const highestPrice = computed(() => product?.value?.price_list?.max_price) || 0;
const productImage = computed(() => product?.value?.productJson?.featured_image) || '';
const lowestPricedVariant = computed(() => variants?.value?.find((v) => `${lowestPriceVariantId.value}` === `${v.id}`)) || {};
const media = computed(() => product?.value?.productJson?.media || []);
const colors = computed(() => {
  const swatches = new Map();
  if (variants?.value?.length > 0) {
    variants.value.forEach((variant) => {
      if (variant?.swatch) {
        const handleizeColor = variant?.variantJson?.option1?.toLowerCase()?.replace(/ /g, '_')?.replace(/:/g, '') || '';

        if (!swatches.has(handleizeColor)) {
          swatches.set(handleizeColor, {
            image: variant?.variantJson?.featured_image || { src: productImage.value },
            color: handleizeColor,
            src: variant.swatch,
            name: variant?.variantJson?.option1 || '',
          });
        }
      }
    });

    return Array.from(swatches.values());
  }

  return [];
});
const editImageUrl = computed(() => {
  if (frameSpec?.value && frameSpecUrl?.value) {
    return `/apps/designer/framing-flow/edit?${frameSpecUrl.value}`;
  }

  return '';
});

const personalizeUrl = computed(() => {
  if (frameSpec?.value && frameSpecUrl?.value) {
    return `/apps/designer/review-your-frame?${frameSpecUrl.value}`;
  }

  return '';
});

// Methods
const toggleGallery = () => {
  galleryOpen.value = !galleryOpen.value;
};
const findMatchByAlt = (searchTerm) => media?.value?.find((img) => {
  if (img?.alt) {
    const productImg = img.alt.split('|')[0];

    return productImg?.toLowerCase()?.trim() === searchTerm?.toLowerCase()?.trim();
  }
  return false;
});
const findGeneralMatch = (searchTerm) => media?.value?.find((img) => {
  if (img?.alt) {
    const productImg = img.alt.split('|')[0];
    const colorMatch = searchTerm.split('/')[0];
    const productColor = productImg.split('/')[0];

    return productColor?.toLowerCase()?.trim() === colorMatch?.toLowerCase()?.trim();
  }
  return false;
});
const onSwatchClick = ({
  name,
}) => {
  isUploadError.value = false;
  const updateMedia = (term) => {
    const match = findMatchByAlt(term);

    // update image with exact hit
    if (match) mainImage.value = match.src;

    // update imaege with general color
    if (!match) {
      const generalMatch = findGeneralMatch(term);
      if (generalMatch) mainImage.value = generalMatch.src;
    }
  };

  if (name) {
    selectedColorVariant.value = name;
    const selectedVariants = getVariantsByColor(selectedColorVariant.value) || [];

    // update sizes if variant is selected
    if (selectedVariants?.length > 0) {
      updateActiveSizes(selectedVariants);

      if (selectedVariant?.value) {
        // check if new color has the same size
        const matchingSize = selectedVariants.find(
          (v) => v.variantJson?.option3 === selectedVariant.value.name,
        );

        if (matchingSize) {
          const matchingSizeVariant = toRaw(matchingSize);
          const variant = ` ${matchingSizeVariant.variantJson?.option1} / ${matchingSizeVariant.variantJson?.option3}` || null;
          const matchingSizeVariantOrientation = matchingSizeVariant?.variantJson?.title?.toLowerCase()?.includes('landscape') ? 'landscape' : 'portrait';

          selectedVariant.value = {
            name: matchingSizeVariant.variantJson?.option3,
            ...matchingSizeVariant,
            orientation: matchingSizeVariantOrientation || null,
          };

          if (frameSpec.value) {
            const activeId = selectedVariant.value.id || null;
            const upsellVariantSku = selectedVariant?.value?.upsell_variant_joinery_sku;
            if (addUpsellItem.value && upsellVariantSku) {
              giftBoxSku.value = upsellVariantSku;
            } else {
              giftBoxSku.value = false;
            }
            changeFrame({
              variant: activeId,
              product: product.value.productJson,
            });

            return;
          }

          // toggle image
          if (variant) {
            updateMedia(variant);
          }

          return;
        }
      }
    }

    updateMedia(name);
    // reset selected variant
    selectedVariant.value = null;
  }
};
const onSizeClick = (size) => {
  isUploadError.value = false;
  const variant = variants?.value?.find((v) => v.id === size.id);

  if (size) {
    selectedVariant.value = {
      name: size.name,
      orientation: size?.orientation || '',
      ...variant,
    };

    if (frameSpec.value) {
      const activeId = selectedVariant.value.id || null;
      const upsellVariantSku = selectedVariant?.value?.upsell_variant_joinery_sku;
      if (addUpsellItem.value && upsellVariantSku) {
        giftBoxSku.value = upsellVariantSku;
      } else {
        giftBoxSku.value = false;
      }
      changeFrame({
        variant: activeId,
        product: product.value.productJson,
      });

      return;
    }

    if (size?.label) {
      const match = findMatchByAlt(size.label);

      // update image with exact hit
      if (match) mainImage.value = match.src;

      // update imaege with general color
      if (!match) {
        const generalMatch = findGeneralMatch(size.label);
        if (generalMatch) mainImage.value = generalMatch.src;
      }
    }
  }
};
const getVariantsByColor = (color) => variants?.value?.filter(
  (variant) => variant.variantJson?.option1 === color,
);
const handleUploadError = (error) => {
  const defaultError = data?.value?.error_message || 'Your photo is too small to be printed in this frame. Please <u>upload another photo</u> or try a smaller frame.';
  const isSmallestVariant = sizes?.value && sizes.value[0].id === selectedVariant?.value?.id;

  if (isSmallestVariant) {
    errorMessage.value = defaultError;
  }

  if (!isSmallestVariant) {
    errorMessage.value = error || defaultError;
  }

  isUploadError.value = true;
};
const onUploading = (percent) => {
  isUploadError.value = false;
  uploadPercent.value = percent;

  startUpload();
};
const addToCart = async (event) => {
  const { target } = event;

  if (frameSpec?.value?.addToCart) {
    // disable button
    if (target) {
      target.disabled = true;
      target.classList.add('is-loading');
    }

    try {
      await frameSpec.value.addToCart({ cartHost: cartUrlHost });

      window.location.href = '/cart';
    } catch (error) {
      console.error({ message: 'Error adding to cart', error });

      // clean up button
      if (target) {
        target.disabled = false;
        target.classList.remove('is-loading');
      }
    }
  }
};
const updateUpsellDisplayName = (item) => {
  if (item.includes(':')) {
    const updatedTitle = item.split(':')[0];

    return updatedTitle;
  }

  return item;
};
const updateActiveSizes = (newVariants = []) => {
  if (newVariants?.length > 0) {
    sizes.value = newVariants.map((variant) => ({
      id: variant?.id || 0,
      name: variant?.variantJson?.option3 || null,
      label: `${variant.variantJson?.option1} / ${variant.variantJson?.option3}` || null,
      orientation: variant?.variantJson?.title?.toLowerCase().includes('landscape') ? 'landscape' : 'portrait',
    }));
  }
};
const resetVariant = () => {
  const initialImage = data?.value?.product?.productJson?.featured_image || null;
  selectedVariant.value = null;

  // reset image
  if (initialImage) {
    mainImage.value = initialImage;
  }
};
const reset = () => {
  includePortrait.value = true;
  resetVariant();
  clearFrameSpec();

  if (addToCartButton?.value) {
    addToCartButton.value.disabled = false;
    addToCartButton.value.classList.remove('is-loading');
  }
};
const mounted = () => {
  reset();

  const selectedVariants = getVariantsByColor(selectedColorVariant.value) || [];
  // Set sizes
  if (selectedVariants?.length > 0) {
    updateActiveSizes(selectedVariants);
  }
  buildAllVariants({
    product: product.value.productJson,
    variants: variants.value,
  });
};

// Composable functions
const {
  canPrintArtwork,
  frameSpec,
  frameSpecPrice,
  frameSpecUrl,
  isUploadProcessing,
  hasMaskRender,
  giftBoxSku,
  initArtworkUpload,
  formatPrice,
  startUpload,
  clearFrameSpec,
  changeFrame,
  buildAllVariants,
  setGiftBox,
} = useUploadedArtwork();

// watcher
watch(frameSpec, (newValue) => {
  const newFrameSpec = toRaw(newValue || null);
  const initalValue = data?.value?.include_landscape || false;
  if (newFrameSpec) {
    const { artworks } = newFrameSpec || {};
    if (artworks?.length > 0 && artworks[0]?.original?.dimensionsInPixels) {
      const { width, height } = artworks[0].original.dimensionsInPixels;
      const widthNum = parseInt(width, 10);
      const heightNum = parseInt(height, 10);

      // artowrk orientation is landscape
      if (widthNum > heightNum) {
        includeLandscape.value = true;
        includePortrait.value = false;
      }

      // artowrk orientation is portrait
      if (widthNum <= heightNum) {
        includeLandscape.value = initalValue;
        includePortrait.value = true;
      }
    }
  }

  // return to inital state if removing artwork
  if (!newFrameSpec) {
    includeLandscape.value = initalValue;
    includePortrait.value = true;
  }
});

// update upsell using checkbox
watch([upsellVariant, addUpsellItem], ([newUpsellVariant, newAddUpsellItem]) => {
  const upsellVariantSku = newUpsellVariant.upsell_variant_joinery_sku;
  if (newAddUpsellItem && upsellVariantSku) {
    setGiftBox(upsellVariantSku);
  } else {
    setGiftBox(false);
  }
});

// Lifecycle hooks
onMounted(() => {
  // Run on mount
  mounted();

  // Handle Safari back/forward cache scenario
  const handlePageShow = (event) => {
    if (event?.persisted) {
      mounted();
    }
  };
  window.addEventListener('pageshow', handlePageShow);

  // cleanup
  onUnmounted(() => {
    window.removeEventListener('pageshow', handlePageShow);
  });
});
</script>
