/**
 * Shortlist Create
 */

import productItemApp from './product-item-app';

function shortlistCreate() {
  const c = {
    dom: {
      backLink: 'js-back-link',
      header: 'js-shortlist-header',
      productItemApp: 'js-product-item-app',
      productCardList: 'js-shortlist-products',
    },
  };

  // Globals
  const $productCardList = document.querySelector(`.${c.dom.productCardList}`);

  // Data
  const sessionStorage = window.sessionStorage || null;
  SDG.Data.shortlistItems = sessionStorage
    ? JSON.parse(sessionStorage.getItem('comparePanelStorage'))
    : null;

  /**
   * Init
   */
  function init() {
    if (!$productCardList || !SDG.Data.shortlistItems) return;

    $productCardList.innerHTML = '';
    SDG.Data.shortlistItems.forEach((shortlistItem) => {
      createProductCard(shortlistItem);
    });

    addEvents();
    initShortlist();
  }

  /**
   * Initialize Shortlist
   */
  function initShortlist() {
    // Shortlist Products
    productItemApp({
      dom: {
        parent: 'shortlist',
      },
    });
  }

  /**
   * Add Events
   */
  function addEvents() {
    __.addEvent({
      id: c.dom.header,
      className: c.dom.backLink,
      event: 'click',
      fn: handleClick,
    });
  }

  /**
   * Handle Click
   */
  function handleClick() {
    window.history.back();
  }

  /**
   * Create Product Card
   */
  function createProductCard(data) {
    const randomNumber = Math.floor(Math.random() * 20000000000);
    const price = data.comparePanelPrice.includes('+')
      ? `starting at ${data.comparePanelPrice.replace('+', '')}`
      : data.comparePanelPrice;

    const $productItemApp = `
      <div
        id="productItemAppWrapper-${randomNumber}"
        class="product-item__wrapper pi js-pi"
        data-product-handle="${data.handle}"
        aria-label="${data.productTitle} ${price}"
      >
        <script>
          ${data.productItemData}
        </script>
        <div
          class="product-item__app js-product-item-app"
          id="shortlist-productItemApp-${randomNumber}"
          data-handle="${data.handle}"
          data-product-id="${data.productId}"
          data-product-title="${data.productTitle}"
          data-image-src="${data.imageSrc}"
          data-image-alt="${data.imageAlt}"
          data-product-card-variant-images="${data.productCardVariantImages}"
          data-product-subtitle="${data.productSubtitle}"
          data-product-badge="${data.productBadge}"
          data-product-dimensions='${data.productDimensions}'
          data-is-collection-page="false"
        >
        </div>
      </div>
    `;
    $productCardList.innerHTML += $productItemApp;
  }

  return init();
}

export default shortlistCreate;
