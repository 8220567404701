/**
 * Refresh
 * @param {Object} data from Persistent Cart API response
 * @param {Number} variantId
 * @type {Function}
 */
import bagItem from './bag-item';

SDG.Bag.refresh = function (data, variantId, cb = false) {
  const c = SDG.Bag.config;

  // cached globals
  const { template } = SDG.Data;

  function init() {
    const isCartPage = template === 'cart';

    if (data) {
      if (c.use.count) {
        const $bagCount = document.getElementById(c.dom.bag_count);
        if ($bagCount) {
          const { innerText: bagCount } = $bagCount;
          const itemCount = __.toNumber(bagCount) + 1;
          SDG.Bag.Count.set(itemCount);

          // toggle hide class for bag count span
          if (itemCount > 0 && __.hasClass($bagCount, c.cls.hide)) {
            __.removeClass($bagCount, c.cls.hide);
          } else if (itemCount === 0 && !__.hasClass($bagCount, c.cls.hide)) {
            __.addClass($bagCount, c.cls.hide);
          }
        }
      }

      if (isCartPage) {
        bagItem();
      }
      if (cb) {
        cb();
      } else {
        SDG.Data.setDisplayItemAdded(variantId, updateBuyItNowButton);
      }
    }
  }

  function updateBuyItNowButton() {
    const $button = document.querySelector(`.${c.dom.checkout_button}`);

    if ($button) {
      const { checkout_url: checkoutUrl } = data || false;

      // update button with checkout url
      if (checkoutUrl) {
        $button.setAttribute('href', checkoutUrl);
      }
    }
  }

  return init();
};
