/*
* Frame Anything
* @param {object} opts
*/

function frameAnything(opts) {
  const config = {
    dom: {
      section: 'js-frame-anything',
      imageWrapper: 'js-frame-anything-image-wrapper',
      framedImage: 'js-frame-anything-framed-image',
      playPauseBtn: 'js-frame-anything-play-pause-btn',
      tickerWrapper: 'js-frame-anything-ticker-wrapper',
    },
  };

  const c = __.extend(config, opts);
  const $sections = document.querySelectorAll(`.${c.dom.section}`);
  const $imageWrappers = document.querySelectorAll(`.${c.dom.imageWrapper}`);
  const $playPauseBtn = document.querySelector(`.${c.dom.playPauseBtn}`);
  const $tickerWrappers = document.querySelectorAll(`.${c.dom.tickerWrapper}`);
  let isAnimationPaused = false;

  /**
   * Init
   */
  function init() {
    if (!$sections?.length) return;

    __.addEvent({
      id: $playPauseBtn,
      event: 'click',
      fn: togglePlayPause,
    });

    revealFramedImage();
  }

  /**
  * Reveal framed image
  */
  function revealFramedImage() {
    $imageWrappers.forEach(($wrapper) => {
      const $framedImage = $wrapper.querySelector(`.${c.dom.framedImage}`);

      if ($framedImage) {
        const rect = $wrapper.getBoundingClientRect();
        const isImageVisible = rect.right > 0 && rect.left < window.innerWidth;

        if (isImageVisible) {
          const progress = (rect.right - window.innerWidth / 2) / rect.width;
          // Change the number below to change the speed of the fade
          const opacity = 1.3 - progress;
          $framedImage.style.opacity = opacity;
        } else {
          // If image left browser viewport, set opacity to 0
          $framedImage.style.opacity = 0;
        }
      }
    });

    requestAnimationFrame(revealFramedImage);
  }

  /**
  * Toggle play/pause
  */
  function togglePlayPause() {
    isAnimationPaused = !isAnimationPaused;

    if (!isAnimationPaused) {
      __.addClass($playPauseBtn, 'playing');
      $tickerWrappers.forEach(($wrapper) => {
        __.removeClass($wrapper, 'paused');
      });
    } else {
      __.removeClass($playPauseBtn, 'playing');
      $tickerWrappers.forEach(($wrapper) => {
        __.addClass($wrapper, 'paused');
      });
    }
  }

  return init();
}

export default frameAnything;
