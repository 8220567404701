import Flickity from 'flickity';

/*
* Get Inspired Carousel
* @param {object} opts
*/

function getInspiredCarousel(opts) {
  const config = {
    dom: {
      carousel: 'js-get-inspired-carousel',
      carouselCell: 'js-get-inspired-carousel-cell',
    },
    flickityOpts: {
      desktop: {
        prevNextButtons: true,
        pageDots: true,
        draggable: true,
        wrapAround: true,
        imagesLoaded: true,
        lazyLoad: true,
        adaptiveHeight: false,
      },
      mobile: {
        prevNextButtons: false,
        pageDots: true,
        draggable: true,
        wrapAround: true,
        imagesLoaded: true,
        lazyLoad: true,
        adaptiveHeight: false,
      },
    },
    cls: {
      flickityOff: 'flickity-off',
    },
  };

  const c = __.extend(config, opts);
  const $carousels = document.body.querySelectorAll(`.${c.dom.carousel}`);
  let view = null;

  /**
   * Init
   */
  function init() {
    if (!$carousels?.length) return;
    onWindowResize();
    __.windowResize(onWindowResize);
  }

  /**
  * Responsive functions
  */
  function onWindowResize() {
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          createCarousel(c.flickityOpts.desktop);
        }
      },
    });

    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
          createCarousel(c.flickityOpts.mobile);
        }
      },
    });
  }

  /**
   * Create Carousel
   * @param {object} opts.flickityOpts
   */
  function createCarousel(flickityOpts) {
    $carousels.forEach(($carousel) => {
      let flickity = null;
      let cellAlign = 0;

      const handleResize = () => {
        const $flickityCell = $carousel.querySelector(`.${c.dom.carouselCell}`);

        const cellsWidth = calculateCellsWidth($carousel);
        const shouldInitialize = cellsWidth > window.innerWidth;

        if (shouldInitialize && !flickity) {
          if (view === 'mobile') {
            // Mobile view: Center the main slide
            cellAlign = 0.5;
          } else {
            // Desktop view: Calculate offset
            const offsetDivisor = 1; // Updated value
            const leftOffset = setFlickityOffset($flickityCell.offsetWidth, offsetDivisor);
            const carouselOffset = $carousel.offsetWidth;
            cellAlign = leftOffset / carouselOffset;
          }

          const extendedFlickityOpts = __.extend(flickityOpts, { cellAlign });
          flickity = new Flickity($carousel, extendedFlickityOpts);

          // Calculate and apply the maximum height to the cells
          const updateCellHeight = () => {
            let maxHeight = 0;
            const carouselCells = $carousel.querySelectorAll(`.${c.dom.carouselCell}`);
            carouselCells.forEach((cell) => {
              cell.style.height = 'auto'; // Reset the height first
              const cellHeight = cell.offsetHeight;
              if (cellHeight > maxHeight) {
                maxHeight = cellHeight;
              }
            });
            carouselCells.forEach((cell) => {
              cell.style.height = `${maxHeight}px`; // Apply the maximum height
            });
          };

          updateCellHeight(); // Initial calculation

          window.addEventListener('resize', () => {
            if (flickity) flickity.resize();
            updateCellHeight();
          });

          __.removeClass($carousel, c.cls.flickityOff);
        } else if (!shouldInitialize && flickity) {
          flickity.destroy();
          flickity = null;
          __.addClass($carousel, c.cls.flickityOff);
        }
      };

      // Add 'flickity-off' class initially
      __.addClass($carousel, c.cls.flickityOff);

      handleResize(); // Initial calculation

      window.addEventListener('resize', handleResize);
    });
  }

  function calculateCellsWidth($carousel) {
    const carouselCells = $carousel.querySelectorAll(`.${c.dom.carouselCell}`);
    let cellsWidth = 0;
    const cellsCount = carouselCells.length;

    carouselCells.forEach((cell, index) => {
      if (index === cellsCount - 1) {
        cellsWidth += cell.offsetWidth / 1.5; // Add half of the width for the last cell
      } else {
        cellsWidth += cell.offsetWidth;
      }
    });

    return cellsWidth;
  }

  /**
   * Set the flickity carousel offset
   */
  function setFlickityOffset(flickityCellOffsetWidth, flickityOffsetDivisor) {
    Flickity.Cell.prototype.setDefaultTarget = () => {
      const marginProperty = this.parent.originSide === 'left' ? 'marginLeft' : 'marginRight';
      this.target = this.x + this.size[marginProperty];
    };

    return flickityCellOffsetWidth / flickityOffsetDivisor;
  }

  return init();
}

export default getInspiredCarousel;
