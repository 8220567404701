import { ref } from 'vue';
import FrameSpec from '@framebridge/toolbox/FrameSpec';
/**
 * useFrameSpecData
 * @description
 * A composable function to manage frame specification data.
 * @param {Object} product, variant, artwork
 */
function useUploadedArtwork() {
  const frameSpec = ref(null);
  const frameSpecUrl = ref(null);
  const frameSpecPrice = ref(null);
  const frameSpecVariants = ref(null);
  const isValid = ref(null);
  const canPrintArtwork = ref(true);
  const isUploadProcessing = ref(false);
  const hasMaskRender = ref(false);
  const uploadArtwork = ref(null);
  const giftBoxSku = ref(null);

  /**
   * Initialize Artwork Upload
   * @description - sets the frameSpec, frameSpecUrl, frameSpecPrice, isValid, canPrintArtwork
   * @param {product} object - the product,
   * @param {variant} string - the variant id
   * @param {upload} object - the upload artwork object
   * @returns {void}
   */
  async function initArtworkUpload({
    product,
    variant = null,
    upload,
  }) {
    const artTypeVals = {
      artTypeSlug: 'digital_upload',
      productCategory: 'digital_photo',
      productCategoryName: 'Digital Photo',
      conveyance: 'uploaded',
      medium: 'paper',
    };
    upload.artwork = { ...upload.artwork, ...artTypeVals };
    uploadArtwork.value = upload;

    // init loading state
    isUploadProcessing.value = true;
    try {
      const spec = await FrameSpec.build({
        query: { product: product.id, variant },
        joineryHost: SDG?.Data?.joineryHost || 'https://staging.framebridge.dev',
        shopifyHost: SDG.Data.shopifyHost || 'https://staging.framebridge.dev',
        artwork: upload.artwork,
      });
      if (giftBoxSku.value) {
        spec.setGiftBox(giftBoxSku.value);
      } else {
        spec.removeGiftBox();
      }
      const fsURL = await spec.urlParams({ stringify: true });
      const hasMask = spec?.variant?.maskedRenderer || false;

      frameSpec.value = spec;
      isValid.value = true;
      frameSpecUrl.value = fsURL;
      canPrintArtwork.value = spec?.canPrintArtwork();
      hasMaskRender.value = hasMask;

      if (!canPrintArtwork.value) {
        isValid.value = false;
        clearFrameSpec();
        canPrintArtwork.value = false;
      }
      if (frameSpec.value) {
        await frameSpec.value.calculatePrice();
        setPrice(frameSpec.value);
      }
    } catch (error) {
      console.error({ message: 'Error building FrameSpec', error });
    } finally {
      // sm time out to prevent flicker
      setTimeout(() => {
        isUploadProcessing.value = false;
      }, 500);
    }
  }

  /**
   * Set Price
   * @description
   * Set the price in the FrameSpec
   * @param {Object} - FrameSpec object
   * @returns {void}
   */
  function setPrice(spec) {
    const { price } = spec || {};
    // set the price with chosen size
    if (price && price?.total_price) {
      const money = price.total_price;

      frameSpecPrice.value = money;
    }
  }

  /**
   * Set Gift Box
   * @description
   * Set the gift box in the FrameSpec
   * @param {String} - upsellVariantSku
   * @returns {void}
   */
  const setGiftBox = async (upsellVariantSku) => {
    giftBoxSku.value = upsellVariantSku;
    if (!frameSpec?.value) return;
    if (upsellVariantSku) {
      frameSpec.value.setGiftBox(upsellVariantSku);
    } else {
      frameSpec.value.removeGiftBox();
    }
    try {
      await frameSpec.value.calculatePrice();
      const fsURL = await frameSpec.value.urlParams({ stringify: true });
      frameSpecUrl.value = fsURL;
      setPrice(frameSpec.value);
    } catch (error) {
      console.error({ message: 'Error setting price from gift box', error });
    }
  };

  /**
   * Clear Frame Spec
   * @description - clears the frameSpec, frameSpecUrl, frameSpecPrice, isValid, canPrintArtwork
   * @returns {void}
   */
  const clearFrameSpec = () => {
    frameSpec.value = null;
    frameSpecUrl.value = null;
    frameSpecPrice.value = null;
    isValid.value = null;
    canPrintArtwork.value = null;
  };

  /**
   * Format Price
   * @description - formats the price
   * @param {number} price
   * @returns {string}
   */
  const formatPrice = (price) => (
    price
      ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(price / 100)
      : ''
  );

  /**
   * Start Upload
   * @description - sets the isUploadProcessing to true
   * @returns {void}
   */
  const startUpload = () => {
    isUploadProcessing.value = true;
  };

  const buildAllVariants = async ({
    product,
    variants = [],
  }) => {
    try {
      // Use Promise.all to build all variants concurrently
      const specs = await Promise.all(
        variants.map((variant) => buildVariant({ product, variant })),
      );

      frameSpecVariants.value = specs.filter((spec) => spec !== null);
    } catch (error) {
      console.error({ message: 'Error building all variants', error });
    }
  };

  const buildVariant = async ({
    product,
    variant = null,
  }) => {
    try {
      const artTypeVals = {
        artTypeSlug: 'digital_upload',
        productCategory: 'digital_photo',
        productCategoryName: 'Digital Photo',
        conveyance: 'uploaded',
        medium: 'paper',
      };
      const upload = { ...artTypeVals };
      const spec = await FrameSpec.build({
        query: { product: product.id, variant: variant?.id },
        joineryHost: SDG?.Data?.joineryHost || 'https://staging.framebridge.com',
        shopifyHost: SDG?.Data?.shopifyHost || 'https://staging.framebridge.dev',
        artwork: upload,
      });
      spec.vid = variant;

      return spec;
    } catch (error) {
      console.error({ message: 'Error building FrameSpec', error });
      return null;
    }
  };
  /**
   * Change Frame
   * @description - changes the frame calls function initArtworkUpload with new variant id
   * @param {product} object - the product,
   * @param {variant} string - the variant id
   * @returns {void}
   */
  const changeFrame = async ({
    product,
    variant = null,
  }) => {
    // init loading state
    isUploadProcessing.value = true;

    if (frameSpecVariants.value?.length > 0) {
      const selectedSpec = frameSpecVariants.value.find((spec) => spec?.vid?.id === variant);
      if (selectedSpec) {
        const clone = frameSpec.value.clone();
        clone.artworks[0].exterior = selectedSpec.artworks[0].exterior;
        clone.setMoulding({ permalink: selectedSpec.moulding.permalink });
        if (giftBoxSku.value) {
          clone.setGiftBox(giftBoxSku.value);
        } else {
          clone.removeGiftBox();
        }
        await clone.updateVariantAndConstraints();
        await clone.calculatePrice();

        const fsURL = await clone.urlParams({ stringify: true });
        setPrice(clone);

        frameSpec.value = clone;
        frameSpecUrl.value = fsURL;
        canPrintArtwork.value = clone?.canPrintArtwork();
        hasMaskRender.value = clone?.variant?.maskedRenderer || false;
        isUploadProcessing.value = false;

        // set error and clear state if can't print artwork
        if (!canPrintArtwork.value) {
          isValid.value = false;
          clearFrameSpec();
          canPrintArtwork.value = false;
        }
        return;
      }
      initArtworkUpload({
        product,
        variant,
        upload: uploadArtwork.value,
      });
    }

    initArtworkUpload({
      product,
      variant,
      upload: uploadArtwork.value,
    });
  };

  return {
    frameSpec,
    frameSpecPrice,
    frameSpecUrl,
    hasMaskRender,
    isValid,
    isUploadProcessing,
    canPrintArtwork,
    giftBoxSku,
    clearFrameSpec,
    initArtworkUpload,
    formatPrice,
    startUpload,
    changeFrame,
    buildAllVariants,
    setGiftBox,
  };
}

export default useUploadedArtwork;
