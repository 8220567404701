/**
 * Gallery Category Carousel
 */

import Flickity from 'flickity';

function galleryCategoryCarousel(opts) {
  const config = {
    dom: {
      carousel: 'js-gallery-category-carousel',
      carouselCell: 'js-gallery-category-carousel-cell',
      carouselParent: 'gallery-category-carousel__section',
      pageDots: 'flickity-page-dots',
      pageDot: 'dot',
    },
    flickityOpts: {
      cellAlign: 'left',
      adaptiveHeight: false,
      prevNextButtons: false,
      pageDots: true,
      draggable: true,
      dragThreshold: 8,
      wrapAround: true,
      imagesLoaded: true,
      watchCSS: true,
      lazyLoad: true,
      resize: true,
    },
    cls: {
      fullHeight: 'full-height',
      isSelected: 'is-selected',
    },
  };

  // Shorthand config
  const c = __.extend(config, opts); // Extend defaults with passed options

  // cached global(s)
  const $carousels = document.querySelectorAll(`.${c.dom.carousel}`);
  let view;

  /**
   * Init
   */
  function init() {
    if ($carousels.length > 0) {
      responsive();
      __.windowResize(responsive);
    }
  }

  /**
   * Make carousel
   */
  function makeCarousel() {
    $carousels.forEach(($carousel) => {
      const $carouselItems = $carousel.querySelectorAll(`.${c.dom.carouselCell}`);

      [...$carouselItems].forEach(($item) => {
        __.removeClass($item, c.cls.fullHeight);
      });

      c.flickityOpts.on = {
        ready: () => {
          [...$carouselItems].forEach(($item) => {
            __.addClass($item, c.cls.fullHeight);
          });
        },
      };

      setTimeout(() => {
        // Set the offset divisor based on viewport size
        let offsetDivisor;
        if (view === 'mobile') {
          offsetDivisor = 1.17;
        }

        const $flickityCell = $carousel.querySelector(`.${c.dom.carouselCell}`);
        const leftOffset = setFlickityOffset($flickityCell.offsetWidth, offsetDivisor);
        const carouselOffset = $carousel.offsetWidth;
        const cellAlign = leftOffset / carouselOffset;
        const flickityOpts = __.extend(c.flickityOpts, { cellAlign });

        // eslint-disable-next-line
        new Flickity($carousel, flickityOpts);
      }, 300);
    });
  }

  /**
   * Responsive functions
   */
  function responsive() {
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          makeCarousel();
        }
      },
    });

    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
          makeCarousel();
        }
      },
    });
  }

  /**
   * Set the flickity carousel offset
   */
  function setFlickityOffset(flickityCellOffsetWidth, flickityOffsetDivisor) {
    Flickity.Cell.prototype.setDefaultTarget = () => {
      const marginProperty = this.parent.originSide === 'left' ? 'marginLeft' : 'marginRight';
      this.target = this.x + this.size[marginProperty];
    };

    return flickityCellOffsetWidth / flickityOffsetDivisor;
  }

  return init();
}

export default galleryCategoryCarousel;
