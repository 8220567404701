/**
 * Lazy sizes config
 */
window.lazySizesConfig = window.lazySizesConfig || {};

/**
 * Remove loading class
 * @param {Element} el
 */
function removeLoadingClass(el) {
  const cls = 'is-loading';
  const elm = __.parents(el, `.${cls}`);

  if (elm) {
    __.removeClass(elm[0], cls);
  }
}

/**
 * Add `lazybeforeunveil` listener
 */
document.addEventListener('lazybeforeunveil', (e) => removeLoadingClass(e.target));
