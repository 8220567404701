/**
 * get query string
 * @param  {Object} object
 * @return {string}
 */
__.getQueryString = function(object) {
  return Object.keys(object).reduce(function(acc, item) {
    const prefix = ! acc ? '' : acc + '&';
    return prefix + __.encode(item) + '=' + __.encode(object[item]);
  }, '');
};
