/**
 * FAQ Accordion
 */

function faqAccordion(opts) {
  const config = {
    dom: {
      wrapper: 'js-faq-accordion',
    },
  };
  const c = __.extend(config, opts);

  // global elements
  const $wrappers = document.body.querySelectorAll(`.${c.dom.wrapper}`);

  /**
   * Init
   */
  function init() {
    if (!$wrappers?.length) return;

    [...$wrappers].forEach(($wrapper) => {
      const faqAcc = SDG.accordion({
        dom: {
          id: $wrapper.id,
        },
      });
      faqAcc.init();
    });
  }

  return init();
}

export default faqAccordion;
