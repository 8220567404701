/**
 * Hero
 */
import carousel from './carousel';

function hero(opts) {
  const config = {
    dom: {
      section: 'js-hero-section',
      carousel: 'js-hero-carousel',
      cell: 'js-hero-carousel-cell',
      image: 'js-hero-image',
    },
  };

  // Shorthand config
  const c = __.extend(config, opts); // Extend defaults with passed options
  const $sections = document.querySelectorAll(`.${c.dom.section}`);
  const $carousel = document.querySelector(`.${c.dom.carousel}`) || null;

  /**
   * Init
   */
  function init() {
    if (!$sections?.length) return;

    initHero();
  }

  function initHero() {
    document.addEventListener('DOMContentLoaded', () => {
      updateFocalPoint();
      if ($carousel) initCarousel();
    });
  }

  function updateFocalPoint() {
    const images = document.querySelectorAll(`.${c.dom.image}`) || [];

    images?.forEach((image) => {
      const focalPointX = image.getAttribute('data-focal-x');
      const focalPointY = image.getAttribute('data-focal-y');

      if (!focalPointX || !focalPointY) return;

      // set focal point
      image.style.objectPosition = `${focalPointX} ${focalPointY}`;
    });
  }

  function initCarousel() {
    // Init carousel
    carousel({
      dom: {
        carousel: c.dom.carousel,
        carouselCell: c.dom.cell,
      },
    });
  }

  return init();
}

export default hero;
