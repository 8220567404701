<template>
  <ul class="swatches product-configurator__pv-swatches list-reset">
    <li
      v-for="(color) in swatches"
      :key="color.color"
      :class="{
          'is-active': color.name === selectedColor,
        }"
      class="swatches__item product-configurator__pv-swatches-item"
    >
      <button
        :data-color="color.color"
        :data-image="color?.src || missingImage"
        :disabled="isUploadProcessing"
        class="swatches__btn btn-icon"
        type="button"
        @click="onSwatchClick({
          name: color?.name,
        })"
      >
        <div
          class="swatch"
        >
          <span
            v-if="color?.src?.includes('//')"
          >
            <ResponsiveImage
              :image="color.src"
              :image-alt="color.color"
              modifier="swatch"
            />
          </span>
          <div
            v-else
            class="ir ir--swatch swatches__btn-swatch"
            :style="{background: color.src}"
          >
            <span class="screenreader">{{ color.color }}</span>
          </div>
          <div class="swatch__active"></div>
        </div>
      </button>
    </li>
    <li
      v-if="swatchAdditonalText"
      class="swatches__item-additional"
    >
      <p>
        {{ swatchAdditonalText }}
      </p>
    </li>
  </ul>
</template>
<script setup>
import { computed, ref, toRefs } from 'vue';
import ResponsiveImage from '../common/responsive-image/ResponsiveImage';

const props = defineProps({
  colors: {
    type: Array,
    required: true,
  },
  noImage: {
    type: String,
    required: true,
  },
  selectedColor: {
    type: String,
    required: true,
  },
  isUploadProcessing: {
    type: Boolean,
    required: false,
    default: false,
  },
  onSwatchClick: {
    type: Function,
    required: true,
  },
});

// Reactive properties
const { colors, noImage } = toRefs(props);
const limit = ref(6);
const totalSwatches = ref(colors?.value?.length || 0);
const missingImage = noImage?.value || '';

// Computed properties
const swatches = computed(() => {
  const productSwatches = colors.value;
  if (productSwatches?.length > limit?.value) {
    return productSwatches.slice(0, limit.value);
  }
  return productSwatches;
});
const swatchAdditonalText = computed(() => {
  if (!limit.value || !totalSwatches.value) return false;

  if (totalSwatches.value > limit.value) {
    return `+ ${totalSwatches.value - limit.value} more`;
  }

  return false;
});
</script>
