/**
 * add class
 * @param {string} el
 * @param {string} className
 */
__.addClass = function(el, className) {

  if (!__.hasClass(el, className)) {
    if (el.classList) {
      el.classList.add(className);
    }
    else {
      el.className += ' ' + className;
    }
  }
};
