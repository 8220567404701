<template>
  <div class="bag-item__dropoff-store">
    <div class="bag-item__dropoff-store-info-wrapper">
      <div class="bag-item__dropoff-store-info">
        <div class="bag-item__dropoff-icon icon icon--location"></div>
        <div class="bag-item__dropoff-content">
          <div class="bag-item__dropoff-title">
            <h2>
              {{ name }}
            </h2>
          </div>
          <div class="bag-item__dropoff-address">
            <p class="js-store-address-line-1">
              {{ addressLine1 }}
            </p>
            <p
              v-if="addressLine2"
              class="js-store-address-line-2"
            >
              {{ addressLine2 }}
            </p>
            <p class="js-store-city-state-zip">
              {{ city }}, {{ state }} {{ postalCode }}
            </p>
          </div>
          <div
            v-if="distance"
            class="bag-item__dropoff-distance"
          >
            <p>
              (<span class="js-store-distance">{{ distance }} {{ distanceUnits }}</span> away)
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="bag-item__dropoff-change-store">
      <p
        class="bag-item__dropoff-change-store-text"
        @click="storeSelectorBtn"
        :data-product-id="productId"
      >
        Change Store
      </p>
    </div>
  </div>
</template>
<script>
import {
  onMounted,
  ref,
  toRefs,
  toRaw,
  watch,
} from 'vue';
import trapFocus from '../../lib/trap-focus';

export default {

  name: 'CartItemDeliveryStore',
  props: {
    selectedDropOffStore: {
      type: Object,
      // eslint-disable-next-line arrow-body-style
      default: () => ({
        name: null,
        address_line_1: null,
        address_line_2: null,
        city: null,
        state: null,
        postal_code: null,
        distance: null,
        distance_units: null,
      }),
    },
    productId: {
      type: String,
      default: '',
    },
    productProperties: {
      type: Object,
      default: () => ({}),
    },
    dropOffStoreProperty: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const {
      productProperties,
      productId,
      selectedDropOffStore,
    } = toRefs(props);
    const itemProperties = toRaw(productProperties.value);
    const id = toRaw(productId.value);
    const name = ref(null);
    const addressLine1 = ref(null);
    const addressLine2 = ref(null);
    const city = ref(null);
    const state = ref(null);
    const postalCode = ref(null);
    const distance = ref(null);
    const distanceUnits = ref(null);

    onMounted(() => {
      const store = selectedDropOffStore.value;
      if (store) {
        name.value = store.name;
        addressLine1.value = store.address_line_1;
        addressLine2.value = store.address_line_2;
        city.value = store.city;
        state.value = store.state;
        postalCode.value = store.postal_code;
        distance.value = store.distance;
        distanceUnits.value = store.distance_units;
      }
    });

    watch(selectedDropOffStore, (newValue) => {
      if (toRaw(newValue)) {
        const store = selectedDropOffStore.value;
        name.value = store.name;
        addressLine1.value = store.address_line_1;
        addressLine2.value = store.address_line_2;
        city.value = store.city;
        state.value = store.state;
        postalCode.value = store.postal_code;
        distance.value = store.distance;
        distanceUnits.value = store.distance_units;
      }
    });

    const storeSelectorBtn = () => {
      const $body = document.body;
      __.addClass($body, 'is-store-selector-exposed');
      const $storeSelectorModal = document.getElementById('storeSelectorModal');
      const $hiddenItemId = $storeSelectorModal.querySelector('.js-hidden-item-id');
      $hiddenItemId.value = id;
      const $hiddenItemProperties = $storeSelectorModal.querySelector('.js-hidden-item-properties');
      $hiddenItemProperties.value = JSON.stringify(itemProperties);

      setTimeout(() => {
        trapFocus($storeSelectorModal);
      }, 150);
    };

    return {
      name,
      addressLine1,
      addressLine2,
      city,
      state,
      postalCode,
      distance,
      distanceUnits,
      storeSelectorBtn,
    };
  },
};
</script>
