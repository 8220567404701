<template>
  <div>
    <section
      v-if="cartLoading"
      id="bag"
      class="bag bag--loading is-loading"
    ></section>

    <section
      v-else-if="cartCount > 0"
      id="bag"
      class="bag"
      :data-count="cartCount"
      :data-total-price="cartTotalPrice"
    >
      <Teleport to="#bagBtnCount">
        <span class="bag__count">
          {{ cartCount }}
        </span>
      </Teleport>
      <div class="bag__form container">
        <h2 class="bag__title">
          {{ title }} ({{ cartCount }})
        </h2>
        <div class="bag__content">
          <section class="bag__summary">
            <div class="bag__summary-inner">
              <div id="bagTotals" class="bag__totals">
                <div class="bag__summary-header">
                  <h2 class="bag__summary-title bag__title--summary">Order Summary</h2>
                </div>
                <CartThermometer
                :requiresNoShipping="requiresNoShipping"
                 @update:isAboveThreshold="handleThresholdUpdate"
                v-if="enableCartThermometer" />
                <div class="bag__summary-totals">
                  <p class="bag__total">
                    <span class="bag__total-label">Subtotal</span>
                    <span id="bagSubtotal" class="bag__total-value">
                      {{ formattedItemsSubtotalPrice }}
                    </span>
                  </p>
                  <p
                  v-if="isAboveThreshold"
                  class="bag__total">
                    <span
                      class="bag__total-label"
                      v-html="cartShippingLineLabel"
                    >
                    </span>
                    <span class="bag__total-value">
                      {{ cartShippingLineValue }}
                    </span>
                  </p>
                  <p
                  v-else-if="requiresNoShipping"
                  class="bag__total">
                    <span
                      class="bag__total-label"
                      v-html="cartShippingLineLabel"
                    >
                    </span>
                    <span class="bag__total-value">
                      {{ cartShippingLineValue }}
                    </span>
                  </p>

                  <p
                  v-else
                  class="bag__total">
                    <span
                      class="bag__total-label"
                      v-html="belowCartShippingLineLabel"
                    >
                    </span>
                    <span class="bag__total-value">
                      {{ belowCartShippingLineValue }}
                    </span>
                  </p>
                  <p v-if="shippingFeeProduct" class="bag__total">
                    <span class="bag__total-label">
                      {{ cartSummaryMailInServiceCopy }}
                    </span>
                    <span class="bag__total-value">
                      {{ formattedMailInServicePrice }}
                    </span>
                  </p>
                  <p class="bag__total">
                    <span class="bag__total-label">
                      {{ cartSummaryTaxLineText }}
                    </span>
                    <span class="bag__total-value">
                      {{ cartSummaryTaxLineValue }}
                    </span>
                  </p>
                  <div class="bag__final-total">
                    <h3 class="bag__total bag__total--cart-total">
                      <span class="bag__total-label">Total</span>
                      <span
                      v-if ="isAboveThreshold"
                      id="bagTotal" class="bag__total-value">
                        {{ formattedItemsTotalPrice }}
                      </span>
                      <span v-else id="bagTotal" class="bag__total-value">
                        {{ totalWithShippingCharge }}
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="bag__actions">
              <a
                v-if="checkoutAvailable"
                :href="checkoutUrl"
                :class="(!checkoutAvailable || checkoutDisabled || checkoutLoading && 'bag__checkout--disabled')"
                class="bag__action bag__checkout js-bag-checkout btn btn--secondary btn--full"
                name="checkout"
                title="Proceed to Checkout"
              >
                <span v-if="checkoutDisabled || checkoutLoading" class="is-loading"></span>
                <span v-else>
                  Checkout
                  <span v-if="formattedItemsTotalPrice" class="hide-desktop" >
                    &nbsp;– {{ formattedItemsTotalPrice }}
                  </span>
                </span>
              </a>
               <button
                v-else
                type="button"
                class="bag__action bag__checkout js-bag-checkout btn btn--secondary btn--full"
                :class="(checkoutDisabled || checkoutLoading && 'bag__checkout--disabled')"
                title="Proceed to Checkout"
                @click="displayErrorMessaging"
              >
                <span v-if="checkoutDisabled || checkoutLoading" class="is-loading"></span>
                <span v-else>
                  Checkout
                  <span v-if="formattedItemsTotalPrice" class="hide-desktop" >
                    &nbsp;– {{ formattedItemsTotalPrice }}
                  </span>
                </span>
              </button>
              <p
                v-if="showErrorMessage"
                class="bag__content-error"
              >
                {{ cartCheckoutPackageError }}
              </p>
            </div>
            <GiftNote />
            <CartDeliveryEstimate v-if="!hideDeliveryEstimate"/>
            <div class="bag__cart-tout" v-if="cartPageToutText">
              <div class="bag__cart-tout-image">
                <ResponsiveImage
                  :image="cartPageToutIcon"
                  image-alt="Cart Page Tout"
                  modifier="square"
                />
              </div>
              <span class="bag__cart-tout-text">{{ cartPageToutText }}</span>
            </div>
          </section>

          <section
            id="bagItems"
            class="bag__items"
          >
            <CartItem
              v-for="item in cartItems"
              :key="item?.id"
              :item="item"
              :allItems="cartItems"
              :checkoutAvailable="checkoutAvailable"
              :hasPackageError="hasPackageError"
              :currency="currency"
              :cartCount="cartCount"
              :itemQuantity="item?.quantity"
              :itemPrice="item?.final_line_price"
              :itemProperties="(item?.properties || {})"
              :selectedDropOffStore="(storeSelectorData || null)"
              :selectedShipToUsStore="(shipToUsData || null)"
              :dropOffStores="computedDropOffStores"
              :togglePackingError="togglePackingError"
              @set-ship-to-us="handleSetShipToUs"
              @toggle-checkout-button="toggleCheckoutButton"
            />
          </section>
        </div>
        <section
          v-if="cartPromotion"
          v-html="cartPromotion"
          class="bag__promotion"
        >
        </section>
      </div>
    </section>

    <section
      v-else-if="error"
      class="error"
    >
      {{ error }}
    </section>

    <section
      v-else
      id="bag"
      class="bag is-empty"
      :data-count="0"
    >
      <div id="bagEmpty" class="container bag__empty">
        <h1 class="bag__empty-title">{{ emptyCartTitle }}</h1>
        <a
          v-if="hasEmptyCartCta1"
          class="btn btn--primary bag__empty-cta"
          :href="emptyCartCta1.url"
        >
          {{ emptyCartCta1.copy }}
        </a>
        <div v-if="hasEmptyCartCta2" class="bag__empty-cta-wrapper">
          <a
            class="btn btn--primary bag__empty-cta"
            :href="emptyCartCta2.url"
          >
            {{ emptyCartCta2.copy }}
          </a>
        </div>
      </div>
    </section>

    <section
      v-if="!wishlistLoading && wishlist && wishlist.length > 0"
      :class="cartCount == 0 ? 'bag bag--wishlist' : 'bag bag--wishlist-mobile'"
      class="wishlist-bag"
    >
      <div class="bag__form container">
        <hr class="hide-mobile" v-if="cartCount > 0" />
        <h2 class="bag__title bag__title--wishlist">{{ wishlistTitle }}</h2>
        <div class="bag__content">
          <section
            class="bag__items"
          >
            <CartItem
              v-for="item in wishlist"
              :key="item?.id"
              :item="item"
              :allItems="wishlist"
              :currency="currency"
              :itemQuantity="item?.quantity"
              :itemPrice="item?.final_line_price"
              :itemProperties="(item?.properties || {})"
              :selectedDropOffStore="(storeSelectorData || null)"
              :dropOffStores="computedDropOffStores"
              :isWishlist="true"
            />
          </section>
        </div>
      </div>
    </section>

    <section
      v-else-if="wishlistError"
      class="error"
    >
      {{ wishlistError }}
    </section>
    <StoreSelector
      @set-drop-off-store="handleSetStoreData"
    ></StoreSelector>
  </div>
</template>

<script>
import {
  Teleport,
  computed,
  onBeforeMount,
  toRef,
  ref,
  watch,
  nextTick,
} from 'vue';
import CartItem from '../cart-item/CartItem';
import useCart from '../composables/cart-actions';
import useCartPromotion from '../composables/cart-promo';
import ResponsiveImage from '../common/responsive-image/ResponsiveImage';
import StoreSelector from '../store-selector/StoreSelector';
import GiftNote from '../gift-note/GiftNote';
import CartDeliveryEstimate from '../cart-delivery-estimate/CartDeliveryEstimate';
import CartThermometer from './CartThermometer';

export default {
  name: 'CartApp',
  components: {
    CartItem,
    Teleport,
    StoreSelector,
    ResponsiveImage,
    GiftNote,
    CartDeliveryEstimate,
    CartThermometer,
  },
  setup() {
    const {
      cartCount,
      cartItems,
      cartLoading,
      cartTotalPrice,
      checkoutUrl,
      itemsSubtotalPrice,
      currency,
      error,
      cartThemeSettings,
      fetchCart,
      wishlist,
      wishlistLoading,
      wishlistError,
      fetchWishlist,
      checkoutLoading,
    } = useCart();
    const { cartPromotion } = useCartPromotion();
    const storage = window.sessionStorage;
    const widgetTag = SDG.Data.stockistWidgetTag;
    const storeSelectorData = toRef(null);
    const shipToUsData = toRef(null);
    const checkoutAvailable = ref(true);
    const checkoutDisabled = ref(false);
    const computedDropOffStores = ref(null);
    const hasPackageError = ref(false);
    const showErrorMessage = ref(false);
    const title = SDG.Data.cartPageTitle || 'Your Cart';
    const hideDeliveryEstimate = SDG.Data.hideDeliveryEstimate || false;
    const enableCartThermometer = SDG.Data.cart.enable_cart_thermometer || false;
    const requiresNoShipping = ref(false);
    const tempShip = 'temp_ship';
    const tempDropOff = 'temp_drop_off';

    const {
      belowCartShippingLineLabel,
      belowCartShippingLineValue,
      belowThresholdShippingCharge,
      cartShippingLineLabel,
      cartShippingLineValue,
      cartSummaryMailInServiceCopy,
      cartSummaryMailInServiceProductVariantId,
      cartSummaryTaxLineText,
      cartSummaryTaxLineValue,
      cartPageToutIcon,
      cartPageToutText,
      cartCheckoutPackageError,
    } = cartThemeSettings;

    const shippingFeeProduct = computed(() => {
      const shipFee = cartItems?.value?.find((item) => item?.properties?._is_shipping_fee === 'true' || item?.variant_id === cartSummaryMailInServiceProductVariantId);
      return shipFee;
    });

    const formattedItemsSubtotalPrice = computed(() => {
      if (shippingFeeProduct?.value) {
        return __.formatPriceDisplay(itemsSubtotalPrice.value - shippingFeeProduct.value.price);
      } else {
        return __.formatPriceDisplay(itemsSubtotalPrice.value);
      }
    });

    const formattedItemsTotalPrice = computed(() => (
      __.formatPriceDisplay(itemsSubtotalPrice.value)
    ));

    const formattedMailInServicePrice = computed(() => (
      __.formatPriceDisplay(shippingFeeProduct?.value?.price)
    ));

    const totalWithShippingCharge = computed(() => {
      const shippingCharge = Number(belowThresholdShippingCharge.replace('.', ''));
      if (!Number.isNaN(shippingCharge)) {
        return __.formatPriceDisplay(itemsSubtotalPrice.value + shippingCharge);
      }
      return __.formatPriceDisplay(itemsSubtotalPrice.value);
    });

    const isAboveThreshold = ref(true);
    const handleThresholdUpdate = (newValue) => {
      if (!enableCartThermometer) {
        isAboveThreshold.value = true;
      } else {
        isAboveThreshold.value = newValue;
      }
    };

    const formattedCartTotalPrice = computed(() => __.formatPriceDisplay(cartTotalPrice.value));

    const handleSetStoreData = (data) => {
      storeSelectorData.value = data;
      shipToUsData.value = null;
    };

    const handleSetShipToUs = (data) => {
      shipToUsData.value = data;
      storeSelectorData.value = null;
    };

    const toggleCheckoutButton = (val) => {
      checkoutDisabled.value = val;
    };

    const handleRequiresShipping = () => {
      requiresNoShipping.value = cartItems.value.every((item) => item.requires_shipping === false);
    };

    /**
     * Set the Stockist store data
     */
    const setDefaultDropOffStores = async () => {
      if (storage) storage.setItem('dropOffStores', null);
      const ipStackData = storage.getItem('ipStackData')
        ? JSON.parse(storage.getItem('ipStackData'))
        : null;

      const { latitude, longitude } = ipStackData || {};
      const stockistEndpoint = latitude && longitude
        ? `https://stockist.co/api/v1/${widgetTag}/locations/search?latitude=${latitude}&longitude=${longitude}`
        : `https://stockist.co/api/v1/${widgetTag}/locations/all`;

      const results = await fetch(stockistEndpoint)
        .then((searchResult) => searchResult.json())
        .catch((err) => console.error(err));

      const filteredResults = (latitude && longitude ? results.locations : results)
        .filter((result) => (
          result.custom_fields.find((field) => (
            field.name.toLowerCase() === 'drop off in store' && field.value.toLowerCase() === 'true'
          ))
        ));

      if (storage) storage.setItem('dropOffStores', JSON.stringify(filteredResults));
      computedDropOffStores.value = filteredResults;
    };

    const {
      title: emptyCartTitle,
      cta1: emptyCartCta1,
      cta2: emptyCartCta2,
    } = SDG?.Data?.emptyCart || {};

    const hasEmptyCartCta1 = computed(() => {
      if (!emptyCartCta1) return false;

      return emptyCartCta1?.copy !== '' && emptyCartCta1?.url !== '';
    });

    const hasEmptyCartCta2 = computed(() => {
      if (!emptyCartCta2) return false;

      return emptyCartCta2?.copy !== '' && emptyCartCta2?.url !== '';
    });

    const wishlistTitle = computed(() => {
      if (!wishlist) return '';
      const wishlistLength = wishlist.value.length;
      const pluralString = wishlistLength > 1 ? 's' : '';
      return `${wishlistLength} Item${pluralString} Saved For Later`;
    });

    const togglePackingError = (val) => {
      const itemsWithShipping = cartItems?.value?.filter((item) => item?.variant_options?.includes('Physical') && item?.product_type !== 'Gift Card') || null;

      if (val) {
        checkoutAvailable.value = false;
        showErrorMessage.value = true;
        hasPackageError.value = true;
      } else if (!val && itemsWithShipping?.length > 0) {
        let hasValidShipping = true;
        itemsWithShipping.forEach((item) => {
          const { _dropOffAtStore: dropOff, shipToUs } = item?.properties || {};

          if ((!dropOff || dropOff === tempDropOff) && (!shipToUs || shipToUs === tempShip)) {
            hasValidShipping = false;
          }
        });

        checkoutAvailable.value = hasValidShipping;
        showErrorMessage.value = !hasValidShipping;
        hasPackageError.value = !hasValidShipping;
      } else {
        checkoutAvailable.value = true;
        showErrorMessage.value = false;
        hasPackageError.value = false;
      }
    };

    const displayErrorMessaging = () => {
      hasPackageError.value = true;
      showErrorMessage.value = true;
      const itemsWithShipping = cartItems?.value?.filter((item) => item?.variant_options?.includes('Physical') && item?.product_type !== 'Gift Card') || null;

      if (itemsWithShipping?.length > 0) {
        itemsWithShipping.find((item) => {
          const {
            _dropOffAtStore: dropOff,
            shipToUs,
          } = item?.properties || {};
          const { variant_id: variantId, id } = item || {};

          if ((!dropOff || dropOff === tempDropOff) && (!shipToUs || shipToUs === tempShip)) {
            checkoutAvailable.value = false;

            if (variantId) {
              nextTick(() => {
                const itemEl = document.getElementById(`bagItem${variantId}-${id}`);

                if (itemEl) {
                  // slight timeout for cart to complete loading
                  setTimeout(() => {
                    itemEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
                  }, 500);
                }
              });
            }
            return true;
          }
          return false;
        });
      }
    };

    onBeforeMount(() => {
      fetchCart();
      fetchWishlist();
      setDefaultDropOffStores();
    });

    watch(
      [cartCount, wishlist, cartLoading, wishlistLoading],
      ([newCartCount, newWishlist, newCartLoading, newWishlistLoading]) => {
        nextTick(() => {
          if (newCartLoading || newWishlistLoading) return;
          if (newCartCount > 0 || newWishlist.length > 0) {
            SDG.Bag.empty(false);
          } else {
            SDG.Bag.empty(true);
          }
          // make sure the body is scrollable when the cart items change
          __.removeClass(document.body, 'overflow-hidden');
        });
      },
      { immediate: true },
    );

    // Handles Checkout cta disable/enable state
    watch(cartItems, (newCartItems) => {
      if (newCartItems?.length > 0) {
        const itemsWithShipping = newCartItems.filter((item) => item?.variant_options?.includes('Physical') && item?.product_type !== 'Gift Card');
        handleRequiresShipping();
        if (itemsWithShipping?.length > 0) {
          const uncheckedItem = itemsWithShipping.find((item) => {
            const {
              _dropOffAtStore: dropOff,
              shipToUs,
            } = item?.properties || {};

            if ((!dropOff || dropOff === tempDropOff)&& (!shipToUs || shipToUs === tempShip)) {
              checkoutAvailable.value = false;
              return true;
            }
            return false;
          });

          if (!uncheckedItem) {
            checkoutAvailable.value = true;
            hasPackageError.value = false;
            showErrorMessage.value = false;
          }
        } else {
          checkoutAvailable.value = true;
          hasPackageError.value = false;
          showErrorMessage.value = false;
        }
      }
    });

    return {
      cartCount,
      cartItems,
      cartPromotion,
      cartLoading,
      cartTotalPrice,
      checkoutUrl,
      currency,
      error,
      formattedCartTotalPrice,
      formattedItemsSubtotalPrice,
      formattedItemsTotalPrice,
      cartShippingLineLabel,
      cartShippingLineValue,
      cartSummaryMailInServiceCopy,
      cartSummaryMailInServiceProductVariantId,
      formattedMailInServicePrice,
      cartSummaryTaxLineText,
      cartCheckoutPackageError,
      cartSummaryTaxLineValue,
      cartPageToutIcon,
      cartPageToutText,
      checkoutAvailable,
      checkoutDisabled,
      checkoutLoading,
      emptyCartTitle,
      emptyCartCta1,
      emptyCartCta2,
      hasEmptyCartCta1,
      hasEmptyCartCta2,
      title,
      wishlist,
      wishlistLoading,
      wishlistError,
      wishlistTitle,
      handleSetStoreData,
      handleSetShipToUs,
      toggleCheckoutButton,
      computedDropOffStores,
      storeSelectorData,
      shipToUsData,
      shippingFeeProduct,
      hideDeliveryEstimate,
      hasPackageError,
      showErrorMessage,
      displayErrorMessaging,
      togglePackingError,
      enableCartThermometer,
      isAboveThreshold,
      handleThresholdUpdate,
      belowCartShippingLineLabel,
      belowCartShippingLineValue,
      totalWithShippingCharge,
      requiresNoShipping,
    };
  },
};
</script>
