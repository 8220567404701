import Flickity from 'flickity';

require('flickity-fade');

/**
 * Shop The Wall – Single
 * @param {object} opts
 */
function howItWorksMedia(opts) {
  const config = {
    dom: {
      carousel: 'js-how-it-works-media-carousel',
      slide: 'js-how-it-works-media-carousel-item',
      slideButton: 'js-how-it-works-media-slide-button',
      slideCopy: 'js-how-it-works-media-slide-copy',
      slideCopyWrapper: 'js-how-it-works-media-slide-copy-wrapper',
      wrapper: 'js-how-it-works-media',
    },
    cls: {
      active: 'is-active',
      isNotSelected: 'is-not-selected',
      isSelected: 'is-selected',
    },
  };
  const c = __.extend(config, opts);

  const flickityOpts = {
    contain: true,
    draggable: false,
    pageDots: false,
    prevNextButtons: false,
    wrapAround: true,
    fade: true,
  };

  // global elements
  const $wrappers = document.body.querySelectorAll(`.${c.dom.wrapper}`);
  const flickityCarousels = [];
  let view;

  /**
   * Init
   */
  function init() {
    if (!$wrappers?.length) return;
    onWindowResize();
    __.windowResize(onWindowResize);
  }

  /**
   * On Window Resize
   */
  function onWindowResize() {
    // desktop
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          rebuildFlickity();
        }
      },
    });

    // mobile
    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
          rebuildFlickity();
        }
      },
    });
  }

  /**
   * Pause carousel when out of view
   * @description Pause carousel when out of view to prevent page shifting
   * @param {object} $wrapper - wrapper to watch section
   * @param {object} $carousel - carousel to pause
   * @returns {void}
   */
  function pauseCarouselWhenOutOfView($wrapper, $carousel) {
    const flickityCarousel = Flickity.data($carousel);

    const carouselObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          flickityCarousel.unpausePlayer();
        } else {
          flickityCarousel.pausePlayer();
        }
      });
    });

    if (!flickityCarousel || !$wrapper) return;

    carouselObserver.observe($wrapper);
  }

  /**
   * rebuild flickity
   */
  function rebuildFlickity() {
    [...$wrappers].forEach(($wrapper, index) => {
      const activeCarousel = flickityCarousels[index];

      if (activeCarousel) activeCarousel.destroy();
      const $carousels = $wrapper.querySelectorAll(`.${c.dom.carousel}`);

      if (!$carousels) return;

      $carousels.forEach(($carousel) => {
        const autoPlay = __.toNumber($carousel.dataset.carouselAutoplaySpeed) * 1000;
        const extendFlickityOpts = __.extend(flickityOpts, { autoPlay });
        extendFlickityOpts.on = {
          ready: () => {
            const $firstSlideCopy = $wrapper.querySelector(`.${c.dom.slideCopy}[data-index="0"]`);
            const $slideCopyHeight = $firstSlideCopy.offsetHeight;
            const $slideParents = __.parents($firstSlideCopy);
            const $firstSlideCopyWrapper = $slideParents
              ? $slideParents.find((el) => el.classList.contains(c.dom.slideCopyWrapper))
              : null;

            if ($firstSlideCopyWrapper) {
              $firstSlideCopyWrapper.style.height = `${$slideCopyHeight}px`;
            }

            pauseCarouselWhenOutOfView($wrapper, $carousel);
          },
        };

        setTimeout(() => {
          const flickityCarousel = new Flickity($carousel, extendFlickityOpts);

          function handleClick(e) {
            flickityCarousel?.pausePlayer();
            const slideButtonIndex = e?.target?.dataset?.index;
            const $newActiveSlide = $wrapper.querySelector(`.${c.dom.slide}[data-index="${slideButtonIndex}"]`) || null;

            if (!$newActiveSlide) return;

            __.addClass($newActiveSlide, c.cls.active);
            flickityCarousel.select(slideButtonIndex, true);

            // unpause after slide change
            setTimeout(() => {
              flickityCarousel?.unpausePlayer();
            }, 300);
          }

          flickityCarousel.on('change', (changeIndex) => {
            // update active and selected classes for slides on change
            const $inactiveSlides = $wrapper.querySelectorAll(`.${c.dom.slide}:not([data-index="${changeIndex}"])`);
            const $slides = $wrapper.querySelectorAll(`.${c.dom.slide}[data-index="${changeIndex}"]`);

            if ($slides && $slides.length > 0) {
              $slides.forEach(($slide) => {
                __.removeClass($slide, c.cls.isNotSelected);
                __.addClass($slide, c.cls.active);
              });
            }

            setTimeout(() => {
              if ($inactiveSlides) {
                $inactiveSlides.forEach(($inactiveSlide) => {
                  __.addClass($inactiveSlide, c.cls.isNotSelected);
                  __.removeClass($inactiveSlide, c.cls.active);
                });
              }
            }, 500);

            // add active class to slide button on change
            const $activeSlideButtons = [...$wrapper.querySelectorAll(`.${c.dom.slideButton}.${c.cls.active}`)];
            const $slideButton = $wrapper.querySelector(`.${c.dom.slideButton}[data-index="${changeIndex}"]`);

            if ($activeSlideButtons?.length) {
              $activeSlideButtons
                .forEach(($activeSlideButton) => {
                  __.removeClass($activeSlideButton, c.cls.active);
                  $activeSlideButton.blur();
                });
            }

            if ($slideButton) {
              __.addClass($slideButton, c.cls.active);
            }

            // add active class and update height for slide copy on change
            const $activeSlideCopy = $wrapper.querySelector(`.${c.dom.slideCopy}.${c.cls.active}`);
            const $slideCopy = $wrapper.querySelector(`.${c.dom.slideCopy}[data-index="${changeIndex}"]`);
            const $slideCopyHeight = $slideCopy.offsetHeight;
            const $slideParents = __.parents($slideCopy);
            const $slideCopyWrapper = $slideParents
              ? $slideParents.find((el) => el.classList.contains(c.dom.slideCopyWrapper))
              : null;

            if ($slideCopyWrapper) {
              $slideCopyWrapper.style.height = `${$slideCopyHeight}px`;
            }

            if ($activeSlideCopy) {
              __.removeClass($activeSlideCopy, c.cls.active);
            }

            if ($slideCopy) {
              __.addClass($slideCopy, c.cls.active);
            }
          });

          __.removeEvent({
            id: $wrapper,
            className: c.dom.slideButton,
            event: 'click',
            fn: (e) => {
              handleClick(e);
            },
          });

          __.addEvent({
            id: $wrapper,
            className: c.dom.slideButton,
            event: 'click',
            fn: (e) => {
              handleClick(e);
            },
          });

          if (activeCarousel) {
            flickityCarousels[index] = flickityCarousel;
          } else {
            flickityCarousels.push(flickityCarousel);
          }
        }, 500); // short timeout for Flickity cell sizing
      });
    });
  }

  return init();
}

export default howItWorksMedia;
