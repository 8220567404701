import { ref, onBeforeMount } from 'vue';
import { createStorefrontApiClient } from '@shopify/storefront-api-client';

const client = SDG.Data.bopisStorefrontApiKey !== '' ? createStorefrontApiClient({
  storeDomain: SDG.Data.shopifyHost,
  apiVersion: '2024-10',
  publicAccessToken: SDG.Data.bopisStorefrontApiKey,
}) : null;

const baseURL = SDG.Data.apiUrl;
const cart = ref({});
const cartCount = ref(0);
const cartTotalPrice = ref(0);
const checkoutUrl = ref('/checkout');
const itemsSubtotalPrice = ref(0);
const cartItems = ref([]);
const cartLoading = ref(true);
const checkoutLoading = ref(true);
const currency = ref(null);
const displayItemAdded = ref(false);
const error = ref(null);
const wishlist = ref([]);
const wishlistLoading = ref(true);
const wishlistError = ref(null);
const cartAttributes = ref({});

function useCart() {
  const { cid } = SDG.Data;
  const { cartThemeSettings } = SDG.Data;

  async function updateCart(data, isWishlist = false) {
    // adds fallback to USD for Shopify Cart Bug
    const currencyVal = data?.currency && data?.currency !== 'XXX' ? data.currency : 'USD';

    if (isWishlist) {
      wishlist.value = data;
      wishlistLoading.value = false;
      checkoutLoading.value = false;
    } else {
      const itemsInCart = data?.items || [];
      const itemCount = data?.item_count || 0;
      let countWithoutAddOns = itemCount;

      if (itemCount > 0 && itemsInCart?.length) {
        const addOnProducts = itemsInCart?.filter((item) => item?.product_type === 'Addition');
        const finalAdditionsCount = addOnProducts.reduce(
          (acc, item) => acc + item.quantity,
          0,
        );
        countWithoutAddOns = itemCount - finalAdditionsCount;
        itemsInCart.reverse();
      }

      cart.value = data;
      cartItems.value = data.items;
      cartCount.value = countWithoutAddOns;
      cartAttributes.value = data.attributes;
      cartTotalPrice.value = data.total_price;
      checkoutUrl.value = data.checkout_url;
      itemsSubtotalPrice.value = data.items_subtotal_price;
      currency.value = currencyVal || 'USD';
      cartLoading.value = false;
      checkoutLoading.value = true;

      handleBuyerDeliveryPreferences(data.id, cartItems.value);

      await handleGWPandShippingFee();
    }
  }

  function fetchCart(isWishlist) {
    checkoutLoading.value = true;
    const path = isWishlist ? 'wishlist' : 'cart';
    const cartCookieValue = decodeURIComponent(SDG.getCookie('cart'));
    const cartId = cartCookieValue && SDG.Data.b2bLocationName ? `gid://shopify/Cart/${cartCookieValue}` : '';
    fetch(`${baseURL}/${path}?cid=${cid}&cartId=${encodeURIComponent(cartId)}`)
      .then((resp) => resp.json())
      .then((data) => {
        updateCart(data, isWishlist);
      })
      .catch((err) => {
        error.value = err;
        console.error(err);
      });
  }

  async function handleBuyerDeliveryPreferences(cartId, items) {
    if (!client || !cartId || !items?.length) return;
    const ids = items.map((item) => `gid://shopify/Product/${item.product_id}`);
    const productQuery = `
      query ProductQuery($ids: [ID!]!) {
        nodes(ids: $ids) {
          ... on Product {
            id
            title
            tags
          }
        }
      }
    `;

    const { data } = await client.request(productQuery, {
      variables: {
        ids,
      },
    });

    const cartHasAllPickupItems = data?.nodes.every((node) => node.tags.includes('In-Store Fulfillable'));
    const hasRetailContent = window.dataLayer?.find((item) => item.event === 'geolocation' && item.rule === 'retail-content');
    // set default cart for pickup
    const cartMutation = `
      mutation cartBuyerIdentityUpdate($buyerIdentity: CartBuyerIdentityInput!, $cartId: ID!) {
        cartBuyerIdentityUpdate(buyerIdentity: $buyerIdentity, cartId: $cartId) {
          cart {
            id
            buyerIdentity {
              preferences {
                delivery {
                  deliveryMethod
                  coordinates {
                    countryCode
                    latitude
                    longitude
                  }
                }
              }
            }
          }
        }
      }
    `;
    const coords = localStorage.getItem('geolocationCoords') || null;
    let coordinates = null;
    if (coords) {
      coordinates = JSON.parse(coords);
      coordinates.countryCode = 'US';
    }
    const isPickup = hasRetailContent && cartHasAllPickupItems;
    const deliveryMethod = isPickup ? 'PICK_UP' : 'SHIPPING';
    await client.request(cartMutation, {
      variables: {
        cartId,
        buyerIdentity: {
          preferences: {
            delivery: {
              deliveryMethod,
              coordinates,
            },
          },
        },
      },
    });
  }

  onBeforeMount(() => {
    SDG.Data.setDisplayItemAdded = (variantId, cb = null, closeCb = null) => {
      displayItemAdded.value = `${variantId}`;

      // init item added app
      fetchCart();

      // callback function
      if (cb) {
        cb(true);
      }

      // stop here if cart template
      if (SDG.Data.template === 'cart') return;

      // remove bag events and init bag drawer again
      SDG.Bag.removeEvents(closeCb);
      SDG.Bag.init();

      // open bag drawer
      SDG.Bag.open(null, closeCb);
    };
  });

  /**
   * Add to Cart By Id
   * @param {String} variantId,
   * @param {Number} qty - optional defaults to 1,
   * @param {Boolean} isWishlist - optional defaults to false,
   * @returns {void} - updates cart state
   */

  const addToCartById = (variantId, properties, qty = 1, isWishlist = false, cb = false) => {
    const postData = {
      items: [
        {
          id: `${variantId}`,
          quantity: qty,
          properties: properties || {},
        },
      ],
    };
    const path = isWishlist ? 'wishlist' : 'cart';

    __.ajax({
      url: `${baseURL}/${path}/add?cid=${cid}`,
      type: 'POST',
      data: JSON.stringify(postData),
      contentType: 'application/json',
      success(data) {
        updateCart(data, isWishlist);

        if (cb) {
          cb(true);
        }
      },
      error(err) {
        error.value = err;
        console.error(err);
      },
    });
  };

  /**
   * Remove Item in Cart By Id
   * @param {Event} e,
   * @param {String} lineItemId,
   * @param {Boolean} isWishlist - optional defaults to false,
   * @param {Boolean} isLoading - optional loading state
   * @param {Function} cb - optional callback function
   * @returns {void} - updates cart state
   */
  const removeCartItemById = (
    e,
    lineItemId,
    isWishlist = false,
    isLoading = null,
    cb = false,
  ) => {
    const path = isWishlist ? 'wishlist' : 'cart';
    const url = `${baseURL}/${path}/remove?cid=${cid}`;

    const item = {
      lineId: `${lineItemId}`,
    };

    if (e?.target) {
      e.target.disabled = true;
    }

    __.ajax({
      url,
      type: 'POST',
      data: JSON.stringify(item),
      contentType: 'application/json',
      success(data) {
        updateCart(data, isWishlist);

        if (isLoading && isLoading === true) {
          isLoading = false;
        }
        if (e?.target) {
          e.target.disabled = false;
        }
        if (cb) {
          cb(true);
        }
      },
      error(err) {
        error.value = err;
        console.error(err);

        if (isLoading && isLoading === true) {
          isLoading = false;
        }
      },
    });
  };

  /**
   * Update Item in Cart By Id
   * @param {Event} e - event object
   * @param {String} lineItemId - item key
   * @param {String} properties - item properties
   * @param {Number} qty - optional defaults to 0
   * @param {Boolean} isWishlist - optional defaults to false
   * @param {Boolean} isLoading - optional loading state
   * @param {Function} cb - optional callback function
   * @returns {void} - updates cart state
   */
  const updateCartItemById = (
    e,
    lineItemId,
    properties,
    qty = 0,
    isWishlist = false,
    isLoading = null,
    cb = false,
  ) => {
    const changeUrl = `${baseURL}/${isWishlist ? 'wishlist' : 'cart'}/change?cid=${cid}`;
    const updateUrl = `${baseURL}/cart/update?cid=${cid}`;
    const { _frame_spec_number: frameSpecNumber } = properties || null;
    const { _bundle_spec_number: bundleSpecNumber } = properties || null;
    const $checkoutBtn = document.querySelector('.js-bag-checkout');
    const item = {
      id: `${lineItemId}`,
      quantity: qty,
      properties: properties || {},
    };

    if (e?.target) {
      e.target.disabled = true;
    }

    if (qty === 0) {
      removeCartItemById(
        e,
        lineItemId,
        isWishlist,
        isLoading,
        cb,
      );
    } else {
      const updates = { [lineItemId]: qty };

      if (frameSpecNumber || bundleSpecNumber) {
        const parentSpecNumber = frameSpecNumber || bundleSpecNumber;

        const childItems = cartItems.value.filter(
          // eslint-disable-next-line no-underscore-dangle
          (cartItem) => cartItem.properties?._parent_design === parentSpecNumber
          // eslint-disable-next-line no-underscore-dangle
          || cartItem.properties?._parent_bundle_spec === parentSpecNumber,
        ) || [];

        if (childItems?.length > 0) {
          childItems.forEach((childItem) => {
            updates[childItem.id] = qty;
          });
        }

        if ($checkoutBtn) {
          $checkoutBtn.setAttribute('data-waiting-for-addons', 'true');
        }
      }

      const updateData = {
        updates,
      };

      const url = Object.keys(updates).length > 1 ? updateUrl : changeUrl;
      const finalData = Object.keys(updates).length > 1 ? updateData : item;

      __.ajax({
        url,
        type: 'POST',
        data: JSON.stringify(finalData),
        contentType: 'application/json',
        success(data) {
          updateCart(data, isWishlist);

          if ($checkoutBtn) {
            $checkoutBtn.removeAttribute('data-waiting-for-addons');
          }

          if (isLoading && isLoading === true) {
            isLoading = false;
          }
          if (e?.target) {
            e.target.disabled = false;
          }

          if (cb) {
            cb(true);
          }
        },
        error(err) {
          error.value = err;
          console.error(err);

          if ($checkoutBtn) {
            $checkoutBtn.removeAttribute('data-waiting-for-addons');
          }

          if (isLoading && isLoading === true) {
            isLoading = false;
          }
        },
      });
    }
  };

  /**
   * Add Upsell Item
   * @param {String} variantId,
   * @param {Number} variantPrice,
   * @param {String} parentProduct - line item id of parent product,
   * @param {Object} parentProductProperties - properties of parent product,
   * @param {Number} qty - optional defaults to 1,
   * @param {Boolean} isWishlist - optional defaults to false,
   * @returns {void} - updates cart state
   */
  const addUpsellItem = (
    variantId,
    variantPrice,
    parentProduct,
    frameSpecId,
    parentProductProperties,
    qty = 1,
    isWishlist = false,
    cb = false,
  ) => {
    const path = isWishlist ? 'wishlist' : 'cart';
    __.ajax({
      url: `${baseURL}/${path}?cid=${cid}`,
      type: 'GET',
      success(cartData) {
        /* eslint-disable no-underscore-dangle */
        const upsellIdList = cartData?.items
          ?.find((item) => item.id === parentProduct)?.properties?._upsell_item_variant_id_list || '';
        const updatedUpsellIdList = addVariantIdIfNotExists(upsellIdList, variantId);
        const upsellPrice = cartData?.items
          ?.find((item) => item.id === parentProduct)?.properties?._upsell_price || 0;

        const itemToAdd = {
          items: [
            {
              id: `${variantId}`,
              quantity: qty,
              properties: {
                _parent_product: parentProduct,
                _parent_design: frameSpecId,
                _upsell_item: 'true',
                _upsell_item_variant_id_list: updatedUpsellIdList,
              },
            },
          ],
        };
        __.ajax({
          url: `${baseURL}/${path}/add?cid=${cid}`,
          type: 'POST',
          data: JSON.stringify(itemToAdd),
          contentType: 'application/json',
          success(addedData) {
            let lastItemAdded;
            let parentToUpdate;

            if (isWishlist && addedData?.length) {
              lastItemAdded = addedData.reduce((lastMatch, item) => {
                if (item?.properties?._upsell_item === 'true') {
                  return item;
                }
                return lastMatch;
              }, null);
            }

            if (!isWishlist && addedData?.items?.length) {
              lastItemAdded = addedData.items.reduce((lastMatch, item) => {
                if (item?.properties?._upsell_item === 'true') {
                  return item;
                }
                return lastMatch;
              }, null);
            }

            // eslint-disable-next-line no-underscore-dangle
            if (lastItemAdded?.properties?._upsell_item === 'true') {
              const parentProps = parentProductProperties || {};
              parentToUpdate = {
                id: `${parentProduct}`,
                properties: {
                  ...parentProps,
                  _upsell_parent: 'true',
                  _upsell_price: `${Number(upsellPrice) + variantPrice}`,
                  _upsell_item_variant_id_list: updatedUpsellIdList,
                },
              };

              __.ajax({
                url: `${baseURL}/${path}/change?cid=${cid}`,
                type: 'POST',
                data: JSON.stringify(parentToUpdate),
                contentType: 'application/json',
                success(data) {
                  updateCart(data, isWishlist);

                  if (cb) {
                    cb(true);
                  }
                },
                error(err) {
                  error.value = err;
                  console.error(err);
                },
              });
            }
          },
          error(err) {
            error.value = err;
            console.error(err);
          },
        });
      },
      error(err) {
        error.value = err;
        console.error(err);
      },
    });
  };

  /**
   * Remove Upsell Item
   * @param {String} variantId,
   * @param {String} parentProduct - line item id of parent product,
   * @param {Object} parentProductProperties - properties of parent product,
   * @param {Number} qty - optional defaults to 0,
   * @param {Boolean} isWishlist - optional defaults to false,
   * @returns {void} - updates cart state
   */
  const removeUpsellItem = (
    variantId,
    parentProduct,
    parentProductProperties,
    qty = 0,
    isWishlist = false,
    cb = false,
    variantPrice = 0,
  ) => {
    const path = isWishlist ? 'wishlist' : 'cart';
    const url = `${baseURL}/${path}/change?cid=${cid}`;

    if (isWishlist) {
      const parentToUpdate = {
        id: `${parentProduct}`,
        properties: {
          ...parentProductProperties,
          _upsell_parent: 'false',
          _upsell_price: '0',
          _upsell_item_variant_id_list: '',
        },
      };

      // API UPDATE OPTIONS
      const parentUpdateOptions = {
        url,
        type: 'POST',
        data: JSON.stringify(parentToUpdate),
        contentType: 'application/json',
        success(data) {
          updateCart(data, isWishlist);
          if (cb) {
            cb(true);
          }
        },
        error(err) {
          error.value = err;
          console.error(err);
        },
      };

      const upsellIdList = variantId.split(',');

      upsellIdList.forEach((id) => {
        const itemToRemove = {
          id,
          quantity: qty,
        };

        const upsellRemoveOptions = {
          url,
          type: 'POST',
          data: JSON.stringify(itemToRemove),
          contentType: 'application/json',
          success() {
            __.ajax(parentUpdateOptions);
          },
          error(err) {
            error.value = err;
            console.error(err);
          },
        };

        // remove upsell variant first
        __.ajax(upsellRemoveOptions);
      });
    } else {
      __.ajax({
        url: `${baseURL}/${path}?cid=${cid}`,
        type: 'GET',
        success(cartData) {
          /* eslint-disable no-underscore-dangle */
          const upsellIdList = cartData?.items
            ?.find((item) => item.id === parentProduct)?.properties?._upsell_item_variant_id_list || '';
          const updatedUpsellIdList = removeVariantIdIfExists(upsellIdList, variantId);
          const upsellPrice = cartData?.items
            ?.find((item) => item.id === parentProduct)?.properties?._upsell_price || 0;

          const itemToRemove = {
            id: `${variantId}`,
            quantity: qty,
          };

          const parentToUpdate = {
            id: `${parentProduct}`,
            properties: {
              ...parentProductProperties,
              _upsell_parent: updatedUpsellIdList ? 'true' : 'false',
              _upsell_price: updatedUpsellIdList ? `${Number(upsellPrice) - variantPrice}` : '0',
              _upsell_item_variant_id_list: updatedUpsellIdList,
            },
          };

          // API UPDATE OPTIONS
          const parentUpdateOptions = {
            url,
            type: 'POST',
            data: JSON.stringify(parentToUpdate),
            contentType: 'application/json',
            success(data) {
              updateCart(data, isWishlist);

              if (cb) {
                cb(true);
              }
            },
            error(err) {
              error.value = err;
              console.error(err);
            },
          };
          const upsellRemoveOptions = {
            url,
            type: 'POST',
            data: JSON.stringify(itemToRemove),
            contentType: 'application/json',
            success() {
              __.ajax(parentUpdateOptions);
            },
            error(err) {
              error.value = err;
              console.error(err);
            },
          };

          // remove upsell variant first
          __.ajax(upsellRemoveOptions);
        },
        error(err) {
          error.value = err;
          console.error(err);
        },
      });
    }
  };

  /**
   * Move Item
   * @param {Event} e,
   * @param {String} lineItemId,
   * @param {Boolean} isWishlist - optional defaults to false,
   * @returns {void} - updates cart state
   */
  const moveItem = (e, lineItemId, isWishlist = false) => {
    if (e?.target) {
      e.target.disabled = true;
    }
    const lineItemData = JSON.stringify({
      items: [lineItemId],
    });
    const path = isWishlist ? 'wishlist/move-to-cart' : 'cart/move-to-wishlist';

    __.ajax({
      url: `${baseURL}/${path}?cid=${cid}`,
      type: 'POST',
      data: lineItemData,
      contentType: 'application/json',
      success(data) {
        updateCart(data, isWishlist);
        // fetch the other cart data that was not just updated
        fetchCart(!isWishlist);
      },
      error(err) {
        error.value = err;
        console.error(err);
      },
    });
  };

  /**
   * Add Drop Off Store to Cart Item by Id
   * @param {Event} e,
   * @param {String} lineItemId
   * @param {Object} parentProductProperties - parent product properties
   * @param {String} storeName - name of drop off store
   * @returns {void} - updates cart state
   */
  const addDropOffStore = (lineItemId, parentProductProperties, storeName) => {
    const url = `${baseURL}/cart/change?cid=${cid}`;
    let item;

    if (!parentProductProperties) {
      item = {
        id: `${lineItemId}`,
        properties: {
          _dropOffAtStore: storeName,
        },
      };
    } else {
      if (parentProductProperties.shipToUs) {
        delete parentProductProperties.shipToUs;
      }
      item = {
        id: `${lineItemId}`,
        properties: {
          ...parentProductProperties,
          _dropOffAtStore: storeName,
        },
      };
    }

    checkoutLoading.value = true;
    __.ajax({
      url,
      type: 'POST',
      data: JSON.stringify(item),
      contentType: 'application/json',
      success(data) {
        updateCart(data);
      },
      error(err) {
        error.value = err;
        console.error(err);
        checkoutLoading.value = false;
      },
    });
  };

  /**
   * Add Ship To Us to Cart Item by Id
   * @param {Event} e,
   * @param {String} lineItemId
   * @param {Object} parentProductProperties - parent product properties
   * @returns {void} - updates cart state
   */
  const addShipToUs = (lineItemId, parentProductProperties, labelOption) => {
    const url = `${baseURL}/cart/change?cid=${cid}`;
    let item;

    if (!parentProductProperties) {
      item = {
        id: `${lineItemId}`,
        properties: {
          shipToUs: labelOption,
        },
      };
    } else {
      // eslint-disable-next-line
      if (parentProductProperties._dropOffAtStore) {
        // eslint-disable-next-line
        delete parentProductProperties._dropOffAtStore;
      }
      item = {
        id: `${lineItemId}`,
        properties: {
          ...parentProductProperties,
          shipToUs: labelOption,
        },
      };
    }
    checkoutLoading.value = true;
    __.ajax({
      url,
      type: 'POST',
      data: JSON.stringify(item),
      contentType: 'application/json',
      success(data) {
        updateCart(data);
      },
      error(err) {
        error.value = err;
        console.error(err);
        checkoutLoading.value = false;
      },
    });
  };

  /**
   * Add Cart Attributes
   * @param {Object} giftNoteAttributes - gift note attributes
   */
  const addCartAttributes = (giftNoteAttributes, cb = false) => {
    const postData = {
      attributes: {
        ...cartAttributes.value,
        ...giftNoteAttributes,
      },
    };

    __.ajax({
      url: `${baseURL}/cart/update?cid=${cid}`,
      type: 'POST',
      data: JSON.stringify(postData),
      contentType: 'application/json',
      success(data) {
        updateCart(data);

        if (cb) {
          cb(true);
        }
      },
      error(err) {
        error.value = err;
        console.error(err);
      },
    });
  };

  const handleGWPandShippingFee = async () => {
    // Ensure we have the most up to date cart data
    const cartData = await fetch(`${baseURL}/cart?cid=${cid}`).then((res) => res.json());
    const gwpEnabled = SDG.Data.gwp.enabled;
    // eslint-disable-next-line no-underscore-dangle
    const gwpItem = cartData?.items?.find((item) => item?.properties?._is_gwp === 'true');
    const gwpVariant = SDG.Data.gwp.variantId;
    const threshold = parseInt(SDG.Data.gwp.threshold, 10);
    let meetsGWPThreshold = false;

    if (
      SDG.Data.gwp.eligibleProductsCollection
      && SDG.Data.gwp.eligibleProductsCollection.length > 0
    ) {
      const eligibleLines = cartData?.items.filter((item) => {
        // We will get these from eligible parents parent
        if (item.product_type === 'Addition') return false;
        // Check if product is in eligible collection
        const collectionProduct = SDG.Data.gwp.eligibleProductsCollection.find(
          (collectionItem) => collectionItem.id === parseInt(item.product_id, 10),
        );
        return !!collectionProduct;
      });
      const addOnItems = cartData?.items.filter((cItem) => cItem.product_type === 'Addition');
      const eligibleLinesSubtotal = eligibleLines.reduce((sTotal, line) => {
        // eslint-disable-next-line no-underscore-dangle
        const lineFS = line.properties?._frame_spec_number;
        // find products that should be included as addtions
        const additions = addOnItems.filter(
          // eslint-disable-next-line no-underscore-dangle
          (aoi) => aoi.properties?._parent_design === lineFS,
        );
        const additionsSubtotal = additions.reduce(
          (sub, addition) => sub + addition.final_line_price,
          0,
        );
        return sTotal + line.final_line_price + additionsSubtotal;
      }, 0);

      meetsGWPThreshold = eligibleLinesSubtotal >= threshold;
    } else {
      meetsGWPThreshold = cartData?.items_subtotal_price >= threshold;
    }

    // gwp add
    if (gwpEnabled && !gwpItem && gwpVariant && meetsGWPThreshold) {
      try {
        const res = await fetch(`${baseURL}/cart/add?cid=${cid}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            items: [
              {
                id: gwpVariant,
                quantity: 1,
                properties: {
                  _is_gwp: 'true',
                },
              },
            ],
          }),
        });
        const newData = await res.json();

        updateCart(newData);
      } catch (err) {
        error.value = err;
        console.error(err);
      }
    }

    // gwp remove
    if (gwpItem && (!meetsGWPThreshold || !gwpEnabled)) {
      try {
        const res = await fetch(`${baseURL}/cart/change?cid=${cid}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: gwpItem.id,
            quantity: 0,
          }),
        });
        const newData = await res.json();

        updateCart(newData);
      } catch (err) {
        error.value = err;
        console.error(err);
      }
    }

    // add shipping fee product
    const { cartSummaryMailInServiceProductVariantId } = SDG.Data.cartThemeSettings;
    // eslint-disable-next-line max-len
    const shippingFeeProduct = cartData?.items?.find((item) => item?.properties?._is_shipping_fee === 'true' || item?.variant_id === cartSummaryMailInServiceProductVariantId);
    const itemsWithShipToUs = cartData?.items?.filter((item) => item?.properties?.shipToUs && item?.variant_options?.includes('Physical') && item?.product_type !== 'Gift Card');
    if (itemsWithShipToUs?.length > 0 && !shippingFeeProduct && cartSummaryMailInServiceProductVariantId !== '') {
      // add fee
      try {
        const res = await fetch(`${baseURL}/cart/add?cid=${cid}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            items: [
              {
                id: cartSummaryMailInServiceProductVariantId,
                quantity: 1,
                properties: {
                  _is_shipping_fee: 'true',
                  _parent_design: 'MAIl_IN_SERVICE_SHIPPING_FEE',
                },
              },
            ],
          }),
        });
        const newData = await res.json();

        updateCart(newData);
      } catch (err) {
        error.value = err;
        console.error(err);
      }
    } else if (itemsWithShipToUs?.length === 0 && shippingFeeProduct) {
      // remove fee
      try {
        const res = await fetch(`${baseURL}/cart/change?cid=${cid}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: shippingFeeProduct.id,
            quantity: 0,
          }),
        });
        const newData = await res.json();

        updateCart(newData);
      } catch (err) {
        error.value = err;
        console.error(err);
      }
    }
    checkoutLoading.value = false;
  };

  /**
   * clone and add to cart
   * @param {Object} FrameSpec - frame spec object
   */
  async function cloneAndAddToCart(frameSpecItem, cb = false) {
    try {
      const newFrameSpec = await frameSpecItem.duplicate();
      const cartUrlHost = SDG.Data.cartHost;

      if (newFrameSpec) {
        await newFrameSpec?.addToCart({ cartHost: cartUrlHost });
        fetchCart();
      }
    } catch (e) {
      console.error({ message: 'Error building FrameSpec', e });
    } finally {
      if (cb) {
        cb(true);
      }
    }
  }

  /**
   * Add variant id to upsell id list if it doesn't exist
   * @param {String} upsellIdList - comma separated list of variant ids
   * @param {String} variantId - variant id to add to the list
   */
  function addVariantIdIfNotExists(upsellIdList, variantId) {
    // Handle undefined upsellIdList by treating it as an empty string
    if (!upsellIdList) {
      upsellIdList = '';
    }

    const idsArray = upsellIdList.split(',').filter((id) => id);
    if (!idsArray.includes(variantId.toString())) {
      idsArray.push(variantId.toString());
    }

    const result = idsArray.join(',');
    return result;
  }

  /**
   * Remove variant id from upsell id list if it exists
   * @param {String} upsellIdList - comma separated list of variant ids
   * @param {String} variantId - variant id to remove from the list
   */
  function removeVariantIdIfExists(upsellIdList, variantId) {
    // Handle undefined upsellIdList by treating it as an empty string
    if (!upsellIdList) {
      upsellIdList = '';
    }

    const idsArray = upsellIdList.split(',').filter((id) => id);
    const index = idsArray.indexOf(variantId.toString());
    if (index !== -1) {
      idsArray.splice(index, 1);
    }

    const result = idsArray.join(',');
    return result;
  }

  return {
    cart,
    cartAttributes,
    cartCount,
    cartItems,
    cartLoading,
    checkoutLoading,
    cartTotalPrice,
    checkoutUrl,
    itemsSubtotalPrice,
    currency,
    displayItemAdded,
    error,
    cartThemeSettings,
    fetchCart,
    addToCartById,
    addUpsellItem,
    removeUpsellItem,
    removeCartItemById,
    updateCartItemById,
    addDropOffStore,
    addShipToUs,
    addCartAttributes,
    fetchWishlist: () => fetchCart(true),
    wishlist,
    wishlistLoading,
    wishlistError,
    moveItem,
    cloneAndAddToCart,
  };
}

export default useCart;
