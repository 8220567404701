import { createApp } from 'vue';
import ItemAdded from '../components/item-added/ItemAdded';

function itemAddedApp(opts) {
  const config = {
    dom: {
      parent: 'bagApp',
    },
  };

  const c = __.extend(config, opts);

  // Globals
  const $parent = document.getElementById(c.dom.parent);
  function init() {
    if (!$parent) return;

    createApp(ItemAdded).mount(`#${c.dom.parent}`);
  }

  init();
}

export default itemAddedApp;
