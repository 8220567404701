import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

/**
 * Manifesto
 * @param {object} opts
 */

function manifesto(opts) {
  const config = {
    dom: {
      main: 'manifesto',
      footer: 'js-manifesto-footer',
      image: 'js-manifesto-image',
      item: 'manifesto-item',
      text: 'js-manifesto-text',
    },
    cls: {
      active: 'is-active',
      desktop_only: 'desktop-only',
      faded: 'is-faded',
      hide_desktop: 'hide-desktop',
      hide_mobile: 'hide-mobile',
      highlighted: 'is-highlighted',
      loading: 'is-loading',
      mobile_only: 'mobile-only',
      scrolled: 'is-scrolled',
      typewriter_active: 'has-typewriter-active',
    },
    speed: 1500,
    manifesto: [
      {
        text: 'places you\'ve been and the things you picked up along the way',
        image: 'https://cdn.shopify.com/s/files/1/0727/7196/1122/files/manifesto-key.png?v=1693438395',
      },
      {
        text: 'dogs',
        image: 'https://cdn.shopify.com/s/files/1/0727/7196/1122/files/manifesto-dog.jpg?v=1693438395',
      },
      {
        text: 'big moments',
        image: 'https://cdn.shopify.com/s/files/1/0727/7196/1122/files/manifesto-medal.png?v=1693438400',
      },
      {
        text: 'Kid\'s art',
        image: 'https://cdn.shopify.com/s/files/1/0727/7196/1122/files/manifesto-plate.png?v=1693438404',
      },
      {
        text: 'celebrate',
        image: 'https://cdn.shopify.com/s/files/1/0727/7196/1122/files/manifesto-wedding.jpg?v=1693438401',
      },
    ],
  };

  // extend config
  const c = __.extend(config, opts);

  // global elements
  const $wrappers = document.body.querySelectorAll(`.${c.dom.main}`);
  let view;

  /**
   * Init
   */
  function init() {
    if (!$wrappers?.length) return;
    onWindowResize();
    __.windowResize(onWindowResize);
  }

  /**
   * On Window Resize
   */
  function onWindowResize() {
    // desktop
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          resetScrollClass();
          addScrollEvents();

          // desktop only
          runScrollTrigger();
        }
      },
    });

    // mobile
    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
          resetScrollClass();
          addScrollEvents();
        }
      },
    });
  }

  /**
   * Add Scroll Events
   */
  function addScrollEvents() {
    __.addEvent({
      id: document,
      event: 'scroll',
      fn: () => {
        __.debounce(handleScrollEvent(), 100);
      },
    });

    // trigger initially on page load, before scroll
    handleScrollEvent();
  }

  /**
   * Reset Scroll Class
   */
  function resetScrollClass() {
    [...$wrappers].forEach(($wrapper) => {
      __.removeClass($wrapper, c.cls.scrolled);
    });
  }

  /**
   * Handle Scroll Event
   * @param {Boolean} isPageLoad
   */
  function handleScrollEvent() {
    [...$wrappers].forEach(($wrapper) => {
      handleMobileFooter($wrapper);
      handleTyperwriterText($wrapper);
    });
  }

  /**
   * Handle Mobile Footer
   * @param {Element} $wrapper
   */
  function handleMobileFooter($wrapper) {
    if (__.hasClass($wrapper, c.cls.scrolled)) return;

    const $footer = $wrapper.querySelector(`.${c.dom.footer}`);
    const { bottom } = $wrapper.getBoundingClientRect();
    if (bottom > window.innerHeight) return;

    if ($footer) __.addClass($footer, c.cls.scrolled);
    __.addClass($wrapper, c.cls.scrolled);
  }

  /**
   * Handle Mobile Footer
   * @param {Element} $wrapper
   */
  function handleTyperwriterText($wrapper) {
    if (__.hasClass($wrapper, c.cls.typewriter_active)) return;

    const offset = window.innerHeight / 2;
    const { top, bottom } = $wrapper.getBoundingClientRect();
    const sectionInView = top + offset - window.innerHeight <= 0 && bottom >= 0;
    if (!sectionInView) return;

    // init typewriter and prevent from occurring again
    __.typewriter({
      wrapper: $wrapper,
      callback: () => {
        handleManifestoAnimation($wrapper);
      },
    });
    __.addClass($wrapper, c.cls.typewriter_active);
  }

  /**
   * Run ScrollTrigger
   */
  function runScrollTrigger() {
    const mm = gsap.matchMedia();
    gsap.registerPlugin(ScrollTrigger);

    mm.add('(min-width: 1024px)', () => {
      [...$wrappers].forEach(($wrapper) => {
        const trigger = $wrapper;
        const scrollTrigger = {
          trigger,
          scrub: 0.5,
          start: 'top 30px',
          pin: true,
          anticipatePin: 1,
        };

        ScrollTrigger.create(scrollTrigger);
      });
    });
  }

  /**
   * Handle Manifesto Animation
   * @param {Element} $wrapper
   */
  function handleManifestoAnimation($wrapper) {
    const $desktopImage = $wrapper.querySelector(`.${c.dom.image}`);

    // mobile just reveals footer
    if (view === 'mobile') {
      const $footer = $wrapper.querySelector(`.${c.dom.footer}`);
      if (!$footer) return;
      setTimeout(() => __.addClass($footer, c.cls.active), 500);
      return;
    }

    // get view specific manifesto text
    const $manifestoText = $wrapper.querySelectorAll(`.${c.dom.text}`);
    const $viewManifestoText = [...$manifestoText].filter(($text) => {
      const isDesktopOnly = __.hasClass($text, c.cls.hide_mobile)
        || __.hasClass($text, c.cls.desktop_only);
      const isMobileOnly = __.hasClass($text, c.cls.hide_desktop)
        || __.hasClass($text, c.cls.mobile_only);
      const doesNotMatchView = (view === 'mobile' && isDesktopOnly)
        || (view === 'desktop' && isMobileOnly);
      return !doesNotMatchView;
    });

    // highlight desktop text and display images (hardcoded)
    $viewManifestoText.forEach(($text) => {
      let globalTimeout = c.speed * -0.5;

      c.manifesto.forEach((manifestoObj, index) => {
        const { text, image } = manifestoObj;
        if (!$text.innerHTML.includes(text)) return;

        // increment global timeout
        globalTimeout += c.speed;

        setTimeout(() => {
          const textHTML = $text.innerHTML;
          const textLength = text.length;
          const textStartIndex = textHTML.indexOf(text);
          const textEndIndex = textStartIndex + textLength;
          const textStart = textHTML.slice(0, textStartIndex);
          const textEnd = textHTML.slice(textEndIndex);
          const imageClass = `image-${index + 1}`;

          // parse new text HTML
          const newText = `${textStart}<span class="${c.dom.item} ${c.cls.highlighted}">${text}</span>${textEnd}`;

          // render new text HTML
          $text.innerHTML = newText;
          __.addClass($text, c.cls.faded);

          // display image for item
          if ($desktopImage && image) {
            const imageHTML = `
              <img
                class="lazyload"
                data-src="${image}"
                data-widths="[180,360,540]"
                data-sizes"auto"
                tabindex="-1"
                alt="Image ${index + 1} for highlighted text: ${text}"
              >
            `;
            __.addClass($desktopImage, imageClass);
            __.addClass($desktopImage, c.cls.loading);
            $desktopImage.innerHTML = imageHTML.trim();
          }

          // reset content after timeout
          setTimeout(() => {
            $text.innerHTML = textHTML;
            __.removeClass($text, c.cls.faded);

            if ($desktopImage) {
              __.removeClass($desktopImage, imageClass);
              $desktopImage.innerHTML = '';
            }
          }, c.speed - 50);
        }, globalTimeout);
      });
    });
  }

  return init();
}

export default manifesto;
